import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { PendoService } from 'src/app/services/pendo/pendo.service';
import { UserTimezoneService } from 'src/app/services/user-timezone/user-timezone.service';
import { environment } from 'src/environments/environment';
import { AuthService } from '../../services/auth/auth.service';
import { CookieService } from 'ngx-cookie-service'; 

@Component({
  selector: 'app-redeem-code',
  templateUrl: './redeem-code.component.html',
  styleUrls: ['./redeem-code.component.scss']
})
export class RedeemCodeComponent implements OnInit {

  // Gets which of both codes is available and will be use to get an access
  unique_code = '';
  url_redirect: string = '';
  providerInfo: ProviderInfo = { name: undefined, redirect: "" };
  cookieTestKey: string = "TariyTestCookie";

  constructor(private activatedRoute: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
    private toastr: ToastrService,
    private pendoService: PendoService,
    private userTimezoneService: UserTimezoneService,
    private cookieService: CookieService) {

  }// constructor ends

  ngOnInit() {
    // Gets authorization code, from Google or Microsoft   
    this.activatedRoute.queryParams.subscribe(params => {
      if (this.unique_code = params['code']) {
        this.providerInfo.name = 'Microsoft';
        this.providerInfo.redirect = environment.microsoft_redirect;

      } else if (this.unique_code = params['googlecode']) {
        this.providerInfo.name = 'Google';
        this.providerInfo.redirect = environment.url_path;

      } else if (this.unique_code = params['sassFormCode']) {
        const cookieToken  = this.getSassFormTokenFromCookie(this.unique_code);
        this.providerInfo.name = 'SassForm';
        this.providerInfo.redirect = 'SASSFORM';
        console.log(`SASSFORM CODE AFTER GET VALUE = ${this.unique_code}`);
        //https://docs.saasform.dev/start/integrate-user-authentication
      } else if (this.unique_code = params['token']) {
        const cookieToken  = this.getSassFormTokenFromCookie(this.unique_code);
        this.providerInfo.name = 'SassForm';
        this.providerInfo.redirect = 'TOKEN';
        console.log(`SASSFORM CODE AFTER GET VALUE = ${this.unique_code}`);
        //https://docs.saasform.dev/start/integrate-user-authentication
      } else {
        this.providerInfo.name = 'SassForm';
        this.providerInfo.redirect = 'ELSE';
      }
    });
    
    this.unique_code = this.unique_code ||"TEST SASSFORM";
    if (this.unique_code) {
      this.sendRedeemCode();
    } else { // if at least a code exists, then ask for an access
      this.invalidCode('Provider error', 'No code provided');
    }
  }

  // Method that ask to auth service for a valid access
  sendRedeemCode(): void {
    let provider = this.providerInfo;
    this.authService.login(this.unique_code, provider.name, provider.redirect).subscribe(response => {
      this.userTimezoneService.updateTimezone().then(() => {
        this.pendoService.initialize(response);
        this.router.navigate(["/admin"]);
      });
    }, err => {
      this.invalidCode('Code authorization', "Invalid code provided");
    });
  }

  /**
   ************* REDEM CODE FOR DEVELOPMENT PURPORSE **********************
  */
  sendRedeemCodeForTesting(): void {
    let loggedUser = this.authService.currentUserValue;
    if (!loggedUser.organizationOnboarding && loggedUser.role == 'Admin') {// First time, first user
      this.router.navigateByUrl('/admin/onboarding-invited');
    } else if (!loggedUser.userOnboarding) {// Organization registered, but first time for this user
      this.router.navigateByUrl('/admin/onboarding-organization');
    } else {// User and his organizated already registered
    }
  }

  /*
  * Method that returns the login if the code provided was invalid
  *  @param title_message - Title of the toast message
  *  @param message_error - Description of the error
  */
  invalidCode(title_message: string, message_error: string): void {
    this.router.navigate(['/login']);
    this.toastr.error(title_message, message_error, {
      disableTimeOut: false,
      closeButton: true,
      positionClass: 'toast-top-right',
      toastClass: 'toast-margin ngx-toastr'
    });
  }

  getSassFormTokenFromCookie(paramToken:string): string {
    try {
      if (this.cookieService.check(this.cookieTestKey)) {
        let cookieTestValue = this.cookieService.get(this.cookieTestKey);
        console.log("The value of the cookie is ", cookieTestValue);
        console.log(".");
      }
      else {
        console.log("COOKIE NOT FOUND");
      }

      const cookieKey = "__session";
      let cookieToken = this.cookieService.get(cookieKey);
      console.log(cookieToken);

      const asterisk = 'TestAsterisc';
      const dot = 'TestDot';
      const empty = 'TestEmpty';
      const bearer = "TestBearer";

      this.cookieService.set(asterisk, 'asteriskValue', 15000, '/', '*.tariy.ai');
      this.cookieService.set(dot, 'dotValue', 15000, '/', '.tariy.ai');
      this.cookieService.set(empty, 'emptyValue', 15000, '/', 'tariy.ai');
      this.cookieService.set(bearer, 'bearerValue');

      const asteriskValue = this.cookieService.get(asterisk);
      const dotValue = this.cookieService.get(dot);
      const emptyValue = this.cookieService.get(empty);
      const bearerValue = this.cookieService.get(bearer);
      const values = `
        asteriskValue=${asteriskValue},
        dotValue=${dotValue},
        emptyValue=${emptyValue},
        bearerValue=${bearerValue},
        paramToken=${paramToken},
        cookieToken=${cookieToken},
        `;
      console.log(values);

      alert(values);

      console.log("All")
      console.log("The value of ALLcookie are ", this.cookieService.getAll());
      console.log("All")

      if (cookieToken) {
        console.log("El usuario esta autenticado con id: " + cookieToken);
      } else {
        console.log('Sassform token not found');
      }
      return cookieToken || paramToken;
    } catch (error) {
      console.error(`error in sassform code=${error}`);
      alert(`error in sassform code=${error}`); 
    }
    return paramToken;
  }
}

// Interface for ProviderInfo
interface ProviderInfo {
  name: string;
  redirect: string
}
