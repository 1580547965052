import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Globals } from 'src/app/global';

@Injectable({
  providedIn: 'root'
})
export class ZoomMeetingService {
  // variable that store the import of Globals Class that will help us to retrieve the urls for every service
  globals = Globals;
  constructor(private http: HttpClient) { }

  async getZoomEvent(meetingUrl): Promise<object> {
    const url = `${this.globals.zoom.meeting}`;
    //const url = `https://api.tariy.ai/zoom-oauth/zoom-app/meeting-details`;
    return await this.http.post(`${url}`, {url: meetingUrl}).toPromise();
  }

  async storeMeeting(meeting): Promise<object> {
    const url = `${this.globals.zoom.save}`;
    //const url = `https://api.tariy.ai/zoom-oauth/zoom-app/store-details`;
      return await this.http.post(`${url}`, meeting).toPromise();
    }

  async addTimezone(meeting): Promise<object> {
    const url = `${this.globals.zoom.timezone}`;
    //const url = `https://api.tariy.ai/zoom-oauth/zoom-app/store-details`;
      return await this.http.post(`${url}`, meeting).toPromise();
    }

  async addJob(meeting): Promise<object> {
    const url = `${this.globals.zoom.jobTitle}`;
    //const url = `https://api.tariy.ai/zoom-oauth/zoom-app/store-details`;
      return await this.http.post(`${url}`, meeting).toPromise();
    }

  async syncMeeting(meetingId): Promise<object> {
    const url = `${this.globals.zoom.sync}`;
    //const url = `https://api.tariy.ai/zoom-oauth/zoom-app/store-details`;meetingUUID
      return await this.http.post(`${url}`, {meetingUUID: meetingId}).toPromise();
    }

  async syncSpeaker(meetingId, name, age, gender, communication): Promise<object> {
    const url = `${this.globals.zoom.syncSpeaker}`;
    let toSync = {
      meetingUUID: meetingId,
      speakerName: name,
      age: age,
      gender: gender,
      race: 7,
      communication: communication,
    }
    //const url = `https://api.tariy.ai/zoom-oauth/zoom-app/store-details`;meetingUUID
      return await this.http.post(`${url}`, toSync).toPromise();
    }

  async syncTitle(meetingId, name, jobTitle): Promise<object> {
    const url = `${this.globals.zoom.syncSpeaker}`;
    let toSync = {
      meetingUUID: meetingId,
      speakerName: name,
    }
    //const url = `https://api.tariy.ai/zoom-oauth/zoom-app/store-details`;meetingUUID
      return await this.http.post(`${url}`, toSync).toPromise();
    }

  async syncAnswers(meetingId, name, agenda, effective, inclusive): Promise<object> {
    const url = `${this.globals.zoom.syncAnswers}`;
    let toSync = {
      meetingUUID: meetingId,
      speakerName: name,
      agenda: agenda,
      effective: effective,
      inclusive: inclusive,
    }
    //const url = `https://api.tariy.ai/zoom-oauth/zoom-app/store-details`;meetingUUID
      return await this.http.post(`${url}`, toSync).toPromise();
  }
}
