<!-- <div class="d-flex carousel-header" (click)="openInsights()">
  <p class="carousel-title">{{title}}</p>
  <div class="estimated" [ngClass]="dataClass" style="margin-left: auto;">
    <img [src]="'../../../assets/img/' + icon + '.svg'" class="estimated" style="margin-left: auto;" />
  </div>
</div>

<div class="carousel-middle">
  <p class="description">{{dataTitle}}</p>
  <p class="amount" [ngClass]="dataClass">{{ amount }}</p>
</div>
<div class="description" *ngIf="!(loading | async)">
  <span>Compare to<b class="ml-1">{{range}}</b></span>
  <span *ngIf="hasCompareData; else noCostData" style="flex-wrap: wrap;">
    <span class="mr-1 mb-0 font-weight-bold" [ngClass]="dataClass">{{yesterdayAmount }}</span>
    <span class="ml-1 mr-1 mb-0 font-weight-bold" [ngClass]="isIncrement ? 'increment': 'decrement'"><fa-icon [icon]="arrowIcon" class="pl-1"></fa-icon></span>
    <span class="ml-1 mr-1 mb-0 font-weight-bold" [ngClass]="isIncrement ? 'increment': 'decrement'">({{ ceiler(((total/yesterdayTotal) * 100) - 100)}}%)</span>
  </span>
  <ng-template #noCostData>
    <span style="flex-wrap: wrap;">No data</span>
  </ng-template>
</div> -->


<!-- segunda version -->
<div class="card">
  <div class="header">
      <div class="title carousel-header">
          <div [ngClass]="dataClass" class="mr-1 d-flex align-items-center justify-content-center">
            <img [src]="'../../../assets/img/' + icon + '.svg'" class="m-0"/>
          </div>
          <div class="text" (click)="openInsights()">{{ title }}</div>
      </div>
      <div class="type">
          <span class="info-svg"
              (click)="openInsights()"></span>
      </div>
  </div>
  <div class="body">
      <div class="dates">
          <div class="startDate">
              <div class="date">
                  {{ dataTitle }}
              </div>

              <div class="total" [ngClass]="dataClass">
                  {{ amount }}
              </div>
          </div>

          <div class="endDate">
              <div class="date">
                  {{ range }}
              </div>

              <div class="total" [ngClass]="dataClass">
                  {{ yesterdayAmount }}
              </div>
          </div>
      </div>
      <div class="footer">
          <div class="row">
              <div class="col-8 pr-0 d-flex">
                  <span
                      class="totalDiff relative-percentage pr-0 d-flex w-100" [ngClass]="isIncrement ? 'increment': 'decrement'">
                      {{ difference }}
                      
                  </span>
              </div>
              <div class="col-4 text-right pl-0" *ngIf="hasCompareData; else noCostData">
                  <span class="totalDiff relative-percentage" [ngClass]="isIncrement ? 'increment': 'decrement'" >
                    <span class="ml-auto mr-1 mb-0 font-weight-bold"><fa-icon [icon]="arrowIcon" class="pl-1"></fa-icon></span>
                      {{ ceiler(((total/yesterdayTotal) * 100) - 100)}}%
                  </span>
              </div>
              <ng-template #noCostData >
                <div class="col-4 text-right pl-0">
                  <span class="totalDiff relative-percentage">N/A</span>
                </div>
              </ng-template>
          </div>
      </div>
  </div>
</div>