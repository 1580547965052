<div class="col-12">
  <div class="modalNote d-none d-xl-block d-lg-block">
    Here you can select the parameters for your team nudges (if none is defined your team will use TARIY default parameters).
  </div>
  <form class="frmGeneral" [formGroup]="nudgeForm">
    <div class="form-row justify-content-center">
      <div class="form-group col-6 col-md-6 justify-content-center d-inline-grid" style="display: inline-grid;">
        <label for="startingTime">Starting Time
          <fa-icon [icon]="describe" class="tipIcon pl-4"
            popper="At what time does your workday begins"
            [popperShowOnStart]="false"
            [popperTrigger]="'hover'"
            [popperHideOnScroll]="true"
            [popperPlacement]="'top'">
          </fa-icon>
        </label>
        <input matTimepicker id="startingTime" mode="12h" class="form-control" color="time-theme" formControlName="startingTime">
      </div>
      <div class="form-group col-6 col-md-6 justify-content-center d-inline-grid" style="display: inline-grid;">
        <label for="dayEnd">Ending Time
          <fa-icon [icon]="describe" class="tipIcon pl-4"
            popper="At what time does your workday ends"
            [popperShowOnStart]="false"
            [popperTrigger]="'hover'"
            [popperHideOnScroll]="true"
            [popperPlacement]="'top'">
          </fa-icon>
        </label>
        <input matTimepicker id="dayEnd" mode="12h" class="form-control" color="time-theme" formControlName="dayEnd">
      </div>
    </div>
  
    <div class="form-row justify-content-center">
      <div class="form-group col-6 col-md-6 justify-content-center d-inline-grid" style="display: inline-grid;">
        <label for="lunchStart">Lunch Starts
          <fa-icon [icon]="describe" class="tipIcon pl-4"
            popper="At what time does your lunch break starts"
            [popperShowOnStart]="false"
            [popperTrigger]="'hover'"
            [popperHideOnScroll]="true"
            [popperPlacement]="'top'"
            [popperModifiers]="modifiers">
          </fa-icon>
        </label>
        <input matTimepicker id="lunchStart" mode="12h" class="form-control" color="time-theme" formControlName="lunchStart">
      </div>
      <div class="form-group col-6 col-md-6 justify-content-center d-inline-grid" style="display: inline-grid;">
        <label for="lunchEnd">Lunch Ends
          <fa-icon [icon]="describe" class="tipIcon pl-4"
            popper="At what time does your lunch break ends"
            [popperShowOnStart]="false"
            [popperTrigger]="'hover'"
            [popperHideOnScroll]="true"
            [popperPlacement]="'top'">
          </fa-icon>
        </label>
        <input matTimepicker id="lunchEnd" mode="12h" class="form-control" color="time-theme" formControlName="lunchEnd">
      </div>
    </div>
  
    <div class="form-row justify-content-center">
      <div class="form-group col-12 col-md-6">
        <label for="strikes">Number of strikes to send nudge
          <fa-icon [icon]="describe" class="tipIcon pl-4"
            popper="How many times will a nudge needs to be triggered to send the nudge notification"
            [popperShowOnStart]="false"
            [popperTrigger]="'hover'"
            [popperHideOnScroll]="true"
            [popperPlacement]="'top'">
          </fa-icon>
        </label>
        <input type="number" id="strikes"class="form-control w-50" formControlName="strikes">
      </div>
    </div>
    <div class="form-row justify-content-center">
      <div class="form-group col-12 col-md-6">
        <label for="meetingPercentage">Meeting Percentage
          <fa-icon [icon]="describe" class="tipIcon pl-4"
            popper="Maximum percentage of your workday to have meetings scheduled"
            [popperShowOnStart]="false"
            [popperTrigger]="'hover'"
            [popperHideOnScroll]="true"
            [popperPlacement]="'top'">
          </fa-icon>
        </label>
        <input type="number" id="meetingPercentage"class="form-control w-50" formControlName="meetingPercentage">
      </div>
    </div>
    <div class="form-row justify-content-center">
      <div class="form-group col-12 col-md-6">
        <label for="meetingPercentage">Minimun Score
          <fa-icon [icon]="describe" class="tipIcon pl-4"
            popper="Minimum TARIY Score to receive per meeting"
            [popperShowOnStart]="false"
            [popperTrigger]="'hover'"
            [popperHideOnScroll]="true"
            [popperPlacement]="'top'">
          </fa-icon>
        </label>
        <input type="number" id="meetingPercentage"class="form-control w-50" formControlName="meetingPercentage">
      </div>
    </div>
    <div class="form-row justify-content-center">
      <div class="form-group col-12 col-md-6">
        <label for="meetingPercentage">Percentage of meetings without agenda
          <fa-icon [icon]="describe" class="tipIcon pl-4"
            popper="Maximum percentage of meetings allowed to have without agenda"
            [popperShowOnStart]="false"
            [popperTrigger]="'hover'"
            [popperHideOnScroll]="true"
            [popperPlacement]="'top'">
          </fa-icon>
        </label>
        <input type="number" id="meetingPercentage"class="form-control w-50" formControlName="meetingPercentage">
      </div>
    </div>
    <div class="form-row justify-content-center">
      <div class="form-group col-12 col-md-6">
        <label for="meetingPercentage">Minimun Participation Percentage 
          <fa-icon [icon]="describe" class="tipIcon pl-4"
            popper="Any participation percentage lower than this will trigger a nudge response"
            [popperShowOnStart]="false"
            [popperTrigger]="'hover'"
            [popperHideOnScroll]="true"
            [popperPlacement]="'top'">
          </fa-icon>
        </label>
        <input type="number" id="meetingPercentage"class="form-control w-50" formControlName="meetingPercentage">
      </div>
    </div>
    <div class="form-row justify-content-center">
      <div class="form-group col-12 col-md-6">
        <label for="meetingPercentage">Negative Sentiment
          <fa-icon [icon]="describe" class="tipIcon pl-4"
            popper="Maximum percentage of negative sentiments allowed in meetings"
            [popperShowOnStart]="false"
            [popperTrigger]="'hover'"
            [popperHideOnScroll]="true"
            [popperPlacement]="'top'">
          </fa-icon>
        </label>
        <input type="number" id="meetingPercentage"class="form-control w-50" formControlName="meetingPercentage">
      </div>
    </div>
        <!-- <div class="form-group col-12 col-md-2 offset-md-4 d-flex align-items-center justify-content-end mb-0">
            <button type="submit" class="btn btn-primary w-100" (click)="saveOrganizationInfo()">{{!isSavingOrganization ? 'SAVE CHANGES' : 'SAVING...'}}</button>
        </div> -->
  </form>
</div>
