<section class="dashboard pb-3 pb-lg-0 pb-xl-0">
    <div class="row">
      <div class="col-12 col-lg-12 col-xl-12 p-0">
        <div class="row mt-2 mb-2">
          <div class="col-12 col-lg-4 d-flex align-items-center">
            <div class="row">
              <div class="col">
                <div class="title-message d-none">How did you do this week?</div>
              </div>
            </div>          
          </div>
          <div class="col-12 col-md-3 d-flex align-items-center justify-content-center flex-column flex-lg-row flex-xl-row">
            <h3 class="page-title mb-2 mb-lg-0 mb-xl-0 mr-4 text-center"><b >Meeting Score&reg;</b></h3>
            <svg width="80" height="80" viewBox="0 0 80 80" class="progress__svg">
              <circle
                [attr.r]="radius"
                cx="40"
                cy="40"
                stroke-width="12"
                class="progress__value"
              >
              </circle>
              <circle
                [style.strokeDasharray]="circumference"
                [style.strokeDashoffset]="dashoffset"
                [attr.r]="radius"
                cx="40"
                cy="40"
                stroke-width="13"
                class="progress__meter"
              >
              </circle>
              <text x="27" y="-35" class="progress__text" *ngIf="!checkNumericScore()">{{ score | number: '1.0-0' }}</text>
              <text x="23" y="-33" class="progress__text" *ngIf="checkNumericScore()">N/A</text>
            </svg>
          </div>
          <div class="col-12 col-sm-6 col-lg-2 mb-sm-3">
            <div class="row w-100">
              <label for="daterange" class="col col-form-label text-right mt-3 ml-auto">Filter by date:</label>
            </div>
          </div>
          <div class="col-12 col-sm-6 col-lg-3 mb-3 mb-lg-0 pl-lg-0 d-flex align-items-center">
            <div class="row w-100">
              <div class="col daterange-input mx-3 mx-lg-0">
                <input type="text" name="daterange" ngxDaterangepickerMd class="form-control daterange" [ranges]="ranges"
                [showCustomRangeLabel]="true" [alwaysShowCalendars]="true" [showRangeLabelOnInput]="true" autocomplete="off"
                [(ngModel)]="selectedRangeDates" (change)="updateDashboard()" placeholder="Choose date" containerStyles='display: "inline-flex"'/>
              </div>
            </div>
          </div>
        </div>
        <div class="row equal">
          <div class="col-12 col-lg-8">
            <div class="row">
              <div class="col-12">
                <div class="row">
                  <div class="header-title">
                    <p>OVERVIEW / ESTIMATED</p>
                  </div>
                </div>
                <div class="row estimated-time">
                  <!-- MEETING COST -->
                  <div class="col-12 col-sm-6 col-lg-3 col-xl-3 p-0" *ngIf="!(loadingMetrics$ | async)">
                    <div class="item-estimated" (click)="openInsights(metricsData['meet_cost']?.insights, 0)">
                      <img src="../../../assets/img/meet_cost.svg" class="estimated" />
                      <div class="description">
                        <p>Meeting Cost</p>
                        <div class="numbers">
                          <div class="price">
                            <span>{{ metricsData['meet_cost']?.total | currency }}</span>
                            <span class="float-right" *ngIf="!(loadingPastMetrics$ | async)">
                              <span [ngClass]="differences['meet_cost']?.total > metricsData['meet_cost']?.total ? 'decrement': 'increment'" *ngIf="differences['meet_cost']?.total > 0">
                                ({{ ceiler(((metricsData['meet_cost']?.total/differences['meet_cost']?.total) * 100) - 100)}}%)
                              </span>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- MEETING COST :: SKELETON -->
                  <div class="col-12 col-sm-6 col-lg-3 col-xl-3 p-0" *ngIf="(loadingMetrics$ | async)">
                    <div class="item-estimated">
                      <ngx-skeleton-loader appearance="circle" [theme]="{ height: '50px',width: '50px'}"></ngx-skeleton-loader>
                      <div class="description">
                        <ngx-skeleton-loader [theme]="{ width: '100%', 'border-radius': '0', height: '15px' }"></ngx-skeleton-loader>
                        <div class="numbers">
                          <div class="price">
                            <ngx-skeleton-loader [theme]="{ width: '100%', 'border-radius': '0', height: '15px' }"></ngx-skeleton-loader>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- Meeting Time -->
                  <div class="col-12 col-sm-6 col-lg-3 col-xl-3 p-0" *ngIf="!(loadingMetrics$ | async)">
                    <div class="item-estimated" (click)="openInsights(metricsData['meet_time']?.insights, 1)">
                      <img src="../../../assets/img/meet_time.svg" class="estimated"/>
                      <div class="description">
                        <p>Meeting Time</p>
                        <div class="numbers">
                          <div class="price">
                            <span>{{ metricsData['meet_time']?.hours | humanizeDuration }}</span>
                            <span class="float-right" *ngIf="!(loadingPastMetrics$ | async)">
                              <span [ngClass]="differences['meet_time']?.hours > metricsData['meet_time']?.hours ? 'decrement': 'increment'" *ngIf="differences['meet_time']?.hours > 0">
                                ({{ ceiler(((metricsData['meet_time']?.hours/differences['meet_time']?.hours) * 100) - 100)}}%)
                              </span>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- Meeting Time :: SKELETON-->
                  <div class="col-12 col-sm-6 col-lg-3 col-xl-3 p-0" *ngIf="(loadingMetrics$ | async)">
                    <div class="item-estimated">
                      <ngx-skeleton-loader appearance="circle" [theme]="{ height: '50px',width: '50px'}"></ngx-skeleton-loader>
                      <div class="description">
                        <ngx-skeleton-loader [theme]="{ width: '100%', 'border-radius': '0', height: '15px' }"></ngx-skeleton-loader>
                        <div class="numbers">
                          <div class="price">
                            <ngx-skeleton-loader [theme]="{ width: '100%', 'border-radius': '0', height: '15px' }"></ngx-skeleton-loader>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- Meeting Length -->
                  <div class="col-12 col-sm-6 col-lg-3 col-xl-3 p-0" *ngIf="!(loadingMetrics$ | async)">
                    <div class="item-estimated" (click)="openInsights(metricsData['meet_len']?.insights, 2)">
                      <img src="../../../assets/img/meet_len.svg" class="estimated"/>
                      <div class="description">
                        <p>Average Meeting Length</p>
                        <div class="numbers">
                          <div class="price">
                            <span>{{ metricsData['meet_len']?.hours | humanizeDuration }}</span>
                            <span class="float-right" *ngIf="!(loadingPastMetrics$ | async)">
                              <span [ngClass]="differences['meet_len']?.hours > metricsData['meet_len']?.hours ? 'decrement': 'increment'" *ngIf="differences['meet_len']?.hours > 0">
                                ({{ ceiler(((metricsData['meet_len']?.hours/differences['meet_len']?.hours) * 100) - 100)}}%)
                              </span>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- Meeting Length :: SKELETON-->
                  <div class="col-12 col-sm-6 col-lg-3 col-xl-3 p-0" *ngIf="(loadingMetrics$ | async)">
                    <div class="item-estimated">
                      <ngx-skeleton-loader appearance="circle" [theme]="{ height: '50px',width: '50px'}"></ngx-skeleton-loader>
                      <div class="description">
                        <ngx-skeleton-loader [theme]="{ width: '100%', 'border-radius': '0', height: '15px' }"></ngx-skeleton-loader>
                        <div class="numbers">
                          <div class="price">
                            <ngx-skeleton-loader [theme]="{ width: '100%', 'border-radius': '0', height: '15px' }"></ngx-skeleton-loader>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                             
                  <!-- Meeting Number -->
                  <div class="col-12 col-sm-6 col-lg-3 col-xl-3 p-0" *ngIf="!(loadingMetrics$ | async)">
                    <div class="item-estimated" (click)="openInsights(metricsData['meet_num']?.insights, 3)">
                      <img src="../../../assets/img/meet_num.svg" class="estimated"/>
                      <div class="description">
                        <p>Meeting Number</p>
                        <div class="numbers">
                          <div class="price">
                            <span>{{ metricsData['meet_num']?.counter | number: '1.0' }}</span>
                            <span class="float-right" *ngIf="!(loadingPastMetrics$ | async)">
                              <span [ngClass]="differences['meet_num']?.counter > metricsData['meet_num']?.counter ? 'decrement': 'increment'" *ngIf="differences['meet_num']?.counter > 0">
                                ({{ ceiler(((metricsData['meet_num']?.counter/differences['meet_num']?.counter) * 100) - 100)}}%)
                              </span>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- Meeting Number :: SKELETON-->
                  <div class="col-12 col-sm-6 col-lg-3 col-xl-3 p-0" *ngIf="(loadingMetrics$ | async)">
                    <div class="item-estimated">
                      <ngx-skeleton-loader appearance="circle" [theme]="{ height: '50px',width: '50px'}"></ngx-skeleton-loader>
                      <div class="description">
                        <ngx-skeleton-loader [theme]="{ width: '100%', 'border-radius': '0', height: '15px' }"></ngx-skeleton-loader>
                        <div class="numbers">
                          <div class="price">
                            <ngx-skeleton-loader [theme]="{ width: '100%', 'border-radius': '0', height: '15px' }"></ngx-skeleton-loader>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- MEETING AVG -->
                  <div class="col-12 col-sm-6 col-lg-3 col-xl-3 p-0" *ngIf="!(loadingMetrics$ | async)">
                    <div class="item-estimated" (click)="openInsights(metricsData['avg_meet_cost']?.insights, 6)">
                      <img src="../../../assets/img/avg_meet_cost.svg" class="estimated" />
                      <div class="description">
                        <p>Meeting Average Cost</p>
                        <div class="numbers">
                          <div class="price">
                            <span>{{ metricsData['avg_meet_cost']?.total | currency }}</span>
                            <span class="float-right" *ngIf="!(loadingPastMetrics$ | async)">
                              <span [ngClass]="differences['avg_meet_cost']?.total > metricsData['avg_meet_cost']?.total ? 'decrement': 'increment'" *ngIf="differences['avg_meet_cost']?.total > 0">
                                ({{ ceiler(((metricsData['avg_meet_cost']?.total/differences['avg_meet_cost']?.total) * 100) - 100)}}%)
                              </span>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- MEETING AVG :: SKELETON -->
                  <div class="col-12 col-sm-6 col-lg-3 col-xl-3 p-0" *ngIf="(loadingMetrics$ | async)">
                    <div class="item-estimated">
                      <ngx-skeleton-loader appearance="circle" [theme]="{ height: '50px',width: '50px'}"></ngx-skeleton-loader>
                      <div class="description">
                        <ngx-skeleton-loader [theme]="{ width: '100%', 'border-radius': '0', height: '15px' }"></ngx-skeleton-loader>
                        <div class="numbers">
                          <div class="price">
                            <ngx-skeleton-loader [theme]="{ width: '100%', 'border-radius': '0', height: '15px' }"></ngx-skeleton-loader>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div> 
                  <!-- <div class="col-12 col-sm-6 col-lg-4 col-xl-4 p-0 d-none" *ngIf="!(loadingMetrics$ | async)"></div> -->
                  <!-- Deep Work -->
                  <div class="col-12 col-sm-6 col-lg-3 col-xl-3 p-0" *ngIf="!(loadingMetrics$ | async)">
                    <div class="item-estimated" (click)="openInsights(metricsData['deep_time']?.insights, 4)">
                      <img src="../../../assets/img/deep_time.svg" class="estimated"/>
                      <div class="description">
                        <p>Deep Work</p>
                        <div class="numbers">
                          <div class="price">
                            <span>{{ metricsData['deep_time']?.hours | humanizeDuration }}</span>
                            <span class="float-right" *ngIf="!(loadingPastMetrics$ | async)">
                              <span [ngClass]="differences['deep_time']?.hours > metricsData['deep_time']?.hours ? 'decrement': 'increment'" *ngIf="differences['deep_time']?.hours > 0">
                                ({{ ceiler(((metricsData['deep_time']?.hours/differences['deep_time']?.hours) * 100) - 100)}}%)
                              </span>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- Deep Work :: SKEKETON-->
                  <div class="col-12 col-sm-6 col-lg-3 col-xl-3 p-0" *ngIf="(loadingMetrics$ | async)">
                    <div class="item-estimated">
                      <ngx-skeleton-loader appearance="circle" [theme]="{ height: '50px',width: '50px'}"></ngx-skeleton-loader>
                      <div class="description">
                        <ngx-skeleton-loader [theme]="{ width: '100%', 'border-radius': '0', height: '15px' }"></ngx-skeleton-loader>
                        <div class="numbers">
                          <div class="price">
                            <ngx-skeleton-loader [theme]="{ width: '100%', 'border-radius': '0', height: '15px' }"></ngx-skeleton-loader>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- Personal Time -->
                  <div class="col-12 col-sm-6 col-lg-3 col-xl-3 p-0" *ngIf="!(loadingMetrics$ | async)">
                    <div class="item-estimated" (click)="openInsights(metricsData['per_time']?.insights, 5)">
                      <img src="../../../assets/img/per_time.svg" class="estimated"/>
                      <div class="description">
                        <p>Personal Time</p>
                        <div class="numbers">
                          <div class="price">
                            <span>{{ metricsData['per_time']?.hours | humanizeDuration }}</span>
                            <span class="float-right" *ngIf="!(loadingPastMetrics$ | async)">
                              <span [ngClass]="differences['per_time']?.hours > metricsData['per_time']?.hours ? 'decrement': 'increment'" *ngIf="differences['per_time']?.hours > 0">
                                ({{ ceiler(((metricsData['per_time']?.hours/differences['per_time']?.hours) * 100) - 100)}}%)
                              </span>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- Personal Time :: SKEKETON-->
                  <div class="col-12 col-sm-6 col-lg-3 col-xl-3 p-0" *ngIf="(loadingMetrics$ | async)">
                    <div class="item-estimated">
                      <ngx-skeleton-loader appearance="circle" [theme]="{ height: '50px',width: '50px'}"></ngx-skeleton-loader>
                      <div class="description">
                        <ngx-skeleton-loader [theme]="{ width: '100%', 'border-radius': '0', height: '15px' }"></ngx-skeleton-loader>
                        <div class="numbers">
                          <div class="price">
                            <ngx-skeleton-loader [theme]="{ width: '100%', 'border-radius': '0', height: '15px' }"></ngx-skeleton-loader>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- MEETING Participants -->
                  <div class="col-12 col-sm-6 col-lg-3 col-xl-3 p-0" *ngIf="!(loadingMetrics$ | async)">
                    <div class="item-estimated" (click)="openInsights(metricsData['meet_par']?.insights, 7)">
                      <img src="../../../assets/img/meet_par.svg" class="estimated" />
                      <div class="description">
                        <p>Average Meeting Participants</p>
                        <div class="numbers">
                          <div class="price">
                            <span>{{ metricsData['meet_par']?.hours | number: '1.00' }}</span>
                            <span class="float-right" *ngIf="!(loadingPastMetrics$ | async)">
                              <span [ngClass]="differences['meet_par']?.hours > metricsData['meet_par']?.hours ? 'decrement': 'increment'" *ngIf="differences['meet_par']?.hours > 0">
                                ({{ ceiler(((metricsData['meet_par']?.hours/differences['meet_par']?.hours) * 100) - 100)}}%)
                              </span>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- MEETING Participants :: SKELETON -->
                  <div class="col-12 col-sm-6 col-lg-3 col-xl-3 p-0" *ngIf="(loadingMetrics$ | async)">
                    <div class="item-estimated">
                      <ngx-skeleton-loader appearance="circle" [theme]="{ height: '50px',width: '50px'}"></ngx-skeleton-loader>
                      <div class="description">
                        <ngx-skeleton-loader [theme]="{ width: '100%', 'border-radius': '0', height: '15px' }"></ngx-skeleton-loader>
                        <div class="numbers">
                          <div class="price">
                            <ngx-skeleton-loader [theme]="{ width: '100%', 'border-radius': '0', height: '15px' }"></ngx-skeleton-loader>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>       
                </div>
              </div>
              <div class="col-12 col-sm-6 mt-4">
                <div class="discussed-topics">
                  <div class="header-title m-0">
                    <p>MOST USED WORDS</p>
                  </div>
                  
                  <!-- SHOW KEYWORDS -->
                  <ul class="list-unstyled topics-bars" *ngIf="!(loadingKeywords$ | async)">
                    <li *ngFor="let keyword of keywordsData">
                      <div class="description">
                        <div class="title">{{keyword.text}}</div>
                        <div class="percentage">{{keyword.percentage}}%</div>
                      </div>
                      <p class="pl-1"><ngb-progressbar type="info" [value]="keyword.percentage" ></ngb-progressbar></p>
                    </li>
                  </ul>
  
                  <!-- NO KEYWORDS FOUND-->
                  <ul class="list-unstyled topics-bars" *ngIf="!(loadingKeywords$ | async) && !(keywordsData.length)">
                    <li>
                      <div class="description">
                        <div class="title">No keywords were found</div>
                      </div>
                    </li>
                  </ul>
                  
                  <!-- SKELETON - LOADING -->
                  <ul class="list-unstyled topics-bars" *ngIf="(loadingKeywords$ | async)">
                    <li *ngFor="let x of [1,2,3,4]">
                      <div class="description">
                        <div class="w-50 mt-2">
                          <ngx-skeleton-loader [theme]="{ width: '100%', 'border-radius': '10px', height: '8px' }"></ngx-skeleton-loader>
                        </div>
                      </div>
                      <p class="pl-1">
                        <ngx-skeleton-loader [theme]="{ width: '100%', 'border-radius': '10px', height: '8px' }"></ngx-skeleton-loader>
                      </p>
                    </li>
                    
                  </ul>
                </div>
              </div>
              <div class="col-12 col-sm-6 mt-4">
                <div class="practices-followed">
                  <div class="header-title m-0">
                    <p>BEST PRACTICES FOLLOWED</p>
                  </div>
                  <ul class="list-unstyled topics-bars">
                    <li>
                      <div class="description">
                        <div class="title">Has Agenda</div>
                        <div class="percentage" *ngIf="!(loadingBestPractices$ | async)">{{ bestPracticesData?.has_agenda | number: '1.0-0' }} %</div>
                      </div>
                      <p *ngIf="!(loadingBestPractices$ | async)"><ngb-progressbar type="info" [value]="bestPracticesData?.has_agenda"  [max]="100"></ngb-progressbar></p>
                      <ngx-skeleton-loader *ngIf="(loadingBestPractices$ | async)" [theme]="{ width: '100%', 'border-radius': '10px', height: '8px' }"></ngx-skeleton-loader>
                    </li>
                    <li>
                      <div class="description">
                        <div class="title">Five Days Notice</div>
                        <div class="percentage" *ngIf="!(loadingBestPractices$ | async)">{{ bestPracticesData?.five_days_notice | number: '1.0-0' }} %</div>
                      </div>
                      <p *ngIf="!(loadingBestPractices$ | async)"><ngb-progressbar type="info" [value]="bestPracticesData?.five_days_notice"  [animated]="true"></ngb-progressbar></p>
                      <ngx-skeleton-loader *ngIf="(loadingBestPractices$ | async)" [theme]="{ width: '100%', 'border-radius': '10px', height: '8px' }"></ngx-skeleton-loader>
                    </li>
                    <li>
                      <div class="description">
                        <div class="title">Less Than 8 Members</div>
                        <div class="percentage" *ngIf="!(loadingBestPractices$ | async)">{{ bestPracticesData?.less_than_8_members | number: '1.0-0' }} %</div>
                      </div>
                      <p *ngIf="!(loadingBestPractices$ | async)"><ngb-progressbar type="info" [value]="bestPracticesData?.less_than_8_members"  [animated]="true"></ngb-progressbar></p>
                      <ngx-skeleton-loader *ngIf="(loadingBestPractices$ | async)" [theme]="{ width: '100%', 'border-radius': '10px', height: '8px' }"></ngx-skeleton-loader>
                    </li>
                    <li>
                      <div class="description">
                        <div class="title">Meeting Under 45 Minutes</div>
                        <div class="percentage" *ngIf="!(loadingBestPractices$ | async)">{{ bestPracticesData?.meeting_under_45_minutes | number: '1.0-0' }} %</div>
                      </div>
                      <p *ngIf="!(loadingBestPractices$ | async)"><ngb-progressbar type="info" [value]="bestPracticesData?.meeting_under_45_minutes"  [animated]="true"></ngb-progressbar></p>
                      <ngx-skeleton-loader *ngIf="(loadingBestPractices$ | async)" [theme]="{ width: '100%', 'border-radius': '10px', height: '8px' }"></ngx-skeleton-loader>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div class="d-none d-lg-block d-xl-block col-12 col-lg-4 pl-0">
            <div class="header-title m-0">
              <p>TARIY TIPS</p>
            </div>
            <div class="tips">
              <app-recommendations></app-recommendations>
            </div>          
          </div>
        </div>
        <div class="d-block d-lg-none d-xl-none col-12  mt-3 mt-lg-0 mt-xl-0">
          <div class="row">
            <div class="col-12">
              <app-recommendations></app-recommendations>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  