import { PercentPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'relativePercentage'
})
export class RelativePercentagePipe extends PercentPipe 
  implements PipeTransform {

  /**
   * Formats the relative percentage.
   * @param value Percentage (examples: 0.15, 0.6, 1, etc...)
   */
  transform(value: any, digitsInfo?: string, locale?: string): string | null {
    let percentage = super.transform(value, locale);
    
    if (value > 0) {
      percentage = "+" + percentage;
    }

    return percentage;
  }

}
