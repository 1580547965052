<div class="modalNote d-none d-xl-block d-lg-block">
  TARIY meeting score&reg; uses the following parameters for evaluating your meetings.
</div>
<!-- <div class="card mt-2">
  <div class="header">
    <!-- <div class="title text-center pt-1 pb-1 main-title" style="height:50%;">TARIY Tips</div>
    <div class="title col-3 d-none d-xl-inline-flex d-lg-inline-flex text-center justify-content-center pt-1" style="height:50%">Parameter</div>
    <div class="title col-9 d-none d-xl-inline-flex d-lg-inline-flex text-center justify-content-center pt-1" style="height:50%">Definition</div>
  </div>
  <div class="body">
    <div class="row" *ngFor="let tip of tips" style="height:auto">
      <div class="col-3 pl-4 align-items-center pb-2 pt-2 d-none d-xl-flex d-lg-flex">{{tip.key}}</div>
      <div class="col-12 col-xl-9 col-lg-9  d-flex pb-2 pt-2"><ul>
        <li *ngFor="let recommendation of tip.value">{{recommendation}}</li>
      </ul></div>
    </div>
  </div>
</div> -->
<!-- opcion -->
<mat-dialog-content class="card mt-2">
  <div class="header">
    <!-- <div class="title text-center pt-1 pb-1 main-title" style="height:50%;">TARIY Tips</div> -->
    <!-- <div class="title col-2 d-none d-xl-inline-flex d-lg-inline-flex text-center justify-content-center pt-1" style="height:50%">Parameter</div> -->
    <div class="title col-3 d-none d-xl-inline-flex d-lg-inline-flex text-center justify-content-center pt-1" style="height:50%">Score</div>
    <div class="title col-9 d-none d-xl-inline-flex d-lg-inline-flex text-center justify-content-center pt-1" style="height:50%">Meaning</div>
  </div>
  <div class="body">
    <div class="row" *ngFor="let tip of tips; let i = index" style="height:auto" [ngClass]="i % 2 == 0 ? tip.class : 'not-colored'">
      <div class="col-12 col-xl-3 col-lg-3 align-items-center justify-content-center d-flex pb-2 pt-2">
        <span class="d-none d-xl-flex d-lg-flex">{{tip.value[0]}}</span>
        <ul class="d-flex d-xl-none d-lg-none" style="flex-direction: column;">
          <li class="w-100" *ngFor="let rec of tip.value">{{rec}}</li>
        </ul>
      </div>
      <div class="col-12 col-xl-9 col-lg-9 align-items-center d-none d-xl-flex d-lg-flex pb-2 pt-2"><span>{{tip.value[1]}}</span></div>
    </div>
  </div>
</mat-dialog-content>