import { Component, OnInit, Inject, Input } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { StepsConnection } from 'src/app/models/settings/steps-connection';
import { trigger, transition, style, animate } from '@angular/animations';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import * as moment from "moment";

@Component({
  selector: 'app-nudge-settings',
  templateUrl: './nudge-settings.component.html',
  styleUrls: ['./nudge-settings.component.scss']
})
export class NudgeSettings implements OnInit {
  tips = [
    {key: 'Duration', value: ['TARIY takes in consideration the real meeting time duration.', 'Tariy recommends meetings under 45 minutes.']},
    {key: 'Meeting Starts', value: ['TARIY uses the difference between the scheduled start and the real meeting starting time.', 'Tariy recommends no more then 3 minutes of late starting time.']},
    {key: 'Timeframe', value: ['TARIY takes in consideration the timeframe in which the meeting is set in.', 'Tariy recommends meetings between 9:00am-12:00pm and 2:00pm-4:00pm.']},
    {key: 'Num of Attendees', value: ['TARIY values how many attendees where in the meeting.', 'Tariy recommends meetings with under 5 attendees.']},
    {key: 'Meeting Ends', value: ['TARIY takes in consideration the real ending time of the meeting.', 'Tariy recommends ending the meeting no more than 5 minutes after the scheduled ending.']},
    {key: 'Day', value: ['TARIY uses the day of the week the meeting took place.', 'Tariy recommends scheduling meeting between Tuesday and Thursday.']},
    {key: 'Has Agenda', value: ['TARIY reads the meeting invation and searches for an agenda.', 'Tariy recommends using an agenda to plan your meeting topics.']},
    {key: 'Notice', value: ['TARIY takes in consideration how many days in advance was the meeting scheduled.', 'Tariy recommends scheduling meetings 5 days in advance.']},
    {key: 'Sentiment', value: ['TARIY reads the meeting transcript and assign a value from negative to positive.', 'Tariy recommends having meetings with a positive sentiment.']},
    {key: 'Participation', value: ['TARIY takes in consideration how much every attendee participated.', 'Tariy recommends that every attendee should at least have a 10% participation.']},
  ];
  columnHeight = 0;
  describe = faQuestionCircle;
  // Nudge settings form group
  nudgeForm: FormGroup;
  defaultStart = '9:00AM';
  defaultStartLunch = '12:30PM';
  defaultEndLunch = '2:00PM';
  defaultEnd = '5:00PM';
  modifiers: [
    {
      name: 'preventOverflow',
      options: {
        mainAxis: false, // true by default
      },
    },
  ];


  constructor(public dialogRef: MatDialogRef<NudgeSettings>, @Inject(MAT_DIALOG_DATA) private data: any, private fb: FormBuilder) { }

  ngOnInit(): void {
    // Set data sent from dialog data to steps
    //this.tips = this.data['tips'];
    this.columnHeight = 100/ (this.tips.length);
    this.nudgeForm = this.fb.group({
      startingTime: [moment(this.defaultStart, ['h:mm A']).toDate()],
      lunchStart: [moment(this.defaultStartLunch, ['h:mm A']).toDate()],
      lunchEnd: [moment(this.defaultEndLunch, ['h:mm A']).toDate()],
      dayEnd: [moment(this.defaultEnd, ['h:mm A']).toDate()],
      strikes: [3, Validators.pattern("^[0-9]*$")],
      meetingPercentage: [30, Validators.pattern("^[0-9]*$")]
    });
  }

  // Close dialog
  close(){
    this.dialogRef.close();
  }

}
