import { MeetingStatusFront, MeetingStatusText } from 'src/enum/meeting-status.enum';
// Class that allows the creation of new instances
export class Meeting {
  id: string;
  event_id: string;
  organizer: string;
  organizerEmail: string;
  title: string;
  picture: string;
  validPicture: boolean;
  date: string;
  duration: number;
  participants: string;
  topics: string[];
  statusId: number;
  statusText: string;
  score: number;
  attendees: string[];
  keywords: string[];
  meetingCost: number;
  attendeesLate: string;
  missed: string[];
  isEarly: boolean;
  isLate: boolean;
  minutesLate: string;
  sentimentScore: number;
  sentiment: any;
  source: string;
  meetingType: string;
  scoreDetails: [];
  meetingTips: [];
  showBehavior: boolean;
  onTime: string;
  productiveColumnMode: boolean;
  productive: number;
  inclusiveColumnMode: boolean;
  inclusive: number;

  private status_types = MeetingStatusFront;
  private statustxt = MeetingStatusText;

  constructor(data) {
    this.id = data.MeetingId ? data.MeetingId : '';
    this.event_id = data.EventId;
    this.organizer = data.Organizer['name'] ?? '';
    this.organizerEmail = data.Organizer['email'] ?? '';
    this.title = data.Title;
    this.picture = data.Organizer['picture'] ?? '';
    this.date = data.Date || data.Starts;
    this.duration = parseFloat(data.Duration); // Meeting duration in Hours
    this.participants = data.Participants;
    this.topics = data.Topics != undefined ? data.Topics.split(', ') : [];
    this.attendees = data.Attendees ?? [];
    this.missed = data.Missed ?? [];
    this.keywords = data.Keywords ?? [];
    this.statusId = data.Status != undefined ? data.Status : this.statustxt['Meeting_not_found'];
    this.statusText = this.status_types[this.statusId].replace(/_/g, " ");
    this.score = parseFloat(data.MeetingScore);
    this.meetingCost = data.MeetingCost ?? 0;
    this.attendeesLate = data.AttendeesLate ?? 0;
    this.isEarly = data.IsEarly;
    this.isLate = data.IsLate;
    this.onTime = data.OnTime;
    this.minutesLate = data.MinuteLates ?? 0;
    this.sentimentScore = data.SentimentScore;
    this.sentiment = data.Sentiment;
    this.source = data.Source;
    this.meetingType = data.MeetingType;
    this.scoreDetails = data.ScoreDetails;
    this.meetingTips = data.MeetingTips;
    this.showBehavior = data.ShowDetails;
    this.productiveColumnMode = data.columnMode ?? false;
    this.productive = parseInt(data.productive ?? 2);
    this.inclusiveColumnMode = data.inclusiveColumnMode ?? false;
    this.inclusive = parseInt(data.inclusive ?? 2);
    this.validPicture = true;
  }

  private objectParser(objeto) {
    Object.keys(objeto).forEach(x => {
      objeto[x] = Number.isInteger(objeto[x]) ? parseInt(objeto[x]) : parseFloat(objeto[x]);
    });
  }
  private whoParticipate(attendee) {
    return attendee.status == 'accepted';
  }

  private whoAbscent(attendee) {
    return attendee.status == 'no_response';
  }
  /**
   * Gets the Score in the following format
   * 60% or N/A - No decimals
   * @returns string
   */
   public getScoreText() {
    return isNaN(this.score) ? 'N/A' : this.score.toFixed(0);
  }
  /**
   * Transforms the Sentiment Score in the following format
   * 60% or N/A - No decimals
   * @returns string
   */
  public getSentimentScoreText() {
    if(this.sentimentScore == null) {
      return '';
    }
    this.objectParser(this.sentiment);
    return this.sentiment.PercentPos > this.sentiment.PercentNeg ? ((this.sentiment?.TotalPos * 100)/(this.sentiment?.TotalPos + this.sentiment?.TotalNeg)).toFixed(0) + '%' : ((this.sentiment?.TotalNeg  * 100)/(this.sentiment?.TotalPos + this.sentiment?.TotalNeg)).toFixed(0) + "%";
  }
  /**
   * Gets the sentiment text value according to the score
   * Positive 15 - 100, Neutral -15 - 15, Negative -100 - -15
   * @returns string
   */
   public getSentimentSpin() {
    if(this.sentimentScore == null) {
      return 'N/A';
    }
    this.objectParser(this.sentiment);
    return this.sentiment.PercentPos > this.sentiment.PercentNeg ? 'Positive' : 'Negative';
  }

  /**
   * Gets the sentiment text for display in tooltip
   * @returns string
   */
  public getTooltipSentiment() {
    if(this.sentiment) {
      let text = 'positive: ' + this.sentiment?.TotalPos + 
      ' negative: ' + this.sentiment?.TotalNeg + 
      ' neutral: ' + this.sentiment?.TotalNeu;
      return text;
    }
    else 
      return '';
  }

  /**
   * Get meeting duration in minutes
   * No seconds returned just complete minutes
   * @returns number
   */
  public getDurationInMinutes() {
    if (isNaN(this.duration)) {
      return 0;
    }
    else {
      return (this.duration * 60).toFixed(0);
    }
  }

  /**
   * 
   * @returns return imnutes late fixed into max 2 digits
   */
  public getMinutesLateFixed() {
    return this.fixNumbers(this.minutesLate);
  }

  /**
   * This method fix numbers into two decimals
   * if is 1 return 1
   * if is 1.0 return 1
   * if is 1.151551 return 1.15
   * if is not a number return 0
   * */
  public fixNumbers(number) {

    if (isNaN(number)) {
      return 0;
    }
    
    let num = number.toString();
    const dec = num.split('.')[1];//if not exist set undefined
    if (dec) {
      if (Number(dec) == 0)//this is for remove zero from example 1.0
      {
        return Number(num).toFixed(0);
      }

      const len = dec.length > 2 ? 2 : dec.length
      return Number(num).toFixed(len)
    }
    return num;
  }

  public invalidPicture() {
    this.validPicture = false;
  }

}

