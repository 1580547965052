import { Component, OnInit, Inject, Input } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { StepsConnection } from 'src/app/models/settings/steps-connection';
import { trigger, transition, style, animate } from '@angular/animations';

@Component({
  selector: 'app-steps-wizard',
  templateUrl: './steps-wizard.component.html',
  styleUrls: ['./steps-wizard.component.scss'],
  animations: [
    trigger(
      'imageAnimation', [
      transition(':increment', [
        style({ transform: 'translateX(-100%) translateY(-50%)', zIndex: 2}),
        animate('300ms', style({ transform: 'translateX(0%) translateY(-50%)' }))
      ]),
      transition(':decrement', [
        style({ transform: 'translateX(100%) translateY(-50%)', zIndex: 2 }),
        animate('300ms', style({ transform: 'translateX(0%) translateY(-50%)' }))
      ])
    ]
  ),
    trigger(
      'textAnimation', [
      transition(':increment', [
        style({ transform: 'translateX(-100%)', zIndex: 2}),
        animate('300ms', style({ transform: 'translateX(0%)' }))
      ]),
      transition(':decrement', [
        style({ transform: 'translateX(100%)', zIndex: 2 }),
        animate('300ms', style({ transform: 'translateX(0%)' }))
      ])
    ]
    )
  ]
})
export class StepsWizardComponent implements OnInit {
  stepsData: StepsConnection[];
  selectedIndexStep = 0;

  constructor(public dialogRef: MatDialogRef<StepsWizardComponent>, @Inject(MAT_DIALOG_DATA) private data: any) { }

  ngOnInit(): void {
    // Set data sent from dialog data to steps
    this.stepsData = this.data['stepsData'];
  }

  // Show previous step from stepsData
  prev(){
    // Go back on steps only when is not first
    if (this.selectedIndexStep > 0){
     this.selectedIndexStep--;
    }
  }

  // Show next step from stepsData
  next(){
    // Close dialog on last step
    if (this.selectedIndexStep == (this.stepsData.length - 1)) {
      this.dialogRef.close();
    }
    // Add selectedIndex when is not last item
    if (this.selectedIndexStep < (this.stepsData.length - 1)){
      this.selectedIndexStep++;
    }
  }

  // Close dialog
  close(){
    this.dialogRef.close();
  }

}
