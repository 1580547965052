import { MeetingStatusFront, MeetingStatusText } from 'src/enum/meeting-status.enum';
// Class that allows the creation of new instances
export class Attendees {
  email: string;
  name: string;
  assisted: number;
  status: number;
  sentiment: any;
  sentimentScore: number;
  participation: any;
  late: any;
  define: boolean;
  picture: string
  gender: string
  validPicture: boolean;

  constructor(data, assisted) {
    this.email = data.email ? data.email : null;
    this.name = data.name;
    this.assisted = this.accepted(data, assisted);
    this.status = data.status ? this.accepted(data.status, assisted) : 0;
    this.define = data.hasOwnProperty('sentiment');
    this.sentiment = this.define ? this.parseSentiment(data.sentiment): {};
    this.sentimentScore = this.createAttendeeSentimentScore();
    this.participation = this.define ? this.parseParticipation(data.participation): {};
    this.late = data.hasOwnProperty('late') ? data.late : 0;
    this.picture = data?.picture;
    this.gender = data?.gender;
    this.validPicture = true;
  }

  private accepted(attendeeStatus, status) {
    if(attendeeStatus.status) { 
      if(attendeeStatus.status == "accepted") {
        return 4;
      }
      else if(attendeeStatus.status == "no_response") {
        return 3;
      }
      else if(attendeeStatus.status == "tentative") {
        return 5;
      }
    }
    else if(status >= 0){
      return status;
    }
    else {
      return -1;
    }
  }

  private parseSentiment(sentiment) {
    let detail = {
      PercentPos : 0,
      PercentNeg : 0,
      TotalPos : parseInt(sentiment.TotalPos),
      TotalNeg : parseInt(sentiment.TotalNeg),
      TotalNeu : parseInt(sentiment.TotalNeu)
    };
    detail.PercentPos = (detail.TotalPos/(detail.TotalPos + detail.TotalNeg)) * 100;
    detail.PercentNeg = (detail.TotalNeg/(detail.TotalPos + detail.TotalNeg)) * 100;
    return detail;
  }

  private parseParticipation(participation) {
    let detail = {
      Participation : parseFloat(participation.Participation),
      WordCount : parseInt(participation.WordCount)
    };
    return detail;
  }

  private createAttendeeSentimentScore() {
    return (this.sentiment.TotalPos/(this.sentiment.TotalPos + this.sentiment.TotalNeg)) * 100;
  }

  private invalidPicture() {
    console.log('invalid picture in detail');
    this.validPicture = false;
  }

 
  public getTooltipSentiment() {
    if(this.define) {
      let text = 'positive: ' + this.sentiment?.TotalPos + 
      ' negative: ' + this.sentiment?.TotalNeg + 
      ' neutral: ' + this.sentiment?.TotalNeu;
      return text;
    }
    else 
      return '';
  }

  public getTooltipParticipation() {
    if(this.define) {
      return `${this.participation.WordCount} words`;
    }
    else 
      return '';
  }

  public getSentimentClass() {
    //return this.sentiment.PercentPos >= this.sentiment.PercentNeg ? 'good-sentiment' : 'bad-sentiment';
    return 'good-sentiment'
  }

  public getParticipationClass() {
    return 'participation';
  }

  public getLateClass() {
    return this.late >= 5 ?  'bad-late': 'good-late';
  }

}

