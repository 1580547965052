import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTimepickerModule } from 'mat-timepicker';
import { MatBadgeModule} from '@angular/material/badge';

// Components
import { HeaderComponent } from '../views/layouts/header/header.component';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SelectSearchComponent } from 'src/app/components/select-search/select-search.component';
import { RecommendationsComponent } from 'src/app/components/recommendations/recommendations.component';
import { DashboardCardComponent } from 'src/app/components/dashboard-card/dashboard-card.component';
import { SidebarComponent } from '../views/layouts/sidebar/sidebar.component';

// External plugins
import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgxPopperjsModule } from 'ngx-popperjs';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';

// Pipes
import { HumanizeDurationPipe } from 'src/app/pipes/humanize-duration/humanize-duration.pipe';
import { RelativePercentagePipe } from 'src/app/pipes/relative-percentage/relative-percentage.pipe';

// Pages
import { Page404Component } from '../views/page404/page404.component';

// Directives
import { DisableControlDirective } from '../directives/disable-control/disable-control.directive';
import { BrowserModule } from '@angular/platform-browser';
import { FlexLayoutModule } from '@angular/flex-layout';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

const components = [
  HeaderComponent,
  SidebarComponent,
  Page404Component,
  DisableControlDirective,
  SelectSearchComponent,
  HumanizeDurationPipe,
  RelativePercentagePipe,
  RecommendationsComponent,
  DashboardCardComponent,
];

const modules = [
  CommonModule,
  FormsModule,
  RouterModule,
  NgbModule,
  FontAwesomeModule,
  NgxDaterangepickerMd,
  NgMultiSelectDropDownModule,
  NgSelectModule,
  ReactiveFormsModule,
  BrowserModule,
  BrowserAnimationsModule,
  FlexLayoutModule,
  NgxPopperjsModule,
  NgxSkeletonLoaderModule,
  MatSlideToggleModule,
  MatTimepickerModule,
  MatBadgeModule
];
@NgModule({
  declarations: [
    ...components
  ],
  imports: [
    ...modules
  ],
  exports:[
    ...modules,
    ...components
  ],
  entryComponents:[
    SelectSearchComponent,
    RecommendationsComponent,
  ]
})
export class SharedModule {
  constructor(library: FaIconLibrary) {
    // Add an icon to the library for convenient access in other components
    library.addIconPacks(fas);
  }
}
