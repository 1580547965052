import * as moment from "moment-timezone";

export class DateUtils {

    /**
     * Returns the current utc offset in minutes
     * @returns utc offset in minutes (example: -420)
     */
    static getUtcOffset(): number {
        var offset = moment().utcOffset();

        return offset;
    }

    /**
     * Returns the current timezone string
     * @returns timezone string (example: America/Hermosillo)
     */
    static getTimezoneString(): string {
        var timezone = moment.tz.guess();

        return timezone !== undefined ? timezone : '';
    }

    /**
     * Returns the current timezone string in hours
     * @returns timezone string in hours (example: -0700)
     */
    static getTimezoneHours(): string {
        var date = moment().format('Z');

        return date;
    }


}