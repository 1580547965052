import { Pipe, PipeTransform } from '@angular/core';
import * as humanizeDuration from 'humanize-duration';

@Pipe({
  name: 'humanizeDuration'
})
export class HumanizeDurationPipe implements PipeTransform {

  transform(hours: number, isShort = false): unknown {
    hours = hours === null ? 0 : hours;

    return humanizeDuration(hours * 60 * 60 * 1000, {
      round: true,
      language: isShort ? "shortEn" : "en",
      maxDecimalPoints: 2,
      units: this.getUnits(hours),
      languages: {
        shortEn: this.getShortEnLanguage(hours)
      }
    });
  }

  private getUnits(hours: number) {
    return hours == 0 ? ["h"] : ["h", "m"]
  }

  private getShortEnLanguage(hours: number) {
    return {
      y: () => "y",
      mo: () => "mo",
      w: () => "w",
      d: () => "d",
      h: () => "hrs",
      m: () => "min",
      s: () => "s",
      ms: () => "ms",
    }
  }

}
