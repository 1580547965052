import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Globals } from 'src/app/global';

@Injectable({
  providedIn: 'root'
})
export class DepartmentsService {
  // variable that store the import of Globals Class that will help us to retrieve the urls for every service
  globals = Globals;
  constructor(private http: HttpClient) { }

  // Retrieves list of departments
  getDepartments(): Promise<object>{
    const url = `${this.globals.urlTraiyBase}${this.globals.resources.department}`;
    return this.http.get(url).toPromise();
  }
}
