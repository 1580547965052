import { Component, OnInit, Inject, Input } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatChipList, MatChip, MatChipsModule } from '@angular/material/chips';
import { StepsConnection } from 'src/app/models/settings/steps-connection';
import { trigger, transition, style, animate } from '@angular/animations';

@Component({
  selector: 'app-zoom-profile',
  templateUrl: './zoom-profile.component.html',
  styleUrls: ['./zoom-profile.component.scss']
})
export class ZoomProfileComponent implements OnInit {
  genders = [
    {name: 'Male', selected: false, value: 0},
    {name: 'Female', selected: false, value: 1},
    {name: 'Non-Binary', selected: false, value: 2},
  ];
  communication = [
    {name: 'Analytical', selected: false, value: 0, tooltip: 'Prefer to communicate with data rather than emotions'},
    {name: 'Intuitive', selected: false, value: 1, tooltip: 'Prefer a more casual, big-picture approach'},
    {name: 'Functional', selected: false, value: 2, tooltip: 'Like details, processes, timelines and wll thought out plans'},
    {name: 'Personal', selected: false, value: 3, tooltip: 'Prefer to have a personal relationship to know what they are thinking'},
  ];
  generation = [
    {name: 'Baby Boomer', selected: false, value: 0, tooltip: 'Born between 1946 to 1964'},
    {name: 'Gen X', selected: false, value: 1, tooltip: 'Born between 1965 to 1980'},
    {name: 'Millennial', selected: false, value: 2, tooltip: 'Born between 1981 to 1996'},
    {name: 'Gen Z', selected: false, value: 3, tooltip: 'Born between 1997 to 2012'},
  ];
  equitySubCategories = [
    {name: 'Increase engagement', selected: false, value: 0},
    {name: 'Improve partication', selected: false, value: 1},
    {name: 'Balanced conversations', selected: false, value: 2},
    {name: 'Fewer interruptions', selected: false, value: 3},
    {name: 'Fewer Monologues', selected: false, value: 4},
    {name: 'More Inclusive meetings', selected: false, value: 5},
    {name: 'More Balanced Meetings (gender ratio)', selected: false, value: 6},
  ];
  efficiencySubCategories = [
    {name: 'Right amount of participants', selected: false, value: 0},
    {name: 'Fewer meetings', selected: false, value: 1},
    {name: 'Shorter Meetings', selected: false, value: 2},
    {name: 'More Productive Meetings', selected: false, value: 3},
    {name: 'Less expensive Meetings', selected: false, value: 4},
    {name: 'No back-to-back Meetings', selected: false, value: 5},
    {name: 'More Organized meetings', selected: false, value: 6},
  ];
  deepworkSubCategories = [
    {name: 'Block my calendar', selected: false, value: 0},
    {name: 'Eliminate distractions', selected: false, value: 1},
    {name: 'Turn off notifications', selected: false, value: 2},
    {name: 'Concentrate on one task at a time', selected: false, value: 3},
    {name: 'Create a routine', selected: false, value: 4},
  ];
  selectedGender = -1
  selectedCommunication = -1
  selectedGeneration = -1
  selectedSubCategories: any[] = []
  jobTitle = ''
  columnHeight = 0;


  constructor(public dialogRef: MatDialogRef<ZoomProfileComponent>, @Inject(MAT_DIALOG_DATA) private data: any) { }

  ngOnInit(): void {
    // Set data sent from dialog data to steps
    //this.tips = this.data['tips'];
  }

  // Close dialog
  close(){
    console.log(this.jobTitle)
    this.dialogRef.close({gender: this.selectedGender, communication: this.selectedCommunication, generation: this.selectedGeneration, jobTitle: this.jobTitle});
  }

  changeSelectedGender(selectedChip) {
    this.selectedGender=selectedChip
  }

  changeSelectedCommunication(selectedChip) {
    this.selectedCommunication=selectedChip
  }

  changeSelectedGeneration(selectedChip) {
    this.selectedGeneration=selectedChip
  }

  changeSubSelected(selectedChip) {
    let index = this.selectedSubCategories.indexOf(selectedChip);
    if (index >= 0) {
      this.selectedSubCategories.splice(index, 1);
    } else {
      this.selectedSubCategories.push(selectedChip);
    }
    
  }

}
