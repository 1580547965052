<section class="login">
  <div class="container-md h-100">
    <div class="row">
      <div class="col">
        <div class="login-modal p-0 p-md-2 mt-0 mt-lg-n3">
          <img src="../../../assets/img/tariy-login.png" alt="" class="logo mb-5">
          <h2 class="title">Empowering All Voices</h2>
          <p>Create meetings that are truly equitable and inclusive for all.
          </p>
          <div class="sign-in-title">SIGN UP WITH:</div>
          <div class="d-flex w-100 flex-column justify-content-between flex-xl-row justify-content-xl-between flex-lg-row justify-content-lg-between align-items-lg-center">
            <button class="btn btn-block mr-3 mb-xl-0 mb-lg-0 mb-3" (click)="signInGoogle()">
              <img src="../../../assets/img/goo-original.svg" class="icon">Sign in with Google</button>
            <button class="btn btn-block" (click)="signInMicrosoft()">
              <img src="../../../assets/img/of-original.svg" class="icon">Sign in with Office 365</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="footer">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <p class="terms">
            By sign in I agree to the <a target="_blank" href="https://www.tariy.ai/terms">Terms and Conditions</a> and <a target="_blank" href="https://www.tariy.ai/privacy">Privacy Policy.</a><a href="https://dev-web.tariy.ai/redeem-code?sassFormCode=yes_2021_07_30_12_24" >.</a>
          </p>
        </div>
      </div>
    </div>
  </div>
</section>




