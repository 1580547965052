<section class="sidebar">
  <ul class="main-menu list-unstyled">
    <li routerLinkActive="active-link" [routerLinkActiveOptions]="{exact: true}">
      <a [routerLink]="['/admin']" >
        <img src="../../../../assets/img/dashboard-black-sidebar.svg" alt="" class="inactive">
        <img src="../../../../assets/img/dashboard-blue-sidebar.svg" alt="" class="active">
        <div class="title">DASHBOARD</div>
      </a>
    </li>
    <li routerLinkActive="active-link">
      <a [routerLink]="['events']" >
        <img src="../../../../assets/img/meetings-black-sidebar.svg" alt="" class="inactive">
        <img src="../../../../assets/img/meetings-blue-sidebar.svg" alt="" class="active">
        <div class="title">EVENTS</div>
      </a>
    </li>
    <li routerLinkActive="active-link" *ngIf="(loggedUserInfo['role'] == roles.Admin) || (loggedUserInfo['role'] == roles.Manager)">
      <a [routerLink]="['my-team']" >
        <img src="../../../../assets/img/myteam-black-sidebar.svg" alt="" class="inactive">
        <img src="../../../../assets/img/myteam-blue-sidebar.svg" alt="" class="active">
        <div class="title">MY TEAM</div>
      </a>
    </li>
    <li routerLinkActive="active-link">
      <a [routerLink]="['insights']" >
        <img src="../../../../assets/img/history-black-sidebar.svg" alt="" class="inactive">
        <img src="../../../../assets/img/history-blue-sidebar.svg" alt="" class="active">
        <div class="title">INSIGHTS</div>
      </a>
    </li>
    <!-- <li routerLinkActive="active-link">
      <a [routerLink]="['reports']" >
        <img src="../../../../assets/img/reports-black-sidebar.svg" alt="" class="inactive">
        <img src="../../../../assets/img/reports-blue-sidebar.svg" alt="" class="active">
        <div class="title">REPORTS</div>
      </a>
    </li> -->
    <li routerLinkActive="active-link">
      <a [routerLink]="['nudges']" >
        <img src="../../../../assets/img/nudges-black-sidebar.svg" alt="" class="inactive">
        <img src="../../../../assets/img/nudges-blue-sidebar.svg" alt="" class="active">
        <div class="title" style="display: block;"><span matBadge="!" [matBadgeHidden]="!showNudgeBadge">NUDGES</span></div>
      </a>
    </li>
    <li routerLinkActive="active-link">
      <a [routerLink]="['ai-training']" >
        <img src="../../../../assets/img/training-gray.svg" alt="" class="inactive">
        <img src="../../../../assets/img/training-blue.svg" alt="" class="active">
        <div class="title" style="display: block;"><span matBadge="!" [matBadgeHidden]="!showNudgeBadge">AI TRAINING</span></div>
      </a>
    </li>
    <!-- <li routerLinkActive="active-link">
      <a [routerLink]="['benchmark']" >
        <img src="../../../../assets/img/benchmark_gray.svg" alt="" class="inactive">
        <img src="../../../../assets/img/benchmark_blue.svg" alt="" class="active">
        <div class="title">BENCHMARK</div>
      </a>
    </li> -->
  </ul>
  <ul class="user-menu list-unstyled">
    <li routerLinkActive="active-link" *ngIf="(loggedUserInfo['role'] == roles.Admin) || (loggedUserInfo['role'] == roles.Manager)">
      <a [routerLink]="['users']" >
        <img src="../../../../assets/img/users.svg" alt="" class="active">
        <img src="../../../../assets/img/users-grey.svg" alt="" class="inactive">
        <div class="title">USERS</div>
      </a>
    </li>
    <!-- <li routerLinkActive="active-link" *ngIf="loggedUserInfo['role'] == roles.Admin"> -->
    <li routerLinkActive="active-link" *ngIf="(loggedUserInfo['role'] == roles.Admin) || (loggedUserInfo['role'] == roles.ITAdmin) || (loggedUserInfo['role'] == roles.Manager)">
      <a [routerLink]="['settings']" >
        <img src="../../../../assets/img/settings.svg" alt="" class="active">
        <img src="../../../../assets/img/settings-grey.svg" alt="" class="inactive">
        <div class="title">SETTINGS</div>
      </a>
    </li>
    <li routerLinkActive="active-link" (click)="activateSubmenu()">
      <a class="open-submenu" #toggleMenu>
        <img src="../../../../assets/img/help-gray.svg" alt="" class="inactive" #toggleImg>
        <img src="../../../../assets/img/settings-grey.svg" alt="" class="active">
        <div class="title" #toggleTxt>HELP</div>
      </a>
    </li>
  </ul>
  <div class="submenu" #submenu *ngIf="showSubmenu">
    <p class="submenu-title" style="border-bottom: 1px solid #c5c5c5;"(click)="openNewTab('https://tariy.canny.io/')">Give Feedback</p>
    <p class="submenu-title" (click)="openNewTab('https://intercom.help/tariy/en/')">Help Center</p>
  </div>
</section>

