import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Globals } from 'src/app/global';
@Injectable({
  providedIn: 'root'
})
export class OrganizationService {
  // variable that store the import of Globals Class that will help us to retrieve the urls for every service
  globals = Globals;

  constructor(private http: HttpClient) { }

  // Retrieves settings organization data
  getOrganizationData(): Promise<object>{
    const url = `${this.globals.settings.organizationData}`;
    return this.http.get(url).toPromise();
  }

  /**
    Set organization data
    @param object {string, string, string, string} params - Data for data organization
  */
  setOrganizationData(params: {OrganizationId: string, OrganizationName: string, IndustryId: string, Industry: string}): Promise<object>{
    const url = `${this.globals.settings.organizationData}`;
    return this.http.put(url, params).toPromise();
  }
}
