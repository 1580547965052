import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Globals } from '../../global';
import { BehaviorSubject, Subject } from 'rxjs';
import { MeetingMember } from 'src/app/models/meeting-member/meeting-member';
import { SortColumn, SortDirection } from 'src/app/views/dashboard/sortable.directive';
import { tap, debounceTime, switchMap, delay } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { PaginationState } from 'src/app/models/pagination/pagination';
import * as moment from "moment-timezone";
import { AuthService } from '../auth/auth.service';
import { User } from 'src/app/models/user/user';

@Injectable({
  providedIn: 'root'
})

export class MembersService {
  // variable that store the import of Globals Class that will help us to retrieve the urls for every service
  globals = Globals
  // Indicates which data is required from
  infoRequired = 'dashboard';
  // Url of users api
  baseUrl = `${this.globals.urlTraiyBase}${this.globals.users.root}`;
  // Liste for new changes on datatable
  private _loading$ = new BehaviorSubject<boolean>(true);
  // Listen for new strings that user provides
  search$ = new Subject<void>();
  // List of members
  private _members$ = new BehaviorSubject<MeetingMember[]>([]);
  // Number of elements shown on datatable
  private _total$ = new BehaviorSubject<number>(0);
  update$ = null;
  userList$ = false;
  // Logged user data
  userLogged: User;
  // State of table response, in charge of display the records as the filters indicate
  private _pagination: PaginationState = {
    page: 1,
    itemsPerPage: 10,
    searchText: '',
    orderColumn: '',
    orderBy: '',
    range_date: { "startDate": moment().clone().startOf('month'), "endDate": moment().clone().endOf('month') },
  };

  constructor(private http: HttpClient,
        private authService: AuthService) {

    this.userLogged = this.authService.loginInfo;
    this.search$.pipe(
      tap(() => this._loading$.next(true)),
      debounceTime(200),
      switchMap(() => this._search()),
      delay(200),
      tap(() => this._loading$.next(false))
    ).subscribe(result => {
      this._members$.next(result['Data']['Users'].map(user => new MeetingMember(user)));
      this._total$.next(result['Data']['Total']);
    });
    this.search$.next();
  }

  // Getters an setters that manage filters an table options
  // that show results on datatable
  get members$() { return this._members$.asObservable(); }
  get total$() { return this._total$.asObservable(); }
  get loading$() { return this._loading$.asObservable(); }
  get page() { return this._pagination.page; }
  get itemsPerPage() { return this._pagination.itemsPerPage; }
  get searchText() { return this._pagination.searchText; }
  get range_date() { return this._pagination.range_date; }
  get update() { return this.update$; }
  get userList() { return this.userList$; }

  set page(page: number) { this._set({page}); }
  set itemsPerPage(itemsPerPage: number) { this._set({itemsPerPage}); }
  set searchText(searchText: string) { this._set({searchText}); }
  set orderColumn(orderColumn: SortColumn) { this._set({orderColumn}); }
  set orderBy(orderBy: SortDirection) { this._set({orderBy}); }
  set range_date(range_date) { this._set({ range_date }); }
  set update(updateRand: string) { this.update$ = updateRand }
  set userList(ulist: boolean) { this.userList$ = ulist }

  // Helper that set values depending on its use
  private _set(patch: Partial<PaginationState>) {
    Object.assign(this._pagination, patch);
    this.search$.next();
  }

  // Applies all filters to final results 
  private _search(): Observable<object>{
    let pagination_params = Object.assign({}, this._pagination);
//    delete pagination_params.range_date;
    const params = Object.keys(pagination_params).map(key => {
      let param;
      if (key != 'range_date'){
        param = key + '=' + pagination_params[key];
      }
      return param;
    }).join('&');
    let url = '';
    let dates = pagination_params['range_date'];
    if(this.infoRequired == 'dashboard'){
      url = `${this.globals.urlTraiyBase}${this.globals.users.root}/`
        + `?${params}`
        + `startDate=${moment(dates['startDate']).startOf('day').toISOString()}`
        + `&endDate=${moment(dates['endDate']).endOf('day').toISOString()}`;
    }else{// inforequired from users modules, this.infoRequired == 'users'
      url = `${this.globals.urlTraiyBase}${this.globals.members.table}?${params}`;
    }
    let user = JSON.parse(localStorage.getItem('traiyUser'));
    let newDates = {
      startDate: this.range_date['startDate'].startOf('day').toISOString(),
      endDate: this.range_date['endDate'].endOf('day').toISOString(),
      userMode: user.hasOwnProperty('userMode') ? user['userMode'] : 0
    };
    if(this.userList$) {
      newDates['startDate'] = moment().startOf('day').toISOString();
      newDates['endDate'] = moment().endOf('day').toISOString();;
      if(this.update$ != null) {
        newDates['updated'] = this.update$;
      }
    }
    url = `https://api.tariy.ai/meeting/events/my-team?${new URLSearchParams(
      newDates
    ).toString()}`;
    return this.http.get(url);
  }

  // Updates the member's list
  public updateMembers() {
    this.search$.next();
  }

}
