
import * as moment from "moment-timezone";
import { MathUtils } from "src/app/utils/mathUtils";
import { MeetingTypesText,  AnalyticTypes, CurrentTypeColors, ForecastTypeColors  } from "src/enum/meeting-types.enum";
import { Role } from "src/enum/Role";
import { MeetingMember } from '../meeting-member/meeting-member';
import { Observable } from 'rxjs';
 
export class HeatmapChart {
  tooltip: {};
  xAxis: {};
  yAxis: {};
  series: any[];
  animationEasing: string;
  animationDelayUpdate: any;
  instance: any;
  legend: any;
  colors: any[];
  forecastColors: any[];
  currenNumber: number;
  forecastNumber: number;
  showForecast: boolean;
  dataSeries: any;
  title: any;
  grid: {};
  visualMap: {};

  constructor(data, label){
    const hours = [
      '12a', '1a', '2a', '3a', '4a', '5a', '6a',
      '7a', '8a', '9a', '10a', '11a',
      '12p', '1p', '2p', '3p', '4p', '5p',
      '6p', '7p', '8p', '9p', '10p', '11p'
    ];
    const days = [
      'Saturday', 'Friday', 'Thursday',
      'Wednesday', 'Tuesday', 'Monday', 'Sunday'
    ];
    this.showForecast = true;
    this.animationEasing = 'elasticOut';
    this.animationDelayUpdate = (idx) => idx * 5;
    this.currenNumber = 1;
    this.forecastNumber = 0;
    this.legend = {
      show: false
    };
    this.grid = {
      height: '50%',
      top: '10%'
    };
    this.xAxis = {
      type: 'category',
      data: hours,
      splitArea: {
        show: true
      }
    };
    this.yAxis = {
      type: 'category',
      data: days,
      splitArea: {
        show: true
      }
    };
    this.visualMap = {
      min: 0,
      max: 10,
      calculable: true,
      orient: 'horizontal',
      left: 'center',
      bottom: '10%'
    };
    this.instance = null;
    this.tooltip = {
      position: 'top'
    };
    this.series = [{
      name: label,
      type: 'heatmap',
      label: {
        show: true
      },
      emphasis: {
        itemStyle: {
          shadowBlur: 10,
          shadowColor: 'rgba(0, 0, 0, 0.5)'
        }
      },
      data: this.dataMapper(data?.incidences),
      animationDelay: (idx) => idx * 10,
    }];
  }
  private setInstance(chart:any, type:string = null): void
  {
     this.instance = chart;
     this.finishLoading();
     if(type != null) {
       this.setChartType(type);
     }
  }
  //transforms to hour,day,amount
  private dataMapper(data:any[]) {
    return data.map(function (item) {
      return [parseInt(item[1]), 6-parseInt(item[0]), parseInt(item[2]) || '-'];
      });
  }
  private turnToPercent(data) {
    return data ? (data*100): 0;
  }


  private finishLoading(): void
  {
     this.instance.hideLoading();
  }

  private setChartType(type:string): void
  {
     this.series.forEach(x => x.type = type);
     this.redraw();
  }

  private redraw(): void
  {
     this.instance.setOption({
       'tooltip': this.tooltip,
       'xAxis': this.xAxis,
       'yAxis': this.yAxis,
       'series': this.series,
       'animationEasing': this.animationEasing,
       'animationDelayUpdate': this.animationDelayUpdate,
       'legend': this.legend
     });
  }
}


