import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { User } from 'src/app/models/user/user';
import { Globals } from '../../global';
import { HttpClient, HttpBackend, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { Role } from 'src/enum/Role';
import { DatePickerConfigService } from '../datepicker-config/datepicker-config.service';
@Injectable({
  providedIn: 'root'
})
export class AuthService{
  // variable to store the currentUsersSubject BehaviorSubject
  private currentUserSubject: BehaviorSubject<User>;
  // variable to store the currentUser observable
  public currentUser: Observable<User>;
  // Stores user info
  public loginInfo: User;
  // variable that store the import of Globals Class that will help us to retrieve the urls for every service
  globals = Globals;
  // Url of users api
  baseUrl = `${this.globals.urlTraiyBase}`;

  constructor(private router: Router,
    private httpClient: HttpClient,
    private handler: HttpBackend,
    private datePickerConfig: DatePickerConfigService) {

      const userFromLocalStorage : User = User.newUserFromLocalStorage(JSON.parse(localStorage.getItem('traiyUser')));
      this.currentUserSubject = new BehaviorSubject<User>(userFromLocalStorage);
      this.currentUser = this.currentUserSubject.asObservable();
      this.getLoginInfo();
  }

  // Validate if the currentUserSubject exists, if exists get its value.
  public get currentUserValue() {
    if (this.currentUserSubject && this.isAuthenticated()) {
      return this.currentUserSubject.value;
    } else {
      this.currentUserSubject.next(null);
      return null;
    }
  }

  // Return the variable that is stored in storage
  getLoginInfo(): void{
    if (localStorage.getItem('traiyUser') != null) {
      const userFromLocalStorage : User = User.newUserFromLocalStorage(JSON.parse(localStorage.getItem('traiyUser')));
      this.loginInfo = userFromLocalStorage;
    }
  }

  // Verifies if user is logged in
  isLoggedIn(): void{
    if (!this.loginInfo) {
      this.router.navigate(['/login']);
    }else{
      if (this.currentUserValue && (this.currentUserValue.role === Role.SuperAdmin || this.currentUserValue.role === Role.Admin || this.currentUserValue.role === Role.Manager)) {
        this.router.navigate(['/admin']);
      }else if (this.currentUserValue && this.currentUserValue.role === Role.Member){
        this.router.navigate(['/member']);
      }else if (this.currentUserValue && this.currentUserValue.role === Role.Waiting){
        this.router.navigate(['/onboarding']);
      }else if (this.currentUserValue && this.currentUserValue.role === Role.ITAdmin){
        this.router.navigate(['/itadmin']);
      }else{
        this.router.navigate(['/login']);
      }
    }
  }

  // Verifies if user is authenticated
  isAuthenticated(): boolean {
    if (this.loginInfo) {
      const token = this.loginInfo.token;
      if (token) {
        return true;
      }
      return false;
    }
    return false;
  }

  // Remove access for user on local storage
  logout(): void {
    const currentUser = localStorage.getItem('traiyUser');
    if (currentUser) {
      // resets the date picker selection
      this.datePickerConfig.resetSelectedDateRange();

      // remove user from local storage to log user out
      localStorage.removeItem('traiyUser');
      this.currentUserSubject.next(null);
      this.router.navigate(['login']);
      this.loginInfo = null;
    }
  }

  /**
   * Method that saves users at local storage
   * @param account User data
   */
   setUser(account: User): boolean{
    this.loginInfo = new User(account);
    localStorage.setItem('traiyUser', JSON.stringify(this.loginInfo));
    this.currentUserSubject.next(this.loginInfo);
    return true;
  }

  /**
   * Method that saves users at local storage
   * @param account User data
   */
   updateUser(account: User): boolean{
    localStorage.setItem('traiyUser', JSON.stringify(this.loginInfo));
    this.currentUserSubject.next(account);
    return true;
  }

    /**
   * Method that request an access to website, by giving an auth code
   * @param code - Authorization code provided by Google or Microsoft
   * @returns token if the authentication is correct, error if not
   */
  login(code: string, providerName: string, redirect: string): Observable<User>{
    const authCode = {
      "Authenticator" : providerName,
      "AuthorizationToken": code,
      "RedirectURI": redirect
    };
    this.httpClient = new HttpClient(this.handler);// Pass handler in order to exclude this request from interceptor
    //return this.httpClient.post(  withCredentials:true)
    //let requets= { url:`${this.baseUrl}${this.globals.users.login}`,body: authCode , withCredentials:true};
     
    if(providerName == 'SassForm')
    {
      alert("Request Withcredentials");
      return this.httpClient.post( `${this.baseUrl}${this.globals.users.login}`, authCode,{withCredentials:true})
      .pipe(
        map(result => {
            let local = result['Data']['User'];
            local['Auth_token'] = result['Data']['Token'];
            this.loginInfo = new User(local);
            localStorage.setItem('traiyUser', JSON.stringify(this.loginInfo));
            this.currentUserSubject.next(this.loginInfo);
            return this.loginInfo;
          })
        );

    }
     
    return this.httpClient.post( `${this.baseUrl}${this.globals.users.login}`, authCode)
      .pipe(
        map(result => {
            let local = result['Data']['User'];
            local['Auth_token'] = result['Data']['Token'];
            this.loginInfo = new User(local);
            localStorage.setItem('traiyUser', JSON.stringify(this.loginInfo));
            this.currentUserSubject.next(this.loginInfo);
            return this.loginInfo;
          })
        );
  }

  /**
   * Method that gets token and access_token that provide info from Microsoft
   * @returns both tokens if the authentication is correct, error if not
  */
  async getMicrosoftTokens(): Promise<object>{
    let options = {
      headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
    };

    return await this.httpClient.post(`https://login.microsoftonline.com/${environment.tenant}/oauth2/v2.0/token`,{
      client_id: environment.microsoft_client_id,
      scope: 'https://graph.microsoft.com/.default',
      client_secret: environment.client_secret,
      grant_type: 'client_credentials'
    }, options).toPromise();
  }

}
