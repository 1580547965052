<section class="redeem-code" *ngIf="providerInfo.name">
  <div class="container">
    <div class="row">
      <div class="col-8 offset-2">
        <div class="code-modal">
          <img src="../../../assets/img/tariy-login.png" alt="" class="mb-3">
          <br>
          <div class="spinner-border text-primary mb-3" role="status">
            <span class="sr-only">Loading...</span>
          </div>
          <p>Building your personalized experience ...</p>           
        </div>
      </div>
    </div>
  </div>
</section>



