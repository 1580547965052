import { Component, HostListener, OnInit, ViewChildren } from '@angular/core';
import { DepartmentsService } from 'src/app/services/departments/departments.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Department } from 'src/app/models/department/department';
import { ActivatedRoute, ActivatedRouteSnapshot } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { title } from 'process';
import { Observable } from 'rxjs';
import { DatePickerRanges, DefaultRangeSelected, RangeDate } from 'src/app/configuration/datepicker-range';
import { ChartMetric } from 'src/app/models/chart-metric/chart-metrics';
import { MeetingMember } from '../../models/meeting-member/meeting-member';
import { DatePickerConfigService } from 'src/app/services/datepicker-config/datepicker-config.service';
import { InsightsService } from 'src/app/services/insights/insights.service';
import { ReportService } from 'src/app/services/reports/report.service';
import { MembersService } from '../../services/members/members.service';
import { MeetingTypes, MeetingTypesText, MeetingtoTotals, TypeUnit, NotImplemented } from 'src/enum/meeting-types.enum';
import { DomSanitizer } from '@angular/platform-browser';
import { EChartsOption } from 'echarts';
import { csv, range } from 'd3';
import { faCalendar } from '@fortawesome/free-solid-svg-icons';
import { IDropdownSettings, NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import {duration} from 'moment-timezone';
import * as moment from 'moment-timezone';


@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {
  user;
  profileForm: FormGroup;
  departments: Department[] = [];
  isSubmitted = false;
  editable = false;
  isLoading = true;
  validPicture = true;

  constructor(
    private deptService: DepartmentsService,
    private fb: FormBuilder,
    private toastService: ToastrService,
  ) {
  }

  async ngOnInit() {
    this.user = JSON.parse(localStorage.getItem('traiyUser'));
    console.log(this.user);
    console.log((this.user?.useData !== undefined))
    this.profileForm = this.fb.group({
      departmentId: [this.user.departmentId, [Validators.required]],
      jobSelected: [this.user.occupationName, [Validators.required]],
      firstName: [this.user.firstname, [Validators.required]],
      lastName: [this.user.lastname, [Validators.required]],
      email: [this.user.email, [Validators.required]],
      useData: [this.user?.useData ?? true,],
    });

    await this.getDepartments();
  }

  get formFields() {
    return this.profileForm.controls;
  }

  getDepartments() {
    this.deptService.getDepartments().then(data => {
      this.departments = data['Data'].map(item => new Department(item));
      this.isLoading = false;
    }).catch(err => {
      this.departments = [];
      this.toastService.error("Can't load list of departments", "Departments");
    })
  }

  getUserValues(): Object {
    return {
      firstname: this.formFields.firstName.value,
      lastname: this.formFields.lastName.value,
      useData: this.formFields.useData.value,
      DepartmentId: this.formFields.departmentId.value,
      Department: this.departments.find(e => e.id == this.formFields.departmentId.value).name,
      OccupationId: this.formFields.jobSelected.value,
      Occupation: this.formFields.jobSelected.value,
    }
  }

  invalidPicture(): void {
    this.validPicture = false;
  }

  saveChanges() {
    if (this.isLoading){
      return;
    }
    this.isSubmitted = true;
    this.isLoading = true;

    
    if (this.profileForm.invalid) {
      this.isLoading = false;
      this.toastService.error('Verify that required inputs are filled.', 'On-boarding');
      return;
    }
    const userValues = this.getUserValues();
    this.user.firstname = userValues['firstname'];
    this.user.lastname = userValues['lastname'];
    this.user.useData = userValues['useData'];
    this.user.DepartmentId = userValues['DepartmentId'];
    this.user.Department = userValues['Department'];
    this.user.OccupationId = userValues['OccupationId'];
    this.user.Occupation = userValues['Occupation'];
    localStorage.setItem('traiyUser', JSON.stringify(this.user));
    this.isLoading = false;
    this.editable = !this.editable;
  }

  enableEdit() {
    this.editable = !this.editable;
  }

}
