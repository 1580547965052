import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Globals } from 'src/app/global';
import { HttpClient } from '@angular/common/http';
import { PaginationState } from 'src/app/models/pagination/pagination';
import { HistoryMetric } from 'src/app/models/history-metric/history-metrics';
import { Subject } from 'rxjs';
import { tap, debounceTime, switchMap, delay } from 'rxjs/operators';
import { AuthService } from '../auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class HistoryService {
  // Listen for new strings that user provides
  private _search$ = new Subject<void>();
  private _pastSearch$ = new Subject<void>();
  // Listen for new changes on datatable or grid
  private _loading$ = new BehaviorSubject<boolean>(true);
  private _pastLoading$ = new BehaviorSubject<boolean>(true);
  // variable that store the import of Globals Class that will help us to retrieve the urls for every service
  globals = Globals;
  // Url of users api
  baseUrl = `${this.globals.urlTraiyBase}`;
  // List of members
  private _metrics$ = new BehaviorSubject<any>([]);
  private _pastMetrics$ = new BehaviorSubject<any>([]);
  private _meetingScore$ = new BehaviorSubject<any>('N/A');
  private _pastMeetingScore$ = new BehaviorSubject<any>(0);
  // Indicates if two ranges will be compared
  private _both_ranges = false;

  private _pagination: PaginationState = {
    page: 1,
    itemsPerPage: 12,
    searchText: '',
    orderColumn: '',
    orderBy: '',
    range_date: null,
    range_date_second: null
  };
  constructor(private httpClient: HttpClient,
    private authService: AuthService) {
    this._search$.pipe(
      tap(() => this._loading$.next(true)),
      debounceTime(200),
      switchMap(() => this._search()),
      delay(200),
      tap(() => this._loading$.next(false))
    ).subscribe(result => {
      let metrics = [];
      for (var key in result['Insights']) {
        result['Insights'][key].query_params.computation = key;
        result['Insights'][key].role = this.authService.loginInfo.role;
        metrics[key] = new HistoryMetric(result['Insights'][key]);
      }
      try {
        if(metrics["busy_time"]){
          metrics["busy_time"].hours = metrics["busy_time"].hours ? metrics["busy_time"].hours?.time.total.hours : 0;      
        }      
        if(metrics["late_time"]){
          metrics["late_time"].hours = metrics["late_time"].hours ? metrics["late_time"].hours?.time.total.hours : 0;      
        }
        if (metrics["free_time"]) {
          const freeTime = metrics["free_time"];
           
          metrics["free_time"].hours = freeTime.hours ?? freeTime.hours?.time?.total.hours ?? 0;
        }
      } catch (error) {

      }
      this._metrics$.next(metrics);
      this._meetingScore$.next(result['Score']);
    });
    this._pastSearch$.pipe(
      tap(() => this._pastLoading$.next(true)),
      debounceTime(200),
      switchMap(() => this._pastSearch()),
      delay(200),
      tap(() => this._pastLoading$.next(false))
    ).subscribe(result => {
      let metrics = [];
      //console.log(`metric in service ${JSON.stringify( result)}`)
      for (var key in result['Insights']) {
        result['Insights'][key].query_params.computation = key;
        result['Insights'][key].role = this.authService.loginInfo.role;
        metrics[key] = new HistoryMetric(result['Insights'][key]);
      }
      try {
        if(metrics["busy_time"]){
          metrics["busy_time"].hours = metrics["busy_time"].hours ? metrics["busy_time"].hours?.time.total.hours : 0;      
        }      
        if(metrics["late_time"]){
          metrics["late_time"].hours = metrics["late_time"].hours ? metrics["late_time"].hours?.time.total.hours : 0;      
        }
        if (metrics["free_time"]) {
          const freeTime = metrics["free_time"];
           
          metrics["free_time"].hours = freeTime.hours ?? freeTime.hours?.time?.total.hours ?? 0;
        }
      } catch (error) {

      }
      this._pastMetrics$.next(metrics);
      this._pastMeetingScore$.next(result['Score']);
    });
  }

  // Getters an setters that manage filters an table options
  // that show results on datatable
  get metrics$() { return this._metrics$.asObservable(); }
  get pastMetrics$() { return this._pastMetrics$.asObservable(); }
  get score$() { return this._meetingScore$.asObservable(); }
  get pastScore$() { return this._pastMeetingScore$.asObservable(); }
  get loading$() { return this._loading$.asObservable(); }
  get pastLoading$() { return this._pastLoading$.asObservable(); }
  get range_date() { return this._pagination.range_date; }
  get range_date_second() { return this._pagination.range_date_second; }
  get both_ranges() { return this._both_ranges; }

  set both_ranges(both_ranges: boolean) { this._both_ranges = both_ranges; }
  set range_date(range_date) {
    this._set({ range_date });
  }
  set range_date_second(range_date_second) {
    this._set({ range_date_second });
  }

  // Helper that set values depending on its use
  private _set(patch: any) {
    Object.assign(this._pagination, patch);

    if (!this.both_ranges) {
      this._search$.next();
      this._pastSearch$.next();
    } else {
      if (this.range_date != null && this.range_date_second != null && this.range_date['startDate'] != null && this.range_date_second['startDate'] != null) {
        this._search$.next();
        this._pastSearch$.next();
      }
    }
  }

  async _search(): Promise<object> {
    this._metrics$.next([]);
    let user = JSON.parse(localStorage.getItem('traiyUser'));
    let dates = {
      starts: this.range_date['startDate'].startOf('day').toISOString(),
      ends: this.range_date['endDate'].endOf('day').toISOString(),
      userMode: user.hasOwnProperty('userMode') ? user['userMode'] : 0
    };
    if (this.range_date_second) {
      dates['starts2'] = this.range_date_second['startDate'].startOf('day').toISOString();
      dates['ends2'] = this.range_date_second['endDate'].endOf('day').toISOString();
    }
    const url = `${this.globals.availabilities.history}?${new URLSearchParams(dates).toString()}`;
    const url2 = `https://api.tariy.ai/meeting/meetings/insights?${new URLSearchParams(
      dates
    ).toString()}`;
    let metrics = await this.httpClient.get(`${url2}`).toPromise();

    return metrics;
  }

  async _pastSearch(): Promise<object> {
    this._pastMetrics$.next([]);
    let user = JSON.parse(localStorage.getItem('traiyUser'));
    let difference = Math.ceil(this.range_date['endDate'].endOf('day').diff(this.range_date['startDate'].startOf('day'), 'hours')/24);
    let dates = {
      starts: this.range_date['startDate'].clone().subtract(difference,'d').startOf('day').toISOString(),
      ends: this.range_date['endDate'].clone().subtract(difference,'d').endOf('day').toISOString(),
      userMode: user.hasOwnProperty('userMode') ? user['userMode'] : 0
    };
    if (this.range_date_second) {
      dates['starts2'] = this.range_date_second['startDate'].startOf('day').toISOString();
      dates['ends2'] = this.range_date_second['endDate'].endOf('day').toISOString();
    }
    const url = `${this.globals.availabilities.history}?${new URLSearchParams(dates).toString()}`;
    const url2 = `https://api.tariy.ai/meeting/meetings/insights?${new URLSearchParams(
      dates
    ).toString()}`;
    let metrics = await this.httpClient.get(`${url2}`).toPromise();

    return metrics;
  }

}
