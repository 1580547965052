
import * as moment from "moment-timezone";
import { MathUtils } from "src/app/utils/mathUtils";
import { MeetingTypesText,  AnalyticTypes  } from "src/enum/meeting-types.enum";
import { Role } from "src/enum/Role";
import { MeetingMember } from '../../models/meeting-member/meeting-member';
import { Observable } from 'rxjs';
 
export class HistoryMetric {
  title: string;
  computation: string;
  //date_start: string;
  //date_end: string;
  total: number | null;
  hours: number | null;
  counter: number | null;
  result: number;
  insights : string[];
  role :string;
  loaded: boolean;
  has_agenda: number;
  btb: number;
  meet_under_45: number;
  meet_under_8: number;
  meet_over_30: number;
  meet_over_45: number;
  meet_over_5: number;
  meet_over_8: number;
  meet_over_avg: number;
  meet_under_avg: number;
  std: number;
  accepted: number;
  mailed: number;
  notice: number;
  acceptRate: number;
  male: number;
  female: number;
  nonBinary: number;
  genderUnknown: number;
  white: number;
  black: number;
  asian: number;
  native: number;
  pacific: number;
  hispanic: number;
  notHispanic: number;
  raceUnknown: number;
  b_20_30: number;
  b_30_40: number;
  b_40_50: number;
  b_50_60: number;
  b_60_70: number;
  b_70_80: number;
  ageUnkown: number;
  topOrganizerGender: number;
  topGender: string;
  topOrganizerRace: number;
  topRace: string;
  topOrganizerAge: number;
  topAge: string;
  top: string;
  
  
  positive: number;
  negative: number;
  neutral: number;
  int_m: number;
  int_f: number;
  int_nb: number;
  int_gz: number;
  int_ml: number;
  int_gx: number;
  int_bb: number;
  topParticipationGender: number;
  participationGender: string;
  topParticipationRace: number;
  participationRace: string;
  topParticipationAge: number;
  participationAge: string;

  constructor(data){
    
    this.computation = data.query_params.computation;
    this.role = data.role;
    this.title = MeetingTypesText[this.computation];
    //this.date_start  = moment(data.query_params.starts).format("LL").toString();
    //this.date_end  = moment(data.query_params.ends).format("LL").toString();
    //this.total = data.Cost.total != null ? data.Cost.total.cost.total : 0;
    //this.hours = data.Time.total != null ? data.Time.total : 0;
    this.total = 0;
    this.hours = 0;
    this.counter = 0;
    
    this.has_agenda = 0;
    this.btb = 0;
    this.meet_under_45 = 0;
    this.meet_under_8 = 0;
    this.notice = 0;
    this.acceptRate = 0;

    
    this.meet_over_30 = 0
    this.meet_over_45 = 0
    this.meet_over_5 = 0
    this.meet_over_8 = 0
    this.meet_over_avg = 0
    this.meet_under_avg = 0
    this.std = 0
    this.accepted = 0
    this.mailed = 0

    
    this.male = 0
    this.female = 0
    this.nonBinary = 0
    this.genderUnknown = 0
    this.white = 0
    this.black = 0
    this.asian = 0
    this.native = 0
    this.pacific = 0
    this.hispanic = 0
    this.notHispanic = 0
    this.raceUnknown = 0
    this.b_20_30 = 0
    this.b_30_40 = 0
    this.b_40_50 = 0
    this.b_50_60 = 0
    this.b_60_70 = 0
    this.b_70_80 = 0
    this.ageUnkown = 0
    this.topOrganizerGender = 0
    this.topGender = 'Unknown'
    this.topOrganizerRace = 0
    this.topRace = 'Unknown'
    this.topOrganizerAge = 0
    this.topAge = 'Unknown'
    this.top = 'Unknown'

    this.positive = 0;
    this.negative = 0;
    this.neutral = 0;
    
    this.int_m = 0;
    this.int_f = 0;
    this.int_nb = 0;
    this.int_gz = 0;
    this.int_ml = 0;
    this.int_gx = 0;
    this.int_bb = 0;
    this.topParticipationGender = 0;
    this.participationGender = 'Unknown';
    this.topParticipationRace = 0;
    this.participationRace = 'Unknown';
    this.topParticipationAge = 0;
    this.participationAge = 'Unknown';

    if ( data.query_params.computation == AnalyticTypes.meet_cost)
    {
      this.insights = this.getMeetingCostText(data); 
      this.total = parseFloat(data.total);
      this.loaded = true;
    }
    else if (data.query_params.computation == AnalyticTypes.avg_meet_cost) 
    {
      this.insights = this.getMeetingAverageCostsText(data); 
      this.total = parseFloat(data.avg);
      this.loaded = true;
      this.meet_over_avg = parseInt(data.over_avg);
      this.meet_under_avg = parseInt(data.under_avg);
      this.std = parseFloat(data.std);
    }
    else if (data.query_params.computation == AnalyticTypes.meet_time) 
    {
      this.insights = this.getMeetingTimesText(data); 
      this.total = parseFloat(data.total);
      this.hours = parseFloat(data.total);
      this.loaded = true;
    }
    else if (data.query_params.computation == AnalyticTypes.meet_len) 
    {
      this.insights = this.getMeetingAverageLengthText(data); 
      this.hours = parseFloat(data.avg);
      this.meet_under_45 = parseFloat(data.meet_over);
      this.loaded = true;
      this.meet_over_30 = parseInt(data.meet_45);
      this.meet_over_45 = parseInt(data.meet_over);
    }
    else if (data.query_params.computation == AnalyticTypes.meet_num) 
    {
      this.insights = this.getMeetingDetailsText(data); 
      this.hours = parseInt(data.total);
      this.counter = parseInt(data.total);
      this.has_agenda = parseInt(data.agenda);
      this.btb = parseInt(data.btb);
      this.notice = parseFloat(data.notice);
      this.loaded = true;
    }
    else if (data.query_params.computation == AnalyticTypes.meet_par) 
    {
      this.insights = this.getMeetingParticipantText(data); 
      this.hours = parseFloat(data.avg);
      this.counter = parseFloat(data.avg);
      this.meet_under_8 = parseInt(data.over_eig);
      this.acceptRate = parseFloat(data.acceptRate);
      this.loaded = true;
      this.meet_over_5 = parseInt(data.over_eig);
      this.meet_over_8 = parseInt(data.over_fiv);
      this.accepted = parseInt(data.accept);
      this.mailed = parseInt(data.mailed);
    }
    else if (data.query_params.computation == AnalyticTypes.per_time) 
    {
      this.insights = this.getPersonalText(data); 
      this.hours = parseFloat(data.hours);
      this.counter = parseFloat(data.hours);
      this.loaded = true;
    }
    else if (data.query_params.computation == AnalyticTypes.deep_time) 
    {
      this.insights = this.getDeepText(data); 
      this.hours = parseFloat(data.hours);
      this.counter = parseFloat(data.hours);
      this.loaded = true;
    }
    else if (data.query_params.computation == AnalyticTypes.meet_gen) 
    {
      this.insights = ['No Insights available.']; 
      this.male = this.getPercentage(data.male, data.total);
      this.female = this.getPercentage(data.female, data.total);
      this.nonBinary = this.getPercentage(data.nonBinary, data.total);
      this.genderUnknown = this.getPercentage(data.unknown, data.total);
      if(this.male > this.female && this.male > this.nonBinary) {
        this.top = 'Male'
      }
      else if(this.female > this.male && this.female > this.nonBinary) {
        this.top = 'Female'
      }
      else if(this.nonBinary > this.male && this.nonBinary > this.female) {
        this.top = 'Non-Binary'
      }
      this.loaded = true;
    }
    else if (data.query_params.computation == AnalyticTypes.meet_emo) 
    {
      this.insights = ['No Insights available.']; 
      this.positive = parseFloat(data?.positive) / 100;
      this.negative = parseFloat(data?.negative) / 100;
      this.neutral = parseFloat(data?.neutral) / 100;
      this.int_m = parseInt(data?.int_m ?? 0);
      this.int_f = parseInt(data?.int_f ?? 0);
      this.int_nb = parseInt(data?.int_nb ?? 0);
      this.int_gz = parseInt(data?.int_gz ?? 0);
      this.int_ml = parseInt(data?.int_ml ?? 0);
      this.int_gx = parseInt(data?.int_gx ?? 0);
      this.int_bb = parseInt(data?.int_bb ?? 0);
      this.total = this.int_m + this.int_f + this.int_nb
      this.loaded = true;
    }
    else if (data.query_params.computation == AnalyticTypes.meet_race) 
    {
      this.insights = ['No Insights available.'];
      let max = 0;
      this.white = this.getPercentage(data.white, data.total);
      if(this.white > max) {
        max = this.white;
        this.top = 'White';
      }
      this.black = this.getPercentage(data.black, data.total);
      if(this.black > max) {
        max = this.black;
        this.top = 'Black';
      }
      this.asian = this.getPercentage(data.asian, data.total);
      if(this.asian > max) {
        max = this.asian;
        this.top = 'Asian';
      }
      this.native = this.getPercentage(data.native, data.total);
      if(this.native > max) {
        max = this.native;
        this.top = 'Native American';
      }
      this.pacific = this.getPercentage(data.pacific, data.total);
      if(this.pacific > max) {
        max = this.pacific;
        this.top = 'Pacific Islander';
      }
      this.hispanic = this.getPercentage(data.hispanic, data.total);
      if(this.hispanic > max) {
        max = this.hispanic;
        this.top = 'Hispanic';
      }
      this.notHispanic = this.getPercentage(data.notHispanic, data.total);
      if(this.notHispanic > max) {
        max = this.notHispanic;
        this.top = 'Not Hispanic';
      }
      this.raceUnknown = this.getPercentage(data.unknown, data.total);
      this.loaded = true;
    }
    else if (data.query_params.computation == AnalyticTypes.meet_age) 
    {
      this.insights = ['No Insights available.'];
      let max = 0
      this.b_20_30 = this.getPercentage(data['20-30'], data.total);
      if(this.b_20_30 > max) {
        max = this.b_20_30;
        this.top = 'Gen Z';
      }
      this.b_30_40 = this.getPercentage(data['30-40'], data.total);
      if(this.b_30_40 > max) {
        max = this.b_30_40;
        this.top = 'Millenial';
      }
      this.b_40_50 = this.getPercentage(data['40-50'], data.total);
      if(this.b_40_50 > max) {
        max = this.b_40_50;
        this.top = 'Gen X';
      }
      this.b_50_60 = this.getPercentage(data['50-60'], data.total);
      if(this.b_50_60 > max) {
        max = this.b_50_60;
        this.top = 'Baby Boomer';
      }
      this.b_60_70 = this.getPercentage(data['60-70'], data.total);
      this.b_70_80 = this.getPercentage(data['70-80'], data.total);
      this.ageUnkown = this.getPercentage(data.unknown, data.total);
      this.loaded = true;
    }
    else if (data.query_params.computation == AnalyticTypes.meet_org) 
    {
      this.insights = ['No Insights available.']; 
      this.topOrganizerGender = parseFloat(data.gender) / 100;
      this.topGender = data['top-gender'];
      this.topOrganizerRace = parseFloat(data.race) / 100;
      this.topRace = data['top-race'];
      this.topOrganizerAge = parseFloat(data.age) / 100;
      this.topAge = data['top-age'];
      this.loaded = true;
      let random = Math.floor(Math.random() * 4)
      if(random == 1)
        this.top = this.topGender
      else if(random == 2)
        this.top = this.topRace
      else if(random == 3){
        if(this.topAge == '20-30')
          this.top = 'Gen Z'
        else if(this.topAge == '30-40')
          this.top = 'Millenial'
        else if(this.topAge == '40-50')
          this.top = 'Gen X'
        else if(this.topAge == '50-60')
          this.top = 'Baby Boomer'
        else
          this.top = this.topAge
      }
    }
    else if (data.query_params.computation == AnalyticTypes.meet_part) 
    {
      this.insights = ['No Insights available.']; 
      this.topParticipationGender = parseFloat(data.gender) / 100;
      this.participationGender = data['top-gender'];
      this.topParticipationRace = parseFloat(data.race) / 100;
      this.participationRace = data['top-race'];
      this.topParticipationAge = parseFloat(data.age) / 100;
      this.participationAge = data['top-age'];
      this.loaded = true;
      let random = Math.floor(Math.random() * 4)
      if(random == 1)
        this.top = this.participationGender
      else if(random == 2)
        this.top = this.participationRace
      else if(random == 3){
        if(this.participationAge == '20-30')
          this.top = 'Gen Z'
        else if(this.participationAge == '30-40')
          this.top = 'Millenial'
        else if(this.participationAge == '40-50')
          this.top = 'Gen X'
        else if(this.participationAge == '50-60')
          this.top = 'Baby Boomer'
        else
          this.top = 'Unknown'
      }
    }
    /*if ( data.query_params.computation == AnalyticTypes.meeting_cost)
    {
      this.insights = this.getMeetingCostInsights(data);
      this.loaded = true;
    }

    else if ( data.query_params.computation == AnalyticTypes.avg_meeting_cost)
    {
      this.insights = this.getMeetingAverageCostsInsights(data);       
      this.loaded = true;
    }
    
    else if ( data.query_params.computation == AnalyticTypes.avg_user_cost)
    {
      this.insights = this.getMeetingAverageUserCostInsights(data);
      this.loaded = false;
    }
    
    else if ( data.query_params.computation == AnalyticTypes.busy_time)
    {
      this.insights = this.getMeetingBusyTimeInsights(data);
      this.loaded = false;
    }

    else if ( data.query_params.computation == AnalyticTypes.free_time)
    {
      this.insights = this.getMeetingFreeTimeInsights(data);
      this.loaded = false;
      try {         
        this.hours = data?.Time?.total?.time?.available?.hours ?? 0;
      } catch (error) {
        console.log(error);
      }
    }
    else if (data.query_params.computation == AnalyticTypes.late_participants)//data.query_params.computation == AnalyticTypes.late_time || 
    {
      this.insights = this.getMeetingLateTimeInsights(data);
      this.loaded = false;
      try {
        this.hours = data?.Analytics?.total_minutes_late ?? 0;
      }catch(error){
        console.log(error);
      }
    }*/


  }

  /**
   * 
   * @param data 
   * @example 
   * Top 3 most costly meetings in USD	The cost of the most expensive meetings is: 1. $830, 2. $500, 3. $450
   *  Top 3 most cheap meetings in USD	The cost of the least expensive meetings is: 1. $350, 2. $370, 3. $380
   *  Most costly meeting by topic	"Daily Meeting" is the most costly subject.
   * @returns 
   */
   private getMeetingCostText(data:any): string[]
   {
     const meetingData =data?.expensive;
     
     if(!meetingData )
     {
       return ['No Insights available.'];
     }
     
     const expensiveMeetings = data.expensive;
     const cheapMeetings = data.cheapest;
 
     let result = [];
     if(expensiveMeetings?.length>0)
     {
       result.push(`The most expensive meetings are: `+ 
         `${expensiveMeetings.map( m=> '"' + m['title'] + '" with $'+ MathUtils.fixNumbers(m['cost'])).join(', ')}` );
     }
 
     if(cheapMeetings?.length>0)
     {
       result.push(`The least expensive meetings are: `+ 
         `${cheapMeetings.map( m=> '"' + m['title'] + '" with $'+ MathUtils.fixNumbers(m['cost'])).join(', ')}` );
     }
 
     if (result.length==0)
     {
       result.push('No Insights available.');
     }
 
     return result;
   }

   /**
   * 
   * @param data 
   * @example 
   * Number of meetings that fall within +/- 34% of the average cost
   * Number of meetings over the average cost
   * Number of meetings under the average cost
   * @returns 
   */
    private getMeetingAverageCostsText(data:any): string[]
    {
      const meetingData =data?.avg;
      
      if(!meetingData )
      {
        return ['No Insights available.'];
      }
      
     //const within_average = (data.?? 0) < 0 ? 0: (meetingData.within_average??0);
     const over_average = (data.over_avg??0) < 0 ? 0: data.over_avg??0 ;
     const under_average  = (data.under_avg??0) < 0  ? 0: data.under_avg ??0;
     const average  = (meetingData??0) < 0  ? 0: meetingData ??0;
  
      let result = [
       //`The total number of meetings the fall within +/- 10% (one standard deviation) of the average cost are ${within_average}.`,
       `The total number of meetings that are greater than $${MathUtils.fixNumbers(average)} cost are ${over_average}.`,
       `The total number of meetings that are less than $${MathUtils.fixNumbers(average)} meeting cost are ${under_average}.`,
      ];
        
  
      return result;
    }

    /**
   * 
   * @param data 
   * @example 
   * Top 3 most costly meetings in USD	The cost of the most expensive meetings is: 1. $830, 2. $500, 3. $450
   *  Top 3 most cheap meetings in USD	The cost of the least expensive meetings is: 1. $350, 2. $370, 3. $380
   *  Most costly meeting by topic	"Daily Meeting" is the most costly subject.
   * @returns 
   */
   private getMeetingTimesText(data:any): string[]
   {
     const meetingData =data?.longest;
     if(!meetingData )
     {
       return ['No Insights available.'];
     }
     
     const longestMeetings = data.longest;
     const shortestMeetings = data.shortest;
 
     let result = [];
     if(longestMeetings?.length>0)
     {
       result.push(`The longest meetings are: `+ 
         `${longestMeetings.map( m=> '"' + m['title'] + '" with a time of '+ MathUtils.fixNumbers(m['duration']) + ' hours').join(', ')}` );
     }
 
     if(shortestMeetings?.length>0)
     {
       result.push(`The shortest meetings are: `+ 
         `${shortestMeetings.map( m=> '"' + m['title'] + '" with a time of '+ MathUtils.fixNumbers(m['duration']) + ' hours').join(', ')}` );
     }
 
     if (result.length==0)
     {
       result.push('No Insights available.');
     }
 
     return result;
   }

   private getMeetingAverageLengthText(data:any): string[]
    {
      const meetingData =data?.avg;
      
      if(!meetingData )
      {
        return ['No Insights available.'];
      }
      
     //const within_average = (data.?? 0) < 0 ? 0: (meetingData.within_average??0);
     const over = (data.meet_over??0) < 0 ? 0: data.meet_over??0 ;
     const within  = (data.meet_45??0) < 0  ? 0: data.meet_45 ??0;
  
      let result = [
       //`The total number of meetings the fall within +/- 10% (one standard deviation) of the average cost are ${within_average}.`,
       `The total number of meetings that are longer than 1 hour are ${over}.`,
       `The total number of meetings that are between 1 hour and 45 minutes are ${within}.`,
      ];
        
  
      return result;
    }

    private getMeetingDetailsText(data:any): string[]
    {
      const meetingData =data?.total;
      
      if(!meetingData )
      {
        return ['No Insights available.'];
      }
      
     //const within_average = (data.?? 0) < 0 ? 0: (meetingData.within_average??0);
     const weekdays = data.days;
     const most_participants = data.most;
     const btb  = (data.btb??0) < 0  ? 0: data.btb ??0;
     const agenda  = (data.agenda??0) < 0  ? 0: data.agenda ??0;
  
      let result = [
       //`The total number of meetings the fall within +/- 10% (one standard deviation) of the average cost are ${within_average}.`,
       `The total number of meetings with agenda are ${agenda}.`,
       `The total number of meetings back to back are ${btb}.`,
      ];
      
      if(weekdays?.length>0)
     {
       result.push(`The day(s) with more meetings are: `+ 
         `${weekdays.map( m=> '"' + m['weekday'] + '" with '+ MathUtils.fixNumbers(m['count']) + ' meetings').join(', ')}` );
     }
     if(most_participants?.length>0)
     {
       result.push(`The meetings with the most participants are: `+ 
         `${most_participants.map( m=> '"' + m['title'] + '" with '+ MathUtils.fixNumbers(m['number']) + ' participants').join(', ')}` );
     }
        
  
      return result;
    }

    private getMeetingParticipantText(data:any): string[]
    {
      const meetingData =data?.avg;
      
      if(!meetingData )
      {
        return ['No Insights available.'];
      }
      
     //const within_average = (data.?? 0) < 0 ? 0: (meetingData.within_average??0);
     const organizer = data.organizer;
     const over_eig  = (data.over_eig??0) < 0  ? 0: data.over_eig ??0;
     const over_fiv  = (data.over_fiv??0) < 0  ? 0: data.over_fiv ??0;
  
      let result = [
       //`The total number of meetings the fall within +/- 10% (one standard deviation) of the average cost are ${within_average}.`,
       `The total number of meetings that have over 8 participants is ${over_eig}.`,
       `The total number of meetings that are between 5 and 8 participants are ${over_fiv}.`,
      ];
      
      if(organizer?.length>0)
     {
       result.push(`The organizers with more meetings are: `+ 
         `${organizer.map( m=> '"' + m['name'] + '" with '+ MathUtils.fixNumbers(m['count']) + ' meetings').join(', ')}` );
     }
        
  
      return result;
    }

    private getPersonalText(data:any): string[]
    {
      const meetingData =data?.most;
      
      if(!meetingData )
      {
        return ['No Insights available.'];
      }
      
     //const within_average = (data.?? 0) < 0 ? 0: (meetingData.within_average??0);
     const most = data.most;
     const least  = data.least;
  
      let result = [];
      
      if(most?.length>0)
     {
       result.push(`The participants with more personal time are: `+ 
         `${most.map( m=> '"' + m['name'] + '" with '+ MathUtils.fixNumbers(m['count']) + ' events totaling ' + MathUtils.fixNumbers(m['hours']) + ' hours').join(', ')}` );
     }
     if(least?.length>0)
     {
       result.push(`The participants with the least personal time are: `+ 
         `${most.map( m=> '"' + m['name'] + '" with '+ MathUtils.fixNumbers(m['count']) + ' events totaling ' + MathUtils.fixNumbers(m['hours']) + ' hours').join(', ')}` );
     }
        
     if(result.length == 0) {
      return ['No Insights available.'];
     }
      return result;
    }

    private getDeepText(data:any): string[]
    {
      const meetingData =data?.most;
      
      if(!meetingData )
      {
        return ['No Insights available.'];
      }
      
     //const within_average = (data.?? 0) < 0 ? 0: (meetingData.within_average??0);
     const most = data.most;
     const least  = data.least;
  
      let result = [];
      
      if(most?.length>0)
     {
       result.push(`The participants with more deep work are: `+ 
         `${most.map( m=> '"' + m['name'] + '" with '+ MathUtils.fixNumbers(m['count']) + ' events totaling ' + MathUtils.fixNumbers(m['hours']) + ' hours').join(', ')}` );
     }
     if(least?.length>0)
     {
       result.push(`The participants with the least deep work are: `+ 
         `${most.map( m=> '"' + m['name'] + '" with '+ MathUtils.fixNumbers(m['count']) + ' events totaling ' + MathUtils.fixNumbers(m['hours']) + ' hours').join(', ')}` );
     }
      
     if(result.length == 0) {
      return ['No Insights available.'];
     }
  
      return result;
    }

  /**
   * 
   * @param data 
   * @example 
   * Top 3 most costly meetings in USD	The cost of the most expensive meetings is: 1. $830, 2. $500, 3. $450
   *  Top 3 most cheap meetings in USD	The cost of the least expensive meetings is: 1. $350, 2. $370, 3. $380
   *  Most costly meeting by topic	"Daily Meeting" is the most costly subject.
   * @returns 
   */
  private getMeetingCostInsights(data:any): string[]
  {
    const meetingData =data?.Cost?.total?.cost;
    
    if(!meetingData )
    {
      return ['No Insights available.'];
    }
    
    const expensiveMeetings = this.getTopMeetingsCost(meetingData?.most_expensive);
    const cheapMeetings = this.getTopMeetingsCost( meetingData?.most_cheap);
    const expensiveMeetingName = this.getTopMeetingsName(meetingData?.most_expensive);

    let result = [];
    if(expensiveMeetings?.length>0)
    {
      result.push(`The most expensive meetings are: `+ 
        `${expensiveMeetings.map( m=> '"' + m['name'] + '" with $'+ MathUtils.fixNumbers(m['cost'])).join(', ')}` );
    }

    if(cheapMeetings?.length>0)
    {
      result.push(`The least expensive meetings are: `+ 
        `${cheapMeetings.map( m=> '"' + m['name'] + '" with $'+ MathUtils.fixNumbers(m['cost'])).join(', ')}` );
    }

    if(expensiveMeetings?.length>0)
    {
      result.push(`"${expensiveMeetings[0]['name']}" is the most costly subject with $${MathUtils.fixNumbers(expensiveMeetings[0]['cost'])}`);
    }

    if (result.length==0)
    {
      result.push('No Insights available.');
    }

    return result;
  }

  /**
   * 
   * @param data 
   * @example 
   * Number of meetings that fall within +/- 34% of the average cost
   * Number of meetings over the average cost
   * Number of meetings under the average cost
   * @returns 
   */
   private getMeetingAverageCostsInsights(data:any): string[]
   {
     const meetingData =data?.Cost?.total?.cost;
     
     if(!meetingData )
     {
       return ['No Insights available.'];
     }
     
    const within_average = (meetingData.within_average?? 0) < 0 ? 0: (meetingData.within_average??0);
    const over_average = (meetingData.over_average??0) < 0 ? 0: meetingData.over_average??0 ;
    const under_average  = (meetingData.under_average??0) < 0  ? 0: meetingData.under_average ??0;
    const average  = (meetingData.total??0) < 0  ? 0: meetingData.total ??0;
 
     let result = [
      `The total number of meetings the fall within +/- 10% (one standard deviation) of the average cost are ${within_average}.`,
      `The total number of meetings that are greater than $${MathUtils.fixNumbers(average)} cost are ${over_average}.`,
      `The total number of meetings that are less than $${MathUtils.fixNumbers(average)} meeting cost are ${under_average}.`,
     ];
       
 
     return result;
   }

   
  /**
   * 
   * @param data 
   * @example 
   * Number of meetings that fall within +/- 34% of the average cost
   * Number of meetings over the average cost
   * Number of meetings under the average cost
   * @returns 
   */
   private getMeetingAverageUserCostInsights(data:any): string[]
   {
     const meetingData =data?.Cost?.total?.cost;
     
     if(!meetingData )
     {
       return ['No Insights available.'];
     }

    const expensiveMeetings = meetingData?.most_expensive.filter(x => x.total_meetings_cost > 0).sort((a, b) => a.total_meetings_cost + b.total_meetings_cost);
    //const cheapMeetings = this.getTopMeetings( meetingData?.most_cheap,1);
    const cheapMeetings = meetingData?.most_cheap.filter(x => x.total_meetings_cost > 0).sort((a, b) => a.total_meetings_cost - b.total_meetings_cost);
    const within_average = meetingData?.within_average;

    let result = [ ];
    if (expensiveMeetings && expensiveMeetings.length>0)
    {
      result.push(`${expensiveMeetings[0].email} is your most expensive attendee during this period, costing you $${  MathUtils.fixNumbers(expensiveMeetings[0].total_meetings_cost)}`);
    }
    
    if (cheapMeetings && cheapMeetings.length>0)
    {
      result.push(`${cheapMeetings[0].email} is your most economic attendee during this period, costing you $${ MathUtils.fixNumbers(cheapMeetings[0].total_meetings_cost)}`);
    }
    if(within_average) {
      result.push(`The total number of meeting attendees the fall within +/- 34% (one standard deviation) of the average cost are ${within_average}.`);
    }
       
 
     return result;
   }

  /**
   * 
   * @param data 
   * @example    
   * @returns 
   */
   private getMeetingBusyTimeInsights(data:any): string[]
   {
     const meetingData =data?.Time?.total?.time;
     const scopeMeetingData =data?.Time?.partial?.time;      
     if(!meetingData )
     {
       return ['No Insights available.'];
     }

    const unavailabilityPercentage =  MathUtils.fixNumbers( (meetingData.unavailability?.percentage??0) );//TODO:Validate this
    let participationPercentage = scopeMeetingData?.participation?.percentage;
    let result = [ ];
    result.push(`The team was unavailable ${unavailabilityPercentage}% of the total time.`);

    if(participationPercentage)
    {
        participationPercentage = MathUtils.fixNumbers( participationPercentage );
               
        if (this.role == Role.Manager)
        {
          result.push(`Your team contributed to ${participationPercentage}% of the total busy time in the meetings they participated.`);

        }
        else if(this.role == Role.Member)
        {
          result.push(`You contributed to ${participationPercentage}% of the total busy time in the meetings you participated.`)
        }
    }
 
     return result;
   }

   
  /**
   * 
   * @param data 
   * @example    
   * @returns 
   */
   private getMeetingFreeTimeInsights(data:any): string[]
   {
     const meetingData =data?.Time?.total?.time ?? data?.Time?.partial?.time;            
     if(!meetingData )
     {
       return ['No Insights available.'];
     }

    const availablePercentage =  MathUtils.fixNumbers( (meetingData.availability?.percentage??0) );//TODO:Validate this
     
    let result = [ ];
    result.push(`The team was available ${availablePercentage}% of the total time.`);
 
     return result;
   }

   
   /**
   * 
   * @param data 
   * @example    
   * @returns 
   */
    private getMeetingLateTimeInsights(data:any): string[]
    {
      const meetingData =data?.Analytics;              
      if(!meetingData )
      {
        return ['No Insights available.'];
      }
     let result = [ ];
     const mostLate = this.getTopMeetings(meetingData.most_lates,3);
     const lessLate = this.getTopMeetings(meetingData.less_late,3);
     if(mostLate && mostLate.length>0)
     {
       result.push(`The accumulated late time from the most late meetings is: `+ 
       mostLate.map(m=>  MathUtils.fixNumbers(m.total_minutes_lates) )?.join(' minutes, ') 
       ) ;
      }
      if(lessLate && lessLate.length>0)
      {
        result.push(`The accumulated late time from the least late meetings is: `+ 
        lessLate.map(m=>  MathUtils.fixNumbers(m.total_minutes_lates) )?.join(' minutes, ') 
        ) ;
      }
      
      if(mostLate && mostLate.length>0)
      {
        try {
          result.push(`"${mostLate[0].title}" accumulated the most late minutes.`);          
        } catch (error) {
          console.error(error);
        }

      }

      if(result.length==0)
      {
        return ['No Insights available.'];
      }

      

  
      return result;
    }
 

  
  private getTopMeetingsCost(meetings,size=3): string []
  {
    if( ! meetings || meetings.length==0)
    {
      return [];
    }
    return meetings.slice(0, size).map(m => {
      return {cost: m.great_total, name: m.title};
    });
  }

  private getTopMeetings(meetings,size=3): any []
  {
    if(!meetings || meetings.length==0)
    {
      return [];
    }
    return meetings.slice(0, size);
  }

  private getPercentage(value,total): number
  {
    return Math.floor(100 * parseInt(value) / parseInt(total)) / 100;
  }

  private getTopMeetingsName(meetings): string
  {     
    if((!meetings) || meetings.length==0)
    {
      return  '';
    }
    
    try {      
      return meetings.slice(0, 1)[0].title ;
    } catch (error) {
      console.error(error)
    }
    return '';
  }
}


