<section class="page404">
  <div class="container">
    <div class="row">
      <div class="col-12 text-center">
        <div class="not-found">
          <img src="./../../../assets/img/404.svg" alt="">
          <div class="w-50">
            <h1>This page was not found</h1>
            <p>You may have misstyped the address or the page was moved.</p>
          </div>
          <button class="btn" (click)="backClicked()">GO BACK</button>
        </div>
      </div>
    </div>
  </div>
</section>
