import { environment } from 'src/environments/environment';

export const Globals = {
    urlTraiyBase: `${environment.api_url}auth/api/`,
    users: {
      root: 'users',
      timezone: '/timezone',
      login: 'login',
    },
    members: {
      table: 'tables/users',
      newTable: 'events/my-team',
    },
    availabilities: {
      root:  `${environment.api_url}availability/api`,
      organizations: 'organizations',
      overview: `${environment.api_url}availability/api/organizations`,
      history: `${environment.api_url}availability/api/availability`,
      bestPractices: `${environment.api_url}availability/api/bestPractices`,
      keywords: `${environment.api_url}availability/api/keywords`,
      scores: `${environment.api_url}availability/api/scores`
    },
    meetings: {
      root: `${environment.api_url}meeting/meetings`,
      query: `${environment.api_url}meeting/events`,
      update:`${environment.api_url}meetings`,
      scoreHistory:`${environment.api_url}meeting/meetings/reports/score`,
      detail: `${environment.api_url}meeting/meetings/details`,
      zoom: `${environment.api_url}meeting/zoom`,
      newdetail: `${environment.api_url}meeting/events/details`,
      nudges: {
        root: `${environment.api_url}meeting/nudges`,
        tips: `/tips`,
        update: `/update-read`,
        check: `/get-new`,
      } 
    },
    zoom: {
      meeting: `${environment.api_url}zoom-oauth/zoom-app/meeting-details`,
      save: `${environment.api_url}zoom-oauth/zoom-app/store-details`,
      timezone: `${environment.api_url}zoom-oauth/zoom-app/add-timezone`,
      jobTitle: `${environment.api_url}zoom-oauth/zoom-app/add-job`,
      sync: `${environment.api_url}zoom-oauth/zoom-app/sync`,
      syncSpeaker: `${environment.api_url}zoom-oauth/zoom-app/sync-speaker`,
      syncAnswers: `${environment.api_url}zoom-oauth/zoom-app/sync-answers`,
      install: `${environment.api_url}zoom-oauth/zoom-app/install`,
    },
    resources:{
      department: 'department',
      occupation: 'occupation',
      industry: 'industry'
    },
    tariytips:{
      root: `${environment.api_url}tariytips/api/tariytips`
    },
    meeting_analytics:{
      best_practices: `${environment.api_url}validator/meeting-analytics/best-practices/organizations`,
    },
    settings:{
      organizationData: `${environment.api_url}auth/api/organization`,
      zoomSettings: `${environment.api_url}zoom-settings/api/organizationsetting`,
      zoomOauth: `${environment.api_url}zoom-oauth`,
      teamsOauth: `${environment.api_url}zoom-oauth/msteams`
    },
    notifications:{
      base: `${environment.api_url}notifications/`,
      update: `update`
    }
}
