import { CustomSelect } from 'src/app/classes/custom-select/custom-select';
// Class to implement attributes of Base List
export class BaseList {
  currentPage: number;
  totalPages: number;
  totalItems: number;
  totalRows: number;
  list: CustomSelect[];

  constructor(page, list) {
    this.currentPage = page['PageNumber'];;
    this.totalItems = page['Total'];
    this.totalRows = page['ItemsPerPage'];
    this.totalPages = this.totalItems/this.totalRows;
    this.list = list;
  }
}
