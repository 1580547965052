import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Globals } from 'src/app/global';

@Injectable({
  providedIn: 'root'
})
export class ResourcesService {
  // variable that store the import of Globals Class that will help us to retrieve the urls for every service
  globals = Globals;

  constructor(public httpClient: HttpClient) { }

  /**
  * Retrieves a list of industries
  * @param {object} - Pagination and search string
  */
  async getIndustries(query?: object): Promise<object> {
    const params = Object.keys(query).map(key => key + '=' + query[key]).join('&');
    const url = `${this.globals.urlTraiyBase}${this.globals.resources.industry}?${params}`;
    return await this.httpClient.get(url).toPromise();
  }

  /**
  * Retrieves a list of jobs
  * @param {object} - Pagination and search string
  */
  async getOccupations(query?: object): Promise<object> {
    const params = Object.keys(query).map(key => key + '=' + query[key]).join('&');
    const url = `${this.globals.urlTraiyBase}${this.globals.resources.occupation}?${params}`;
    return await this.httpClient.get(url).toPromise();
  }

  // Retrieves list of departments
  getDepartments(query?: object): Promise<object> {
    const params = Object.keys(query).map(key => key + '=' + query[key]).join('&');
    const url = `${this.globals.urlTraiyBase}${this.globals.resources.department}?${params}`;
    return this.httpClient.get(url).toPromise();
  }

}
