import { Injectable } from '@angular/core';
import { debounceTime, delay, switchMap, tap } from 'rxjs/operators';
import { SortColumn, SortDirection } from '../../views/dashboard/sortable.directive';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { Globals } from '../../global';
import { PaginationState } from 'src/app/models/pagination/pagination';
import { HttpClient } from '@angular/common/http';
import { Meeting } from 'src/app/models/meetings/meeting';
import { ReceivedNudges } from 'src/app/models/nudges/receivedNudges';
import * as moment from "moment-timezone";
import { AuthService } from '../auth/auth.service';

@Injectable({
  providedIn: 'root'
})

export class SearchNudgeService {
  // variable that store the import of Globals Class that will help us to retrieve the urls for every service
  globals = Globals
  // Url of users api
  baseUrl = `${this.globals.urlTraiyBase}`;
  // Listen for new changes on datatable
  private _loading$ = new BehaviorSubject<boolean>(true);
  // Listen for new strings that user provides
  _search$ = new Subject<void>();
  // List of meetings
  private _nudges$ = new BehaviorSubject<boolean>(false);
  // If filter organizar should be added or disabled, depends on logged user role
  
  constructor(private http: HttpClient,
    private authService:AuthService) {

    this._search$.pipe(
      tap(() => this._loading$.next(true)),
      debounceTime(200),
      switchMap(() => this._search()),
      delay(200),
      tap(() => this._loading$.next(false))
    ).subscribe(result => {
      this._nudges$.next(result['newNudges']);
    });

    this._search$.next();
    setInterval(()=> {
      this._search$.next();
    }, 15*1000*60)
  }

  // Getters an setters that manage filters an table options
  // that show results on datatable
  get nudges$() { return this._nudges$.asObservable(); }
  get loading$() { return this._loading$.asObservable(); }

  // Helper that set values depending on its use


  // Applies all filters to final results
  private _search(): Observable<object> {
    const url = `${this.globals.meetings.nudges.root}${this.globals.meetings.nudges.check}`;
    return this.http.get(url);
  }
}
