import { Component, QueryList, ViewChildren } from '@angular/core';
import { Observable } from 'rxjs';
import { NgbdSortableHeader, SortEvent } from './sortable.directive';
import { DatePickerRanges, DefaultRangeSelected } from '../../configuration/datepicker-range';
import { HistoryService } from 'src/app/services/history/history.service';
//import { BestPracticesService } from 'src/app/services/best-practices/best-practices.service';
import { HistoryMetric } from 'src/app/models/history-metric/history-metrics';
import { ScoreHistory } from 'src/app/models/score-history/score-history';
import { ToastrService } from 'ngx-toastr';
import * as moment from "moment-timezone";
import { KeywordsService } from 'src/app/services/keywords/keywords.service';
import { Keyword } from 'src/app/classes/keyword/keyword';
import { DatePickerConfigService } from 'src/app/services/datepicker-config/datepicker-config.service';
import { GeneralMetricsService } from 'src/app/services/general-metrics/general-metrics.service';
import { ScoreDefinitionComponent } from 'src/app/components/scoreDefinition/score-definition.component';
import { DashboardCardComponent } from 'src/app/components/dashboard-card/dashboard-card.component';
import { MatDialog } from '@angular/material/dialog';
import { faInfoCircle, faArrowAltCircleUp, faArrowAltCircleDown, faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { DistributionPie } from 'src/app/models/distribution-pie/distribution-pie';
import { PolarPie } from 'src/app/models/polar-pie/polar-pie';
import { HeatmapChart } from 'src/app/models/heatmap/heatmap';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent {
  // Sets a directive to apply the funcionality of sorting
  @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;
  ceiler = Math.ceil;
  toInt = parseInt;
  
  tipIcon = faInfoCircle;
  // Period of time that user might need of defining by himself
  ranges = DatePickerRanges;

  loadingMetrics$: Observable<boolean>;
  loadingBestPractices$: Observable<boolean>;
  loadingKeywords$: Observable<boolean>;

  loadingPastMetrics$: Observable<boolean>;

  // Stores the metrics availables for history section
  metricsData = [];
  differences = [];
  //bestPracticesData:any;
  keywordsData: Keyword[];
  selectedRangeDates = this.datePickerConfig.selectedDateRange;

  bestPractices$: Observable<any>;
  // Score of this member
  score: any;
  pastScore: any;
  radius = 30;
  circumference = 0;
  dashoffset = 0;
  costCarousel = [' active', '', '', ''];
  timeCarousel = [' active', '', '', ''];
  freetimeCarousel = [' active', ''];
  meetingCarousel = [' active', '', ''];
  participantCarousel = [' active', '', ''];
  teamDynamicsCarousel = [' active', ''];
  slides = [0,0,0,0,0,0, 0,0,0,0,0,0, 0,0];
  genderCarousel = [' active', '', '', ''];
  raceCarousel = [' active', '', '', '', '', '', '', ''];
  ageCarousel = [' active', '', '', '', ''];
  sentimentCarousel = [' active', '', '', '', '', '', ''];
  participationCarousel = [' active', '', ''];
  organizersCarousel = [' active', '', ''];
  efficiencyCarousel = [' active', '', ''];
  distributionCarousel = [' active'];
  incrementIcon = faArrowAltCircleUp;
  decrementIcon = faArrowAltCircleDown;
  nextCardIcon = faChevronRight;
  previousCardIcon = faChevronLeft;
  seeOverview = true;
  filterRanges:any = {};
  rangeText = 'Custom Range';
  scoreChart: any;
  chartInstance: any;
  productiveChart: any;
  productiveInstance: any;
  inclusiveChart: any;
  inclusiveInstance: any;
  heatmapChart: any;
  heatmapInstance: any;
  firstTime = true;
  genderChart: any;
  genderInstance: any;
  raceChart: any;
  raceInstance: any;
  ageChart: any;
  ageInstance: any;
  constructor(public historyService: HistoryService,
    //public bestPracticesService: BestPracticesService,
    public keywordsService: KeywordsService,
    private toastr: ToastrService,
    private dialog: MatDialog,
    private datePickerConfig: DatePickerConfigService,
    private metricsService: GeneralMetricsService
    ) {
    let temp = [moment().startOf('day').toISOString(),
    moment().subtract(1, 'days').startOf('day').toISOString(),
    moment().subtract(6, 'days').startOf('day').toISOString(),
    moment().startOf('month').toISOString(),
    moment().subtract(1, 'month').startOf('month').toISOString(),
    moment().startOf('week').toISOString(),
    moment().startOf('quarter').toISOString(),
    moment().startOf('year').toISOString()];
    this.filterRanges[temp[0]] = [moment().endOf('day').toISOString(),'Yesterday'];
    this.filterRanges[temp[1]] = [moment().subtract(1, 'days').endOf('day').toISOString(),'Day Before Yesterday'];
    this.filterRanges[temp[2]] = [moment().endOf('day').toISOString(),'Previous 7 days'];
    this.filterRanges[temp[3]] = [moment().endOf('month').toISOString(),'Last Month'];
    this.filterRanges[temp[4]] = [moment().subtract(1, 'month').endOf('month').toISOString(),'Month Before Last'];
    this.filterRanges[temp[5]] = [moment().endOf('week').toISOString(),'Last week'];
    this.filterRanges[temp[6]] = [moment().endOf('quarter').toISOString(),'Last Quarter'];
    this.filterRanges[temp[7]] = [moment().endOf('year').toISOString(),'Last Year'];
    this.loadingMetrics$ = this.historyService.loading$;
    this.loadingPastMetrics$ = this.historyService.pastLoading$;

    this.loadingMetrics$.subscribe(data => {
    }, (err) => {
      this.toastr.error("Error", err, {
        disableTimeOut: false,
        closeButton: true,
        positionClass: 'toast-top-right',
        toastClass: 'toast-margin ngx-toastr'
      });
    });
    this.loadingPastMetrics$.subscribe(data => {
    }, (err) => {
      this.toastr.error("Error", err, {
        disableTimeOut: false,
        closeButton: true,
        positionClass: 'toast-top-right',
        toastClass: 'toast-margin ngx-toastr'
      });
    });

    this.historyService.metrics$.subscribe(data => {
      this.metricsData = data;
      if('meet_gen' in this.metricsData) {
        this.genderChart = new DistributionPie(this.metricsData['meet_gen'], 'Gender Distribution', 0);
        if(this.genderInstance)
          this.genderChart.setInstance(this.genderInstance);
        this.raceChart = new DistributionPie(this.metricsData['meet_race'], 'Race Distribution', 1);
        if(this.raceInstance)
          this.raceChart.setInstance(this.raceInstance);
        this.ageChart = new DistributionPie(this.metricsData['meet_age'], 'Age Distribution', 2);
        if(this.ageInstance)
          this.ageChart.setInstance(this.ageInstance);
      }
    });
    this.historyService.pastMetrics$.subscribe(data => {
      this.differences = data;
    });

    this.historyService.score$.subscribe(data => {
      this.score = data;
      if(!Number.isNaN(this.score)) {
        this.circumference = this.radius * 2 * Math.PI;
        this.dashoffset = this.circumference * ((this.score/100));
      }
    });

    this.historyService.pastScore$.subscribe(data => {
      this.pastScore = data;
      console.log('past score', this.pastScore);
    });

    /*this.bestPractices$ = this.bestPracticesService.metrics$;
    this.loadingBestPractices$ = this.bestPracticesService.loading$;
    this.loadingBestPractices$.subscribe(data => {
    }, (err) => {
      this.toastr.error("Error", err, {
        disableTimeOut: false,
        closeButton: true,
        positionClass: 'toast-top-right',
        toastClass: 'toast-margin ngx-toastr'
      });
    });

    this.bestPractices$.subscribe(data => {
      this.bestPracticesData = data;
    });*/

    this.loadingKeywords$ = this.keywordsService.loading$;
    this.loadingKeywords$.subscribe(data => {
    }, (err) => {
      this.toastr.error("Error", err, {
        disableTimeOut: false,
        closeButton: true,
        positionClass: 'toast-top-right',
        toastClass: 'toast-margin ngx-toastr'
      });
    });

    // Subscribe Keywords
    this.keywordsService.keywords$.subscribe(data => {
      this.keywordsData = data
    }, (err) => {
      this.toastr.error("Error", err, {
        disableTimeOut: false,
        closeButton: true,
        positionClass: 'toast-top-right',
        toastClass: 'toast-margin ngx-toastr'
      });
    });
    
  }

  scoreDifference() {
    // resetting other headers
    if(Number.isNaN(this.score)){
      console.log('score not a number', this.score)
      return 0
    }
    if(Number.isNaN(this.pastScore)){
      console.log('past score not a number', this.pastScore)
      return 0
    }
    console.log('scores good', this.score, this.pastScore)
    return Math.abs(this.score - this.pastScore)
  }

  scoreBigger() {
    // resetting other headers
    if(Number.isNaN(this.score)){
      return 0
    }
    if(Number.isNaN(this.pastScore)){
      return 0
    }
    
    return this.score > this.pastScore ? 2 : 1
  }

  /**
   * Receives params that indicates by which column should be sort
   * @param {column, direction}: SortEvent
   */
  onSort({column, direction}: SortEvent) {
    // resetting other headers
    this.headers.forEach(header => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });
  }

  onChartInit(echartInstance, chartSelected) {
    switch(chartSelected) {
      case 0:
        this.scoreChart.setInstance(echartInstance);
        this.chartInstance = echartInstance;
        break;
      case 1:
        this.productiveChart.setInstance(echartInstance);
        this.productiveInstance = echartInstance;
        break;
      case 2:
        this.inclusiveChart.setInstance(echartInstance);
        this.inclusiveInstance = echartInstance;
        break;
      case 3:
        this.heatmapChart.setInstance(echartInstance);
        this.heatmapInstance = echartInstance;
        break;
      default:
        break;
    }
  }

  onDistributionChartInit(echartInstance, distributionType) {
    switch(distributionType) {
      case 0:
        this.genderChart.setInstance(echartInstance);
        this.genderInstance = echartInstance;
        break;
      case 1:
        this.raceChart.setInstance(echartInstance);
        this.raceInstance = echartInstance;
        break;
      case 2:
        this.ageChart.setInstance(echartInstance);
        this.ageInstance = echartInstance;
        break;
      default:
        break;
    }
  }

  switchView(value) {
    this.seeOverview = value;
  }

  nextSlide(carousel, slide, position) {
    carousel[slide[position]] = '';
    if(slide[position] >= (carousel.length - 1)) {
      slide[position] = 0;
    }
    else {
      slide[position]++;
    }
    carousel[slide[position]] = ' active';
  }

  previousSlide(carousel, slide, position) {
    carousel[slide[position]] = '';
    slide[position] = slide[position] <= 0 ? carousel.length - 1 : slide[position] - 1;
    carousel[slide[position]] = ' active';
  }

  updateDashboard(){
    this.datePickerConfig.selectedDateRange = this.selectedRangeDates;
    this.historyService.range_date = this.selectedRangeDates;
    //this.bestPracticesService.range_date = this.selectedRangeDates;
    this.keywordsService.range_date = this.selectedRangeDates;
    if(this.selectedRangeDates['startDate'].toISOString() in this.filterRanges) {
      if(this.selectedRangeDates['endDate'].toISOString() === this.filterRanges[this.selectedRangeDates['startDate'].toISOString()][0]) {
        this.rangeText = this.filterRanges[this.selectedRangeDates['startDate'].toISOString()][1];
      }
      else {
        this.rangeText = 'Custom Range';
      }
    }
    else {
      this.rangeText = 'Custom Range';
    }
    this.getScore();
    if(this.firstTime) {
      this.firstTime = false
    }
    else {
    }
    
  }

  isText(data) {
    return !Number.isInteger(data);
  }

  getScore(): void {
    this.metricsService.getScoreHistory(this.selectedRangeDates).then(result => {
      if(result) {
        this.scoreChart = new ScoreHistory(result['Metrics']['xAxis'], result['Metrics']['score']);
        if(this.chartInstance)
          this.scoreChart.setInstance(this.chartInstance);
        this.productiveChart = new DistributionPie(result['Feedback'], 'Productive', 3)
        if(this.productiveInstance)
          this.productiveChart.setInstance(this.productiveInstance)
        this.inclusiveChart = new DistributionPie(result['Inclusiveness'], 'Inclusive', 4)
        if(this.inclusiveInstance)
          this.inclusiveChart.setInstance(this.inclusiveInstance)
        this.heatmapChart = new HeatmapChart(result['Heatmap'], 'Meetings Distribution')
        if(this.heatmapInstance)
          this.heatmapChart.setInstance(this.heatmapInstance)

        Object.keys(result['Organizers']).forEach(key =>{
          this.scoreChart.addCurrentSeries(result['Organizers'][key]['score'], key, 'line')
        });
      }
    }).catch(err => {})
  }

  //Show Meeting Tips
  showTips(){
      // Opens dialog steps-direction with steps passed as data array
    const dialogRef = this.dialog.open(ScoreDefinitionComponent, {
      width: '80%',
      height: 'auto',
      minHeight: '200px'
    });
  }

}
