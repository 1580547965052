import { AfterViewInit, Component } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { AuthService } from '../../services/auth/auth.service';
import Swal from 'sweetalert2';
declare const gapi: any;
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements AfterViewInit {
  //Swal alerts
  Swal = require('sweetalert2')
  // Loads environment variables
  environment = environment;
  // Common url for Microsoft login
  microsoft_login_url = "https://login.microsoftonline.com/common/oauth2/v2.0/authorize?"
  // Auxiliar variable for Google api
  public auth2: any;

  private scopeGoogle = [
    'profile',
    'email',
    'https://www.googleapis.com/auth/calendar.events.readonly',
    'https://www.googleapis.com/auth/calendar.readonly',
  ].join(' ');


  private scopeMicrosoft = [
    'openid',
    'email',
    'offline_access',
    //'https://graph.microsoft.com/Mail.Read',//
    'https://graph.microsoft.com/Calendars.Read',
    'https://graph.microsoft.com/Calendars.Read.Shared',
    //'https://graph.microsoft.com/Calendars.ReadWrite',//
    //'https://graph.microsoft.com/Calendars.ReadWrite.Shared',//
    'https://graph.microsoft.com/User.Read',
    'https://graph.microsoft.com/Files.Read.All',//
    'https://graph.microsoft.com/Sites.Read.All',//
  ].join(' ');
  //
  constructor(private router: Router,
    private authService: AuthService) {
    this.authService.isLoggedIn();
  }

  ngAfterViewInit() {
    this.googleInit();
  }

  // Initialize google api service
  googleInit(): void {
    let that = this;
    gapi.load('auth2', function () {
      that.auth2 = gapi.auth2.init({
        client_id: environment.google_client_id,
        cookiepolicy: 'single_host_origin',
        scope: that.scopeGoogle
      });
    });
  }

  // When button is clicked, then sends to redeem's code page
  signInGoogle() {
    this.auth2.grantOfflineAccess({ prompt: 'select_account'}).then(responseAuth => {
      setTimeout(() => this.checkScopes(responseAuth.code), 2000);
    }).catch(error => {
      if(error.error === 'access_denied') {
        let googleUser = this.auth2.currentUser.get();
        let acceptedScopes = googleUser.getGrantedScopes();
        this.swalConfirm("You didn't grant TARIY all requested permissions", 'Google Login', 'error', googleUser);
      }
    });
  }

  forceScopeLogin(user) {
    user.grantOfflineAccess({ prompt: 'select_account', scopes: this.scopeGoogle}).then(scopeResp => {
      setTimeout(() => this.checkScopes(scopeResp.code), 2000);
    }).catch(error => {
      if(error.error === 'access_denied') {
        let googleUser = this.auth2.currentUser.get();
        let acceptedScopes = googleUser.getGrantedScopes();
        this.swalConfirm("You didn't grant TARIY all requested permissions", 'Google Login', 'error', googleUser);
      }
    });
  }

  checkScopes(code) {
    let googleUser = this.auth2.currentUser.get();
    let acceptedScopes = googleUser.getGrantedScopes();

    if(!googleUser.hasGrantedScopes(this.scopeGoogle)) {
      this.swalConfirm("You didn't grant TARIY all requested permissions", 'Google Login', 'error', googleUser);
    }
    else {
      this.router.navigate(['/redeem-code'],{ queryParams: { googlecode : code } }).then(() => {
        window.location.reload();
      });
    }
  }
  //Swal Confirm message
  swalConfirm(message:string, topic:string, type:any, user:any) {
    Swal.fire({
      icon: type,
      title: topic,
      text: message,
      showCancelButton: true,
      confirmButtonColor: '#478bf5',
      confirmButtonText: 'Retry'
    }).then(result => {
      if(result.isConfirmed) {
        this.forceScopeLogin(user);
      }
    });
  }

  // When button is clicked, then open the Microsoft's login with our params
  signInMicrosoft(): void{
      const params_login = new URLSearchParams({
      client_id:     environment.microsoft_client_id,
      response_type: "code",
      redirect_uri:  `${environment.microsoft_redirect}`,
      response_mode: "query",
      scope:         this.scopeMicrosoft,
      state:         "12345",
    });
    window.open(`${this.microsoft_login_url}${params_login}`,"_self");
  }
}
