import { Component, OnInit, Inject, Input } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { StepsConnection } from 'src/app/models/settings/steps-connection';
import { trigger, transition, style, animate } from '@angular/animations';

@Component({
  selector: 'app-tips-details',
  templateUrl: './tips-details.component.html',
  styleUrls: ['./tips-details.component.scss']
})
export class TipsDetailsComponent implements OnInit {
  tips = [];
  columnHeight = 0;

  constructor(public dialogRef: MatDialogRef<TipsDetailsComponent>, @Inject(MAT_DIALOG_DATA) private data: any) { }

  ngOnInit(): void {
    // Set data sent from dialog data to steps
    this.tips = this.data['tips'];
    this.columnHeight = 100/ (this.tips.length);
  }

  // Close dialog
  close(){
    this.dialogRef.close();
  }

}
