import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";

// Layout
import { PanelComponent } from '../../views/layouts/panel/panel.component';
// Pages
import { MemberProfileComponent } from "src/app/views/member-profile/member-profile.component";
import { Page404Component } from "src/app/views/page404/page404.component";
import { Role } from "src/enum/Role";
import { AuthGuard } from "src/app/guards/auth/auth.guard";
import { MeetingDetailComponent } from "src/app/views/meeting-detail/meeting-detail.component";


import { WaitingDashboardComponent } from "src/app/views/waiting-dashboard/waiting-dashboard.component";
import { MemberInsightsComponent } from "src/app/views/member-insights/member-insights.component";
import { ConstructionComponent } from '../../views/construction/construction.component';
import { MeetingsComponent } from "src/app/views/meetings/meetings.component";
import { SettingsComponent } from "src/app/views/settings/settings.component";
import { NudgesComponent } from "src/app/views/nudges/nudges.component";
import { InsightsComponent } from "src/app/views/insights/insights.component";
import { ReportsComponent } from '../../views/reports/reports.component';
import { HelpComponent } from "src/app/views/help/help.component";
import { ProfileComponent} from '../../views/profile/profile.component';

const routes: Routes = [
  {
    path: '',component: PanelComponent,
    children: [
      { path: '', component: WaitingDashboardComponent, canActivate: [AuthGuard], data: { roles: [Role.ITAdmin] } },
      { path: 'events', component: MeetingsComponent, canActivate: [AuthGuard], data: { roles: [Role.ITAdmin] } },
      { path: 'nudges', component: ConstructionComponent, canActivate: [AuthGuard], data: { roles: [Role.ITAdmin] } },
      { path: 'insights', component: MemberInsightsComponent, canActivate: [AuthGuard], data: { roles: [Role.ITAdmin] } },
      { path: 'reports', component: ConstructionComponent, canActivate: [AuthGuard], data: { roles: [Role.ITAdmin] } },      { path: 'event-detail/:eventId', component: MeetingDetailComponent, canActivate: [AuthGuard], data: { roles: [Role.SuperAdmin, Role.Admin, Role.ITAdmin, Role.Manager] }},
      { path: 'event-detail/:eventId/:meetingId', component: MeetingDetailComponent, canActivate: [AuthGuard], data: { roles: [Role.ITAdmin] }},
      { path: 'settings', component: SettingsComponent, canActivate: [AuthGuard], data: { roles: [Role.ITAdmin] } },
      { path: 'settings/:code', component: SettingsComponent, canActivate: [AuthGuard], data: { roles: [Role.ITAdmin] } },
      { path: 'help', component: HelpComponent, canActivate: [AuthGuard], data: { roles: [Role.SuperAdmin, Role.Admin, Role.Manager] } },
      { path: 'profile', component: ProfileComponent, canActivate: [AuthGuard], data: { roles: [Role.ITAdmin] } },
      { path: '**', component: Page404Component }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ITAdminRoutingModule { }
