import { Component } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { ZoomSettingsService } from 'src/app/services/settings/zoom-settings.service';
import { TeamsSettingsService } from 'src/app/services/settings/teams-settings.service';
import { NotificationSettingsService } from 'src/app/services/settings/notification.service';
import { environment } from 'src/environments/environment';
import { OneSignal } from 'onesignal-ngx';
import { Intercom } from 'ng-intercom';
import { timeThursdays } from 'd3';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'tribe-web';
  zoomLogin = environment.zoom_oauth;
  teamsLogin = environment.teams_oauth;
  oneId = environment.onesignal;
  email = '';
  constructor(private zoomToast: ToastrService,
    private zoomService: ZoomSettingsService,
    private teamService: TeamsSettingsService,
    private onsi: OneSignal,
    private notificationService: NotificationSettingsService,
    private intercom: Intercom) {
    if (localStorage.getItem('traiyUser')){

      let user = JSON.parse(localStorage.getItem('traiyUser'));
      this.email = user['email'];
      let name = `${user['firstname']} ${user['lastname']}`
      this.onsi.init({
        serviceWorkerPath: "assets/onesignal",
        appId: this.oneId
      });
      //this.checkZoomBind(user);
      this.onsi.on('subscriptionChange', function(isSubscribed) {
        this.createSubscription(isSubscribed);
      }.bind(this));

      this.intercom.boot({
        user_id: this.email,
        email: name,
        app_id: 'cvgrqmpc'
      });
    }
    
  }
  
  createSubscription(state) {
    this.onsi.sendTag('email', this.email);
    let userId = this.onsi.getUserId().then(data => {
      this.notificationService.updateUserId(state, data).then(resp => {
      }).catch(er => {
      });
    });
  }
  //zoom toast
  toastPop(message:string) {
    this.zoomToast.error(message, 'Zoom error', {
      disableTimeOut: true,
      closeButton: true,
      enableHtml: true,
      positionClass: 'toast-top-center',
      toastClass: 'toast-margin ngx-toastr'
    });
  }

  //check zoomBind
  checkZoomBind(user) {
    if (!user['zoomBind'] && !user['teamsBind']) {
      this.zoomService.statusZoomSettings().then((data:any) => {
        user['zoomBind'] = data.success;
        this.teamService.statusTeamsSettings().then((received:any) => {
          user['teamsBind'] = received.success;
          localStorage.setItem('traiyUser', JSON.stringify(user));    
          if(!user['zoomBind'] && !user['teamsBind']) {   
            if (user['role'] == 'Admin') {
              this.toastPop('No meeting account is binded to TARIY <a href="' + this.zoomLogin + '">click here for zoom</a> or <a href="' + this.teamsLogin + '">click here for teams</a>')
            }
            else {
              this.toastPop('No meeting account is binded to TARIY, please notify your administrator')
            }
          }
        });
      });
    }
  }
}
