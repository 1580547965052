import { Injectable } from '@angular/core';
import { UserRequest } from 'src/app/models/user-request/user-request';
import { User } from 'src/app/models/user/user';
import { DateUtils } from 'src/app/utils/dateUtils';
import { AuthService } from '../auth/auth.service';
import { MemberCrudService } from '../member-crud/member-crud.service';

@Injectable({
  providedIn: 'root'
})
export class UserTimezoneService {

  constructor(
    private memberCrudSvc: MemberCrudService,
    private authService: AuthService
  ) { }

  /**
   * Returns a user object that will be used to update the user
   * @returns an updated user request object
   */
  getUserValues(user: User) {
    return {
      //FirstName: user['firstname'],
      //LastName: user['lastname'],
      Email: user['email'],
      //Schedule: user['schedule'],
      //DepartmentId: user['departmentId'],
      //Department: user['departmentName'],
      //OccupationId: user['occupationId'],
      //Occupation: user['occupationName'],
      //Salary: user['yearSalary'],
      //OrganizationId: user['organizationId'],
      //CompanyName: user['organizationName'],
      //RoleName: user['role'],
      TimezoneString: DateUtils.getTimezoneString(),
      TimezoneOffset: DateUtils.getUtcOffset()
    }
  }

  /**
   * Captures and updates the current timezone
   * @returns resolves to true if there were no errors
   */
  async updateTimezone(): Promise<boolean> {
    let ret = true;

    // updates if the user is logged in:
    if (this.authService.isAuthenticated() &&
        !this.authService.loginInfo.userOnboarding &&
        !this.authService.loginInfo.organizationOnboarding) {
      try {
        const values = this.getUserValues(this.authService.currentUserValue);
        await this.memberCrudSvc.updateTimezone(values);
      } catch (error) {
        console.error(error.message);
        ret = false;
      }
    }

    return ret;
  }

}
