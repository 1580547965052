export const MeetingTypeFaIcon = {
    'personal_time': 'fa-user',
    'one_on_one': 'fa-user-friends',
    'team': 'fa-users'
};

export const MeetingTypeText = {
    'personal_time': 'Personal time',
    'one_on_one': 'One on One',
    'team': 'Team'
}