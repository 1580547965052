import { Pipe, PipeTransform } from '@angular/core';
import { SearchMatchesService } from '../../services/search-matches/search-matches.service';
@Pipe({
  name: 'highlightSearch'
})

export class HighlightSearchPipe implements PipeTransform {

  constructor(private searchMatchesService: SearchMatchesService){}

  /**
   * Search for given string in a sentence or paragraph, replaces this string with a tag
   * @param value Paragraph or sentence
   * @param args Given string
   */
  transform(value: any, args: any): any {
    if (!args) { return value; }
    const re = new RegExp(args, 'gi'); //'gi' for case insensitive and can use 'g' if you want the search to be case sensitive.
    const count = (value.match(re) || []).length;
    this.searchMatchesService.numberMatches$.emit({ count: count });
    return value.replace(re, "<mark>$&</mark>");
  }
}
