<div class="modalNote d-none d-xl-block d-lg-block">
  TARIY recommendation based on the meeting data, use the tips provided to improve the shown parameters
</div>
<div class="card mt-2">
  <div class="header">
    <div class="title text-center pt-1 pb-1 main-title" style="height:50%;">TARIY Tips</div>
    <div class="title col-6 d-none d-xl-inline-flex d-lg-inline-flex text-center justify-content-center pt-1" style="height:50%">Parameter</div>
    <div class="title col-6 d-none d-xl-inline-flex d-lg-inline-flex text-center justify-content-center pt-1" style="height:50%">Tip</div>
  </div>
  <div class="body">
    <div class="row" *ngFor="let tip of tips" style="height:auto">
      <div class="col-4 pl-4 align-items-center pb-2 pt-2 d-none d-xl-flex d-lg-flex">{{tip.key}}</div>
      <div class="col-12 col-xl-8 col-lg-8  d-flex pb-2 pt-2"><ul><li>{{tip.value}}</li></ul></div>
    </div>
  </div>
</div>
