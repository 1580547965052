<section class="meetings">
  <div class="container-fluid main-menu">
    <div class="row">
      <div class="col-12 col-sm-3 col-lg-4 col-xl-2 d-none d-lg-block d-xl-block">
        <div class="options pr-0 pr-lg-3 pr-xl-3">
          <div class="item" (click)="showList = !showList" [ngClass]="showList ? 'active': ''">
            <img src="../../../assets/img/List-a.svg" alt="" *ngIf="showList">
            <img src="../../../assets/img/List-i.svg" alt="" *ngIf="!showList">
            <span>List</span>
          </div>
          <div class="item" (click)="showList = !showList" [ngClass]="!showList ? 'active': ''">
            <img src="../../../assets/img/Grid-a.svg" alt="" *ngIf="!showList">
            <img src="../../../assets/img/Grid-i.svg" alt="" *ngIf="showList">
            <span>Grid</span>
          </div>
        </div>
      </div>
      <div class="col-5 col-xl-7 status-options-buttons">
        <div class="group-buttons d-flex align-items-center justify-content-center justify-content-lg-center flex-wrap">
          <button class="btn rounded-pill" (click)="service.status = ''"  [disabled]="service.loading$ | async"
          [ngClass]="service.status == '' ? 'btn-primary':'btn-outline-primary'">All</button>
          <button class="btn rounded-pill" (click)="service.status = statusText.Processing" [disabled]="service.loading$ | async"
          [ngClass]="service.status == statusText.Processing? 'btn-secondary':'btn-outline-secondary'">Processing</button>
          <button class="btn rounded-pill" (click)="service.status = statusText.Scheduled" [disabled]="service.loading$ | async"
          [ngClass]="service.status ==  statusText.Scheduled ? 'btn-warning':'btn-outline-warning'">Scheduled</button>
          <button class="btn rounded-pill"(click)="service.status = statusText.Completed" [disabled]="service.loading$ | async"
          [ngClass]="service.status == statusText.Completed ? 'btn_Completed':'btn_outline_completed'">Completed</button>
          <button class="btn rounded-pill"(click)="service.status = statusText.Meeting_not_found" [disabled]="service.loading$ | async"
          [ngClass]="service.status == statusText.Meeting_not_found ? 'btn_Not_Found':'btn_outline_not_found '">Not found</button>
          <button class="btn rounded-pill"(click)="service.status = statusText.Personal_time" [disabled]="service.loading$ | async"
          [ngClass]="service.status == statusText.Personal_time ? 'btn_Personal_Time':'btn_outline_personal_time'">Personal time</button>
          <button class="btn rounded-pill"(click)="service.status = statusText.Deep_work" [disabled]="service.loading$ | async"
          [ngClass]="service.status == statusText.Deep_work ? 'btn_Deep_Work':'btn_outline_deep_work'">Deep work</button>
        </div>
      </div>
      <div class="col-12 col-xl-7 col-lg-5 status-options-select">
        <label for="status">Status:</label>
        <select name="status" class="form-control my-1" [(ngModel)]="service.status">
          <option value="">All</option>
          <option *ngFor="let status of statusText | keyvalue" [value]="status.value">{{ status.key }}</option>
        </select>
      </div>
      <div class="col-12 col-xl-3 col-lg-3 mb-3 mb-lg-0 pl-lg-0 d-flex align-items-center">
        <div class="col daterange-input mx-3 mx-lg-0">
            <input type="text" #datepicker name="daterange" ngxDaterangepickerMd class="form-control daterange meetings" [(ngModel)]="selectedRangeDates"            
            [ranges]="ranges" (change)="updateTable()" [showCustomRangeLabel]="true" [alwaysShowCalendars]="true" [showRangeLabelOnInput]="true" autocomplete="off"
              placeholder="Choose date" containerStyles='display: "inline-flex"'/>
        </div>
      </div>
    </div>
  </div>
  <div class="container-fluid dashtable " >
    <div class="col-12">
      <div class="table-container" [ngClass]="!showList ? 'grid-version': ''">
        <div class="col-12">
          <div class="row d-flex align-items-center py-4">
            <div class="col-12 col-lg-3 d-flex">
              <input class="form-control input-search ml-0 ml-lg-2 ml-xl-2 my-3 my-lg-0 my-xl-0" autocomplete="off"
              type="text" name="searchText" [(ngModel)]="service.searchText" placeholder="Search:"/>
            </div>

            <div class="col-12 col-lg-3">
              <span class="meeting-type-filter">
                <button
                  popper="{{ getMeetingTypeText('personal_time') }}"
                  [popperShowOnStart]="false"
                  [popperTrigger]="'hover'"
                  [popperHideOnScroll]="true"
                  [popperPlacement]="'bottom'"
                  [disabled]="service.loading$ | async"
                  [ngClass]="selectedMeetingType === 'personal_time' ? 'selected' : 'unselected'"
                  (click)="onClickMeetingTypeFilter('personal_time')">
                  <i class="fas fa-user"></i>
                </button>

                &nbsp;

                <button
                  popper="{{ getMeetingTypeText('one_on_one') }}"
                  [popperShowOnStart]="false"
                  [popperTrigger]="'hover'"
                  [popperHideOnScroll]="true"
                  [popperPlacement]="'bottom'"
                  [disabled]="service.loading$ | async"
                  [ngClass]="selectedMeetingType === 'one_on_one' ? 'selected' : 'unselected'"
                  (click)="onClickMeetingTypeFilter('one_on_one')">
                  <i class="fas fa-user-friends"></i>
                </button>

                &nbsp;

                <button 
                  popper="{{ getMeetingTypeText('team') }}"
                  [popperShowOnStart]="false"
                  [popperTrigger]="'hover'"
                  [popperHideOnScroll]="true"
                  [popperPlacement]="'bottom'"
                  [disabled]="service.loading$ | async"
                  [ngClass]="selectedMeetingType === 'team' ? 'selected' : 'unselected'"
                  (click)="onClickMeetingTypeFilter('team')">
                  <i class="fas fa-users"></i>
                </button>
              </span>
            </div>

            <div class="col-12 col-lg-6 d-flex justify-content-center justify-content-lg-end ">
              <div class="number_pages justify-content-xl-end">
                <label class="mr-2">Show</label>
                <select class="custom-select select-page"  [(ngModel)]="service.itemsPerPage" [disabled]="service.loading$ | async">
                  <option [ngValue]="6">6</option>
                  <option [ngValue]="12">12</option>
                  <option [ngValue]="24">24</option>
                </select>
                <label class="ml-2">Entries</label>
              </div>
              <ngb-pagination class="pagination-element ml-3 mt-1" size="sm" [collectionSize]="(total$ | async)!" [(page)]="service.page" [pageSize]="service.itemsPerPage" [maxSize]="4">
              </ngb-pagination>
            </div>
          </div>
        </div>
        <div class="col-12 pl-0 pr-0" *ngIf="showList">
          <table class="table table-hover">
            <thead>
              <tr>
                <th class="title" sortable="title" (sort)="onSort($event)">Title</th>
                <th class="keywords">Keywords</th>
                <th class="date-time">Date and time</th>
                <th class="duration">Duration</th>
                <th class="participants">Participants</th>
                <th class="button-holder">
                  <span  popper="Meeting feedback" [popperShowOnStart]="false"
                    [popperTrigger]="'hover'"
                    [popperHideOnScroll]="true"
                    [popperPlacement]="'top'"
                    [popperModifiers]="offsetPopper">
                      Productive
                  </span>
                </th>
                <th class="button-holder">
                  <span  popper="Meeting inclusiveness" [popperShowOnStart]="false"
                    [popperTrigger]="'hover'"
                    [popperHideOnScroll]="true"
                    [popperPlacement]="'top'"
                    [popperModifiers]="offsetPopper">
                      Inclusive
                  </span>
                </th>
                <th class="score text-center" sortable="score" (sort)="onSort($event)">Meeting score&reg;</th>
                <th class="status text-center">Status</th>
              </tr>
            </thead>
            <tbody>
              <div class="loading" *ngIf="(service.loading$ | async)">
                <div class="text-center">
                  <div class="loading" ><fa-icon icon="circle-notch" class="fa-lg fa-spin mr-2"></fa-icon>Loading...</div>
                </div>
              </div>
              <tr *ngIf="!(service.loading$ | async) && (meetings$ | async).length == 0"><td colspan="7" class="text-center font-weight-light">No results found</td></tr>
              <tr *ngFor="let meeting of meetings$ | async; index as i">
                <!-- <td [routerLink]="['/admin/event-detail', meeting.event_id, meeting.id]" (click)="openEvent(meeting.event_id, meeting.id)"> -->
                <td (click)="openEvent(meeting.event_id, meeting.id)">
                  <!-- Event source -->
                  <img
                    popper="{{ getMeetingSourceText(meeting?.source) }}"
                    [popperShowOnStart]="false"
                    [popperTrigger]="'hover'"
                    [popperHideOnScroll]="true"
                    [popperPlacement]="'bottom'"
                    *ngIf="meeting && meeting?.source !== 'other'"
                    src="../../../assets/img/{{ getMeetingSourceIcon(meeting?.source) }}"
                    class="title-meeting-source"
                    alt="{{ meeting?.source }}">

                  &nbsp;

                  <!-- Event title -->
                  {{ meeting.title }} &nbsp;

                  <!-- Event type -->
                  <i
                    popper="{{ getMeetingTypeText(meeting?.meetingType) }}"
                    [popperShowOnStart]="false"
                    [popperTrigger]="'hover'"
                    [popperHideOnScroll]="true"
                    [popperPlacement]="'bottom'"
                    class="title-meeting-type fas"
                    [ngClass]="getMeetingTypeIcon(meeting.meetingType)"></i>
                </td>
                <td (click)="openEvent(meeting.event_id, meeting.id)">
                    {{ formatTopicsString(meeting.topics) }}
                </td>
                <td (click)="openEvent(meeting.event_id, meeting.id)" class="date">{{ meeting.date | date:'MMM d, y\nh:mm a':userTimezone  }}</td>
                <td (click)="openEvent(meeting.event_id, meeting.id)">{{ meeting.duration | humanizeDuration: true }}</td>
                <td (click)="openEvent(meeting.event_id, meeting.id)">{{ meeting.participants }}</td>

                <td (click)="openEvent(meeting.event_id, meeting.id)" *ngIf="!meeting.productiveColumnMode" class="text-center">
                  <div class="w-100 h-100 d-flex justify-content-center">
                    <div *ngIf="meeting.productive <= 1" [ngClass]="meeting.productive == 1 ? 'pos-img' : 'neg-img'"
                      popper="{{ meeting.productive == 1 ? 'This meeting was productive': meeting.productive == 0 ? 'This meeting was unproductive' : 'No data available' }}" [popperShowOnStart]="false"
                      [popperTrigger]="'hover'"
                      [popperHideOnScroll]="true"
                      [popperPlacement]="'top'"
                      [popperModifiers]="offsetPopper">
                    </div>
                  </div>
                </td>
                <td *ngIf="meeting.productiveColumnMode" class="text-center">
                  <div class="w-100 h-100 d-flex justify-content-center">
                    <div [class]="'pos-btn-img feedback mr-2'"
                      (click)="giveFeedback(meeting.event_id, true, meeting.title, i)"
                      popper="Rate meeting as productive" 
                      [popperShowOnStart]="false"
                      [popperTrigger]="'hover'"
                      [popperHideOnScroll]="true"
                      [popperPlacement]="'top'"
                      [popperModifiers]="offsetPopper">
                    </div>
                    <div [class]="'neg-btn-img feedback'"
                      (click)="giveFeedback(meeting.event_id, false, meeting.title, i)"
                      popper="Rate meeting as unproductive" 
                      [popperShowOnStart]="false"
                      [popperTrigger]="'hover'"
                      [popperHideOnScroll]="true"
                      [popperPlacement]="'top'"
                      [popperModifiers]="offsetPopper">
                    </div>
                  </div>
                  <!-- <fa-icon [icon]="positiveFB" [class]="'pos-btn feedback'" (click)="giveFeedback(meeting.event_id, true, meeting.title, i)" popper="Rate meeting as productive" [popperShowOnStart]="false"
                  [popperTrigger]="'hover'"
                  [popperHideOnScroll]="true"
                  [popperPlacement]="'top'"
                  [popperModifiers]="offsetPopper"></fa-icon>
                  <fa-icon [icon]="negativeFB" [class]="'neg-btn feedback pl-2'" (click)="giveFeedback(meeting.event_id, false, meeting.title, i)" popper="Rate meeting as unproductive" [popperShowOnStart]="false"
                  [popperTrigger]="'hover'"
                  [popperHideOnScroll]="true"
                  [popperPlacement]="'top'"
                  [popperModifiers]="offsetPopper"></fa-icon> -->
                </td>
                <!-- diversity -->
                <td (click)="openEvent(meeting.event_id, meeting.id)" *ngIf="!meeting.inclusiveColumnMode" class="text-center">
                  <div class="w-100 h-100 d-flex justify-content-center">
                    <div *ngIf="meeting.inclusive <= 1" [ngClass]="meeting.inclusive == 1 ? 'pos-img' : 'neg-img'"
                      popper="{{ meeting.inclusive == 1 ? 'This meeting was inclusive': meeting.inclusive == 0 ? 'This meeting was noninclusive' : 'No data available' }}" [popperShowOnStart]="false"
                      [popperTrigger]="'hover'"
                      [popperHideOnScroll]="true"
                      [popperPlacement]="'top'"
                      [popperModifiers]="offsetPopper">
                    </div>
                  </div>
                </td>
                <td *ngIf="meeting.inclusiveColumnMode" class="text-center">
                  <div class="w-100 h-100 d-flex justify-content-center">
                    <div [class]="'pos-btn-img feedback mr-2'"
                      (click)="giveDiversity(meeting.event_id, true, meeting.title, i)"
                      popper="Rate meeting as inclusive" 
                      [popperShowOnStart]="false"
                      [popperTrigger]="'hover'"
                      [popperHideOnScroll]="true"
                      [popperPlacement]="'top'"
                      [popperModifiers]="offsetPopper">
                    </div>
                    <div [class]="'neg-btn-img feedback'"
                      (click)="giveDiversity(meeting.event_id, false, meeting.title, i)"
                      popper="Rate meeting as noninclusive" 
                      [popperShowOnStart]="false"
                      [popperTrigger]="'hover'"
                      [popperHideOnScroll]="true"
                      [popperPlacement]="'top'"
                      [popperModifiers]="offsetPopper">
                    </div>
                  </div>
                </td>
                <!-- termina -->
                  
                <td (click)="openEvent(meeting.event_id, meeting.id)" class="numbers"><span class="percentage" [ngClass]="{'decrease' : meeting.score < 70, 'notAssign': meeting.getScoreText() == 'N/A'}">{{ meeting.getScoreText() }}</span></td>
                <td (click)="openEvent(meeting.event_id, meeting.id)" class="text-center"><span class="status-pill" [ngClass]="meeting.statusText">{{ meeting.statusText }}</span></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
  <div class="container-fluid mt-n4" *ngIf="!showList">
    <div class="col-12 grid-table">
        <ng-container *ngIf="(meetings$ | async)?.length > 0; else elseTemplate">
          <div class="row">
            <div class="col-12 col-lg-6 col-xl-4" *ngFor="let meeting of meetings$ | async; index as i">
            <div class="card" [ngClass]="meeting.statusText.replace(' ', '_')" [routerLink]="['/admin/event-detail', meeting.event_id, meeting.id]">
              <div class="header">
              <span class="dot" [ngClass]="meeting.statusText.replace(' ', '_')"></span>
              <h4 class="mr-auto">
                <!-- Event title -->
                {{ meeting.title }} &nbsp;

                <!-- Event type -->
                <i
                  class="title-meeting-type fas"
                  [ngClass]="getMeetingTypeIcon(meeting.meetingType)"></i> &nbsp;

                <!-- Event source -->
                <img
                  *ngIf="meeting && meeting?.source !== 'other'"
                  src="../../../assets/img/{{ getMeetingSourceIcon(meeting?.source) }}"
                  class="title-meeting-source"
                  alt="{{ meeting?.source }}">
              </h4>
              <div class="percentage justify-content-center" [ngClass]="meeting.statusText.replace(' ', '_')">{{ meeting.getScoreText() }}</div><!---->
              </div>
              <div class="body">
                <div class="organizer">
                  <img src="{{ meeting?.picture }}" class="img-fluid mr-2 profile" [ngClass]="meeting.statusText.replace(' ', '_')" *ngIf="meeting.validPicture" (error)="meeting.invalidPicture()">
                  <img src="../../../assets/img/no-picture-user.png" class="img-fluid mr-2 profile no-photo" [ngClass]="meeting.statusText.replace(' ', '_')" *ngIf="!meeting.validPicture">
                  <div class="user">
                    <span>ORGANIZER</span>
                    <h4>{{ meeting.organizer }}</h4>
                  </div>
                  <div class="time" [ngClass]="meeting.statusText.replace(' ', '_')">
                    <span class="hours">{{ meeting.duration | humanizeDuration: true}}</span>
                    <span class="date">{{ meeting.date | date:'MMM d, y' }}</span>
                  </div>
                </div>
                <div class="topics">
                  <ul class="list">
                    <li *ngFor="let topic of meeting.topics" [ngClass]="meeting.statusText.replace(' ', '_')">{{ topic }}</li>
                  </ul>
                </div>
                <div class="participants" >
                  <div class="title" [ngClass]="meeting.statusText.replace(' ', '_')">PARTICIPANTS</div>
                  <p>{{ meeting.participants }}</p>
                </div>
              </div>
            </div>
            </div>
          </div>
        </ng-container>
        <ng-template #elseTemplate>
          <div class="row" *ngIf="!(service.loading$ | async)">
            <div class="col-12">
              <div class="text-center w-100 pt-4"><h6>No results found</h6></div>
            </div>
          </div>
          <div class="row" *ngIf="(service.loading$ | async)">
            <div class="col-12 col-lg-6 col-xl-4" *ngFor="let meeting of [1,2,3,4,5,6]; index as i">
              <div class="card">
                <div class="header d-flex justify-content-between">
                  <div>
                    <ngx-skeleton-loader appearance="circle" [theme]="{ height: '10px',width: '10px'}"></ngx-skeleton-loader>
                    <ngx-skeleton-loader
                     [theme]="{ width: '200px', 'border-radius': '0', height: '22px', 'margin-bottom': '0px', 'margin-left': '20px' }"
                     ></ngx-skeleton-loader>
                  </div>
                 <ngx-skeleton-loader appearance="circle" [theme]="{ height: '40px',width: '40px'}"></ngx-skeleton-loader>
                </div>
                <div class="body">
                  <div class="organizer">
                    <ngx-skeleton-loader appearance="circle" [theme]="{ height: '100px',width: '100px'}"></ngx-skeleton-loader>
                    <div class="gravatar-title ml-4 d-flex flex-column w-100">
                      <div>
                        <ngx-skeleton-loader [theme]="{width: '100px','border-radius': '0', height: '15px', 'margin-bottom': '5px'}"></ngx-skeleton-loader>
                      </div>
                      <div>
                        <ngx-skeleton-loader
                        [theme]="{ width: '150px', 'border-radius': '0', height: '15px', 'margin-bottom': '0px' }"
                        ></ngx-skeleton-loader>
                      </div>
                    </div>
                    <div class="time pt-2">
                      <ngx-skeleton-loader
                        [theme]="{ width: '80px', 'border-radius': '0', height: '15px', 'margin-bottom': '0px' }"
                      ></ngx-skeleton-loader>
                      <ngx-skeleton-loader
                        [theme]="{ width: '80px', 'border-radius': '0', height: '15px', 'margin-bottom': '0px' }"
                      ></ngx-skeleton-loader>
                    </div>
                  </div>
                  <div class="topics d-flex px-2 border-skeleton flex-wrap">
                    <div *ngFor="let topic of [1,2,3,4]" class="ml-4">
                      <ngx-skeleton-loader
                        [theme]="{ width: '80px', 'border-radius': '0', height: '15px', 'margin-bottom': '0px' }"
                      ></ngx-skeleton-loader>
                    </div>
                  </div>
                  <div class="participants pb-0" >
                    <div class="title" ngClass="Processing"><ngx-skeleton-loader></ngx-skeleton-loader></div>
                    <p><ngx-skeleton-loader></ngx-skeleton-loader></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ng-template>
    </div>
  </div>
</section>
