import { AfterViewInit, Component, ChangeDetectorRef, ChangeDetectionStrategy, Renderer2, HostListener } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { environment } from '../../../environments/environment';
import { AuthService } from '../../services/auth/auth.service';
import Swal from 'sweetalert2';
import * as moment from "moment";
import { faMinusCircle, faArrowAltCircleUp, faArrowAltCircleDown, faChevronLeft, faChevronRight, faQuestionCircle, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { ZoomMeetingService } from 'src/app/services/zoom-meeting/zoom-meeting.service';
import { GenderType, AgeType, CommunicationType} from 'src/enum/speaker-traits.enum'
import { MatDialog } from '@angular/material/dialog';
import { ZoomProfileComponent } from 'src/app/components/zoomProfile/zoom-profile.component';
import { DateUtils } from 'src/app/utils/dateUtils';
declare const zoomSdk: any; //import zoomSdk from '@zoom/appssdk'
declare const gapi: any;
@Component({
  selector: 'zoom-meeting',
  templateUrl: './zoom-meeting.component.html',
  styleUrls: ['./zoom-meeting.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class ZoomMeetingComponent implements AfterViewInit {
  //Swal alerts
  Swal = require('sweetalert2')
  // Loads environment variables
  environment = environment;
  // Common url for Microsoft login
  microsoft_login_url = "https://login.microsoftonline.com/common/oauth2/v2.0/authorize?"
  // Auxiliar variable for Google api
  public auth2: any;

  private scopeGoogle = [
    'profile',
    'email',
    'https://www.googleapis.com/auth/calendar.events.readonly',
    'https://www.googleapis.com/auth/calendar.readonly',
  ].join(' ');


  private scopeMicrosoft = [
    'openid',
    'email',
    'offline_access',
    //'https://graph.microsoft.com/Mail.Read',//
    'https://graph.microsoft.com/Calendars.Read',
    'https://graph.microsoft.com/Calendars.Read.Shared',
    //'https://graph.microsoft.com/Calendars.ReadWrite',//
    //'https://graph.microsoft.com/Calendars.ReadWrite.Shared',//
    'https://graph.microsoft.com/User.Read',
    'https://graph.microsoft.com/Files.Read.All',//
    'https://graph.microsoft.com/Sites.Read.All',//
  ].join(' ');
  //

  inMeeting = false

  radius = 25;
  circumference = 50 * Math.PI;
  dashoffset = 0;
  dashoffsetScore = 0;
  clockCircumference = 10 * Math.PI;
  clockPercent = `${10 * Math.PI} ${10 * Math.PI}`;

  currentDuration = 0;
  duration = 1800;
  maxInterruptions = 30;
  timelapse = [];
  speakers = [];
  interruptions = 0;
  engagement = 0;

  monologueCount = 0;
  monologueDuration = 0;
  maxMonologue = 90;
  speakerName = '';
  monologueHelper = true;

  alerts = [];
  event:any = {};

  genderParticipation = [0, 0, 0, 0]
  genderAxes: any[] = [0,0,0,0]
  participationThreshold = 0.2;
  maleParticipants = []
  femaleParticipants = []
  nonBinaryParticipants = []
  unknownParticipants = []

  communicationParticipation = [0, 0, 0, 0, 0]
  communicationAxes: any[] = [0,0,0,0,0]
  analyticalParticipants = []
  intuitiveParticipants = []
  functionalParticipants = []
  personalParticipants = []
  unknownCommmunicationParticipants = []

  ageParticipation = [0, 0, 0, 0, 0]
  ageAxes: any[] = [0,0,0,0,0]
  boomerParticipants = []
  genxParticipants = []
  millenialParticipants = []
  genzParticipants = []
  unknownAgeParticipants = []

  meetingScore = 40
  participationScore = 0
  previousScore = 0

  improveIcon = faArrowAltCircleUp
  unimproveIcon = faArrowAltCircleDown
  sameIcon = faMinusCircle
  leftArrow = faChevronLeft
  rightArrow = faChevronRight
  inviteIcon = faEnvelope
  questionIcon = faQuestionCircle

  alertSlide = 0

  reactionSpeaker = ''
  reactionTime = 0

  showInput = false
  emails = ''

  agendaAnswer = -1
  effectiveAnswer = -1
  inclusiveAnswer = -1

  showQuestions = false
  fullBarPercent = 96

  acceptRace = true;
  meetingUUID = ''
  isHost = true

  myName = ''
  userStored = false

  gendersTotal = {male: 0, female: 0, nonBinary: 0, unknown: 0}
  communicationsTotal = {analytical: 0, intuitive: 0, personal: 0, functional: 0, unknown: 0}
  agesTotal = {bb: 0, gx: 0, ml: 0, gz: 0, unknown: 0}

  syncTimezone = false
  syncJob = false
  lastVersion = 0
  currentVersion = 0
  utcOffset = 0
  userJob = ''
  deleteCurrentStorage = false

  @HostListener('window:beforeunload', ['$event'])
  beforeunloadHandler(event) {
    this.zoomService.storeMeeting(this.genderParticipation).then(data => {
    })
    //event.preventDefault();
    return false;   
  }

  @HostListener('window:unload', [ '$event' ])
  unloadHandler(event) {
    this.zoomService.storeMeeting(this.genderParticipation).then(data => {
    })
  }

  constructor(private router: Router,
    private changeDetection: ChangeDetectorRef,
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private zoomService: ZoomMeetingService,
    private dialog: MatDialog,
    private renderer: Renderer2) {
      let versionHistory = {
        0: true,
        1: true,
        2: true,
        3: true,
        4: true,
        5: true,
        6: true,
        7: true,
        8: false,
        9: true,
        10: true,
        11: true,
      }
      this.activatedRoute.queryParams.subscribe(params => {
        this.currentVersion = parseInt(params['v']);
        if(localStorage.getItem('version')) {
          this.lastVersion = parseInt(localStorage.getItem('version'))
        }
        
        for(let i = this.lastVersion+1; i <= this.currentVersion; i++) {
          if(versionHistory[i]) {
            this.deleteCurrentStorage = true
            break
          }
        }
        localStorage.setItem('version', `${this.currentVersion}`)
      });
      

      this.utcOffset = DateUtils.getUtcOffset()

      this.renderer.listen('window', 'click',(e:Event)=>{
        let classes = (event.target as Element).className
        if(!classes.includes('gender-box') && !classes.includes('gender-option') && !classes.includes('form-control') && !classes.includes('invite-button') && !classes.includes('speaker-name')) {
          this.showInput = false
          this.speakers.forEach(speaker => {
            speaker.openOptions = false
            speaker.openAge = false
            speaker.openGender = false
            speaker.openCommunication = false
          })
        }
      });
      //
      this.renderer.listen('window', 'keypress',(e:KeyboardEvent)=>{
        //console.log(e)
        if(e.key === 'Enter') {
          this.showInput = false
        }
      });
      this.duration = Math.floor(1800)
      this.maxInterruptions = Math.floor(this.duration/60)
      this.startTime()

    if(localStorage.getItem('tariyAcceptRace')) {
      this.acceptRace = true;
    }
  }

  ngAfterViewInit() {
    this.zoomInit();
  }

  getStartScore(seconds: number) {
    let absSeconds = Math.abs(seconds)
    let maxScore = 10;
    if (seconds < 0)
      return maxScore
    let maxTime = 300;
    let score = absSeconds / maxTime;
    score = (score > 1) ? 1: score;
    return (1 - score)*maxScore
  }

  getTotalGenderParticipation() {
    let sum = this.genderParticipation.reduce((partial, a) => partial +a, 0)
    return sum === 0 ? 1:sum
  }

  getTotalSpeakTime() {
    let total = 0
    this.speakers.forEach(speak => {
      total += speak.duration
    })
    return total === 0 ? 1:total
  }

  getParticipationScore() {
    if(this.speakers.length === 0)
      return
    let participationTime = this.getTotalSpeakTime()
    let participationPoints = 1/this.speakers.length
    this.participationScore = 0
    for(let speaker of this.speakers) {
      let participation = speaker.duration/participationTime
      if(participation >= this.participationThreshold) {
        this.participationScore += participationPoints
      }
      else if(participation >= (this.participationThreshold*0.75)) {
        this.participationScore += participationPoints*0.75
      }
      else if(participation >= (this.participationThreshold*0.5)) {
        this.participationScore += participationPoints*0.5
      }
      else if(participation >= (this.participationThreshold*0.25)) {
        this.participationScore += participationPoints*0.25
      }
    }
  }

  addGenderSpeechTime(lastSpeaker: string, duration: number) {
    /*if(this.maleParticipants.indexOf(lastSpeaker) >= 0) {
      this.genderParticipation[0] += duration
    }
    else if(this.femaleParticipants.indexOf(lastSpeaker) >= 0) {
      this.genderParticipation[1] += duration
    }
    else if(this.nonBinaryParticipants.indexOf(lastSpeaker) >= 0) {
      this.genderParticipation[2] += duration
    }
    else if(this.unknownParticipants.indexOf(lastSpeaker) >= 0) {
      this.genderParticipation[3] += duration
    }*/
    let total = this.getTotalSpeakTime()
    let genders = this.getGenderTimes()

    let axesHelper = [0,0,0,0]
    axesHelper[0] = (genders.male/total)*this.fullBarPercent + 1.5
    axesHelper[1] = axesHelper[0] + ((genders.female/total)*this.fullBarPercent)
    axesHelper[2] = axesHelper[1] + ((genders.nonBinary/total)*this.fullBarPercent)
    axesHelper[3] = axesHelper[2] + ((genders.unknown/total)*this.fullBarPercent)
    this.genderAxes[0] = `${axesHelper[0]}%`
    this.genderAxes[1] = `${axesHelper[1]}%`
    this.genderAxes[2] = `${axesHelper[2]}%`
    this.genderAxes[3] = `${axesHelper[3]}%`
  }

  addCommunicationSpeechTime(lastSpeaker: string, duration: number) {
    /*if(this.analyticalParticipants.indexOf(lastSpeaker) >= 0) {
      this.communicationParticipation[0] += duration
    }
    else if(this.intuitiveParticipants.indexOf(lastSpeaker) >= 0) {
      this.communicationParticipation[1] += duration
    }
    else if(this.functionalParticipants.indexOf(lastSpeaker) >= 0) {
      this.communicationParticipation[2] += duration
    }
    else if(this.personalParticipants.indexOf(lastSpeaker) >= 0) {
      this.communicationParticipation[3] += duration
    }
    else if(this.unknownCommmunicationParticipants.indexOf(lastSpeaker) >= 0) {
      this.communicationParticipation[4] += duration
    }*/
    let total = this.getTotalSpeakTime()
    let communication = this.getCommunicationTimes()
    
    let axesHelper = [0,0,0,0,0]
    axesHelper[0] = (communication.analytical/total)*this.fullBarPercent + 1.5
    axesHelper[1] = axesHelper[0] + ((communication.intuitive/total)*this.fullBarPercent)
    axesHelper[2] = axesHelper[1] + ((communication.functional/total)*this.fullBarPercent)
    axesHelper[3] = axesHelper[2] + ((communication.personal/total)*this.fullBarPercent)
    axesHelper[4] = axesHelper[3] + ((communication.unknown/total)*this.fullBarPercent)
    this.communicationAxes[0] = `${axesHelper[0]}%`
    this.communicationAxes[1] = `${axesHelper[1]}%`
    this.communicationAxes[2] = `${axesHelper[2]}%`
    this.communicationAxes[3] = `${axesHelper[3]}%`
    this.communicationAxes[4] = `${axesHelper[4]}%`
  }

  addAgeSpeechTime(lastSpeaker: string, duration: number) {
    /*if(this.boomerParticipants.indexOf(lastSpeaker) >= 0) {
      this.ageParticipation[0] += duration
    }
    else if(this.genxParticipants.indexOf(lastSpeaker) >= 0) {
      this.ageParticipation[1] += duration
    }
    else if(this.millenialParticipants.indexOf(lastSpeaker) >= 0) {
      this.ageParticipation[2] += duration
    }
    else if(this.genzParticipants.indexOf(lastSpeaker) >= 0) {
      this.ageParticipation[3] += duration
    }
    else if(this.unknownAgeParticipants.indexOf(lastSpeaker) >= 0) {
      this.ageParticipation[4] += duration
    }*/
    let total = this.getTotalSpeakTime()
    let ages = this.getAgeTimes()
    
    let axesHelper = [0,0,0,0,0]
    axesHelper[0] = (ages.bb/total)*this.fullBarPercent + 1.5
    axesHelper[1] = axesHelper[0] + ((ages.gx/total)*this.fullBarPercent)
    axesHelper[2] = axesHelper[1] + ((ages.ml/total)*this.fullBarPercent)
    axesHelper[3] = axesHelper[2] + ((ages.gz/total)*this.fullBarPercent)
    axesHelper[4] = axesHelper[3] + ((ages.unknown/total)*this.fullBarPercent)
    this.ageAxes[0] = `${axesHelper[0]}%`
    this.ageAxes[1] = `${axesHelper[1]}%`
    this.ageAxes[2] = `${axesHelper[2]}%`
    this.ageAxes[3] = `${axesHelper[3]}%`
    this.ageAxes[4] = `${axesHelper[4]}%`
  }

  getSpeakerGender(name: string) {
    if(this.maleParticipants.indexOf(name) >= 0) {
      return 0
    }
    else if(this.femaleParticipants.indexOf(name) >= 0) {
      return 1
    }
    else if(this.nonBinaryParticipants.indexOf(name) >= 0) {
      return 2
    }
    return 3
  }

  getSpeakerCommunication(name: string) {
    if(this.analyticalParticipants.indexOf(name) >= 0) {
      return 0
    }
    else if(this.intuitiveParticipants.indexOf(name) >= 0) {
      return 1
    }
    else if(this.functionalParticipants.indexOf(name) >= 0) {
      return 2
    }
    else if(this.personalParticipants.indexOf(name) >= 0) {
      return 3
    }
    return 4
  }

  getSpeakerGeneration(name: string) {
    if(this.boomerParticipants.indexOf(name) >= 0) {
      return 0
    }
    else if(this.genxParticipants.indexOf(name) >= 0) {
      return 1
    }
    else if(this.millenialParticipants.indexOf(name) >= 0) {
      return 2
    }
    else if(this.genzParticipants.indexOf(name) >= 0) {
      return 3
    }
    return 4
  }

  addMonologuetoSpeaker() {
    let index = this.speakers.findIndex(speaker => speaker.name === this.speakerName)
    if(index > -1) {
      this.speakers[index].monologues += 1
    }
  }

  // Initialize google api service

  async zoomInit()  {
    let zoomConfig = await zoomSdk.config({
      size: { width: 560, height: 360 },
      capabilities: [
          /* Add Capabilities Here */
          'shareApp',
          //"onShareApp",
          "onActiveSpeakerChange",
          //"onMyActiveSpeakerChange",
          //"onMeeting",
          "getMeetingParticipants",
          "openUrl",
          //"sendAppInvitationToAllParticipants",
          //"onMessage",
          //"getUserContext",
          "onReaction",
          "getMeetingJoinUrl",
          "getMeetingUUID"
      ],
    });
    console.log('zoom config', zoomConfig)
    this.inMeeting = zoomConfig.runningContext === 'inMeeting'
    /*if(this.inMeeting) {
      let eventRoute = `../login`;
      this.router.navigate([eventRoute])
    }*///
    /*zoomSdk.getUserContext().then((data) => {
      console.log('meeting context')
    })*/
    console.log('meeting join')
    zoomSdk.getMeetingUUID().then(data => {
      console.log(data)
      this.meetingUUID = data.meetingUUID
      console.log('meeting uuid', this.meetingUUID)
    });
    zoomSdk.getUserContext().then(data => {
      console.log(data)
      this.isHost = data.role == 'host'
      this.myName = data.screenName
      if(this.deleteCurrentStorage) {
        localStorage.removeItem(this.myName)
        this.deleteCurrentStorage = false
      }
      this.userStored = localStorage.getItem(this.myName) ? true: false
      if(!this.userStored) {
        this.completeProfile();
      }
      console.log('is host', this.isHost)
      if(!this.isHost) {
        this.zoomService.syncMeeting(this.meetingUUID).then((data:any) => {
          if(data?.syncData) {
            this.currentDuration = parseInt(data.zoomMeeting.currentDuration);
            this.duration = parseInt(data.zoomMeeting.endsIn);
            this.interruptions = parseInt(data.zoomMeeting.interruptions);
            this.meetingScore = parseFloat(data.zoomMeeting.meetingScore);
            this.participationScore = parseFloat(data.zoomMeeting.participationScore);
            this.speakers = data.zoomMeeting.speakers
            this.resetParticipants()
            this.resetParticipations()
            let i = 0
            for(let speaker of this.speakers) {
              speaker.duration = parseInt(speaker.duration)
              speaker.monologues = parseInt(speaker.monologues)
              speaker.gender = parseInt(speaker.gender)
              speaker.race = parseInt(speaker.race)
              speaker.communication = parseInt(speaker?.communication ?? '4')
              speaker.age = parseInt(speaker.age)
              this.setAges(speaker)
              this.setGenders(speaker)
              this.setCommunication(speaker)
              if(this.userStored && this.myName === speaker.name) {
                let localUser = JSON.parse(localStorage.getItem(this.myName))
                console.log('sync changing my settings', i)
                this.changeAge(localUser.age, i)
                this.changeGender(localUser.gender, i)
                this.changeCommunication(localUser.communication, i)
              }
              i++;
            }
          }
          else {
            Swal.fire({
              icon: 'error',
              title: 'User is not host',
              text: 'TARIY APP is only viewable by host user or when host has open the app',
              showCancelButton: true,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              confirmButtonText: 'Accept'
            })
          }
        });
      }
    });
    try {
    zoomSdk.getMeetingJoinUrl().then((data) => {
      console.log('join url', data)
      this.zoomService.getZoomEvent(data.joinUrl).then(zoomEvent => {
        console.log('zoom event', zoomEvent)
        this.event = zoomEvent
        this.event.starts = moment(this.event.starts)
        this.event.ends = moment(this.event.ends)
        let nowUtc = moment.utc()
  
        let startDiff = moment.duration(nowUtc.diff(this.event.starts))
        this.meetingScore += this.getStartScore(startDiff.asSeconds())
        this.duration = Math.floor(this.event.remaining)
        this.maxInterruptions = Math.floor(this.duration/60)
        for(let user of this.event.users) {
          if(user.gender === 'Male') {
            this.maleParticipants.push(user.name)
          }
          else if (user.gender === 'Female') {
            this.femaleParticipants.push(user.name)
          }
          else if (user.gender === 'Non-Binary') {
            this.nonBinaryParticipants.push(user.name)
          }
          else {
            this.unknownParticipants.push(user.name)
          }
          //race
          /*if(user?.race === 'White') {
            this.whiteParticipants.push(user.name)
          }
          else if (user?.race === 'African-American') {
            this.blackParticipants.push(user.name)
          }
          else if (user?.race === 'Asian') {
            this.asianParticipants.push(user.name)
          }
          else if (user?.race === 'Native American') {
            this.nativeParticipants.push(user.name)
          }
          else if (user?.race === 'Pacific Islander') {
            this.islanderParticipants.push(user.name)
          }
          else if (user?.race === 'Hispanic/Latino') {
            this.hispanicParticipants.push(user.name)
          }
          else if (user?.race === 'Not Hispanic/Latino') {
            this.notHispanicParticipants.push(user.name)
          }
          else {
            this.unknownRaceParticipants.push(user.name)
          }*/
          //communication
          if(user?.communication === 'Analytical') {
            this.analyticalParticipants.push(user.name)
          }
          else if (user?.communication === 'Intuitive') {
            this.intuitiveParticipants.push(user.name)
          }
          else if (user?.communication === 'Functional') {
            this.functionalParticipants.push(user.name)
          }
          else if (user?.communication === 'Personal') {
            this.personalParticipants.push(user.name)
          }
          else {
            this.unknownCommmunicationParticipants.push(user.name)
          }
          //age
          if(user?.age === 'Baby Boomer') {
            this.boomerParticipants.push(user.name)
          }
          else if (user?.age === 'Gen X') {
            this.genxParticipants.push(user.name)
          }
          else if (user?.age === 'Millennial') {
            this.millenialParticipants.push(user.name)
          }
          else if (user?.age === 'Gen Z') {
            this.genzParticipants.push(user.name)
          }
          else {
            this.unknownAgeParticipants.push(user.name)
          }
        }
        
      })
    })
    }
    catch(e) {
      console.log('error')
      console.log(e)
    }
    /*zoomSdk.onMyActiveSpeakerChange((data) => {
      console.log('my active speaker change', data)
    })*/
    console.log('before reaction')
    zoomSdk.onReaction((data) => {
      console.log('reaction', data)
      this.reactionSpeaker = data.screenName
      this.reactionTime = data.timestamp
    })
    /*zoomSdk.onMessage((data) => {
      console.log('new message', data)
    })*/
    zoomSdk.onActiveSpeakerChange((data) => {
      console.log('speaker change', data)
      let currentSpeaker = ''
      if (data.users.length > 1) {
        this.interruptions++;
        if(this.interruptions > this.maxInterruptions) {
          this.meetingScore += (this.interruptions >= (this.maxInterruptions + 20)) ? 0: -1
        }
        let activeSpeakers = data.users.map((speaker) => {
          return speaker.screenName;
        });
        if(this.speakerName) {
          activeSpeakers.forEach(speakName => {
            if(this.speakerName != speakName) {
              let index = this.speakers.findIndex(speaker => speaker.name === speakName)
              if(index > -1) {
                this.speakers[index].interruptions += 1
              }
            }
          })
        }
      }
      else {
        currentSpeaker = data.users[0].screenName
        if(this.timelapse.length > 0) {
          if(this.timelapse[this.timelapse.length-1].speakers.length === 1) {
            if(this.timelapse[this.timelapse.length-1].speakers[0] !== data.users[0].screenName) {
              this.engagement++;
              this.meetingScore += this.engagement <= 40 ? 0.5:0;
            }
            //this.engagement += this.timelapse[this.timelapse.length-1].speakers[0] !== data.users[0].screenName ? 1:0;
            
          }
        }
        
      }
      //only name
      let activeSpeakers = data.users.map((speaker) => {
        return speaker.screenName;
      });
      if(this.reactionSpeaker) {
        //let reactionIndex = activeSpeakers.findIndex(this.reactionSpeaker)
        console.log('reaction difference', moment().unix() - this.reactionTime)
        if((moment().unix() - this.reactionTime) > 15) {
          this.meetingScore += -1
          this.reactionSpeaker = ''
          this.reactionTime = 0
        }
        else {
          if(this.reactionSpeaker === 'Juan Bautista' ) {
            this.meetingScore += 1
            this.reactionSpeaker = ''
            this.reactionTime = 0
          }
        }
      }
      if (this.timelapse.length > 0) {
        let startedAt = this.timelapse[this.timelapse.length - 1].timestamp
        let speechDuration = data.timestamp - startedAt
        this.monologueDuration += speechDuration

        let lastSpeakers = this.timelapse[this.timelapse.length - 1].speakers
        for(let lastSpeaker of lastSpeakers) {
          if(data.users.some(speaker => speaker.screenName === lastSpeaker)) {
            let index = this.speakers.findIndex(speaker => speaker.name === lastSpeaker)
            if(index > -1) {
              this.speakers[index].duration += speechDuration
              this.addGenderSpeechTime(lastSpeaker, speechDuration)
              this.addAgeSpeechTime(lastSpeaker, speechDuration)
              this.addCommunicationSpeechTime(lastSpeaker, speechDuration)
            }
            else {
              this.speakers.push({name: lastSpeaker, duration: speechDuration, gender: this.getSpeakerGender(lastSpeaker), race: 7,
                                  communication: this.getSpeakerCommunication(lastSpeaker), age: this.getSpeakerGeneration(lastSpeaker), monologues: 0, 
                                  openOptions: false, openGender: false, openAge: false, openCommunication: false, interruptions: 0})
              this.addGenderSpeechTime(lastSpeaker, speechDuration)
              this.addAgeSpeechTime(lastSpeaker, speechDuration)
              this.addCommunicationSpeechTime(lastSpeaker, speechDuration)
              if(this.userStored && this.myName === lastSpeaker) {
                let userIndex = this.speakers.findIndex(speaker => speaker.name === lastSpeaker)
                let localUser = JSON.parse(localStorage.getItem(this.myName))
                
                console.log('speaker change changing my settings', userIndex)
                this.changeAge(localUser.age, userIndex)
                this.changeGender(localUser.gender, userIndex)
                this.changeCommunication(localUser.communication, userIndex)
              }
            }
          }
        }

        if(data.users.length === 1) {
          if(currentSpeaker !== this.speakerName) {
            this.monologueDuration = 0;
            this.monologueHelper = true;
            this.speakerName = currentSpeaker;
          }
          else {
            if((this.monologueDuration >= this.maxMonologue) && this.monologueHelper) {
              this.monologueCount++
              this.addMonologuetoSpeaker()
              this.monologueHelper = false;
              if(this.monologueCount <= 3) {
                this.meetingScore += -4
              }
              else if(this.monologueCount == 4) {
                this.meetingScore += -3
              }
            }
            
          }
        }
      }
      this.timelapse.push({timestamp: data.timestamp, speakers: activeSpeakers})
      this.getParticipationScore()

      zoomSdk.getMeetingParticipants().then(participants => {
        this.maxMonologue = participants.participants.length === 2 ? 60:90; 
        this.participationThreshold = participants.participants.length >= 5 ? (1/participants.participants.length - 0.05): 0.2

        for(let participant of participants.participants) {
          let index = this.speakers.findIndex(speaker => speaker.name === participant.screenName)
          if(index < 0) {
            this.speakers.push({name: participant.screenName, duration: 0, gender: this.getSpeakerGender(participant.screenName), race: 7,
                                communication: this.getSpeakerCommunication(participant.screenName), age: this.getSpeakerGeneration(participant.screenName), monologues: 0, 
                                openOptions: false, openGender: false, openAge: false, openCommunication: false, interruptions: 0})
            console.log(this.event)
            let userIndex = -1
            try {
              userIndex = this.event.users.findIndex(speaker => speaker.name === participant.screenName)
            }
            catch(e) {
              console.log(e)
            }
            if(this.userStored && this.myName === participant.screenName) {
              let localUser = JSON.parse(localStorage.getItem(this.myName))
              let participantIndex = this.speakers.findIndex(speaker => speaker.name === participant.screenName)
              console.log('meeting participants changing my settings', participantIndex)
              this.changeAge(localUser.age, participantIndex)
              this.changeGender(localUser.gender, participantIndex)
              this.changeCommunication(localUser.communication, participantIndex)
            }
            if(userIndex >= 0) {
              if(this.event.users[userIndex].gender === 'Male') {
                if(this.maleParticipants.indexOf(participant.screenName) === -1) {
                  this.maleParticipants.push(participant.screenName)
                }
              }
              else if(this.event.users[userIndex].gender === 'Female') {
                if(this.femaleParticipants.indexOf(participant.screenName) === -1) {
                  this.femaleParticipants.push(participant.screenName)
                }
              }
              else if(this.event.users[userIndex].gender === 'Non-Binary') {
                if(this.nonBinaryParticipants.indexOf(participant.screenName) === -1) {
                  this.nonBinaryParticipants.push(participant.screenName)
                }
              }
              else {
                if(this.unknownParticipants.indexOf(participant.screenName) === -1) {
                  this.unknownParticipants.push(participant.screenName)
                }
              }
              //communication
              if(this.event.users[userIndex]?.communication === 'Analytical') {
                if(this.analyticalParticipants.indexOf(participant.screenName) === -1) {
                  this.analyticalParticipants.push(participant.screenName)
                }
              }
              else if(this.event.users[userIndex]?.communication === 'Intuitive') {
                if(this.intuitiveParticipants.indexOf(participant.screenName) === -1) {
                  this.intuitiveParticipants.push(participant.screenName)
                }
              }
              else if(this.event.users[userIndex]?.communication === 'Functional') {
                if(this.functionalParticipants.indexOf(participant.screenName) === -1) {
                  this.functionalParticipants.push(participant.screenName)
                }
              }
              else if(this.event.users[userIndex]?.communication === 'Personal') {
                if(this.personalParticipants.indexOf(participant.screenName) === -1) {
                  this.personalParticipants.push(participant.screenName)
                }
              }
              else {
                if(this.unknownCommmunicationParticipants.indexOf(participant.screenName) === -1) {
                  this.unknownCommmunicationParticipants.push(participant.screenName)
                }
              }
              //age
              if(this.event.users[userIndex]?.age === 'Baby Boomer') {
                if(this.boomerParticipants.indexOf(participant.screenName) === -1) {
                  this.boomerParticipants.push(participant.screenName)
                }
              }
              else if(this.event.users[userIndex]?.age === 'Gen X') {
                if(this.genxParticipants.indexOf(participant.screenName) === -1) {
                  this.genxParticipants.push(participant.screenName)
                }
              }
              else if(this.event.users[userIndex]?.age === 'Millennial') {
                if(this.millenialParticipants.indexOf(participant.screenName) === -1) {
                  this.millenialParticipants.push(participant.screenName)
                }
              }
              else if(this.event.users[userIndex]?.age === 'Gen Z') {
                if(this.genzParticipants.indexOf(participant.screenName) === -1) {
                  this.genzParticipants.push(participant.screenName)
                }
              }
              else {
                if(this.unknownAgeParticipants.indexOf(participant.screenName) === -1) {
                  this.unknownAgeParticipants.push(participant.screenName)
                }
              }
            }
            else {
              this.unknownParticipants.push(participant.screenName)
              this.unknownCommmunicationParticipants.push(participant.screenName)
              this.unknownAgeParticipants.push(participant.screenName)
            }
          }

          
        }
      })
      zoomSdk.getMeetingUUID().then(meeting => {
        this.changeDetection.detectChanges();
        this.speakers = this.speakers.map(el => Object.assign({}, el))

        if (this.timelapse.length > 1) {
          let meetingRecap = {
            meeting: meeting,
            startingTimestamp: this.timelapse[0].timestamp,
            speakers: this.speakers,
            interruptions: this.interruptions,
            starts: this.event.starts,
            emails: this.emails,
            meetingScore: this.meetingScore,
            participationScore: this.participationScore,
            duration: this.duration,
            currentDuration: this.currentDuration
          }
          this.zoomService.storeMeeting(meetingRecap).then((storeEvent: any) => {
            if(storeEvent?.syncData) {
              let toChangeIndex = this.speakers.map(x => x.name).indexOf(storeEvent?.toSync?.name)
              if(toChangeIndex != -1) {
                let age = parseInt(storeEvent?.toSync?.age);
                let gender = parseInt(storeEvent?.toSync?.gender);
                let race = parseInt(storeEvent?.toSync?.race);
                let communication = parseInt(storeEvent?.toSync?.communication);
                this.changeAge(age, toChangeIndex)
                this.changeGender(gender, toChangeIndex)
                this.changeCommunication(communication, toChangeIndex)
              }
            }
          })
          
          if(this.timelapse.length > 5) {
            if(!this.syncTimezone) {
              this.zoomService.addTimezone({meetingUUID: this.meetingUUID, utcOffset: this.utcOffset}).then((syncEvent: any) => {
                console.log(syncEvent)
                this.syncTimezone = true;
              })
            }
            if(localStorage.getItem('jobTitle') && !this.syncJob) {
              this.userJob = localStorage.getItem('jobTitle')
              this.sendJobTitle()
              
            }
          }
        }
          
          /*this.zoomService.storeMeeting({meeting: meeting, speakers: data}).then(data => {
            console.log(data)
          })*/
          /*axios.post('https://api.tariy.ai/zoom-oauth/speaker', {meeting: meeting, speakers: data}).then(result => {
              console.log(result)
          })*/
      })
    })
    //this.changeDetection.detectChanges();
  }

  checkMeetingStatus() {
    let participationTime = this.getTotalSpeakTime()
    let minParticipation = this.participationThreshold > 0.15 ? this.participationThreshold: 0.15

    if (this.currentDuration > (this.duration/3)) {
      this.showQuestions = true
    }
    if(this.currentDuration > this.duration) {
      this.alerts.push('Meeting time over')
      this.meetingScore += (this.currentDuration > (this.duration + 300)) ? 0: (-1/60)
    }
    else if((this.currentDuration + 300) > this.duration) {
      this.alerts.push('5 min remaining in meeting')
    }
    if(this.monologueDuration > this.maxMonologue) {
      this.alerts.push(`${this.speakerName} has been talking continuosly for too long`)
    }
    if(this.interruptions > this.maxInterruptions) {
      this.alerts.push(`This meeting has had a bigger number of interruptions than recommended`)
    }
    if(this.currentDuration > 300) {
      for(let speaker of this.speakers) {
        let participation = speaker.duration/participationTime
        if(speaker.duration === 0) {
          this.alerts.push(`${speaker.name} hasn't participated yet`)
        }
        else if(participation <= (minParticipation - 0.1)) {
          this.alerts.push(`${speaker.name} has low participation`)
        }
      }
    }
  }

  startTime() {
    let timerId = setInterval(() => {
      //console.log('porcentage', (this.currentDuration/this.duration))
      this.alerts = [];
      this.currentDuration++;
      this.dashoffset = this.circumference - (this.circumference * (this.currentDuration/this.duration));
      this.clockPercent = `${this.clockCircumference - (this.currentDuration/this.duration * this.clockCircumference)} ${this.clockCircumference}`
      this.dashoffsetScore = (this.circumference * (this.meetingScore + (this.participationScore*30)) / 100);
      if(this.currentDuration % 15 == 0) {
        this.previousScore = this.meetingScore + (this.participationScore*30)
        if(!this.isHost) {
          this.syncToHost()
          
          if(localStorage.getItem('jobTitle') && !this.syncJob) {
            this.userJob = localStorage.getItem('jobTitle')
            this.sendJobTitle()
          }
        }
      }
      this.checkMeetingStatus();
    }, 1000)
  }

  changeSlide(forward:boolean) {
    if(forward) {
      if((this.alertSlide + 2) > this.alerts.length) {
        this.alertSlide = 0
      }
      else {
        this.alertSlide++
      }
    }
    else {
      if(this.alertSlide === 0) {
        this.alertSlide = this.alerts.length - 1
      }
      else {
        this.alertSlide--
      }
    }
  }

  async invite() {
    this.showInput = !this.showInput
    /*await zoomSdk.sendAppInvitationToAllParticipants()
    .then(function(){
    // success
    })
    .catch(function(error){
    // there was an error
    })*/
  }

  addEmail(event) {
  }

  async openInfo() {
    await zoomSdk.openUrl({ url: "https://www.tariy.ai/" });
  }

  openOptions(index: number) {
    this.speakers[index].openOptions = !this.speakers[index].openOptions
  }

  openAge(index: number) {
    if(this.speakers[index].name == this.myName) {
      this.speakers[index].openAge = true
      this.speakers[index].openGender = false
      this.speakers[index].openCommunication = false
    }
  }

  openGender(index: number) {
    if(this.speakers[index].name == this.myName) {
      this.speakers[index].openGender = true
      this.speakers[index].openAge = false
      this.speakers[index].openCommunication = false
    }
  }

  openCommunication(index: number) {
    if(this.acceptRace) {
      if(this.speakers[index].name == this.myName) {
        this.speakers[index].openCommunication = true
        this.speakers[index].openAge = false
        this.speakers[index].openGender = false
      }
    }
    else {
      this.swalConfirm('By accepting, you choose to share this information with TARIY', 'Race analytics', 'question', index)
    }
  }

  changeGender(gender: number, index: number) {
    this.speakers[index].openGender = false
    if(this.speakers[index].gender === 0) {
      let participantIndex = this.maleParticipants.indexOf(this.speakers[index].name)
      this.maleParticipants.splice(participantIndex, 1)
      this.genderParticipation[0] += -this.speakers[index].duration
    }
    else if(this.speakers[index].gender === 1) {
      let participantIndex = this.femaleParticipants.indexOf(this.speakers[index].name)
      this.femaleParticipants.splice(participantIndex, 1)
      this.genderParticipation[1] += -this.speakers[index].duration
    }
    else if(this.speakers[index].gender === 2) {
      let participantIndex = this.nonBinaryParticipants.indexOf(this.speakers[index].name)
      this.nonBinaryParticipants.splice(participantIndex, 1)
      this.genderParticipation[2] += -this.speakers[index].duration
    }
    else if(this.speakers[index].gender === 3) {
      let participantIndex = this.unknownParticipants.indexOf(this.speakers[index].name)
      this.unknownParticipants.splice(participantIndex, 1)
      this.genderParticipation[3] += -this.speakers[index].duration
    }
    this.speakers[index].gender = gender
    /*if(gender === 0) {
      this.maleParticipants.push(this.speakers[index].name)
      this.genderParticipation[0] += this.speakers[index].duration
    }
    else if(gender === 1) {
      this.femaleParticipants.push(this.speakers[index].name)
      this.genderParticipation[1] += this.speakers[index].duration
    }
    else if(gender === 2) {
      this.nonBinaryParticipants.push(this.speakers[index].name)
      this.genderParticipation[2] += this.speakers[index].duration
    }*/

    let total = this.getTotalSpeakTime()
    let genders = this.getGenderTimes()

    let axesHelper = [0,0,0,0]
    axesHelper[0] = (genders.male/total)*this.fullBarPercent + 1.5
    axesHelper[1] = axesHelper[0] + ((genders.female/total)*this.fullBarPercent)
    axesHelper[2] = axesHelper[1] + ((genders.nonBinary/total)*this.fullBarPercent)
    axesHelper[3] = axesHelper[2] + ((genders.unknown/total)*this.fullBarPercent)
    this.genderAxes[0] = `${axesHelper[0]}%`
    this.genderAxes[1] = `${axesHelper[1]}%`
    this.genderAxes[2] = `${axesHelper[2]}%`
    this.genderAxes[3] = `${axesHelper[3]}%`
    if(!this.isHost) {
      this.zoomService.syncSpeaker(this.meetingUUID, this.speakers[index].name, this.speakers[index].age, this.speakers[index].gender, this.speakers[index].communication ).then(data => {});
    }
    if(this.myName === this.speakers[index].name) {
      let localUser = {
        age: this.speakers[index].age,
        gender: this.speakers[index].gender,
        race: 7,
        communication: this.speakers[index].communication
      }
      localStorage.setItem(this.myName, JSON.stringify(localUser))
    }
  }

  changeCommunication(communication: number, index: number) {
    this.speakers[index].openCommunication = false
    if(this.speakers[index].communication === 0) {
      let participantIndex = this.analyticalParticipants.indexOf(this.speakers[index].name)
      this.analyticalParticipants.splice(participantIndex, 1)
      this.communicationParticipation[0] += -this.speakers[index].duration
    }
    else if(this.speakers[index].communication === 1) {
      let participantIndex = this.intuitiveParticipants.indexOf(this.speakers[index].name)
      this.intuitiveParticipants.splice(participantIndex, 1)
      this.communicationParticipation[1] += -this.speakers[index].duration
    }
    else if(this.speakers[index].communication === 2) {
      let participantIndex = this.functionalParticipants.indexOf(this.speakers[index].name)
      this.functionalParticipants.splice(participantIndex, 1)
      this.communicationParticipation[2] += -this.speakers[index].duration
    }
    else if(this.speakers[index].communication === 3) {
      let participantIndex = this.personalParticipants.indexOf(this.speakers[index].name)
      this.personalParticipants.splice(participantIndex, 1)
      this.communicationParticipation[3] += -this.speakers[index].duration
    }
    else if(this.speakers[index].communication === 4) {
      let participantIndex = this.unknownCommmunicationParticipants.indexOf(this.speakers[index].name)
      this.unknownCommmunicationParticipants.splice(participantIndex, 1)
      this.communicationParticipation[4] += -this.speakers[index].duration
    }
    this.speakers[index].communication = communication
    /*if(communication === 0) {
      this.analyticalParticipants.push(this.speakers[index].name)
      this.communicationParticipation[0] += this.speakers[index].duration
    }
    else if(communication === 1) {
      this.intuitiveParticipants.push(this.speakers[index].name)
      this.communicationParticipation[1] += this.speakers[index].duration
    }
    else if(communication === 2) {
      this.functionalParticipants.push(this.speakers[index].name)
      this.communicationParticipation[2] += this.speakers[index].duration
    }
    else if(communication === 3) {
      this.personalParticipants.push(this.speakers[index].name)
      this.communicationParticipation[3] += this.speakers[index].duration
    }*/
    
    let total = this.getTotalSpeakTime()
    let communications = this.getCommunicationTimes()

    let axesHelper = [0,0,0,0,0]
    axesHelper[0] = (communications.analytical/total)*this.fullBarPercent + 1.5
    axesHelper[1] = axesHelper[0] + ((communications.intuitive/total)*this.fullBarPercent)
    axesHelper[2] = axesHelper[1] + ((communications.functional/total)*this.fullBarPercent)
    axesHelper[3] = axesHelper[2] + ((communications.personal/total)*this.fullBarPercent)
    axesHelper[4] = axesHelper[3] + ((communications.unknown/total)*this.fullBarPercent)
    this.communicationAxes[0] = `${axesHelper[0]}%`
    this.communicationAxes[1] = `${axesHelper[1]}%`
    this.communicationAxes[2] = `${axesHelper[2]}%`
    this.communicationAxes[3] = `${axesHelper[3]}%`
    this.communicationAxes[4] = `${axesHelper[4]}%`

    if(!this.isHost) {
      this.zoomService.syncSpeaker(this.meetingUUID, this.speakers[index].name, this.speakers[index].age, this.speakers[index].gender, this.speakers[index].communication ).then(data => {});
    }
    if(this.myName === this.speakers[index].name) {
      let localUser = {
        age: this.speakers[index].age,
        gender: this.speakers[index].gender,
        race: 7,
        communication: this.speakers[index].communication
      }
      localStorage.setItem(this.myName, JSON.stringify(localUser))
    }
  }

  changeAge(age: number, index: number) {
    this.speakers[index].openAge = false
    if(this.speakers[index].age === 0) {
      let participantIndex = this.boomerParticipants.indexOf(this.speakers[index].name)
      this.boomerParticipants.splice(participantIndex, 1)
      this.ageParticipation[0] += -this.speakers[index].duration
    }
    else if(this.speakers[index].age === 1) {
      let participantIndex = this.genxParticipants.indexOf(this.speakers[index].name)
      this.genxParticipants.splice(participantIndex, 1)
      this.ageParticipation[1] += -this.speakers[index].duration
    }
    else if(this.speakers[index].age === 2) {
      let participantIndex = this.millenialParticipants.indexOf(this.speakers[index].name)
      this.millenialParticipants.splice(participantIndex, 1)
      this.ageParticipation[2] += -this.speakers[index].duration
    }
    else if(this.speakers[index].age === 3) {
      let participantIndex = this.genzParticipants.indexOf(this.speakers[index].name)
      this.genzParticipants.splice(participantIndex, 1)
      this.ageParticipation[3] += -this.speakers[index].duration
    }
    else if(this.speakers[index].age === 4) {
      let participantIndex = this.unknownAgeParticipants.indexOf(this.speakers[index].name)
      this.unknownAgeParticipants.splice(participantIndex, 1)
      this.ageParticipation[4] += -this.speakers[index].duration
    }

    this.speakers[index].age = age
    /*if(age === 0) {
      this.boomerParticipants.push(this.speakers[index].name)
      this.ageParticipation[0] += this.speakers[index].duration
    }
    else if(age === 1) {
      this.genxParticipants.push(this.speakers[index].name)
      this.ageParticipation[1] += this.speakers[index].duration
    }
    else if(age === 2) {
      this.millenialParticipants.push(this.speakers[index].name)
      this.ageParticipation[2] += this.speakers[index].duration
    }
    else if(age === 3) {
      this.genzParticipants.push(this.speakers[index].name)
      this.ageParticipation[3] += this.speakers[index].duration
    }*/

    let total = this.getTotalSpeakTime()
    let ages = this.getAgeTimes()

    let axesHelper = [0,0,0,0,0]
    axesHelper[0] = (ages.bb/total)*this.fullBarPercent + 1.5
    axesHelper[1] = axesHelper[0] + ((ages.gx/total)*this.fullBarPercent)
    axesHelper[2] = axesHelper[1] + ((ages.ml/total)*this.fullBarPercent)
    axesHelper[3] = axesHelper[2] + ((ages.gz/total)*this.fullBarPercent)
    axesHelper[4] = axesHelper[3] + ((ages.unknown/total)*this.fullBarPercent)
    this.ageAxes[0] = `${axesHelper[0]}%`
    this.ageAxes[1] = `${axesHelper[1]}%`
    this.ageAxes[2] = `${axesHelper[2]}%`
    this.ageAxes[3] = `${axesHelper[3]}%`
    this.ageAxes[4] = `${axesHelper[4]}%`

    if(!this.isHost) {
      this.zoomService.syncSpeaker(this.meetingUUID, this.speakers[index].name, this.speakers[index].age, this.speakers[index].gender, this.speakers[index].communication ).then(data => {});
    }
    if(this.myName === this.speakers[index].name) {
      let localUser = {
        age: this.speakers[index].age,
        gender: this.speakers[index].gender,
        race: 7,
        communication: this.speakers[index].communication
      }
      localStorage.setItem(this.myName, JSON.stringify(localUser))
    }
  }

  formatDuration(seconds: number) {
    let absSeconds = Math.abs(seconds)
    return `${seconds < 0 ? '-': ''}${Math.floor(absSeconds/60)}:${(absSeconds % 60) < 10 ? 0: ''}${absSeconds % 60}`
  }

  answerAgenda(answer: number) {
    this.agendaAnswer = answer
    this.zoomService.syncAnswers(this.meetingUUID, this.myName, this.agendaAnswer, this.effectiveAnswer, this.inclusiveAnswer).then(data => {})
  }
  answerInclusive(answer: number) {
    this.inclusiveAnswer = answer
    this.zoomService.syncAnswers(this.meetingUUID, this.myName, this.agendaAnswer, this.effectiveAnswer, this.inclusiveAnswer).then(data => {})
  }
  answerEffective(answer: number) {
    this.effectiveAnswer = answer
    this.zoomService.syncAnswers(this.meetingUUID, this.myName, this.agendaAnswer, this.effectiveAnswer, this.inclusiveAnswer).then(data => {})
  }

  getPercent(total) {
    return `${Math.round(100 * total/this.getTotalSpeakTime())}%`
  }

  resetParticipants() {
    this.maleParticipants = []
    this.femaleParticipants = []
    this.nonBinaryParticipants = []
    this.unknownParticipants = []
    this.analyticalParticipants = []
    this.intuitiveParticipants = []
    this.functionalParticipants = []
    this.personalParticipants = []
    this.unknownCommmunicationParticipants = []
    this.boomerParticipants = []
    this.genxParticipants = []
    this.millenialParticipants = []
    this.genzParticipants = []
    this.unknownAgeParticipants = []
  }

  resetParticipations() {
    this.genderParticipation = [0, 0, 0, 0]
    this.communicationParticipation = [0, 0, 0, 0, 0]
    this.ageParticipation = [0, 0, 0, 0, 0]
  }

  setAges(speaker:any) {
    let age = speaker.age
    if(age === AgeType.Baby_Boomer) {
      this.boomerParticipants.push(speaker.name)
      this.ageParticipation[0] += speaker.duration
    }
    else if(age === AgeType.Gen_X) {
      this.genxParticipants.push(speaker.name)
      this.ageParticipation[1] += speaker.duration
    }
    else if(age === AgeType.Millennial) {
      this.millenialParticipants.push(speaker.name)
      this.ageParticipation[2] += speaker.duration
    }
    else if(age === AgeType.Gen_Z) {
      this.genzParticipants.push(speaker.name)
      this.ageParticipation[3] += speaker.duration
    }
    else {
      this.unknownAgeParticipants.push(speaker.name)
      this.ageParticipation[4] += speaker.duration
    }
  }

  setGenders(speaker:any) {
    let gender = speaker.gender
    if(gender === GenderType.Male) {
      this.maleParticipants.push(speaker.name)
      this.genderParticipation[0] += speaker.duration
    }
    else if(gender === GenderType.Female) {
      this.femaleParticipants.push(speaker.name)
      this.genderParticipation[1] += speaker.duration
    }
    else if(gender === GenderType.Non_Binary) {
      this.nonBinaryParticipants.push(speaker.name)
      this.genderParticipation[2] += speaker.duration
    }
    else {
      this.unknownParticipants.push(speaker.name)
      this.genderParticipation[3] += speaker.duration
    }
  }

  setCommunication(speaker:any) {
    let communication = speaker.communication
    if(communication === CommunicationType.Analytical) {
      this.analyticalParticipants.push(speaker.name)
      this.communicationParticipation[0] += speaker.duration
    }
    else if(communication === CommunicationType.Intuitive) {
      this.intuitiveParticipants.push(speaker.name)
      this.communicationParticipation[1] += speaker.duration
    }
    else if(communication === CommunicationType.Functional) {
      this.functionalParticipants.push(speaker.name)
      this.communicationParticipation[2] += speaker.duration
    }
    else if(communication === CommunicationType.Personal) {
      this.personalParticipants.push(speaker.name)
      this.communicationParticipation[3] += speaker.duration
    }
    else {
      this.unknownCommmunicationParticipants.push(speaker.name)
      this.communicationParticipation[4] += speaker.duration
    }
  }

  syncToHost() {
    this.zoomService.syncMeeting(this.meetingUUID).then((data:any) => {
      if(data?.syncData) {
        this.syncMeeting(data.zoomMeeting)
      }
    })
  }

  syncMeeting(zoomMeeting) {
    this.interruptions = parseInt(zoomMeeting.interruptions);
    this.meetingScore = parseFloat(zoomMeeting.meetingScore);
    this.participationScore = parseFloat(zoomMeeting.participationScore);

    this.speakers = zoomMeeting.speakers
    for(let speaker of this.speakers) {
      speaker.duration = parseInt(speaker.duration)
      speaker.monologues = parseInt(speaker.monologues)
      speaker.gender = parseInt(speaker.gender)
      speaker.race = parseInt(speaker.race)
      speaker.communication = parseInt(speaker?.communication ?? '4')
      speaker.age = parseInt(speaker.age)
      this.resetParticipants()
      this.resetParticipations()
      this.setAges(speaker)
      this.setGenders(speaker)
      this.setCommunication(speaker)
    }
  }

  swalConfirm(message:string, topic:string, type:any, index: number) {
    Swal.fire({
      icon: type,
      title: topic,
      text: message,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Accept'
    }).then(result => {
      if(result.isConfirmed) {
        this.acceptRace = true
        this.speakers[index].openCommunication = true
        this.speakers[index].openAge = false
        this.speakers[index].openGender = false
        localStorage.setItem('tariyAcceptRace', 'true')
      }
    });
  }

  storeMyName(age, gender, communication) {
    let localUser = {
      age: age < 0 ? 4 : age,
      gender: gender < 0 ? 3 : gender,
      race: 7,
      communication: communication < 0 ? 4: communication
    }
    console.log(localUser, this.myName)
    if(this.myName) {
      localStorage.setItem(this.myName, JSON.stringify(localUser))
    }
    let i = 0;
    
    if(this.speakers.length == 0) {
      this.speakers.push({name: this.myName, duration: 0, gender: this.getSpeakerGender(localUser.gender), race: 7,
        communication: this.getSpeakerCommunication(localUser.communication), age: this.getSpeakerGeneration(localUser.age), monologues: 0, 
        openOptions: false, openGender: false, openAge: false, openCommunication: false, interruptions: 0})
    }
    
    for(let speaker of this.speakers) {
      if(this.myName === speaker.name) {
        this.changeAge(localUser.age, i)
        this.changeGender(localUser.gender, i)
        this.changeCommunication(localUser.communication, i)
      }
      i++;
    }
  }

  getGenderTimes() {
    let total = {
      male: 0,
      female: 0,
      nonBinary: 0,
      unknown: 0
    }
    this.speakers.forEach(speak => {
      if(speak.gender == GenderType.Male) {
        total.male += speak.duration
      }
      else if(speak.gender == GenderType.Female) {
        total.female += speak.duration
      }
      else if(speak.gender == GenderType.Non_Binary) {
        total.nonBinary += speak.duration
      }
      else {
        total.unknown += speak.duration
      }
    })
    this.gendersTotal = total
    return total
  }

  getCommunicationTimes() {
    let total = {
      analytical: 0,
      intuitive: 0,
      personal: 0,
      functional: 0,
      unknown: 0
    }
    this.speakers.forEach(speak => {
      if(speak.communication == CommunicationType.Analytical) {
        total.analytical += speak.duration
      }
      else if(speak.communication == CommunicationType.Intuitive) {
        total.intuitive += speak.duration
      }
      else if(speak.communication == CommunicationType.Personal) {
        total.personal += speak.duration
      }
      else if(speak.communication == CommunicationType.Functional) {
        total.functional += speak.duration
      }
      else {
        total.unknown += speak.duration
      }
    })
    this.communicationsTotal = total
    return total
  }

  getAgeTimes() {
    let total = {
      bb: 0,
      gx: 0,
      ml: 0,
      gz: 0,
      unknown: 0
    }
    this.speakers.forEach(speak => {
      if(speak.age == AgeType.Baby_Boomer) {
        total.bb += speak.duration
      }
      else if(speak.age == AgeType.Gen_X) {
        total.gx += speak.duration
      }
      else if(speak.age == AgeType.Millennial) {
        total.ml += speak.duration
      }
      else if(speak.age == AgeType.Gen_Z) {
        total.gz += speak.duration
      }
      else {
        total.unknown += speak.duration
      }
    })
    this.agesTotal = total
    return total
  }

  setJobTitle(jobTitle) {
    localStorage.setItem('jobTitle', jobTitle)
    this.userJob = jobTitle
  }

  sendJobTitle() {
    this.zoomService.addJob({meetingUUID: this.meetingUUID, speakerName:this.myName, jobTitle:this.userJob}).then((addJob: any) => {
      console.log(addJob)
      this.syncJob = true;
    })
  }

  completeProfile(){
    const dialogRef = this.dialog.open(ZoomProfileComponent, {
      width: '95%',
      height: 'auto',
      minHeight: '200px',
      backdropClass: 'profileBackground',
      panelClass: 'profilePanel',
      disableClose: true
    });
    dialogRef.afterClosed().subscribe(profile => {
      console.log(profile.jobTitle)
      this.storeMyName(profile.generation, profile.gender, profile.communication)
      if(profile.jobTitle) {
        this.setJobTitle(profile.jobTitle)
      }
      zoomSdk.openUrl({ url: `https://insights.tariy.ai/zoom-install${profile.jobTitle ? `?job=${encodeURI(profile.jobTitle)}`: ''}` });
      /*if(profile.jobTitle) {
        this.zoomService.syncSpeaker(this.meetingUUID, this.myName, profile.jobTitle ).then(data => {});
      }*/

    })
  }
}
