<section class="dashboard pb-3 pb-lg-0 pb-xl-0">
  <div class="row">
    <div class="col-12 col-lg-12 col-xl-12 p-0">
      <div class="row mt-2 mb-2">
        <div class="col-12 col-lg-4 d-flex align-items-center">
          <div class="row">
            <div class="col">
              <div class="d-none">How did your team do this week?</div>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-3 d-flex align-items-center justify-content-center flex-column flex-lg-row flex-xl-row">
          <h3 class="page-title mb-2 mb-lg-0 mb-xl-0 mr-4 text-center"><b >Meeting Score&reg;</b></h3>
          <svg width="80" height="80" viewBox="0 0 80 80" class="progress__svg">
            <circle
              [attr.r]="radius"
              cx="40"
              cy="40"
              stroke-width="12"
              class="progress__value"
            >
            </circle>
            <circle
              [style.strokeDasharray]="circumference"
              [style.strokeDashoffset]="dashoffset"
              [attr.r]="radius"
              cx="40"
              cy="40"
              stroke-width="13"
              class="progress__meter"
            >
            </circle>
            <text x="27" y="-33" class="progress__text" *ngIf="!isText(score)">{{ score}}</text>
            <text x="23" y="-33" class="progress__text" *ngIf="isText(score)">{{ score}}</text>
          </svg>
          <fa-icon [icon]="score > pastScore ? incrementIcon : decrementIcon" class="pl-2" [ngClass]="scoreBigger() > 1 ? 'incrementScore':'decrementScore'" *ngIf="!(loadingMetrics$ | async)"></fa-icon>
          <span class="pl-2" [ngClass]="scoreBigger() > 1 ? 'incrementScore':'decrementScore'" *ngIf="!(loadingMetrics$ | async)">{{scoreDifference()}}</span>
          <fa-icon [icon]="tipIcon" class="tipIcon pl-4" (click)="showTips()"></fa-icon>
        </div>
        <div class="col-12 col-sm-6 col-lg-2 mb-sm-3">
          <div class="row w-100">
            <label for="daterange" class="col col-form-label text-right mt-3 ml-auto">Filter by date:</label>
          </div>
        </div>
        <div class="col-12 col-sm-6 col-lg-3 mb-3 mb-lg-0 pl-lg-0 d-flex align-items-center">
          <div class="row w-100">
            <div class="col daterange-input mx-3 mx-lg-0">
              <input type="text" name="daterange" ngxDaterangepickerMd class="form-control daterange" [ranges]="ranges"
              [showCustomRangeLabel]="true" [alwaysShowCalendars]="true" [showRangeLabelOnInput]="true" autocomplete="off"
              [(ngModel)]="selectedRangeDates" (change)="updateDashboard()" placeholder="Choose date" containerStyles='display: "inline-flex"'/>
            </div>
          </div>
        </div>
      </div>
      <div class="row equal">
        <div class="col-12 col-lg-9">
          <div class="row">
            <div class="col-12">
              <div class="row">
                <div class="header-title">
                  <p class="d-flex align-items-end" style="height: 30px;">
                    <span [ngClass]="seeOverview ? 'card-selector-active': 'card-selector'" class="card-tab" (click)="switchView(true)">MEETING EFFICIENCY</span>
                    <span [ngClass]="seeOverview ? 'card-selector': 'card-selector-active'" class="card-tab" (click)="switchView(false)">MEETING EQUITY</span>
                  </p>
                </div>
              </div>
              <div class="row estimated-time" *ngIf="seeOverview; else meetingDynamics">
                <!-- MEETING COST -->
                <div class="col-12 col-sm-6  col-lg-4 col-xl-4 p-0 mb-4" *ngIf="!(loadingMetrics$ | async)">
                  <div class="item-estimated">
                    <div id="carouselExampleControls" class="carousel slide d-flex" data-ride="carousel">
                      <div class="carousel-inner d-flex">
                        <app-dashboard-card class="carousel-item" [ngClass]="costCarousel[0]" 
                        [title]="'Meeting Cost'" [dataTitle]="'Cost in meetings'" [icon]="'meet_cost'" [amount]="(metricsData['meet_cost']?.total | currency)"
                        [total]="metricsData['meet_cost']?.total" [range]="rangeText" [isIncrement]="differences['meet_cost']?.total > metricsData['meet_cost']?.total" 
                        [yesterdayAmount]="(differences['meet_cost']?.total | currency)" [yesterdayTotal]="differences['meet_cost']?.total" [hasCompareData]="differences['meet_cost']?.total > 0"
                        [carouselClass]="costCarousel[0]" [dataClass]="'meet_cost'" [loading]="loadingPastMetrics$"
                        [insight]="metricsData['meet_cost']?.insights" 
                        [difference]="(differences['meet_cost']?.total > 0) ? (metricsData['meet_cost']?.total - differences['meet_cost']?.total | currency) : ('0' | currency)">
                        </app-dashboard-card>
                        <app-dashboard-card class="carousel-item" [ngClass]="costCarousel[1]"   
                        [title]="'Meeting Cost'" [dataTitle]="'Avg Cost'" [icon]="'avg_meet_cost'" [amount]="(metricsData['avg_meet_cost']?.total | currency)"
                        [total]="metricsData['avg_meet_cost']?.total" [range]="rangeText" [isIncrement]="differences['avg_meet_cost']?.total > metricsData['avg_meet_cost']?.total" 
                        [yesterdayAmount]="(differences['avg_meet_cost']?.total | currency)" [yesterdayTotal]="differences['avg_meet_cost']?.total" [hasCompareData]="differences['avg_meet_cost']?.total > 0"
                        [carouselClass]="costCarousel[1]" [dataClass]="'avg_meet_cost'" [loading]="loadingPastMetrics$"
                        [insight]="metricsData['avg_meet_cost']?.insights"
                        [difference]="(differences['avg_meet_cost']?.total > 0) ? (metricsData['avg_meet_cost']?.total - differences['avg_meet_cost']?.total | currency) : ('0' | currency)">
                        </app-dashboard-card>
                        <app-dashboard-card class="carousel-item" [ngClass]="costCarousel[2]"   
                        [title]="'Meeting Cost'" [dataTitle]="'Standard Deviation'" [icon]="'avg_meet_cost'" [amount]="(metricsData['avg_meet_cost']?.std | currency)"
                        [total]="metricsData['avg_meet_cost']?.std" [range]="rangeText" [isIncrement]="differences['avg_meet_cost']?.std > metricsData['avg_meet_cost']?.std" 
                        [yesterdayAmount]="(differences['avg_meet_cost']?.std | currency)" [yesterdayTotal]="differences['avg_meet_cost']?.std" [hasCompareData]="differences['avg_meet_cost']?.std > 0"
                        [carouselClass]="costCarousel[2]" [dataClass]="'avg_meet_cost'" [loading]="loadingPastMetrics$"
                        [insight]="metricsData['avg_meet_cost']?.insights"
                        [difference]="(differences['avg_meet_cost']?.std > 0) ? (metricsData['avg_meet_cost']?.std - differences['avg_meet_cost']?.std | currency) : ('0' | currency)">
                        </app-dashboard-card>
                        <app-dashboard-card class="carousel-item" [ngClass]="costCarousel[3]"   
                        [title]="'Meeting Cost'" [dataTitle]="'Meetings Over Avg'" [icon]="'avg_meet_cost'" [amount]="(metricsData['avg_meet_cost']?.meet_over_avg | number: '1.0')"
                        [total]="metricsData['avg_meet_cost']?.meet_over_avg" [range]="rangeText" [isIncrement]="differences['avg_meet_cost']?.meet_over_avg > metricsData['avg_meet_cost']?.meet_over_avg" 
                        [yesterdayAmount]="(differences['avg_meet_cost']?.meet_over_avg | number: '1.0')" [yesterdayTotal]="differences['avg_meet_cost']?.meet_over_avg" [hasCompareData]="differences['avg_meet_cost']?.meet_over_avg > 0"
                        [carouselClass]="costCarousel[3]" [dataClass]="'avg_meet_cost'" [loading]="loadingPastMetrics$"
                        [insight]="metricsData['avg_meet_cost']?.insights"
                        [difference]="(differences['avg_meet_cost']?.meet_over_avg > 0) ? (metricsData['avg_meet_cost']?.meet_over_avg - differences['avg_meet_cost']?.meet_over_avg | number: '1.0') : ('0' | number: '1.0')">
                        </app-dashboard-card>
                      </div>
                      <div class="btn-holder">
                        <span class="mr-auto" [ngClass]="slides[0] == 0 ? 'carousel-btn-inactive' : 'carousel-btn'" (click)="previousSlide(costCarousel, slides, 0)"><fa-icon [icon]="previousCardIcon"></fa-icon></span>
                        <span class="ml-5" [ngClass]="slides[0] == (costCarousel.length - 1) ? 'carousel-btn-inactive' : 'carousel-btn'" (click)="nextSlide(costCarousel, slides, 0)"><fa-icon [icon]="nextCardIcon"></fa-icon></span>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- MEETING COST :: SKELETON -->
                <div class="col-12 col-sm-6   col-lg-4 col-xl-4 p-0 mb-4" *ngIf="(loadingMetrics$ | async)">
                  <div class="item-estimated">
                    <ngx-skeleton-loader appearance="circle" [theme]="{ height: '50px',width: '50px'}"></ngx-skeleton-loader>
                    <div class="description">
                      <ngx-skeleton-loader [theme]="{ width: '100%', 'border-radius': '0', height: '15px' }"></ngx-skeleton-loader>
                      <div class="numbers">
                        <div class="price">
                          <ngx-skeleton-loader [theme]="{ width: '100%', 'border-radius': '0', height: '15px' }"></ngx-skeleton-loader>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- Meeting Time -->
                <div class="col-12 col-sm-6   col-lg-4 col-xl-4 p-0 mb-4" *ngIf="!(loadingMetrics$ | async)">
                  <div class="item-estimated">
                    <div id="carouselExampleControls" class="carousel slide d-flex" data-ride="carousel">
                      <div class="carousel-inner d-flex  ">
                        <app-dashboard-card class="carousel-item" [ngClass]="timeCarousel[0]"   
                        [title]="'Meeting Time'" [dataTitle]="'Time in meetings'" [icon]="'meet_time'" [amount]="(metricsData['meet_time']?.hours | number: '1.2-2') + ' hrs'"
                        [total]="metricsData['meet_time']?.hours" [range]="rangeText" [isIncrement]="differences['meet_time']?.hours > metricsData['meet_time']?.hours" 
                        [yesterdayAmount]="(differences['meet_time']?.hours | number: '1.2-2') + ' hrs'" [yesterdayTotal]="differences['meet_time']?.hours" [hasCompareData]="differences['meet_time']?.hours > 0"
                        [carouselClass]="timeCarousel[0]" [dataClass]="'meet_time'" [loading]="loadingPastMetrics$"
                        [insight]="metricsData['meet_time']?.insights"
                        [difference]="(differences['meet_time']?.hours > 0) ? (metricsData['meet_time']?.hours - differences['meet_time']?.hours | humanizeDuration: true) : ('0' | humanizeDuration: true)">
                        </app-dashboard-card>
                        <app-dashboard-card class="carousel-item" [ngClass]="timeCarousel[1]"   
                        [title]="'Meeting Time'" [dataTitle]="'Avg Length'" [icon]="'meet_len'" [amount]="(metricsData['meet_len']?.hours | humanizeDuration: true)"
                        [total]="metricsData['meet_len']?.hours" [range]="rangeText" [isIncrement]="differences['meet_len']?.hours > metricsData['meet_len']?.hours" 
                        [yesterdayAmount]="(differences['meet_len']?.hours | humanizeDuration: true)" [yesterdayTotal]="differences['meet_len']?.hours" [hasCompareData]="differences['meet_len']?.hours > 0"
                        [carouselClass]="timeCarousel[1]" [dataClass]="'meet_len'" [loading]="loadingPastMetrics$"
                        [insight]="metricsData['meet_len']?.insights"
                        [difference]="(differences['meet_len']?.hours > 0) ? (metricsData['meet_len']?.hours - differences['meet_len']?.hours | humanizeDuration: true) : ('0' | humanizeDuration: true)">
                        </app-dashboard-card>
                        <app-dashboard-card class="carousel-item" [ngClass]="timeCarousel[2]"   
                        [title]="'Meeting Time'" [dataTitle]="'Between 45 and 60'" [icon]="'meet_len'" [amount]="(metricsData['meet_len']?.meet_over_30 | number: '1.0')"
                        [total]="metricsData['meet_len']?.meet_over_30" [range]="rangeText" [isIncrement]="differences['meet_len']?.meet_over_30 > metricsData['meet_len']?.meet_over_30" 
                        [yesterdayAmount]="(differences['meet_len']?.meet_over_30 | number: '1.0')" [yesterdayTotal]="differences['meet_len']?.meet_over_30" [hasCompareData]="differences['meet_len']?.meet_over_30 > 0"
                        [carouselClass]="timeCarousel[2]" [dataClass]="'meet_len'" [loading]="loadingPastMetrics$"
                        [insight]="metricsData['meet_len']?.insights"
                        [difference]="(differences['meet_len']?.meet_over_30 > 0) ? (metricsData['meet_len']?.meet_over_30 - differences['meet_len']?.meet_over_30 | number: '1.0') : ('0' | number: '1.0')">
                        </app-dashboard-card>
                        <app-dashboard-card class="carousel-item" [ngClass]="timeCarousel[3]"   
                        [title]="'Meeting Time'" [dataTitle]="'Over 1 hour'" [icon]="'meet_len'" [amount]="(metricsData['meet_len']?.meet_over_45 | number: '1.0')"
                        [total]="metricsData['meet_len']?.meet_over_45" [range]="rangeText" [isIncrement]="differences['meet_len']?.meet_over_45 > metricsData['meet_len']?.meet_over_45" 
                        [yesterdayAmount]="(differences['meet_len']?.meet_over_45 | number: '1.0')" [yesterdayTotal]="differences['meet_len']?.meet_over_45" [hasCompareData]="differences['meet_len']?.meet_over_45 > 0"
                        [carouselClass]="timeCarousel[3]" [dataClass]="'meet_len'" [loading]="loadingPastMetrics$"
                        [insight]="metricsData['meet_len']?.insights"
                        [difference]="(differences['meet_len']?.meet_over_45 > 0) ? (metricsData['meet_len']?.meet_over_45 - differences['meet_len']?.meet_over_45 | number: '1.0') : ('0' | number: '1.0')">
                        </app-dashboard-card>
                      </div>
                      <div class="btn-holder">
                        <span class="mr-auto" [ngClass]="slides[1] == 0 ? 'carousel-btn-inactive' : 'carousel-btn'" (click)="previousSlide(timeCarousel, slides, 1)"><fa-icon [icon]="previousCardIcon"></fa-icon></span>
                        <span class="ml-5" [ngClass]="slides[1] == (timeCarousel.length - 1) ? 'carousel-btn-inactive' : 'carousel-btn'" (click)="nextSlide(timeCarousel, slides, 1)"><fa-icon [icon]="nextCardIcon"></fa-icon></span>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- Meeting Time :: SKELETON-->
                <div class="col-12 col-sm-6   col-lg-4 col-xl-4 p-0 mb-4" *ngIf="(loadingMetrics$ | async)">
                  <div class="item-estimated">
                    <ngx-skeleton-loader appearance="circle" [theme]="{ height: '50px',width: '50px'}"></ngx-skeleton-loader>
                    <div class="description">
                      <ngx-skeleton-loader [theme]="{ width: '100%', 'border-radius': '0', height: '15px' }"></ngx-skeleton-loader>
                      <div class="numbers">
                        <div class="price">
                          <ngx-skeleton-loader [theme]="{ width: '100%', 'border-radius': '0', height: '15px' }"></ngx-skeleton-loader>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- Meeting Length -->
                <div class="col-12 col-sm-6   col-lg-4 col-xl-4 p-0 mb-4" *ngIf="!(loadingMetrics$ | async)">
                  <div class="item-estimated">
                    <div id="carouselExampleControls" class="carousel slide d-flex" data-ride="carousel">
                      <div class="carousel-inner d-flex  ">
                        <app-dashboard-card class="carousel-item" [ngClass]="freetimeCarousel[0]"   
                        [title]="'Non Meeting Time'" [dataTitle]="'Deep work'" [icon]="'deep_time'" [amount]="(metricsData['deep_time']?.hours | humanizeDuration: true)"
                        [total]="metricsData['deep_time']?.hours" [range]="rangeText" [isIncrement]="differences['deep_time']?.hours > metricsData['deep_time']?.hours" 
                        [yesterdayAmount]="(differences['deep_time']?.hours | humanizeDuration: true)" [yesterdayTotal]="differences['deep_time']?.hours" [hasCompareData]="differences['deep_time']?.hours > 0"
                        [carouselClass]="freetimeCarousel[0]" [dataClass]="'deep_time'" [loading]="loadingPastMetrics$"
                        [insight]="metricsData['deep_time']?.insights"
                        [difference]="(differences['deep_time']?.hours > 0) ? (metricsData['deep_time']?.hours - differences['deep_time']?.hours | humanizeDuration: true) : ('0' | humanizeDuration: true)">
                        </app-dashboard-card>
                        <app-dashboard-card class="carousel-item" [ngClass]="freetimeCarousel[1]"   
                        [title]="'Non Meeting Time'" [dataTitle]="'Personal Time'" [icon]="'per_time'" [amount]="(metricsData['per_time']?.hours | humanizeDuration: true)"
                        [total]="metricsData['per_time']?.hours" [range]="rangeText" [isIncrement]="differences['per_time']?.hours > metricsData['per_time']?.hours" 
                        [yesterdayAmount]="(differences['per_time']?.hours | humanizeDuration: true)" [yesterdayTotal]="differences['per_time']?.hours" [hasCompareData]="differences['per_time']?.hours > 0"
                        [carouselClass]="freetimeCarousel[1]" [dataClass]="'per_time'" [loading]="loadingPastMetrics$"
                        [insight]="metricsData['per_time']?.insights"
                        [difference]="(differences['per_time']?.hours > 0) ? (metricsData['per_time']?.hours - differences['per_time']?.hours | humanizeDuration: true) : ('0' | humanizeDuration: true)">
                        </app-dashboard-card>
                      </div>
                      <div class="btn-holder">
                        <span class="mr-auto" [ngClass]="slides[2] == 0 ? 'carousel-btn-inactive' : 'carousel-btn'" (click)="previousSlide(freetimeCarousel, slides, 2)"><fa-icon [icon]="previousCardIcon"></fa-icon></span>
                        <span class="ml-5" [ngClass]="slides[2] == (freetimeCarousel.length - 1) ? 'carousel-btn-inactive' : 'carousel-btn'" (click)="nextSlide(freetimeCarousel, slides, 2)"><fa-icon [icon]="nextCardIcon"></fa-icon></span>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- Meeting Length :: SKELETON-->
                <div class="col-12 col-sm-6   col-lg-4 col-xl-4 p-0 mb-4" *ngIf="(loadingMetrics$ | async)">
                  <div class="item-estimated">
                    <ngx-skeleton-loader appearance="circle" [theme]="{ height: '50px',width: '50px'}"></ngx-skeleton-loader>
                    <div class="description">
                      <ngx-skeleton-loader [theme]="{ width: '100%', 'border-radius': '0', height: '15px' }"></ngx-skeleton-loader>
                      <div class="numbers">
                        <div class="price">
                          <ngx-skeleton-loader [theme]="{ width: '100%', 'border-radius': '0', height: '15px' }"></ngx-skeleton-loader>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- Meeting Number -->
                <div class="col-12 col-sm-6  col-lg-4 col-xl-4 p-0 mb-4 mb-lg-0 mb-xl-0" *ngIf="!(loadingMetrics$ | async)">
                  <div class="item-estimated">
                    <div id="carouselExampleControls" class="carousel slide d-flex" data-ride="carousel">
                      <div class="carousel-inner d-flex  ">
                        <app-dashboard-card class="carousel-item" [ngClass]="meetingCarousel[0]"   
                        [title]="'Meeting Details'" [dataTitle]="'Number of meetings'" [icon]="'meet_num'" [amount]="(metricsData['meet_num']?.counter | number: '1.0')"
                        [total]="metricsData['meet_num']?.counter" [range]="rangeText" [isIncrement]="differences['meet_num']?.counter > metricsData['meet_num']?.counter" 
                        [yesterdayAmount]="(differences['meet_num']?.counter | number: '1.0')" [yesterdayTotal]="differences['meet_num']?.counter" [hasCompareData]="differences['meet_num']?.counter > 0"
                        [carouselClass]="meetingCarousel[0]" [dataClass]="'meet_num'" [loading]="loadingPastMetrics$"
                        [insight]="metricsData['meet_num']?.insights"
                        [difference]="(differences['meet_num']?.counter > 0) ? (metricsData['meet_num']?.counter - differences['meet_num']?.counter | number: '1.0') : ('0' | number: '1.0')">
                        </app-dashboard-card>
                        <app-dashboard-card class="carousel-item" [ngClass]="meetingCarousel[1]"   
                        [title]="'Meeting Details'" [dataTitle]="'Meetings with Agenda'" [icon]="'meet_num'" [amount]="(metricsData['meet_num']?.has_agenda | number: '1.0')"
                        [total]="metricsData['meet_num']?.has_agenda" [range]="rangeText" [isIncrement]="differences['meet_num']?.has_agenda > metricsData['meet_num']?.has_agenda" 
                        [yesterdayAmount]="(differences['meet_num']?.has_agenda | number: '1.0')" [yesterdayTotal]="differences['meet_num']?.has_agenda" [hasCompareData]="differences['meet_num']?.has_agenda > 0"
                        [carouselClass]="meetingCarousel[1]" [dataClass]="'meet_num'" [loading]="loadingPastMetrics$"
                        [insight]="metricsData['meet_num']?.insights"
                        [difference]="(differences['meet_num']?.has_agenda > 0) ? (metricsData['meet_num']?.has_agenda - differences['meet_num']?.has_agenda | number: '1.0') : ('0' | number: '1.0')">
                        </app-dashboard-card>
                        <app-dashboard-card class="carousel-item" [ngClass]="meetingCarousel[2]"   
                        [title]="'Meeting Details'" [dataTitle]="'Back-to-Back Meetings'" [icon]="'meet_num'" [amount]="(metricsData['meet_num']?.btb | number: '1.0')"
                        [total]="metricsData['meet_num']?.btb" [range]="rangeText" [isIncrement]="differences['meet_num']?.btb > metricsData['meet_num']?.btb" 
                        [yesterdayAmount]="(differences['meet_num']?.btb | number: '1.0')" [yesterdayTotal]="differences['meet_num']?.btb" [hasCompareData]="differences['meet_num']?.btb > 0"
                        [carouselClass]="meetingCarousel[2]" [dataClass]="'meet_num'" [loading]="loadingPastMetrics$"
                        [insight]="metricsData['meet_num']?.insights"
                        [difference]="(differences['meet_num']?.btb > 0) ? (metricsData['meet_num']?.btb - differences['meet_num']?.btb | number: '1.0') : ('0' | number: '1.0')">
                        </app-dashboard-card>
                      </div>
                      <div class="btn-holder">
                        <span class="mr-auto" [ngClass]="slides[3] == 0 ? 'carousel-btn-inactive' : 'carousel-btn'" (click)="previousSlide(meetingCarousel, slides, 3)"><fa-icon [icon]="previousCardIcon"></fa-icon></span>
                        <span class="ml-5" [ngClass]="slides[3] == (meetingCarousel.length - 1) ? 'carousel-btn-inactive' : 'carousel-btn'" (click)="nextSlide(meetingCarousel, slides, 3)"><fa-icon [icon]="nextCardIcon"></fa-icon></span>
                      </div>
                    </div>
                  </div>
                </div>
                
                <!-- Meeting Number :: SKELETON-->
                <div class="col-12 col-sm-6  col-lg-4 col-xl-4 p-0 mb-4 mb-lg-0 mb-xl-0" *ngIf="(loadingMetrics$ | async)">
                  <div class="item-estimated">
                    <ngx-skeleton-loader appearance="circle" [theme]="{ height: '50px',width: '50px'}"></ngx-skeleton-loader>
                    <div class="description">
                      <ngx-skeleton-loader [theme]="{ width: '100%', 'border-radius': '0', height: '15px' }"></ngx-skeleton-loader>
                      <div class="numbers">
                        <div class="price">
                          <ngx-skeleton-loader [theme]="{ width: '100%', 'border-radius': '0', height: '15px' }"></ngx-skeleton-loader>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- AVG MEETING COST -->
                <div class="col-12 col-sm-6  col-lg-4 col-xl-4 p-0 mb-4 mb-lg-0 mb-xl-0" *ngIf="!(loadingMetrics$ | async)">
                  <div class="item-estimated">
                    <div id="carouselExampleControls" class="carousel slide d-flex" data-ride="carousel">
                      <div class="carousel-inner d-flex  ">
                        <app-dashboard-card class="carousel-item" [ngClass]="participantCarousel[0]"   
                        [title]="'Meeting Participants'" [dataTitle]="'Avg Participants'" [icon]="'meet_par'" [amount]="(metricsData['meet_par']?.counter | number: '1.0')"
                        [total]="metricsData['meet_par']?.counter" [range]="rangeText" [isIncrement]="differences['meet_par']?.counter > metricsData['meet_par']?.counter" 
                        [yesterdayAmount]="(differences['meet_par']?.counter | number: '1.0')" [yesterdayTotal]="differences['meet_par']?.counter" [hasCompareData]="differences['meet_par']?.counter > 0"
                        [carouselClass]="participantCarousel[0]" [dataClass]="'meet_par'" [loading]="loadingPastMetrics$"
                        [insight]="metricsData['meet_par']?.insights"
                        [difference]="(differences['meet_par']?.counter > 0) ? (metricsData['meet_par']?.counter - differences['meet_par']?.counter | number: '1.0') : ('0' | number: '1.0')">
                        </app-dashboard-card>
                        <app-dashboard-card class="carousel-item" [ngClass]="participantCarousel[1]"   
                        [title]="'Meeting Participants'" [dataTitle]="'Meetings between 5 and 8'" [icon]="'meet_par'" [amount]="(metricsData['meet_par']?.meet_over_5 | number: '1.0')"
                        [total]="metricsData['meet_par']?.meet_over_5" [range]="rangeText" [isIncrement]="differences['meet_par']?.meet_over_5 > metricsData['meet_par']?.meet_over_5" 
                        [yesterdayAmount]="(differences['meet_par']?.meet_over_5 | number: '1.0')" [yesterdayTotal]="differences['meet_par']?.meet_over_5" [hasCompareData]="differences['meet_par']?.meet_over_5 > 0"
                        [carouselClass]="participantCarousel[1]" [dataClass]="'meet_par'" [loading]="loadingPastMetrics$"
                        [insight]="metricsData['meet_par']?.insights"
                        [difference]="(differences['meet_par']?.meet_over_5 > 0) ? (metricsData['meet_par']?.meet_over_5 - differences['meet_par']?.meet_over_5 | number: '1.0') : ('0' | number: '1.0')">
                        </app-dashboard-card>
                        <app-dashboard-card class="carousel-item" [ngClass]="participantCarousel[2]"   
                        [title]="'Meeting Participants'" [dataTitle]="'Meetings with 8+'" [icon]="'meet_par'" [amount]="(metricsData['meet_par']?.meet_over_8 | number: '1.0')"
                        [total]="metricsData['meet_par']?.meet_over_8" [range]="rangeText" [isIncrement]="differences['meet_par']?.meet_over_8 > metricsData['meet_par']?.meet_over_8" 
                        [yesterdayAmount]="(differences['meet_par']?.meet_over_8 | number: '1.0')" [yesterdayTotal]="differences['meet_par']?.meet_over_8" [hasCompareData]="differences['meet_par']?.meet_over_8 > 0"
                        [carouselClass]="participantCarousel[2]" [dataClass]="'meet_par'" [loading]="loadingPastMetrics$"
                        [insight]="metricsData['meet_par']?.insights"
                        [difference]="(differences['meet_par']?.meet_over_8 > 0) ? (metricsData['meet_par']?.meet_over_8 - differences['meet_par']?.meet_over_8 | number: '1.0') : ('0' | number: '1.0')">
                        </app-dashboard-card>
                      </div>
                      <div class="btn-holder">
                        <span class="mr-auto" [ngClass]="slides[4] == 0 ? 'carousel-btn-inactive' : 'carousel-btn'" (click)="previousSlide(participantCarousel, slides, 4)"><fa-icon [icon]="previousCardIcon"></fa-icon></span>
                        <span class="ml-5" [ngClass]="slides[4] == (participantCarousel.length - 1) ? 'carousel-btn-inactive' : 'carousel-btn'" (click)="nextSlide(participantCarousel, slides, 4)"><fa-icon [icon]="nextCardIcon"></fa-icon></span>
                      </div>
                    </div>
                  </div>
                </div>
                
                <!-- AVG MEETING COST :: SKELETON-->
                <div class="col-12 col-sm-6  col-lg-4 col-xl-4 p-0 mb-4 mb-lg-0 mb-xl-0" *ngIf="(loadingMetrics$ | async)">
                  <div class="item-estimated">
                    <ngx-skeleton-loader appearance="circle" [theme]="{ height: '50px',width: '50px'}"></ngx-skeleton-loader>
                    <div class="description">
                      <ngx-skeleton-loader [theme]="{ width: '100%', 'border-radius': '0', height: '15px' }"></ngx-skeleton-loader>
                      <div class="numbers">
                        <div class="price">
                          <ngx-skeleton-loader [theme]="{ width: '100%', 'border-radius': '0', height: '15px' }"></ngx-skeleton-loader>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- Deep Work -->
                <div class="col-12 col-sm-6  col-lg-4 col-xl-4 p-0 mb-4 mb-lg-0 mb-xl-0" *ngIf="!(loadingMetrics$ | async)">
                  <div class="item-estimated">
                    <div id="carouselExampleControls" class="carousel slide d-flex" data-ride="carousel">
                      <div class="carousel-inner d-flex  ">
                        <app-dashboard-card class="carousel-item" [ngClass]="teamDynamicsCarousel[1]"   
                        [title]="'Team Dynamics'" [dataTitle]="'Participants Invited'" [icon]="'team_dyn'" [amount]="(metricsData['meet_par']?.mailed | number: '1.0')"
                        [total]="metricsData['meet_par']?.mailed" [range]="rangeText" [isIncrement]="differences['meet_par']?.mailed > metricsData['meet_par']?.mailed" 
                        [yesterdayAmount]="(differences['meet_par']?.mailed | number: '1.0')" [yesterdayTotal]="differences['meet_par']?.mailed" [hasCompareData]="differences['meet_par']?.mailed > 0"
                        [carouselClass]="teamDynamicsCarousel[0]" [dataClass]="'team_dyn'" [loading]="loadingPastMetrics$"
                        [insight]="metricsData['meet_par']?.insights"
                        [difference]="(differences['meet_par']?.mailed > 0) ? (metricsData['meet_par']?.mailed - differences['meet_par']?.mailed | number: '1.0') : ('0' | number: '1.0')">
                        </app-dashboard-card>
                        <app-dashboard-card class="carousel-item" [ngClass]="teamDynamicsCarousel[0]"   
                        [title]="'Team Dynamics'" [dataTitle]="'Accepted Invitations'" [icon]="'team_dyn'" [amount]="(metricsData['meet_par']?.accepted | number: '1.0')"
                        [total]="metricsData['meet_par']?.accepted" [range]="rangeText" [isIncrement]="differences['meet_par']?.accepted > metricsData['meet_par']?.accepted" 
                        [yesterdayAmount]="(differences['meet_par']?.accepted | number: '1.0')" [yesterdayTotal]="differences['meet_par']?.accepted" [hasCompareData]="differences['meet_par']?.accepted > 0"
                        [carouselClass]="teamDynamicsCarousel[1]" [dataClass]="'team_dyn'" [loading]="loadingPastMetrics$"
                        [insight]="metricsData['meet_par']?.insights"
                        [difference]="(differences['meet_par']?.accepted > 0) ? (metricsData['meet_par']?.accepted - differences['meet_par']?.accepted | number: '1.0') : ('0' | number: '1.0')">
                        </app-dashboard-card>
                      </div>
                      <div class="btn-holder">
                        <span class="mr-auto" [ngClass]="slides[5] == 0 ? 'carousel-btn-inactive' : 'carousel-btn'" (click)="previousSlide(teamDynamicsCarousel, slides, 5)"><fa-icon [icon]="previousCardIcon"></fa-icon></span>
                        <span class="ml-5" [ngClass]="slides[5] == (teamDynamicsCarousel.length - 1) ? 'carousel-btn-inactive' : 'carousel-btn'" (click)="nextSlide(teamDynamicsCarousel, slides, 5)"><fa-icon [icon]="nextCardIcon"></fa-icon></span>
                      </div>
                    </div>
                  </div>
                </div>
                
                <!-- Deep Work :: SKEKETON-->
                <div class="col-12 col-sm-6  col-lg-4 col-xl-4 p-0 mb-4 mb-lg-0 mb-xl-0" *ngIf="(loadingMetrics$ | async)">
                  <div class="item-estimated">
                    <ngx-skeleton-loader appearance="circle" [theme]="{ height: '50px',width: '50px'}"></ngx-skeleton-loader>
                    <div class="description">
                      <ngx-skeleton-loader [theme]="{ width: '100%', 'border-radius': '0', height: '15px' }"></ngx-skeleton-loader>
                      <div class="numbers">
                        <div class="price">
                          <ngx-skeleton-loader [theme]="{ width: '100%', 'border-radius': '0', height: '15px' }"></ngx-skeleton-loader>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- Personal Time -->
                <!-- Personal Time :: SKEKETON-->
                <div class="d-none col-12 col-sm-6 col-lg-3 col-xl-3 p-0 mb-4 mb-lg-0 mb-xl-0" *ngIf="(loadingMetrics$ | async)">
                  <div class="item-estimated">
                    <ngx-skeleton-loader appearance="circle" [theme]="{ height: '50px',width: '50px'}"></ngx-skeleton-loader>
                    <div class="description">
                      <ngx-skeleton-loader [theme]="{ width: '100%', 'border-radius': '0', height: '15px' }"></ngx-skeleton-loader>
                      <div class="numbers">
                        <div class="price">
                          <ngx-skeleton-loader [theme]="{ width: '100%', 'border-radius': '0', height: '15px' }"></ngx-skeleton-loader>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- Meeting Participants -->
                <!-- Meeting Participants :: SKEKETON-->
                <div class="d-none col-12 col-sm-6 col-lg-3 col-xl-3 p-0 mb-4 mb-lg-0 mb-xl-0" *ngIf="(loadingMetrics$ | async)">
                  <div class="item-estimated">
                    <ngx-skeleton-loader appearance="circle" [theme]="{ height: '50px',width: '50px'}"></ngx-skeleton-loader>
                    <div class="description">
                      <ngx-skeleton-loader [theme]="{ width: '100%', 'border-radius': '0', height: '15px' }"></ngx-skeleton-loader>
                      <div class="numbers">
                        <div class="price">
                          <ngx-skeleton-loader [theme]="{ width: '100%', 'border-radius': '0', height: '15px' }"></ngx-skeleton-loader>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>


                <div class="col-12 mt-4" style="padding: 0px 10px 0px 0px;
                width: 98%;
                min-height: 342px;
                flex-flow: column;">
                  <div class="row chart-card" style="display: flex;
                  background-color: white;
                  border-left-width: 3px;
                  border-radius: 3px;
                  box-shadow: 1px 1px 8px 0px rgb(0 0 0 / 20%);
                  margin: 5px 0;
                  "
                  ><!--padding: 10px 10px;-->
                    <div class="row chart-header" style="background-color: #f5f5f5;
                    justify-content: center;
                    padding: 6px 0px;
                    align-items: center;
                    border-bottom: 2px solid #f0f0f0;
                    display: flex;
                    height: 54px;
                    width: 100%;">
                      <span class="title" [ngClass]="efficiencyCarousel[0]">Meeting Score&reg;</span>
                      <span class="title" [ngClass]="efficiencyCarousel[1]">Meeting Feedback</span>
                      <span class="title" [ngClass]="efficiencyCarousel[2]">Meetings Distribution</span>
                    </div>
                    <div class="col-12 chart-content" [ngClass]="efficiencyCarousel[0]">
                      <div echarts [options]="scoreChart" (chartInit)="onChartInit($event, 0)" style="height: 288px; width: 100%;"></div>
                    </div>
                    <div class="col-12 col-lg-6 col-xl-6 p-0 chart-content" [ngClass]="efficiencyCarousel[1]">
                      <div echarts [options]="productiveChart" (chartInit)="onChartInit($event, 1)" style="height: 288px; width: 100%;"></div>
                    </div>
                    <div class="col-12 col-lg-6 col-xl-6 p-0 chart-content" [ngClass]="efficiencyCarousel[1]">
                      <div echarts [options]="inclusiveChart" (chartInit)="onChartInit($event, 2)" style="height: 288px; width: 100%;"></div>
                    </div>
                    <div class="col-12 chart-content" [ngClass]="efficiencyCarousel[2]">
                      <div echarts [options]="heatmapChart" (chartInit)="onChartInit($event, 3)" style="height: 288px; width: 100%;"></div>
                    </div>
                  </div>
                  <div class="btn-holder">
                    <span class="mr-auto" [ngClass]="slides[12] == 0 ? 'carousel-btn-inactive' : 'carousel-btn'" (click)="previousSlide(efficiencyCarousel, slides, 12)"><fa-icon [icon]="previousCardIcon"></fa-icon></span>
                    <span class="ml-5" [ngClass]="slides[12] == (efficiencyCarousel.length - 1) ? 'carousel-btn-inactive' : 'carousel-btn'" (click)="nextSlide(efficiencyCarousel, slides, 12)"><fa-icon [icon]="nextCardIcon"></fa-icon></span>
                  </div>
                </div>

                <!-- <div class="col-12 mt-4" style="padding: 0px 10px 0px 0px;
                  width: 98%;
                  min-height: 342px;
                  flex-flow: column;">
                  <div class="row" style="background-color: white;
                  border-left-width: 3px;
                  border-radius: 3px;
                  box-shadow: 1px 1px 8px 0px rgb(0 0 0 / 20%);
                  margin: 5px 0;
                  padding: 10px 10px;">
                    <div echarts class="col-12 col-sm-12 col-lg-4 col-xl-4 p-0" [options]="scoreChart" (chartInit)="onChartInit($event, 0)" style="height: 342px;"></div>
                    <div echarts class="col-12 col-sm-12 col-lg-4 col-xl-4 p-0" [options]="productiveChart" (chartInit)="onChartInit($event, 1)" style="height: 342px;"></div>
                    <div echarts class="col-12 col-sm-12 col-lg-4 col-xl-4 p-0" [options]="inclusiveChart" (chartInit)="onChartInit($event, 2)" style="height: 342px;"></div>
                  </div>
                </div> -->

              </div>
              <ng-template #meetingDynamics>
                <div class="row estimated-time">
                  <!-- MEETING COST -->
                  <div class="col-12 col-sm-6 col-lg-4 col-xl-4 p-0 mb-4" *ngIf="!(loadingMetrics$ | async)">
                    <div class="item-estimated">
                      <div id="carouselExampleControls" class="carousel slide d-flex" data-ride="carousel">
                        <div class="carousel-inner d-flex  ">
                          <app-dashboard-card class="carousel-item" [ngClass]="genderCarousel[0]"   
                          [title]="'Gender'" [dataTitle]="'Male participants'" [icon]="'meet_gen'" [amount]="(metricsData['meet_gen']?.male | percent)"
                          [total]="metricsData['meet_gen']?.male" [range]="rangeText" [isIncrement]="differences['meet_gen']?.male > metricsData['meet_gen']?.male" 
                          [yesterdayAmount]="(differences['meet_gen']?.male | percent)" [yesterdayTotal]="differences['meet_gen']?.male" [hasCompareData]="differences['meet_gen']?.male > 0"
                          [carouselClass]="genderCarousel[0]" [dataClass]="'meet_gen'" [loading]="loadingPastMetrics$"
                          [difference]="0">
                          </app-dashboard-card>
                          <app-dashboard-card class="carousel-item" [ngClass]="genderCarousel[1]"   
                          [title]="'Gender'" [dataTitle]="'Female participants'" [icon]="'meet_gen'" [amount]="(metricsData['meet_gen']?.female | percent)"
                          [total]="metricsData['meet_gen']?.female" [range]="rangeText" [isIncrement]="differences['meet_gen']?.female > metricsData['meet_gen']?.female" 
                          [yesterdayAmount]="(differences['meet_gen']?.female | percent)" [yesterdayTotal]="differences['meet_gen']?.female" [hasCompareData]="differences['meet_gen']?.female > 0"
                          [carouselClass]="genderCarousel[1]" [dataClass]="'meet_gen'" [loading]="loadingPastMetrics$"
                          [difference]="0">
                          </app-dashboard-card>
                          <app-dashboard-card class="carousel-item" [ngClass]="genderCarousel[2]"   
                          [title]="'Gender'" [dataTitle]="'Non-Binary participants'" [icon]="'meet_gen'" [amount]="(metricsData['meet_gen']?.nonBinary | percent)"
                          [total]="metricsData['meet_gen']?.nonBinary" [range]="rangeText" [isIncrement]="differences['meet_gen']?.nonBinary > metricsData['meet_gen']?.nonBinary" 
                          [yesterdayAmount]="(differences['meet_gen']?.nonBinary | percent)" [yesterdayTotal]="differences['meet_gen']?.nonBinary" [hasCompareData]="differences['meet_gen']?.nonBinary > 0"
                          [carouselClass]="genderCarousel[2]" [dataClass]="'meet_gen'" [loading]="loadingPastMetrics$"
                          [difference]="0">
                          </app-dashboard-card>
                          <app-dashboard-card class="carousel-item" [ngClass]="genderCarousel[3]"   
                          [title]="'Gender'" [dataTitle]="'Unknown gender'" [icon]="'meet_gen'" [amount]="(metricsData['meet_gen']?.genderUnknown | percent)"
                          [total]="metricsData['meet_gen']?.genderUnknown" [range]="rangeText" [isIncrement]="differences['meet_gen']?.genderUnknown > metricsData['meet_gen']?.genderUnknown" 
                          [yesterdayAmount]="(differences['meet_gen']?.genderUnknown | percent)" [yesterdayTotal]="differences['meet_gen']?.genderUnknown" [hasCompareData]="differences['meet_gen']?.genderUnknown > 0"
                          [carouselClass]="genderCarousel[3]" [dataClass]="'meet_gen'" [loading]="loadingPastMetrics$"
                          [difference]="0">
                          </app-dashboard-card>
                        </div>
                        <div class="btn-holder">
                          <span class="mr-auto" [ngClass]="slides[6] == 0 ? 'carousel-btn-inactive' : 'carousel-btn'" (click)="previousSlide(genderCarousel, slides, 6)"><fa-icon [icon]="previousCardIcon"></fa-icon></span>
                          <span class="ml-5" [ngClass]="slides[6] == (genderCarousel.length - 1) ? 'carousel-btn-inactive' : 'carousel-btn'" (click)="nextSlide(genderCarousel, slides, 6)"><fa-icon [icon]="nextCardIcon"></fa-icon></span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- MEETING COST :: SKELETON -->
                  <div class="col-12 col-sm-6 col-lg-4 col-xl-4 p-0 mb-4" *ngIf="(loadingMetrics$ | async)">
                    <div class="item-estimated">
                      <ngx-skeleton-loader appearance="circle" [theme]="{ height: '50px',width: '50px'}"></ngx-skeleton-loader>
                      <div class="description">
                        <ngx-skeleton-loader [theme]="{ width: '100%', 'border-radius': '0', height: '15px' }"></ngx-skeleton-loader>
                        <div class="numbers">
                          <div class="price">
                            <ngx-skeleton-loader [theme]="{ width: '100%', 'border-radius': '0', height: '15px' }"></ngx-skeleton-loader>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- Meeting Time -->
                  <div class="col-12 col-sm-6 col-lg-4 col-xl-4 p-0 mb-4" *ngIf="!(loadingMetrics$ | async)">
                    <div class="item-estimated">
                      <div id="carouselExampleControls" class="carousel slide d-flex" data-ride="carousel">
                        <div class="carousel-inner d-flex  ">
                          <app-dashboard-card class="carousel-item" [ngClass]="raceCarousel[0]"   
                          [title]="'Race'" [dataTitle]="'White'" [icon]="'meet_race'" [amount]="(metricsData['meet_race']?.white | percent)"
                          [total]="metricsData['meet_race']?.white" [range]="rangeText" [isIncrement]="differences['meet_race']?.white > metricsData['meet_race']?.white" 
                          [yesterdayAmount]="(differences['meet_race']?.white | percent)" [yesterdayTotal]="differences['meet_race']?.white" [hasCompareData]="differences['meet_race']?.white > 0"
                          [carouselClass]="raceCarousel[0]" [dataClass]="'meet_race'" [loading]="loadingPastMetrics$"
                          [difference]="0">
                          </app-dashboard-card>
                          <app-dashboard-card class="carousel-item" [ngClass]="raceCarousel[1]"   
                          [title]="'Race'" [dataTitle]="'Black or African American'" [icon]="'meet_race'" [amount]="(metricsData['meet_race']?.black | percent)"
                          [total]="metricsData['meet_race']?.black" [range]="rangeText" [isIncrement]="differences['meet_race']?.black > metricsData['meet_race']?.black" 
                          [yesterdayAmount]="(differences['meet_race']?.black | percent)" [yesterdayTotal]="differences['meet_race']?.black" [hasCompareData]="differences['meet_race']?.black > 0"
                          [carouselClass]="raceCarousel[1]" [dataClass]="'meet_race'" [loading]="loadingPastMetrics$"
                          [difference]="0">
                          </app-dashboard-card>
                          <app-dashboard-card class="carousel-item" [ngClass]="raceCarousel[2]"   
                          [title]="'Race'" [dataTitle]="'Asian'" [icon]="'meet_race'" [amount]="(metricsData['meet_race']?.asian | percent)"
                          [total]="metricsData['meet_race']?.asian" [range]="rangeText" [isIncrement]="differences['meet_race']?.asian > metricsData['meet_race']?.asian" 
                          [yesterdayAmount]="(differences['meet_race']?.asian | percent)" [yesterdayTotal]="differences['meet_race']?.asian" [hasCompareData]="differences['meet_race']?.asian > 0"
                          [carouselClass]="raceCarousel[2]" [dataClass]="'meet_race'" [loading]="loadingPastMetrics$"
                          [difference]="0">
                          </app-dashboard-card>
                          <app-dashboard-card class="carousel-item" [ngClass]="raceCarousel[3]"   
                          [title]="'Race'" [dataTitle]="'Native American'" [icon]="'meet_race'" [amount]="(metricsData['meet_race']?.native | percent)"
                          [total]="metricsData['meet_race']?.native" [range]="rangeText" [isIncrement]="differences['meet_race']?.native > metricsData['meet_race']?.native" 
                          [yesterdayAmount]="(differences['meet_race']?.native | percent)" [yesterdayTotal]="differences['meet_race']?.native" [hasCompareData]="differences['meet_race']?.native > 0"
                          [carouselClass]="raceCarousel[3]" [dataClass]="'meet_race'" [loading]="loadingPastMetrics$"
                          [difference]="0">
                          </app-dashboard-card>
                          <app-dashboard-card class="carousel-item" [ngClass]="raceCarousel[4]"   
                          [title]="'Race'" [dataTitle]="'Pacific Islander'" [icon]="'meet_race'" [amount]="(metricsData['meet_race']?.pacific | percent)"
                          [total]="metricsData['meet_race']?.pacific" [range]="rangeText" [isIncrement]="differences['meet_race']?.pacific > metricsData['meet_race']?.pacific" 
                          [yesterdayAmount]="(differences['meet_race']?.pacific | percent)" [yesterdayTotal]="differences['meet_race']?.pacific" [hasCompareData]="differences['meet_race']?.pacific > 0"
                          [carouselClass]="raceCarousel[4]" [dataClass]="'meet_race'" [loading]="loadingPastMetrics$"
                          [difference]="0">
                          </app-dashboard-card>
                          <app-dashboard-card class="carousel-item" [ngClass]="raceCarousel[5]"   
                          [title]="'Race'" [dataTitle]="'Hispanic /Latino'" [icon]="'meet_race'" [amount]="(metricsData['meet_race']?.hispanic | percent)"
                          [total]="metricsData['meet_race']?.hispanic" [range]="rangeText" [isIncrement]="differences['meet_race']?.hispanic > metricsData['meet_race']?.hispanic" 
                          [yesterdayAmount]="(differences['meet_race']?.hispanic | percent)" [yesterdayTotal]="differences['meet_race']?.hispanic" [hasCompareData]="differences['meet_race']?.hispanic > 0"
                          [carouselClass]="raceCarousel[5]" [dataClass]="'meet_race'" [loading]="loadingPastMetrics$"
                          [difference]="0">
                          </app-dashboard-card>
                          
                          <app-dashboard-card class="carousel-item" [ngClass]="raceCarousel[6]"   
                          [title]="'Race'" [dataTitle]="'Not Hispanic /Latino'" [icon]="'meet_race'" [amount]="(metricsData['meet_race']?.notHispanic | percent)"
                          [total]="metricsData['meet_race']?.notHispanic" [range]="rangeText" [isIncrement]="differences['meet_race']?.notHispanic > metricsData['meet_race']?.notHispanic" 
                          [yesterdayAmount]="(differences['meet_race']?.notHispanic | percent)" [yesterdayTotal]="differences['meet_race']?.notHispanic" [hasCompareData]="differences['meet_race']?.notHispanic > 0"
                          [carouselClass]="raceCarousel[6]" [dataClass]="'meet_race'" [loading]="loadingPastMetrics$"
                          [difference]="0">
                          </app-dashboard-card>
                          <app-dashboard-card class="carousel-item" [ngClass]="raceCarousel[7]"   
                          [title]="'Race'" [dataTitle]="'Unknown race'" [icon]="'meet_race'" [amount]="(metricsData['meet_race']?.raceUnknown | percent)"
                          [total]="metricsData['meet_race']?.raceUnknown" [range]="rangeText" [isIncrement]="differences['meet_race']?.raceUnknown > metricsData['meet_race']?.raceUnknown" 
                          [yesterdayAmount]="(differences['meet_race']?.raceUnknown | percent)" [yesterdayTotal]="differences['meet_race']?.raceUnknown" [hasCompareData]="differences['meet_race']?.raceUnknown > 0"
                          [carouselClass]="raceCarousel[7]" [dataClass]="'meet_race'" [loading]="loadingPastMetrics$"
                          [difference]="0">
                          </app-dashboard-card>
                        </div>
                        <div class="btn-holder">
                          <span class="mr-auto" [ngClass]="slides[7] == 0 ? 'carousel-btn-inactive' : 'carousel-btn'" (click)="previousSlide(raceCarousel, slides, 7)"><fa-icon [icon]="previousCardIcon"></fa-icon></span>
                          <span class="ml-5" [ngClass]="slides[7] == (raceCarousel.length - 1) ? 'carousel-btn-inactive' : 'carousel-btn'" (click)="nextSlide(raceCarousel, slides, 7)"><fa-icon [icon]="nextCardIcon"></fa-icon></span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- Meeting Time :: SKELETON-->
                  <div class="col-12 col-sm-6 col-lg-4 col-xl-4 p-0 mb-4" *ngIf="(loadingMetrics$ | async)">
                    <div class="item-estimated">
                      <ngx-skeleton-loader appearance="circle" [theme]="{ height: '50px',width: '50px'}"></ngx-skeleton-loader>
                      <div class="description">
                        <ngx-skeleton-loader [theme]="{ width: '100%', 'border-radius': '0', height: '15px' }"></ngx-skeleton-loader>
                        <div class="numbers">
                          <div class="price">
                            <ngx-skeleton-loader [theme]="{ width: '100%', 'border-radius': '0', height: '15px' }"></ngx-skeleton-loader>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- Meeting Length -->
                  <div class="col-12 col-sm-6 col-lg-4 col-xl-4 p-0 mb-4" *ngIf="!(loadingMetrics$ | async)">
                    <div class="item-estimated">
                      <div id="carouselExampleControls" class="carousel slide d-flex" data-ride="carousel">
                        <div class="carousel-inner d-flex  ">
                          <app-dashboard-card class="carousel-item" [ngClass]="ageCarousel[0]"   
                          [title]="'Generation'" [dataTitle]="'Gen Z'" [icon]="'meet_age'" [amount]="(metricsData['meet_age']?.b_20_30 | percent)"
                          [total]="metricsData['meet_age']?.b_20_30" [range]="rangeText" [isIncrement]="differences['meet_age']?.b_20_30 > metricsData['meet_age']?.b_20_30" 
                          [yesterdayAmount]="(differences['meet_age']?.b_20_30 | percent)" [yesterdayTotal]="differences['meet_age']?.b_20_30" [hasCompareData]="differences['meet_age']?.b_20_30 > 0"
                          [carouselClass]="ageCarousel[0]" [dataClass]="'meet_age'" [loading]="loadingPastMetrics$"
                          [difference]="0">
                          </app-dashboard-card>
                          <app-dashboard-card class="carousel-item" [ngClass]="ageCarousel[1]"   
                          [title]="'Generation'" [dataTitle]="'Millenial'" [icon]="'meet_age'" [amount]="(metricsData['meet_age']?.b_30_40 | percent)"
                          [total]="metricsData['meet_age']?.b_30_40" [range]="rangeText" [isIncrement]="differences['meet_age']?.b_30_40 > metricsData['meet_age']?.b_30_40" 
                          [yesterdayAmount]="(differences['meet_age']?.b_30_40 | percent)" [yesterdayTotal]="differences['meet_age']?.b_30_40" [hasCompareData]="differences['meet_age']?.b_30_40 > 0"
                          [carouselClass]="ageCarousel[1]" [dataClass]="'meet_age'" [loading]="loadingPastMetrics$"
                          [difference]="0">
                          </app-dashboard-card>
                          <app-dashboard-card class="carousel-item" [ngClass]="ageCarousel[2]"   
                          [title]="'Generation'" [dataTitle]="'Gen X'" [icon]="'meet_age'" [amount]="(metricsData['meet_age']?.b_40_50 | percent)"
                          [total]="metricsData['meet_age']?.b_40_50" [range]="rangeText" [isIncrement]="differences['meet_age']?.b_40_50 > metricsData['meet_age']?.b_40_50" 
                          [yesterdayAmount]="(differences['meet_age']?.b_40_50 | percent)" [yesterdayTotal]="differences['meet_age']?.b_40_50" [hasCompareData]="differences['meet_age']?.b_40_50 > 0"
                          [carouselClass]="ageCarousel[2]" [dataClass]="'meet_age'" [loading]="loadingPastMetrics$"
                          [difference]="0">
                          </app-dashboard-card>
                          <app-dashboard-card class="carousel-item" [ngClass]="ageCarousel[3]"   
                          [title]="'Generation'" [dataTitle]="'Baby Boomer'" [icon]="'meet_age'" [amount]="(metricsData['meet_age']?.b_50_60 | percent)"
                          [total]="metricsData['meet_age']?.b_50_60" [range]="rangeText" [isIncrement]="differences['meet_age']?.b_50_60 > metricsData['meet_age']?.b_50_60" 
                          [yesterdayAmount]="(differences['meet_age']?.b_50_60 | percent)" [yesterdayTotal]="differences['meet_age']?.b_50_60" [hasCompareData]="differences['meet_age']?.b_50_60 > 0"
                          [carouselClass]="ageCarousel[3]" [dataClass]="'meet_age'" [loading]="loadingPastMetrics$"
                          [difference]="0">
                          </app-dashboard-card>
                          <!-- <app-dashboard-card class="carousel-item" [ngClass]="ageCarousel[4]"   
                          [title]="'Age Group'" [dataTitle]="'60 - 70'" [icon]="'meet_age'" [amount]="(metricsData['meet_age']?.b_60_70 | percent)"
                          [total]="metricsData['meet_age']?.b_60_70" [range]="rangeText" [isIncrement]="differences['meet_age']?.b_60_70 > metricsData['meet_age']?.b_60_70" 
                          [yesterdayAmount]="(differences['meet_age']?.b_60_70 | percent)" [yesterdayTotal]="differences['meet_age']?.b_60_70" [hasCompareData]="differences['meet_age']?.b_60_70 > 0"
                          [carouselClass]="ageCarousel[4]" [dataClass]="'meet_age'" [loading]="loadingPastMetrics$"
                          [difference]="0">
                          </app-dashboard-card>
                          <app-dashboard-card class="carousel-item" [ngClass]="ageCarousel[5]"   
                          [title]="'Age Group'" [dataTitle]="'70 - 80'" [icon]="'meet_age'" [amount]="(metricsData['meet_age']?.b_70_80 | percent)"
                          [total]="metricsData['meet_age']?.b_70_80" [range]="rangeText" [isIncrement]="differences['meet_age']?.b_70_80 > metricsData['meet_age']?.b_70_80" 
                          [yesterdayAmount]="(differences['meet_age']?.b_70_80 | percent)" [yesterdayTotal]="differences['meet_age']?.b_70_80" [hasCompareData]="differences['meet_age']?.b_70_80 > 0"
                          [carouselClass]="ageCarousel[5]" [dataClass]="'meet_age'" [loading]="loadingPastMetrics$"
                          [difference]="0">
                          </app-dashboard-card> -->
                          <app-dashboard-card class="carousel-item" [ngClass]="ageCarousel[4]"   
                          [title]="'Age Group'" [dataTitle]="'Unknown age'" [icon]="'meet_age'" [amount]="(metricsData['meet_age']?.ageUnkown | percent)"
                          [total]="metricsData['meet_age']?.ageUnkown" [range]="rangeText" [isIncrement]="differences['meet_age']?.ageUnkown > metricsData['meet_age']?.ageUnkown" 
                          [yesterdayAmount]="(differences['meet_age']?.ageUnkown | percent)" [yesterdayTotal]="differences['meet_age']?.ageUnkown" [hasCompareData]="differences['meet_age']?.ageUnkown > 0"
                          [carouselClass]="ageCarousel[4]" [dataClass]="'meet_age'" [loading]="loadingPastMetrics$"
                          [difference]="0">
                          </app-dashboard-card>
                        </div>
                        <div class="btn-holder">
                          <span class="mr-auto" [ngClass]="slides[8] == 0 ? 'carousel-btn-inactive' : 'carousel-btn'" (click)="previousSlide(ageCarousel, slides, 8)"><fa-icon [icon]="previousCardIcon"></fa-icon></span>
                          <span class="ml-5" [ngClass]="slides[8] == (ageCarousel.length - 1) ? 'carousel-btn-inactive' : 'carousel-btn'" (click)="nextSlide(ageCarousel, slides, 8)"><fa-icon [icon]="nextCardIcon"></fa-icon></span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- Meeting Length :: SKELETON-->
                  <div class="col-12 col-sm-6 col-lg-4 col-xl-4 p-0 mb-4" *ngIf="(loadingMetrics$ | async)">
                    <div class="item-estimated">
                      <ngx-skeleton-loader appearance="circle" [theme]="{ height: '50px',width: '50px'}"></ngx-skeleton-loader>
                      <div class="description">
                        <ngx-skeleton-loader [theme]="{ width: '100%', 'border-radius': '0', height: '15px' }"></ngx-skeleton-loader>
                        <div class="numbers">
                          <div class="price">
                            <ngx-skeleton-loader [theme]="{ width: '100%', 'border-radius': '0', height: '15px' }"></ngx-skeleton-loader>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- Meeting Number -->
                  <div class="col-12 col-sm-6 col-lg-4 col-xl-4 p-0 mb-4 mb-lg-0 mb-xl-0" *ngIf="!(loadingMetrics$ | async)">
                    <div class="item-estimated">
                      <div id="carouselExampleControls" class="carousel slide d-flex" data-ride="carousel">
                        <div class="carousel-inner d-flex  ">
                          <app-dashboard-card class="carousel-item" [ngClass]="sentimentCarousel[0]"   
                          [title]="'Behavior'" [dataTitle]="'Male interruptions'" [icon]="'meet_emo'" [amount]="(metricsData['meet_emo']?.int_m | number: '1.0')"
                          [total]="metricsData['meet_emo']?.int_m" [range]="rangeText" [isIncrement]="differences['meet_emo']?.int_m > metricsData['meet_emo']?.int_m" 
                          [yesterdayAmount]="(differences['meet_emo']?.int_m | number: '1.0')" [yesterdayTotal]="differences['meet_emo']?.int_m" [hasCompareData]="differences['meet_emo']?.int_m > 0"
                          [carouselClass]="sentimentCarousel[0]" [dataClass]="'meet_emo'" [loading]="loadingPastMetrics$"
                          [difference]="(differences['meet_emo']?.int_m > 0) ? (metricsData['meet_emo']?.int_m - differences['meet_emo']?.int_m | number: '1.0') : ('0' | number: '1.0')">
                          </app-dashboard-card>
                          <app-dashboard-card class="carousel-item" [ngClass]="sentimentCarousel[1]"   
                          [title]="'Behavior'" [dataTitle]="'Female interruptions'" [icon]="'meet_emo'" [amount]="(metricsData['meet_emo']?.int_f | number: '1.0')"
                          [total]="metricsData['meet_emo']?.int_f" [range]="rangeText" [isIncrement]="differences['meet_emo']?.int_f > metricsData['meet_emo']?.int_f" 
                          [yesterdayAmount]="(differences['meet_emo']?.int_f | number: '1.0')" [yesterdayTotal]="differences['meet_emo']?.int_f" [hasCompareData]="differences['meet_emo']?.int_f > 0"
                          [carouselClass]="sentimentCarousel[1]" [dataClass]="'meet_emo'" [loading]="loadingPastMetrics$"
                          [difference]="(differences['meet_emo']?.int_f > 0) ? (metricsData['meet_emo']?.int_f - differences['meet_emo']?.int_f | number: '1.0') : ('0' | number: '1.0')">
                          </app-dashboard-card>
                          <app-dashboard-card class="carousel-item" [ngClass]="sentimentCarousel[2]"   
                          [title]="'Behavior'" [dataTitle]="'Non-Binary'" [icon]="'meet_emo'" [amount]="(metricsData['meet_emo']?.int_nb | number: '1.0')"
                          [total]="metricsData['meet_emo']?.int_nb" [range]="rangeText" [isIncrement]="differences['meet_emo']?.int_nb > metricsData['meet_emo']?.int_nb" 
                          [yesterdayAmount]="(differences['meet_emo']?.int_nb | number: '1.0')" [yesterdayTotal]="differences['meet_emo']?.int_nb" [hasCompareData]="differences['meet_emo']?.int_nb > 0"
                          [carouselClass]="sentimentCarousel[2]" [dataClass]="'meet_emo'" [loading]="loadingPastMetrics$"
                          [difference]="(differences['meet_emo']?.int_nb > 0) ? (metricsData['meet_emo']?.int_nb - differences['meet_emo']?.int_nb | number: '1.0') : ('0' | number: '1.0')">
                          </app-dashboard-card>
                          <app-dashboard-card class="carousel-item" [ngClass]="sentimentCarousel[3]"   
                          [title]="'Behavior'" [dataTitle]="'Gen Z interruptions'" [icon]="'meet_emo'" [amount]="(metricsData['meet_emo']?.int_gz | number: '1.0')"
                          [total]="metricsData['meet_emo']?.int_gz" [range]="rangeText" [isIncrement]="differences['meet_emo']?.int_gz > metricsData['meet_emo']?.int_gz" 
                          [yesterdayAmount]="(differences['meet_emo']?.int_gz | number: '1.0')" [yesterdayTotal]="differences['meet_emo']?.int_gz" [hasCompareData]="differences['meet_emo']?.int_gz > 0"
                          [carouselClass]="sentimentCarousel[3]" [dataClass]="'meet_emo'" [loading]="loadingPastMetrics$"
                          [difference]="(differences['meet_emo']?.int_gz > 0) ? (metricsData['meet_emo']?.int_gz - differences['meet_emo']?.int_gz | number: '1.0') : ('0' | number: '1.0')">
                          </app-dashboard-card>
                          <app-dashboard-card class="carousel-item" [ngClass]="sentimentCarousel[4]"   
                          [title]="'Behavior'" [dataTitle]="'Millenial interruptions'" [icon]="'meet_emo'" [amount]="(metricsData['meet_emo']?.int_ml | number: '1.0')"
                          [total]="metricsData['meet_emo']?.int_ml" [range]="rangeText" [isIncrement]="differences['meet_emo']?.int_ml > metricsData['meet_emo']?.int_ml" 
                          [yesterdayAmount]="(differences['meet_emo']?.int_ml | number: '1.0')" [yesterdayTotal]="differences['meet_emo']?.int_ml" [hasCompareData]="differences['meet_emo']?.int_ml > 0"
                          [carouselClass]="sentimentCarousel[4]" [dataClass]="'meet_emo'" [loading]="loadingPastMetrics$"
                          [difference]="(differences['meet_emo']?.int_ml > 0) ? (metricsData['meet_emo']?.int_ml - differences['meet_emo']?.int_ml | number: '1.0') : ('0' | number: '1.0')">
                          </app-dashboard-card>
                          <app-dashboard-card class="carousel-item" [ngClass]="sentimentCarousel[5]"   
                          [title]="'Behavior'" [dataTitle]="'Gen X interruptions'" [icon]="'meet_emo'" [amount]="(metricsData['meet_emo']?.int_gx | number: '1.0')"
                          [total]="metricsData['meet_emo']?.int_gx" [range]="rangeText" [isIncrement]="differences['meet_emo']?.int_gx > metricsData['meet_emo']?.int_gx" 
                          [yesterdayAmount]="(differences['meet_emo']?.int_gx | number: '1.0')" [yesterdayTotal]="differences['meet_emo']?.int_gx" [hasCompareData]="differences['meet_emo']?.int_gx > 0"
                          [carouselClass]="sentimentCarousel[5]" [dataClass]="'meet_emo'" [loading]="loadingPastMetrics$"
                          [difference]="(differences['meet_emo']?.int_gx > 0) ? (metricsData['meet_emo']?.int_gx - differences['meet_emo']?.int_gx | number: '1.0') : ('0' | number: '1.0')">
                          </app-dashboard-card>
                          <app-dashboard-card class="carousel-item" [ngClass]="sentimentCarousel[6]"   
                          [title]="'Behavior'" [dataTitle]="'Baby Boomers interruptions'" [icon]="'meet_emo'" [amount]="(metricsData['meet_emo']?.int_bb | number: '1.0')"
                          [total]="metricsData['meet_emo']?.int_bb" [range]="rangeText" [isIncrement]="differences['meet_emo']?.int_bb > metricsData['meet_emo']?.int_bb" 
                          [yesterdayAmount]="(differences['meet_emo']?.int_bb | number: '1.0')" [yesterdayTotal]="differences['meet_emo']?.int_bb" [hasCompareData]="differences['meet_emo']?.int_bb > 0"
                          [carouselClass]="sentimentCarousel[6]" [dataClass]="'meet_emo'" [loading]="loadingPastMetrics$"
                          [difference]="(differences['meet_emo']?.int_bb > 0) ? (metricsData['meet_emo']?.int_bb - differences['meet_emo']?.int_bb | number: '1.0') : ('0' | number: '1.0')">
                          </app-dashboard-card>


                          <!-- <app-dashboard-card class="carousel-item" [ngClass]="sentimentCarousel[1]"   
                          [title]="'Behavior'" [dataTitle]="'Negative'" [icon]="'meet_emo'" [amount]="(metricsData['meet_emo']?.negative | percent)"
                          [total]="metricsData['meet_emo']?.negative" [range]="rangeText" [isIncrement]="differences['meet_emo']?.negative > metricsData['meet_emo']?.negative" 
                          [yesterdayAmount]="(differences['meet_emo']?.negative | percent)" [yesterdayTotal]="differences['meet_emo']?.negative" [hasCompareData]="differences['meet_emo']?.negative > 0"
                          [carouselClass]="sentimentCarousel[1]" [dataClass]="'meet_emo'" [loading]="loadingPastMetrics$"
                          [difference]="0">
                          </app-dashboard-card>
                          <app-dashboard-card class="carousel-item" [ngClass]="sentimentCarousel[2]"   
                          [title]="'Behavior'" [dataTitle]="'Neutral'" [icon]="'meet_emo'" [amount]="(metricsData['meet_emo']?.neutral | percent)"
                          [total]="metricsData['meet_emo']?.neutral" [range]="rangeText" [isIncrement]="differences['meet_emo']?.neutral > metricsData['meet_emo']?.neutral" 
                          [yesterdayAmount]="(differences['meet_emo']?.neutral | percent)" [yesterdayTotal]="differences['meet_emo']?.neutral" [hasCompareData]="differences['meet_emo']?.neutral > 0"
                          [carouselClass]="sentimentCarousel[2]" [dataClass]="'meet_emo'" [loading]="loadingPastMetrics$"
                          [difference]="0">
                          </app-dashboard-card> -->
                        </div>
                        <div class="btn-holder">
                          <span class="mr-auto" [ngClass]="slides[9] == 0 ? 'carousel-btn-inactive' : 'carousel-btn'" (click)="previousSlide(sentimentCarousel, slides, 9)"><fa-icon [icon]="previousCardIcon"></fa-icon></span>
                          <span class="ml-5" [ngClass]="slides[9] == (sentimentCarousel.length - 1) ? 'carousel-btn-inactive' : 'carousel-btn'" (click)="nextSlide(sentimentCarousel, slides, 9)"><fa-icon [icon]="nextCardIcon"></fa-icon></span>
                        </div>
                      </div>
                    </div>
                  </div>
                  
                  <!-- Meeting Number :: SKELETON-->
                  <div class="col-12 col-sm-6  col-lg-4 col-xl-4 p-0 mb-4 mb-lg-0 mb-xl-0" *ngIf="(loadingMetrics$ | async)">
                    <div class="item-estimated">
                      <ngx-skeleton-loader appearance="circle" [theme]="{ height: '50px',width: '50px'}"></ngx-skeleton-loader>
                      <div class="description">
                        <ngx-skeleton-loader [theme]="{ width: '100%', 'border-radius': '0', height: '15px' }"></ngx-skeleton-loader>
                        <div class="numbers">
                          <div class="price">
                            <ngx-skeleton-loader [theme]="{ width: '100%', 'border-radius': '0', height: '15px' }"></ngx-skeleton-loader>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- AVG MEETING COST -->
                  <div class="col-12 col-sm-6  col-lg-4 col-xl-4 p-0 mb-4 mb-lg-0 mb-xl-0" *ngIf="!(loadingMetrics$ | async)">
                    <div class="item-estimated">
                      <div id="carouselExampleControls" class="carousel slide d-flex" data-ride="carousel">
                        <div class="carousel-inner d-flex  ">
                          <app-dashboard-card class="carousel-item" [ngClass]="participationCarousel[0]"   
                          [title]="'Participation'" [dataTitle]="'Top Gender is ' + metricsData['meet_part']?.topParticipationGenderpationGender" [icon]="'meet_part'" [amount]="(metricsData['meet_part']?.topParticipationGender | percent)"
                          [total]="metricsData['meet_part']?.topParticipationGender" [range]="rangeText" [isIncrement]="differences['meet_part']?.topParticipationGender > metricsData['meet_part']?.topParticipationGender" 
                          [yesterdayAmount]="(differences['meet_part']?.topParticipationGender | percent)" [yesterdayTotal]="differences['meet_part']?.topParticipationGender" [hasCompareData]="differences['meet_part']?.topParticipationGender > 0"
                          [carouselClass]="participationCarousel[0]" [dataClass]="'meet_part'" [loading]="loadingPastMetrics$"
                          [difference]="0">
                          </app-dashboard-card>
                          <app-dashboard-card class="carousel-item" [ngClass]="participationCarousel[1]"   
                          [title]="'Participation'" [dataTitle]="'Top Race is ' + metricsData['meet_part']?.participationRace" [icon]="'meet_part'" [amount]="(metricsData['meet_part']?.topParticipationRace | percent)"
                          [total]="metricsData['meet_part']?.topParticipationRace" [range]="rangeText" [isIncrement]="differences['meet_part']?.topParticipationRace > metricsData['meet_part']?.topParticipationRace" 
                          [yesterdayAmount]="(differences['meet_part']?.topParticipationRace | percent)" [yesterdayTotal]="differences['meet_part']?.topParticipationRace" [hasCompareData]="differences['meet_part']?.topParticipationRace > 0"
                          [carouselClass]="participationCarousel[1]" [dataClass]="'meet_part'" [loading]="loadingPastMetrics$"
                          [difference]="0">
                          </app-dashboard-card>
                          <app-dashboard-card class="carousel-item" [ngClass]="participationCarousel[2]"   
                          [title]="'Participation'" [dataTitle]="'Top Age is ' + metricsData['meet_part']?.participationAge" [icon]="'meet_part'" [amount]="(metricsData['meet_part']?.topParticipationAge | percent)"
                          [total]="metricsData['meet_part']?.topParticipationAge" [range]="rangeText" [isIncrement]="differences['meet_part']?.topParticipationAge > metricsData['meet_part']?.topParticipationAge" 
                          [yesterdayAmount]="(differences['meet_part']?.topParticipationAge | percent)" [yesterdayTotal]="differences['meet_part']?.topParticipationAge" [hasCompareData]="differences['meet_part']?.topParticipationAge > 0"
                          [carouselClass]="participationCarousel[2]" [dataClass]="'meet_part'" [loading]="loadingPastMetrics$"
                          [difference]="0">
                          </app-dashboard-card>
                        </div>
                        <div class="btn-holder">
                          <span class="mr-auto" [ngClass]="slides[10] == 0 ? 'carousel-btn-inactive' : 'carousel-btn'" (click)="previousSlide(participationCarousel, slides, 10)"><fa-icon [icon]="previousCardIcon"></fa-icon></span>
                          <span class="ml-5" [ngClass]="slides[10] == (participationCarousel.length - 1) ? 'carousel-btn-inactive' : 'carousel-btn'" (click)="nextSlide(participationCarousel, slides, 10)"><fa-icon [icon]="nextCardIcon"></fa-icon></span>
                        </div>
                      </div>
                    </div>
                  </div>
                  
                  <!-- AVG MEETING COST :: SKELETON-->
                  <div class="col-12 col-sm-6  col-lg-4 col-xl-4 p-0 mb-4 mb-lg-0 mb-xl-0" *ngIf="(loadingMetrics$ | async)">
                    <div class="item-estimated">
                      <ngx-skeleton-loader appearance="circle" [theme]="{ height: '50px',width: '50px'}"></ngx-skeleton-loader>
                      <div class="description">
                        <ngx-skeleton-loader [theme]="{ width: '100%', 'border-radius': '0', height: '15px' }"></ngx-skeleton-loader>
                        <div class="numbers">
                          <div class="price">
                            <ngx-skeleton-loader [theme]="{ width: '100%', 'border-radius': '0', height: '15px' }"></ngx-skeleton-loader>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- Deep Work -->
                  <div class="col-12 col-sm-6  col-lg-4 col-xl-4 p-0 mb-4 mb-lg-0 mb-xl-0" *ngIf="!(loadingMetrics$ | async)">
                    <div class="item-estimated">
                      <div id="carouselExampleControls" class="carousel slide d-flex" data-ride="carousel">
                        <div class="carousel-inner d-flex  ">
                          <app-dashboard-card class="carousel-item" [ngClass]="organizersCarousel[0]"   
                          [title]="'Organizers'" [dataTitle]="'Top Gender is ' + metricsData['meet_org']?.topGender" [icon]="'meet_org'" [amount]="(metricsData['meet_org']?.topOrganizerGender | percent)"
                          [total]="metricsData['meet_org']?.topOrganizerGender" [range]="rangeText" [isIncrement]="differences['meet_org']?.topOrganizerGender > metricsData['meet_org']?.topOrganizerGender" 
                          [yesterdayAmount]="(differences['meet_org']?.topOrganizerGender | percent)" [yesterdayTotal]="differences['meet_org']?.topOrganizerGender" [hasCompareData]="differences['meet_org']?.topOrganizerGender > 0"
                          [carouselClass]="organizersCarousel[0]" [dataClass]="'meet_org'" [loading]="loadingPastMetrics$"
                          [difference]="0">
                          </app-dashboard-card>
                          <app-dashboard-card class="carousel-item" [ngClass]="organizersCarousel[1]"   
                          [title]="'Organizers'" [dataTitle]="'Top Race is ' + metricsData['meet_org']?.topRace" [icon]="'meet_org'" [amount]="(metricsData['meet_org']?.topOrganizerRace | percent)"
                          [total]="metricsData['meet_org']?.topOrganizerRace" [range]="rangeText" [isIncrement]="differences['meet_org']?.topOrganizerRace > metricsData['meet_org']?.topOrganizerRace" 
                          [yesterdayAmount]="(differences['meet_org']?.topOrganizerRace | percent)" [yesterdayTotal]="differences['meet_org']?.topOrganizerRace" [hasCompareData]="differences['meet_org']?.topOrganizerRace > 0"
                          [carouselClass]="organizersCarousel[1]" [dataClass]="'meet_org'" [loading]="loadingPastMetrics$"
                          [difference]="0">
                          </app-dashboard-card>
                          <app-dashboard-card class="carousel-item" [ngClass]="organizersCarousel[2]"   
                          [title]="'Organizers'" [dataTitle]="'Top Age is ' + metricsData['meet_org']?.topAge" [icon]="'meet_org'" [amount]="(metricsData['meet_org']?.topOrganizerAge | percent)"
                          [total]="metricsData['meet_org']?.topOrganizerAge" [range]="rangeText" [isIncrement]="differences['meet_org']?.topOrganizerAge > metricsData['meet_org']?.topOrganizerAge" 
                          [yesterdayAmount]="(differences['meet_org']?.topOrganizerAge | percent)" [yesterdayTotal]="differences['meet_org']?.topOrganizerAge" [hasCompareData]="differences['meet_org']?.topOrganizerAge > 0"
                          [carouselClass]="organizersCarousel[2]" [dataClass]="'meet_org'" [loading]="loadingPastMetrics$"
                          [difference]="0">
                          </app-dashboard-card>
                        </div>
                        <div class="btn-holder">
                          <span class="mr-auto" [ngClass]="slides[11] == 0 ? 'carousel-btn-inactive' : 'carousel-btn'" (click)="previousSlide(organizersCarousel, slides, 11)"><fa-icon [icon]="previousCardIcon"></fa-icon></span>
                          <span class="ml-5" [ngClass]="slides[11] == (organizersCarousel.length - 1) ? 'carousel-btn-inactive' : 'carousel-btn'" (click)="nextSlide(organizersCarousel, slides, 11)"><fa-icon [icon]="nextCardIcon"></fa-icon></span>
                        </div>
                      </div>
                    </div>
                  </div>
                  
                  <!-- Deep Work :: SKEKETON-->
                  <div class=" col-12 col-sm-6  col-lg-4 col-xl-4 p-0 mb-4 mb-lg-0 mb-xl-0" *ngIf="(loadingMetrics$ | async)">
                    <div class="item-estimated">
                      <ngx-skeleton-loader appearance="circle" [theme]="{ height: '50px',width: '50px'}"></ngx-skeleton-loader>
                      <div class="description">
                        <ngx-skeleton-loader [theme]="{ width: '100%', 'border-radius': '0', height: '15px' }"></ngx-skeleton-loader>
                        <div class="numbers">
                          <div class="price">
                            <ngx-skeleton-loader [theme]="{ width: '100%', 'border-radius': '0', height: '15px' }"></ngx-skeleton-loader>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- Personal Time -->
                  <!-- Personal Time :: SKEKETON-->
                  <div class="d-none col-12 col-sm-6 col-lg-3 col-xl-3 p-0 mb-4 mb-lg-0 mb-xl-0" *ngIf="(loadingMetrics$ | async)">
                    <div class="item-estimated">
                      <ngx-skeleton-loader appearance="circle" [theme]="{ height: '50px',width: '50px'}"></ngx-skeleton-loader>
                      <div class="description">
                        <ngx-skeleton-loader [theme]="{ width: '100%', 'border-radius': '0', height: '15px' }"></ngx-skeleton-loader>
                        <div class="numbers">
                          <div class="price">
                            <ngx-skeleton-loader [theme]="{ width: '100%', 'border-radius': '0', height: '15px' }"></ngx-skeleton-loader>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- Meeting Participants -->
                  <!-- Meeting Participants :: SKEKETON-->
                  <div class="d-none col-12 col-sm-6 col-lg-3 col-xl-3 p-0 mb-4 mb-lg-0 mb-xl-0" *ngIf="(loadingMetrics$ | async)">
                    <div class="item-estimated">
                      <ngx-skeleton-loader appearance="circle" [theme]="{ height: '50px',width: '50px'}"></ngx-skeleton-loader>
                      <div class="description">
                        <ngx-skeleton-loader [theme]="{ width: '100%', 'border-radius': '0', height: '15px' }"></ngx-skeleton-loader>
                        <div class="numbers">
                          <div class="price">
                            <ngx-skeleton-loader [theme]="{ width: '100%', 'border-radius': '0', height: '15px' }"></ngx-skeleton-loader>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
  
                  <div class="col-12 mt-4" style="padding: 0px 10px 0px 0px;
                  width: 98%;
                  min-height: 342px;
                  flex-flow: column;">
                    <div class="row chart-card" style="display: flex;
                    background-color: white;
                    border-left-width: 3px;
                    border-radius: 3px;
                    box-shadow: 1px 1px 8px 0px rgb(0 0 0 / 20%);
                    margin: 5px 0;
                    "
                    ><!--padding: 10px 10px;-->
                      <div class="row chart-header" style="background-color: #f5f5f5;
                      justify-content: center;
                      padding: 6px 0px;
                      align-items: center;
                      border-bottom: 2px solid #f0f0f0;
                      display: flex;
                      height: 54px;
                      width: 100%;">
                        <span class="title" [ngClass]="distributionCarousel[0]">Diversity Distribution</span>
                        <!-- <span class="title" [ngClass]="distributionCarousel[1]">Additional Distributions</span> -->
                      </div>
                      <div class="col-12 col-lg-4 col-xl-4 p-0 chart-content" [ngClass]="distributionCarousel[0]">
                        <div class="pt-3" echarts [options]="genderChart" (chartInit)="onDistributionChartInit($event,0)" style="height: 288px; width: 100%;"></div>
                      </div>
                      <div class="col-12 col-lg-4 col-xl-4 p-0 chart-content" [ngClass]="distributionCarousel[0]">
                        <div class="pt-3" echarts [options]="raceChart" (chartInit)="onDistributionChartInit($event,1)" style="height: 288px; width: 100%;"></div>
                      </div>
                      <div class="col-12 col-lg-4 col-xl-4 p-0 chart-content" [ngClass]="distributionCarousel[0]">
                        <div class="pt-3" echarts [options]="ageChart" (chartInit)="onDistributionChartInit($event,2)" style="height: 288px; width: 100%;"></div>
                      </div>
                      <!-- <div class="col-12 col-lg-6 col-xl-6 p-0 chart-content" [ngClass]="distributionCarousel[1]">
                        <div class="pt-3" echarts [options]="ageChart" (chartInit)="onDistributionChartInit($event,2)" style="height: 288px; width: 100%;"></div>
                      </div>
                      <div class="col-12 col-lg-6 col-xl-6 p-0 chart-content" [ngClass]="distributionCarousel[1]">
                        <div class="pt-3" echarts [options]="raceChart" (chartInit)="onDistributionChartInit($event,1)" style="height: 288px; width: 100%;"></div>
                      </div> -->
                    </div>
                    <div class="btn-holder">
                      <span class="mr-auto" [ngClass]="slides[13] == 0 ? 'carousel-btn-inactive' : 'carousel-btn'" (click)="previousSlide(distributionCarousel, slides, 13)"><fa-icon [icon]="previousCardIcon"></fa-icon></span>
                      <span class="ml-5" [ngClass]="slides[13] == (distributionCarousel.length - 1) ? 'carousel-btn-inactive' : 'carousel-btn'" (click)="nextSlide(distributionCarousel, slides, 13)"><fa-icon [icon]="nextCardIcon"></fa-icon></span>
                    </div>
                  </div>
  
                  
                </div>
              </ng-template>
            </div>
            <!-- aqui empieza -->
          </div>
        </div>
        <div class="d-none d-lg-block d-xl-block col-12 col-lg-3 pl-0">
          <div class="discussed-topics">
            <div class="header-title m-0">
              <p>MOST USED WORDS</p>
            </div>
            <ul class="list-unstyled topics-bars" *ngIf="!(loadingKeywords$ | async)">
              <li *ngFor="let keyword of keywordsData">
                <div class="description">
                  <div class="title">{{keyword.text}}</div>
                  <div class="percentage">{{keyword.percentage}}%</div>
                </div>
                <p class="pl-1"><ngb-progressbar type="info" [value]="keyword.percentage" ></ngb-progressbar></p>
              </li>
            </ul>

            <!-- NO KEYWORDS FOUND-->
            <ul class="list-unstyled topics-bars" *ngIf="!(loadingKeywords$ | async) && !(keywordsData.length)">
              <li>
                <div class="description">
                  <div class="title">No keywords were found</div>
                </div>
              </li>
            </ul>

            <!-- SKELETON - LOADING -->
            <ul class="list-unstyled topics-bars" *ngIf="(loadingKeywords$ | async)">
              <li *ngFor="let x of [1,2,3,4]">
                <div class="description">
                  <div class="w-50 mt-2">
                    <ngx-skeleton-loader [theme]="{ width: '100%', 'border-radius': '10px', height: '8px' }"></ngx-skeleton-loader>
                  </div>
                </div>
                <p class="pl-1">
                  <ngx-skeleton-loader [theme]="{ width: '100%', 'border-radius': '10px', height: '8px' }"></ngx-skeleton-loader>
                </p>
              </li>

            </ul>
          </div>

          <div class="discussed-topics mt-4">
            <div class="header-title m-0">
              <p>BEST PRACTICES FOLLOWED</p>
            </div>
            <ul class="list-unstyled topics-bars">
              <li>
                <div class="description">
                  <div class="title">Has Agenda</div><!--(loadingBestPractices$ | async)-->
                  <div class="percentage" *ngIf="!(loadingMetrics$ | async)">{{ (metricsData['meet_num']?.has_agenda / metricsData['meet_num']?.counter) * 100 | number: '1.0-0' }} %</div>
                </div>
                <p *ngIf="!(loadingMetrics$ | async)"><ngb-progressbar type="info" [value]="metricsData['meet_num']?.has_agenda"  [max]="metricsData['meet_num']?.counter"></ngb-progressbar></p>
                <ngx-skeleton-loader *ngIf="(loadingMetrics$ | async)" [theme]="{ width: '100%', 'border-radius': '10px', height: '8px' }"></ngx-skeleton-loader>
              </li>
              <li>
                <div class="description">
                  <div class="title">Acceptance Rate</div><!--(loadingBestPractices$ | async)-->
                  <div class="percentage" *ngIf="!(loadingMetrics$ | async)">{{ metricsData['meet_par']?.acceptRate | number: '1.0-0' }} %</div>
                </div>
                <p *ngIf="!(loadingMetrics$ | async)"><ngb-progressbar type="info" [value]="metricsData['meet_par']?.acceptRate"  [max]="100"></ngb-progressbar></p>
                <ngx-skeleton-loader *ngIf="(loadingMetrics$ | async)" [theme]="{ width: '100%', 'border-radius': '10px', height: '8px' }"></ngx-skeleton-loader>
              </li>
              <li>
                <div class="description">
                  <div class="title">Five Days Notice</div>
                  <div class="percentage" *ngIf="!(loadingMetrics$ | async)">{{ metricsData['meet_num']?.notice | number: '1.0-0' }} %</div>
                </div>
                <p *ngIf="!(loadingMetrics$ | async)"><ngb-progressbar type="info" [value]="metricsData['meet_num']?.notice"  [animated]="true"></ngb-progressbar></p>
                <ngx-skeleton-loader *ngIf="(loadingMetrics$ | async)" [theme]="{ width: '100%', 'border-radius': '10px', height: '8px' }"></ngx-skeleton-loader>
              </li>
              <li>
                <div class="description">
                  <div class="title">Less Than 8 Members</div>
                  <div class="percentage" *ngIf="!(loadingMetrics$ | async)">{{ ((metricsData['meet_num']?.counter - metricsData['meet_par']?.meet_under_8) / metricsData['meet_num']?.counter) * 100 | number: '1.0-0' }} %</div>
                </div>
                <p *ngIf="!(loadingMetrics$ | async)"><ngb-progressbar type="info" [value]="(metricsData['meet_num']?.counter - metricsData['meet_par']?.meet_under_8)" [max]="metricsData['meet_num']?.counter"  [animated]="true"></ngb-progressbar></p>
                <ngx-skeleton-loader *ngIf="(loadingMetrics$ | async)" [theme]="{ width: '100%', 'border-radius': '10px', height: '8px' }"></ngx-skeleton-loader>
              </li>
              <li>
                <div class="description">
                  <div class="title">Meeting Under 45 Minutes</div>
                  <div class="percentage" *ngIf="!(loadingMetrics$ | async)">{{ ((metricsData['meet_num']?.counter - metricsData['meet_len']?.meet_under_45) / metricsData['meet_num']?.counter) * 100 | number: '1.0-0' }} %</div>
                </div>
                <p *ngIf="!(loadingMetrics$ | async)"><ngb-progressbar type="info" [value]="(metricsData['meet_num']?.counter - metricsData['meet_len']?.meet_under_45)" [max]="metricsData['meet_num']?.counter" [animated]="true"></ngb-progressbar></p>
                <ngx-skeleton-loader *ngIf="(loadingMetrics$ | async)" [theme]="{ width: '100%', 'border-radius': '10px', height: '8px' }"></ngx-skeleton-loader>
              </li>
            </ul>
          </div>

        </div>
        <div class="d-none d-lg-block d-xl-block col-12 col-lg-4 pl-0">

        </div>
      </div>
      <div class="d-block d-lg-none d-xl-none col-12  mt-3 mt-lg-0 mt-xl-0">
        <div class="row">
          <div class="col-12">
            <app-recommendations></app-recommendations>
          </div>
        </div>
      </div>
      <!-- aqui termina -->
    </div>
  </div>
</section>
