import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Globals } from 'src/app/global';

@Injectable({
  providedIn: 'root'
})
export class ZoomSettingsService {
  // variable that store the import of Globals Class that will help us to retrieve the urls for every service
  globals = Globals;

  constructor(private http: HttpClient) { }

  // Retrieves settings zoom data
  getZoomSettings(): Promise<object>{
    const url = `${this.globals.settings.zoomSettings}`;
    return this.http.get(url).toPromise();
  }

  /**
    Set settings zoom data
    @param object {string, string} params - Data for zoom settings
  */
  setZoomSettings(params: {code: string, organization: string}): Promise<object>{
    //const url = `${this.globals.settings.zoomSettings}`;
    
    //const url = 'https://phbeqykb8j.execute-api.us-west-2.amazonaws.com/default/zoomoauth';
    const url = `${this.globals.settings.zoomOauth}/oauth`;
    return this.http.post(url, params).toPromise();
  }

  /**
    Set settings zoom data
    @param object {string, string} params - Data for zoom settings
  */
    setZoomAppSettings(params: {code: string}): Promise<object>{
      //const url = `${this.globals.settings.zoomSettings}`;
      
      //const url = 'https://phbeqykb8j.execute-api.us-west-2.amazonaws.com/default/zoomoauth';
      const url = `${this.globals.zoom.install}`;
      return this.http.post(url, params).toPromise();
    }

  /**
    Get zoom status data
    @param object params - Data for zoom settings
  */
    statusZoomSettings(): Promise<object>{
      //const url = `${this.globals.settings.zoomSettings}`;
      
      //const url = 'https://rdw98qzgu8.execute-api.us-west-2.amazonaws.com/Prod/status';
      const url = `${this.globals.settings.zoomOauth}/status`;
      return this.http.get(url).toPromise();
    }
  

  // Delete settings zoom data
  deleteZoomSettings(): Promise<object>{
    //const url = `${this.globals.settings.zoomSettings}`;
    const url = `${this.globals.settings.zoomOauth}/delete`;
    return this.http.post(url, {}).toPromise();
  }
}
