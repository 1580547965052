import { Component, OnInit, Inject, Input } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { StepsConnection } from 'src/app/models/settings/steps-connection';
import { trigger, transition, style, animate } from '@angular/animations';

@Component({
  selector: 'app-notification-settings',
  templateUrl: './notification-settings.component.html',
  styleUrls: ['./notification-settings.component.scss']
})
export class NotificationSettings implements OnInit {
  tips = [
    {key: 'Duration', value: ['TARIY takes in consideration the real meeting time duration.', 'Tariy recommends meetings under 45 minutes.']},
    {key: 'Meeting Starts', value: ['TARIY uses the difference between the scheduled start and the real meeting starting time.', 'Tariy recommends no more then 3 minutes of late starting time.']},
    {key: 'Timeframe', value: ['TARIY takes in consideration the timeframe in which the meeting is set in.', 'Tariy recommends meetings between 9:00am-12:00pm and 2:00pm-4:00pm.']},
    {key: 'Num of Attendees', value: ['TARIY values how many attendees where in the meeting.', 'Tariy recommends meetings with under 5 attendees.']},
    {key: 'Meeting Ends', value: ['TARIY takes in consideration the real ending time of the meeting.', 'Tariy recommends ending the meeting no more than 5 minutes after the scheduled ending.']},
    {key: 'Day', value: ['TARIY uses the day of the week the meeting took place.', 'Tariy recommends scheduling meeting between Tuesday and Thursday.']},
    {key: 'Has Agenda', value: ['TARIY reads the meeting invation and searches for an agenda.', 'Tariy recommends using an agenda to plan your meeting topics.']},
    {key: 'Notice', value: ['TARIY takes in consideration how many days in advance was the meeting scheduled.', 'Tariy recommends scheduling meetings 5 days in advance.']},
    {key: 'Sentiment', value: ['TARIY reads the meeting transcript and assign a value from negative to positive.', 'Tariy recommends having meetings with a positive sentiment.']},
    {key: 'Participation', value: ['TARIY takes in consideration how much every attendee participated.', 'Tariy recommends that every attendee should at least have a 10% participation.']},
  ];
  columnHeight = 0;


  constructor(public dialogRef: MatDialogRef<NotificationSettings>, @Inject(MAT_DIALOG_DATA) private data: any) { }

  ngOnInit(): void {
    // Set data sent from dialog data to steps
    //this.tips = this.data['tips'];
    this.columnHeight = 100/ (this.tips.length);
  }

  // Close dialog
  close(){
    this.dialogRef.close();
  }

}
