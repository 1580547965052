// Class that allows the creation of new instances
export class MeetingMember {
  id: string;
  attended: any;
  available_time: number;
  deepwork_time: number;
  booked: any;
  date: string;
  department: string;
  departmentId: string;
  discussed_topic: string;
  duration: number;
  email: string;
  firstname: string;
  fullname: string;
  lastname: string;
  late: any;
  title: string;
  occupation: string;
  occupationId: string;
  organizationId: string;
  organizationName: string;
  participants: string[];
  participated: any;
  picture: string;
  role: string;
  salary: number;
  schedule: string;
  scheduled_percentage: number;
  score: number;
  skipped: any;
  status: number;
  topics: string[];
  year_salary: number;
  validPicture: boolean;
  organizerScore: string;
  participantScore: string;

  constructor(data){
    this.id = data.Id;
    this.firstname = data.FirstName;
    this.lastname = data.LastName;
    this.fullname = data.Full_Name ?? data.FullName;
    this.email = data.Email;
    this.title = data.title;
    this.picture = data.Picture;
    this.discussed_topic = data.MostDiscussedTopics;
    this.booked = data.Booked ? this.parseMeetingsHours(data.Booked) : null;
    this.attended = data.Attended ? this.parseMeetingsHours(data.Attended) : null;
    this.participated = data.Participated ? this.parseMeetingsHours(data.Participated): null;
    this.skipped = data.Skkiped ? this.parseMeetingsHours(data.Skkiped) : null;
    this.late = data.Late ? this.parseMeetingsHours(data.Late) : null;
    this.scheduled_percentage = data.SheduledPercentage;
    this.available_time = data.AvailableTime;
    this.deepwork_time = data.DeepWork;
    this.date = data.date;
    this.duration = data.duration;
    this.participants = data.participants;
    this.topics = data.Topic ? data.Topics : [];
    this.status = data.status;
    this.score = data.score;
    this.role = data.RoleName;
    this.department = data.Department;
    this.departmentId = data.DepartmentId;
    this.schedule = data.Schedule;
    this.salary = data.Salary;
    this.year_salary = data.YearSalary;
    this.occupation = data.Occupation;
    this.occupationId = data.OccupationId;
    this.organizationId = data.OrganizationId;
    this.organizationName = data.CompanyName;
    this.validPicture = true;
    this.participantScore = data.ParticipantScore ? this.parseScore(data.ParticipantScore) : '0';
    this.organizerScore = data.HostScore ? this.parseScore(data.HostScore) : '0';
  }

  /**
   * Creates an object with a 'meeting' and 'hours' attribute from a string
   * @param text text to parse
   * @returns object with a meeting and hour attribute
   */
  private parseMeetingsHours(text) {
    var meetings = '0';
    var hours = '0';

    try {
      const parsedText = text.match(/[\d|,|.|\+]+/g);

      if (parsedText !== undefined && parsedText !== null) {
        meetings = parsedText[0] === undefined ? '0' : parsedText[0];
        hours = parsedText[1] === undefined ? '0' : parsedText[1];
      }
    } catch (err) {
      console.error(err)
    }

    return {
      meetings: meetings,
      hours: hours
    };
  }

  private invalidPicture() {
    this.validPicture = false;
  }

  private parseScore(score) {
    return `${Math.round(parseFloat(score))}`;
  }

}

