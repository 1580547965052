import { ResourcesService } from "src/app/services/resources/resources.service";
import { BaseList } from "../base-list/base-list";
import { CustomSelect } from "../custom-select/custom-select";
import { SourceCustomSelect } from 'src/app/classes/source-custom-select/source-custom-select';

export class SourceIndustries extends SourceCustomSelect{

  constructor( private resources: ResourcesService ) {
    super();
  }
  /**
  * To get data of Industries items
  * @param {Array} query - Params to search and paginate data of Industries items
  */
  async fetchMore(query): Promise<BaseList> {
    return await this.resources.getIndustries(query).then(data => {
      const items = data['Data']['Industries'].map( element => new CustomSelect(element.Id, element.Name));
      return new BaseList(data['Data'], items);
    });
  }

}
