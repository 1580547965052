
import * as moment from "moment-timezone";
import { MathUtils } from "src/app/utils/mathUtils";
import { MeetingTypesText,  AnalyticTypes, CurrentTypeColors, ForecastTypeColors  } from "src/enum/meeting-types.enum";
import { Role } from "src/enum/Role";
import { MeetingMember } from '../meeting-member/meeting-member';
import { Observable } from 'rxjs';
 
export class DistributionPie {
  tooltip: {};
  xAxis: {};
  yAxis: {};
  series: any[];
  animationEasing: string;
  animationDelayUpdate: any;
  instance: any;
  legend: any;
  colors: any[];
  forecastColors: any[];
  currenNumber: number;
  forecastNumber: number;
  showForecast: boolean;
  dataSeries: any;
  title: any;

  constructor(data, label, mode){
    this.showForecast = true;
    this.animationEasing = 'elasticOut';
    this.animationDelayUpdate = (idx) => idx * 5;
    this.currenNumber = 1;
    this.forecastNumber = 0;
    this.legend = {
      show: false
    };
    this.title = {
      text: mode > 2 ? `${label} total: ${data?.total}`: label,
      left: 'center',
      show: mode > 2 ? false: true,
      padding: [0,5,5,5]
    }
    this.instance = null;
    this.tooltip = {
      trigger: 'item'
    };
    switch(mode) {
      case 0:
        this.dataSeries = [
          {name: 'Unknown', value: this.turnToPercent(data?.genderUnknown)},
        ];
        if(data?.male > 0)
          this.dataSeries.push({name: 'Male', value: this.turnToPercent(data?.male)})
        if(data?.female > 0)
          this.dataSeries.push({name: 'Female', value: this.turnToPercent(data?.female)})
        if(data?.nonBinary > 0)
          this.dataSeries.push({name: 'Non-Binary', value: this.turnToPercent(data?.nonBinary)})
        break;
      case 1:
        this.dataSeries = [
          {name: 'Unknown', value: this.turnToPercent(data?.raceUnknown)},
        ];
        if(data?.white > 0)
          this.dataSeries.push({name: 'White', value: this.turnToPercent(data?.white)})
        if(data?.black > 0)
          this.dataSeries.push({name: 'Black or African American', value: this.turnToPercent(data?.black)})
        if(data?.asian > 0)
          this.dataSeries.push({name: 'Asian', value: this.turnToPercent(data?.asian)})
          if(data?.native > 0)
          this.dataSeries.push({name: 'Native American', value: this.turnToPercent(data?.native)})
        if(data?.pacific > 0)
          this.dataSeries.push({name: 'Pacific Islander', value: this.turnToPercent(data?.pacific)})
        if(data?.hispanic > 0)
          this.dataSeries.push({name: 'Hispanic /Latino', value: this.turnToPercent(data?.hispanic)})
        if(data?.notHispanic > 0)
        this.dataSeries.push({name: 'Not Hispanic /Latino', value: this.turnToPercent(data?.notHispanic)})
        break;
      case 2:
        this.dataSeries = [
          {name: 'Unknown', value: this.turnToPercent(data?.ageUnkown)},
        ];
        if(data?.b_20_30 > 0)
          this.dataSeries.push({name: 'Gen Z', value: this.turnToPercent(data?.b_20_30)})
        if(data?.b_30_40 > 0)
          this.dataSeries.push({name: 'Millenial', value: this.turnToPercent(data?.b_30_40)})
        if(data?.b_40_50 > 0)
          this.dataSeries.push({name: 'Gen X', value: this.turnToPercent(data?.b_40_50)})
          if(data?.b_50_60 > 0)
          this.dataSeries.push({name: 'Baby Boomer', value: this.turnToPercent(data?.b_50_60)})
        // if(data?.b_60_70 > 0)
        //   this.dataSeries.push({name: '60-70', value: this.turnToPercent(data?.b_60_70)})
        // if(data?.b_70_80 > 0)
        //   this.dataSeries.push({name: '70-80', value: this.turnToPercent(data?.b_70_80)})
        break;
      case 3:
        this.dataSeries = [
          {name: 'Unrated', value: data?.unrated},
        ];
        if(data?.productive > 0)
          this.dataSeries.push({name: 'Productive', value: data?.productive})
        if(data?.unproductive > 0)
          this.dataSeries.push({name: 'Unproductive', value: data?.unproductive})
        break;
      case 4:
        this.dataSeries = [
          {name: 'Unrated', value: data?.unrated},
        ];
        if(data?.inclusive > 0)
          this.dataSeries.push({name: 'Inclusive', value: data?.inclusive})
        if(data?.uninclusive > 0)
          this.dataSeries.push({name: 'Noninclusive', value: data?.uninclusive})
        break;  
      default:
        this.dataSeries = [];
        break;
    }
    this.series = [{
      name: label,
      type: 'pie',
      radius: ['40%', '70%'],
      avoidLabelOverlap: true,
      itemStyle: {
        borderRadius: 10,
        borderColor: '#fff',
        borderWidth: 2
      },
      label: {
        position: 'outer',
        bleedMargin: 5
      },
      emphasis: {
        label: {
          show: true,
          fontSize: '16',
          fontWeight: 'bold'
        }
      },
      labelLine: {
        show: true
      },
      data: this.dataSeries,
      animationDelay: (idx) => idx * 10,
    }];
  }
  private setInstance(chart:any, type:string = null): void
  {
     this.instance = chart;
     this.finishLoading();
     if(type != null) {
       this.setChartType(type);
     }
  }
  private turnToPercent(data) {
    return data ? (data*100): 0;
  }


  private finishLoading(): void
  {
     this.instance.hideLoading();
  }

  private setChartType(type:string): void
  {
     this.series.forEach(x => x.type = type);
     this.redraw();
  }

  private redraw(): void
  {
     this.instance.setOption({
       'tooltip': this.tooltip,
       'xAxis': this.xAxis,
       'yAxis': this.yAxis,
       'series': this.series,
       'animationEasing': this.animationEasing,
       'animationDelayUpdate': this.animationDelayUpdate,
       'legend': this.legend
     });
  }
}


