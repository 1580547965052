import { Injectable } from '@angular/core';
import { debounceTime, delay, switchMap, tap } from 'rxjs/operators';
import { SortColumn, SortDirection } from '../../views/dashboard/sortable.directive';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { Globals } from '../../global';
import { PaginationState } from 'src/app/models/pagination/pagination';
import { HttpClient } from '@angular/common/http';
import { Meeting } from 'src/app/models/meetings/meeting';
import * as moment from "moment-timezone";
import { AuthService } from '../auth/auth.service';

@Injectable({
  providedIn: 'root'
})

export class MeetingsService {
  // variable that store the import of Globals Class that will help us to retrieve the urls for every service
  globals = Globals
  // Url of users api
  baseUrl = `${this.globals.urlTraiyBase}`;
  // Listen for new changes on datatable
  private _loading$ = new BehaviorSubject<boolean>(true);
  // Listen for new strings that user provides
  _search$ = new Subject<void>();
  // List of meetings
  private _meetings$ = new BehaviorSubject<Meeting[]>([]);
  // Number of elements shown on datatable
  private _total$ = new BehaviorSubject<number>(0);
  // If filter organizar should be added or disabled, depends on logged user role
  organizerFilter = false;
  //meeting selected
  private _meetingId = '';
  private _meetingSelected = false;
  // Default filters that are applied on first request on datatable
  private _pagination: PaginationState = {
    page: 1,
    itemsPerPage: 10,
    searchText: '',
    orderColumn: '',
    orderBy: '',
    range_date: { "startDate": moment().clone().startOf('month'), "endDate": moment().clone().endOf('month') },
    status: '',
    meetingType: '',
    organizer: false
  };

  constructor(private http: HttpClient,
    private authService:AuthService) {

    this._search$.pipe(
      tap(() => this._loading$.next(true)),
      debounceTime(200),
      switchMap(() => this._search()),
      delay(200),
      tap(() => this._loading$.next(false))
    ).subscribe(result => {
      this._meetings$.next(result['Meetings'].map(user => new Meeting(user)));
      this._total$.next(result['Total']);
    });

    this._search$.next();
  }

  // Getters an setters that manage filters an table options
  // that show results on datatable
  get meetings$() { return this._meetings$.asObservable(); }
  get total$() { return this._total$.asObservable(); }
  get loading$() { return this._loading$.asObservable(); }
  get page() { return this._pagination.page; }
  get itemsPerPage() { return this._pagination.itemsPerPage; }
  get searchText() { return this._pagination.searchText; }
  get status() { return this._pagination.status; }
  get meetingType() { return this._pagination.meetingType; }
  get range_date() { return this._pagination.range_date; }
  get organizer() { return this._pagination.organizer; }
  get meetingId() {return this._meetingId;}
  get meetingSelected() {return this._meetingSelected;}

  set page(page: number) { this._set({page}); }
  set itemsPerPage(itemsPerPage: number) { this._set({itemsPerPage}); }
  set searchText(searchText: string) { this._set({searchText}); }
  set orderColumn(orderColumn: SortColumn) { this._set({orderColumn}); }
  set orderBy(orderBy: SortDirection) { this._set({orderBy}); }
  set status(status: number | string) { this._set({status}); }
  set meetingType(meetingType: string) { this._set({meetingType}); }
  set range_date(range_date) { this._set({range_date}); }
  set organizer(organizer) { this._set({ organizer }); }
  set meetingId(id) {
    this._meetingId = id;
    this._meetingSelected = true;
  }

  // Helper that set values depending on its use
  private _set(patch: any) {
    Object.assign(this._pagination, patch);
    this._search$.next();
  }

  // Applies all filters to final results
  private _search(): Observable<object> {
    let pagination_params = Object.assign({} ,this._pagination);
    delete pagination_params.range_date;

    if (!this.organizerFilter){ // Depending if datatable requires a organizer filters
      delete pagination_params.organizer;
    }

    const startDate = this.range_date['startDate'].startOf('day').toISOString();
    const endDate = this.range_date['endDate'].endOf('day').toISOString(); 
    let user = JSON.parse(localStorage.getItem('traiyUser'));
    let mode = user.hasOwnProperty('userMode') ? user['userMode'] : 0;
    const params = Object.keys(pagination_params).map(key => key + '=' + pagination_params[key]).join('&') + 
    `&starts=${startDate}&ends=${endDate}&userMode=${mode}${this.meetingSelected ? '&id=' + this.meetingId : ''}`;
    const url = `${this.globals.meetings.query}?${params}`;
    /*return this.http.post(url,{
      "users_id":[ this.authService.loginInfo.email ],
      "starts": startDate,
      "ends": endDate
    });*/
    return this.http.get(url);
  }

  /**
    Set settings teams data
    @param object {string, string} params - Data for zoom settings
  */
    saveFeedback(id:string, title:string, response:number): Promise<object>{
      const url = `https://vrvipwvjmjiaolq4e7cjwkbrvy0bglja.lambda-url.us-west-1.on.aws?title=Siniestro&response=1`;
      let params = {
        title: title,
        id: id,
        response: response
      };
      return this.http.post(url, params).toPromise();
    }

    /**
    Set settings teams data
    @param object {string, string} params - Data for zoom settings
  */
    saveInclusivenessFeedback(id:string, title:string, response:number): Promise<object>{
      const url = `https://chfxszkbragabqdc4xuqdxcucq0etbcb.lambda-url.us-west-1.on.aws?title=Siniestro&response=1`;
      let params = {
        title: title,
        id: id,
        response: response
      };
      return this.http.post(url, params).toPromise();
    }

    modifyMeeting(index: number, columnMode: boolean, productive: number, score?: string) {
      this._meetings$.value[index].productiveColumnMode = columnMode;
      this._meetings$.value[index].productive = productive;
      if(score !== undefined) {
        this._meetings$.value[index].score = parseFloat(score);
      }
    }

    modifyMeetingInlusive(index: number, inclusiveColumnMode: boolean, inclusive: number, score?: string) {
      this._meetings$.value[index].inclusiveColumnMode = inclusiveColumnMode;
      this._meetings$.value[index].inclusive = inclusive;
      if(score !== undefined) {
        this._meetings$.value[index].score = parseFloat(score);
      }
    }




}
