import { Component, HostListener, OnInit, QueryList, ViewChildren } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MeetingsService } from '../../services/meetings/meetings.service';
import { Observable } from 'rxjs';
import { NgbdSortableHeader, SortEvent } from '../dashboard/sortable.directive';
import { MeetingStatusText } from 'src/enum/meeting-status.enum';
import { DatePickerRanges, DefaultRangeSelected } from 'src/app/configuration/datepicker-range';
import { Meeting } from 'src/app/models/meetings/meeting'; 
import { DateUtils } from 'src/app/utils/dateUtils';
import { DatePickerConfigService } from 'src/app/services/datepicker-config/datepicker-config.service';
import * as moment from 'moment-timezone';
import { MeetingTypeFaIcon, MeetingTypeText } from 'src/enum/meeting-type.enum';
import { MeetingSourceIcon, MeetingSourceText } from 'src/enum/meeting-source.enum';
import { faCheckCircle,  faTimesCircle, faMinusCircle, faThumbsUp, faThumbsDown} from '@fortawesome/free-solid-svg-icons';
import Swal from 'sweetalert2';


@Component({
  selector: 'app-meetings',
  templateUrl: './meetings.component.html',
  styleUrls: ['./meetings.component.scss']
})
export class MeetingsComponent implements OnInit{
  // Helper that show/hide grid or table of meetings
  showList = true;
  // Observable that contains and listen the list of members
  meetings$: Observable<Meeting[]>;
  // Indicates how many members per page will be displayed
  total$: Observable<number>;
  // Sets a directive to apply the funcionality of sorting
  @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;
  // Load meeting status by key as a string
  statusText = MeetingStatusText;
  // Period of time that user might need of defining by himself
  ranges = DatePickerRanges;
  // Default selected range
  selectedRangeDates = this.datepickerConfig.selectedDateRange;
  // User timezone
  userTimezone = DateUtils.getTimezoneHours();
  // Meeting type
  selectedMeetingType = this.datepickerConfig.selectedMeetingTypeFilter;
  //feedbackIcons
  //positiveFB = faCheckCircle;
  //negativeFB = faTimesCircle;
  positiveFB = faThumbsUp;
  negativeFB = faThumbsDown;
  naFB = faMinusCircle;
  selectorFb = 2;
  Swal = require('sweetalert2')
  offsetPopper = [{
    name: 'offset',
    options: {
      offset: [10, 20],
    }
  }]

  constructor(public service: MeetingsService,
    private datepickerConfig: DatePickerConfigService,
    private aroute: ActivatedRoute,
    private router: Router
  ) {
    this.service.itemsPerPage = 12;
    this.service.organizerFilter = false;
    this.meetings$ = service.meetings$;
    this.total$ = service.total$;
  }
  
  ngOnInit(){
    if(window.innerWidth < 992){
      this.showList = false;
    }
    if(this.aroute.snapshot.queryParams.hasOwnProperty('eventId')) {
      this.service.meetingId = this.aroute.snapshot.queryParams['eventId'];
    }
  }
  giveFeedback(id, feedback, title, index) {
    let message = `Are you sure you want to mark '${title}' as ${feedback ? 'productive': 'unproductive'}?`;
    this.swalConfirm(message, 'Meeting feedback', `${feedback ? 'success': 'error'}`, `${feedback ? '#4f90f5': '#EB4E69'}`, feedback, id, title, index, 0);
  }
  giveDiversity(id, feedback, title, index) {
    let message = `Are you sure you want to mark '${title}' as ${feedback ? 'inclusive': 'noninclusive'}?`;
    this.swalConfirm(message, 'Meeting feedback', `${feedback ? 'success': 'error'}`, `${feedback ? '#4f90f5': '#EB4E69'}`, feedback, id, title, index, 1);
  }
  openEvent(eventId:string, meetingId:string) {
    let eventRoute = `../event-detail/${eventId}`;
    this.router.navigate([eventRoute], {relativeTo: this.aroute});
  }
  /**
   * Receives params that indicates by which column should be sort
   * @param {column, direction}: SortEvent
   */
  onSort({column, direction}: SortEvent) {
    // resetting other headers
    this.headers.forEach(header => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });

    this.service.orderColumn = column;
    this.service.orderBy = direction;
  }

  swalConfirm(message:string, topic:string, type:any, confirmColor: string, feedback: boolean, id: string, title: string, index: number, mode: number) {
    Swal.fire({
      icon: type,
      title: topic,
      text: message,
      showCancelButton: true,
      confirmButtonColor: confirmColor,
      cancelButtonColor: '#d3d3d3',
      confirmButtonText: 'Confirm'
    }).then(result => {
      if(result.isConfirmed) {
        switch(mode) {
          case 0:
            this.service.saveFeedback(id, title, Number(feedback)).then((data:any) => {
              if(data.success) {
                this.swalAlert(`Your feedback for '${title}' has been saved.`, 'Thank you for your feedback!', 'success');
                this.service.modifyMeeting(index, false, Number(feedback), data.score);
              }
              else {
                this.swalAlert(`${data.error}`, 'There has been an error', 'error');
                this.service.modifyMeeting(index, false, Number(feedback));
              }
            })
            break;
          case 1:
            this.service.saveInclusivenessFeedback(id, title, Number(feedback)).then((data:any) => {
              if(data.success) {
                this.swalAlert(`Your feedback for '${title}' has been saved.`, 'Thank you for your feedback!', 'success');
                this.service.modifyMeetingInlusive(index, false, Number(feedback), data.score);
              }
              else {
                this.swalAlert(`${data.error}`, 'There has been an error', 'error');
                this.service.modifyMeetingInlusive(index, false, Number(feedback));
              }
            })
            break;
          default:
            this.swalAlert(`Unable mode to update feedback`, 'There has been an error', 'error');
            break;
        }
      }
    });
  }

  //swal alert type (successs, error, warning, info, question)
  swalAlert(message:string, topic:string, type:any) {
    Swal.fire({
      icon: type,
      title: topic,
      text: message
    });
  }

  // When the window's size is lowest than the specified
  // always shows the grid version
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    let width = event.target.innerWidth;
    if(width < 992){
      this.showList = false;
    }
  }

  /**
   * Updates the table on date changes
   */
  public updateTable() {     
    this.datepickerConfig.selectedDateRange = this.selectedRangeDates;
    this.datepickerConfig.selectedMeetingTypeFilter = this.selectedMeetingType;

    this.service.range_date = this.selectedRangeDates;
    this.service.meetingType = this.selectedMeetingType;
  }

  /**
   * Returns the correct font awesome class for the meeting type
   */
  public getMeetingTypeIcon(meetingType: string): string {
    if (meetingType !== '') {
      return MeetingTypeFaIcon[meetingType]
    }

    return '';
  }

  /**
   * Returns the meeting type text
   */
  public getMeetingTypeText(meetingType: string): string {
    if (meetingType !== '') {
      return MeetingTypeText[meetingType];
    }

    return '';
  }

  /**
   * Returns the filename of the meeting source
   */
  public getMeetingSourceIcon(meetingSource: string): string {
    if (meetingSource !== '') {
      return MeetingSourceIcon[meetingSource];
    }

    return '';
  }

  /**
   * Returns the meeting source text
   */
  public getMeetingSourceText(meetingSource: string): string {
    if (meetingSource !== '') {
      return MeetingSourceText[meetingSource];
    }

    return '';
  }

  /**
   * Returns a formatted topics string
   */
  public formatTopicsString(topics: string[]): string {
    return topics
      .map(x => x.replace(',', ''))
      .map(x => x.replace('_', ' '))
      .join(', ')
  }

  /**
   * Listens when the user clicks on the user meeting filter
   * @param meetingType meeting type string
   */
  public onClickMeetingTypeFilter(meetingType) {
    // resets filter if the user pressed the same
    if (meetingType === this.selectedMeetingType) {
      this.selectedMeetingType = '';
    } else {
      this.selectedMeetingType = meetingType;
    }

    this.updateTable();
  }
}
