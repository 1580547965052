import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './views/login/login.component';
import { ZoomMeetingComponent } from './views/zoom-meeting/zoom-meeting.component';
import { ZoomInstallComponent } from './views/zoom-install-app/zoom-install.component';
import { ZoomRedirectComponent } from './views/zoom-redirect/zoom-redirect.component';
import { Page404Component } from './views/page404/page404.component';
import { PrivacyComponent } from './views/privacy/privacy.component';
import { RedeemCodeComponent } from './views/redeem-code/redeem-code.component';

const routes: Routes = [
  { path: 'login', component: LoginComponent },
  { path: 'zoom-meeting', component: ZoomMeetingComponent },
  { path: 'auth', component: ZoomInstallComponent },
  { path: 'zoom-install', component: ZoomRedirectComponent },
  { path: 'redeem-code', component: RedeemCodeComponent },
  { path: 'privacy-policy', component: PrivacyComponent },
  { path: 'admin', loadChildren: () => import('./routing/panel/panel-routing.module').then( m => m.PanelRoutingModule ) },
  { path: 'member', loadChildren: () => import('./routing/member/member-routing.module').then( m => m.MemberRoutingModule ) },
  { path: 'onboarding', loadChildren: () => import('./routing/waitlist/waitlist-routing.module').then( m => m.WaitlistRoutingModule ) },
  { path: 'itadmin', loadChildren: () => import('./routing/itadmin/itadmin-routing.module').then( m => m.ITAdminRoutingModule ) },
  { path: '', redirectTo: '/login', pathMatch: 'full' },
  { path: '**', component: Page404Component }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: false })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
