import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { tap, debounceTime, switchMap, delay } from 'rxjs/operators';
import { Keyword } from 'src/app/classes/keyword/keyword';
import { Globals } from 'src/app/global';
import { RangeDate } from '../../configuration/datepicker-range';

@Injectable({
  providedIn: 'root'
})
export class KeywordsService {

  // Listen for new strings that user provides
  private _search$ = new Subject<void>();
  // Listen for new changes on datatable or grid
  private _loading$ = new BehaviorSubject<boolean>(true);
  // variable that store the import of Globals Class that will help us to retrieve the urls for every service
  globals = Globals;
  // Range of dates to filter
  private _range_date: RangeDate;
  // List of keywords
  private _keywords$ = new BehaviorSubject<Keyword[]>([]);

  constructor(private httpClient: HttpClient) {
    this._search$.pipe(
      tap(() => this._loading$.next(true)),
      switchMap(() => this._search()),
      tap(() => this._loading$.next(false))
    ).subscribe(result => {
      let keywords: Keyword[] = [];
      try{
        if(result['IsSuccess'] && result['Data']['Keywords']){     
          result['Data']['Keywords'].forEach(keyword => {
            let kw = new Keyword(keyword);
            keywords.push(kw);
          });
        }
      }catch(e){}
      this._keywords$.next(keywords);
    });
   }

  // Expose private Subjects as public Observables
  get keywords$() { return this._keywords$.asObservable(); }
  get loading$() { return this._loading$.asObservable(); }

  // Set range and get the new keywords
  public set range_date( range_date: RangeDate) {
    this._range_date = range_date;
    this._search$.next();
  }

  /**
   * Search for keywords in the API
   * @returns keywords: Keyword[]
   */
  public async _search(): Promise<object> {
    this._keywords$.next([]);
    let dates = {
      starts: this._range_date['startDate'].startOf('day').toISOString(),
      ends: this._range_date['endDate'].endOf('day').toISOString(),
      keyword: '1'
    };

    //const url = `${this.globals.availabilities.keywords}?${ new URLSearchParams(dates).toString() }`;
    const url = `${this.globals.meetings.query}?${ new URLSearchParams(dates).toString() }`

    let keywords;
    try{
      keywords = await this.httpClient.get(`${url}`).toPromise();
    }catch(e){
        console.log("Error retrieving data");
    }

    return keywords;
  }

}
