interface KeywordResponseI {
    Text: String;
    Count: Number;
    Percentage: Number;
}

export class Keyword {
    private _text: String;
    private _count: Number;
    private _percentage: Number;

    constructor( keyword: KeywordResponseI) {
        this._text = keyword.Text;
        this._count = keyword.Count;
        this._percentage = keyword.Percentage;
    }

    get text(){ return this._text};
    get count(){ return this._count};
    get percentage(){ return this._percentage};
}
