// Class that allows the creation of new instances
export class Transcript {
  speaker: string;
  text: string;
  sentiment: string;
  constructor(data){
    this.speaker = data.speaker_label;
    this.text = data.Text.charAt(0).toUpperCase() + data.Text.slice(1);
    this.sentiment = data.Sentiment?.sentiment;
  }
}
