import { Component } from '@angular/core';
import { Observable, Subject, combineLatest } from 'rxjs';
import { debounceTime, delay, distinctUntilChanged, map } from 'rxjs/operators';
import { SearchMatchesService } from 'src/app/services/search-matches/search-matches.service';
import { Transcript } from '../../models/transcript/transcript';
import { ActivatedRoute } from '@angular/router';
import { Meeting } from 'src/app/models/meetings/meeting';
import { Attendees } from 'src/app/models/attendees/attendees';
import { ToastrService } from 'ngx-toastr';
import { MeetingDetailService } from '../../services/meetring-detail/meeting-detail.service';
import { MeetingTypeFaIcon, MeetingTypeText } from 'src/enum/meeting-type.enum';
import { MeetingSourceIcon, MeetingSourceText } from 'src/enum/meeting-source.enum';
import { faCheck, faMinus, faChevronCircleLeft, faLightbulb } from '@fortawesome/free-solid-svg-icons';
import { ScoreDetailsComponent } from 'src/app/components/scoreDetails/score-details.component';
import { TipsDetailsComponent } from 'src/app/components/tipsDetails/tips-details.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-meeting-detail',
  templateUrl: './meeting-detail.component.html',
  styleUrls: ['./meeting-detail.component.scss']
})
export class MeetingDetailComponent{
  // List of comments between members of a meeting
  chats: Transcript[];
  // Word that would be search through conversation
  searchText = "";
  // Number of string that matches between search text and conversation
  totalMatches = 0;
  // Listen every typed text
  txtQueryChanged: Subject<string> = new Subject<string>();
  // Main id that provides the general information of event
  eventId = "";
  // Id that allows to get the transcription
  meetingId = "";
  // Information of event
  eventDetail: Meeting;
  // Loading detail of event
  loadingDetail = false;
  // Loading detail of event
  internalAttendees: Attendees[] = [];
  // Loading detail of event
  externalAttendees: Attendees[] = [];
  //checkIcon = faCheckCircle;
  //crossIcon = faTimesCircle;
  //unknownIcon = faQuestionCircle;
  attendeeStatus = ['Missed', 'Attended', 'Unknown', 'Not Accepted', 'Accepted', 'Maybe'];
  //role
  role = '';
  email = '';
  validPicture = true;
  
  radius = 30;
  circumference = 0;
  dashoffset = 0;
  
  checkIcon = faCheck;
  crossIcon = faMinus;
  backIcon = faChevronCircleLeft;
  tipIcon = faLightbulb;

  constructor(private meetingDetailService: MeetingDetailService,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private searchMatchesService: SearchMatchesService,
    private toastService: ToastrService) {

    this.loadMeetingDetail();

    // receives number of occurences by comment
    this.searchMatchesService.numberMatches$.
    pipe(delay(200)).
    subscribe( counter => {
      this.totalMatches+= counter.count;
    });

    // Each time the string is typed, it's filter to compare on time and
    // it should be diferent with the previous string sent
    this.txtQueryChanged
     .pipe(debounceTime(200), distinctUntilChanged())
     .subscribe(model => {
        this.totalMatches = 0;
        this.searchText = model;
    });
  }

  /**
   * Listen for new changes on input search
   * @param query Word new word ready to search
   */
  onFieldChange(query:string){
    this.txtQueryChanged.next(query);
  }

  invalidPicture(): void {
    this.validPicture = false;
  }

  // Loads meeting detail and if event exist load is too
  loadMeetingDetail():void{
    this.route.paramMap.subscribe(params => {
      //JSON.parse(localStorage.getItem('traiyUser')['role']
      let user = JSON.parse(localStorage.getItem('traiyUser'));
      this.role = user['role'];
      this.email = user['email'];
      this.eventId = params.get('eventId');
      this.meetingId = params.get('meetingId');
      if(this.meetingId == '') {
        this.meetingId = null;
      }
      this.loadingDetail = true;

      this.meetingDetailService.meetingDetail(this.eventId, this.meetingId)
      .then(data => {
        this.eventDetail = new Meeting(data['MeetingDetails']);
        this.circumference = this.radius * 2 * Math.PI;
        this.dashoffset = this.circumference * ((this.eventDetail.score/100));
        this.groupAttendees(data);
        if(this.meetingId != null){
          return data; //this.meetingDetailService.getTranscriptByMeeting(this.meetingId);
        }
        return data;
      })
      .then( data => {
        console.log('entro busca chat', data);
        if(data.MeetingDetails && data.MeetingDetails.Conversations){
          console.log('encontro chat chat');
          this.chats = data.MeetingDetails.Conversations.map(chat => new Transcript(chat));
          console.log(this.chats.length);
        }
      })
      .catch(err => {
        console.log('error', err);
        this.toastService.error("Can't load meeting detail.", "Meeting detail");
      }).finally(() => {
        this.loadingDetail = false;
      });
    }); 
  }

  //Show Score Details
  showDetails(){
    if(this.eventDetail.scoreDetails == null) {
      this.toastService.error("No score to display", "Score details");
    }
    else {
      // Opens dialog steps-direction with steps passed as data array
      const dialogRef = this.dialog.open(ScoreDetailsComponent, {
        panelClass: (window.innerWidth < 768) ? '':'score-details-dialog',
        width: '450px',
        height: '40%',
        data: {scoreDefinition: this.eventDetail.scoreDetails}
      });
    }
  }

  //Show Meeting Tips
  showTips(){
    if(this.eventDetail.meetingTips == null) {
      this.toastService.error("No tips to display", "Tips detail");
    }
    else {
      // Opens dialog steps-direction with steps passed as data array
      const dialogRef = this.dialog.open(TipsDetailsComponent, {
        width: '60%',
        height: 'auto',
        minHeight: '200px',
        data: {tips: this.eventDetail.meetingTips}
      });
    }
  }

  // Process Attendees in Internal and External
  groupAttendees(event):void{
    let domain = JSON.parse(localStorage.getItem('traiyUser'))['email'].split("@")[1];
    event.MeetingDetails.Attendees.forEach(attended => {
      let temporalAttendee: Attendees;
      if(attended['email'] !== null) {  
        if(attended['email'].includes(domain)) {
          temporalAttendee = new Attendees(attended, 1);
          this.internalAttendees.push(temporalAttendee);
        }
        else {
          temporalAttendee = new Attendees(attended, 1);
          this.externalAttendees.push(temporalAttendee);
        }
      }
      else {
        temporalAttendee = new Attendees(attended, 1);
        this.externalAttendees.push(temporalAttendee);
      }
    });
    if(event.MeetingDetails.Missed) {
      event.MeetingDetails.Missed.forEach(attended => {
        let temporalAttendee: Attendees;
        if(attended['email'] !== null) {  
          if(attended['email'].includes(domain)) {
            temporalAttendee = new Attendees(attended, 0);
            this.internalAttendees.push(temporalAttendee);
          }
          else {
            temporalAttendee = new Attendees(attended, 2);
            this.externalAttendees.push(temporalAttendee);
          }
        }
        else {
          temporalAttendee = new Attendees(attended, 2);
          this.externalAttendees.push(temporalAttendee);
        }
      });
    }
  }

  /**
   * Returns the correct font awesome class for the meeting type
   */
  public getMeetingTypeIcon(meetingType: string): string {
    if (meetingType !== '') {
      return MeetingTypeFaIcon[meetingType]
    }

    return '';
  }

  /**
   * Returns the text of the meeting type
   */
  public getMeetingTypeText(meetingType: string): string {
    if (meetingType !== '') {
      return MeetingTypeText[meetingType];
    }

    return '';
  }

  /**
   * Returns the text of the meeting source
   */
  public getMeetingSourceText(meetingSource: string): string {
    if (meetingSource !== '') {
      return MeetingSourceText[meetingSource];
    }

    return '';
  }

  /**
   * Returns the filename of the meeting source
   */
  public getMeetingSourceIcon(meetingSource: string): string {
    if (meetingSource !== '') {
      return MeetingSourceIcon[meetingSource];
    }

    return '';
  }


}
