import { Self } from "@angular/core";
import { Role } from "src/enum/Role";

// Class that represents an instance for logged user
export class User {
  id: string;
  token: string;
  firstname: string;
  lastname: string;
  email: string;
  picture: string;
  role: string;
  organizationId: string;
  organizationName: string;
  occupationId: string;
  occupationName: string;
  departmentId: string;
  departmentName: string;
  salary: number;
  yearSalary: number;
  schedule: string;
  organizationOnboarding: boolean;
  userOnboarding: boolean;
  timezoneString: string;
  timezoneOffset: number;
  userMode: number;

  constructor(data){
    this.id = data.Id;
    this.token = data.Auth_token ?? data.Token;
    this.firstname = data.First_Name ?? data.FirstName;
    this.lastname = data.Last_Name ?? data.LastName;
    this.email = data.Email;
    this.picture = data.Picture;
    this.role = data.RoleName;
    this.organizationId = data.OrganizationId ?? data.organizationId;
    this.organizationName = data.CompanyName ?? data.organizationName;
    this.occupationId = data.OccupationId;
    this.occupationName = data.OccupationName ?? data.Occupation;
    this.departmentId = data.DepartmentId ? data.DepartmentId : null;
    this.departmentName = data.DepartmentName ?? data.Department;
    this.salary = data.Salary;
    this.yearSalary = data.YearSalary;
    this.schedule = data.Schedule;
    this.organizationOnboarding = data.OrganizationOnboarding;
    this.userOnboarding = data.UserOnboarding;
    this.timezoneString = data.TimezoneString;
    this.timezoneOffset = data.timezoneOffset;
    this.userMode = data.hasOwnProperty('userMode') ? data.userMode : 0;
  }

  /**
   * Creates a new instance of user using a local storage object of the user session
   * @param localUser A local storage User
   * @returns New User
   */
  public static newUserFromLocalStorage(localUser): User {

    if(!localUser) {
      return null;
    }
    
    let newUser = {
      Id: localUser.id,
      Token: localUser.token,
      FirstName: localUser.firstname,
      LastName: localUser.lastname,
      Email: localUser.email,
      Picture: localUser.picture,
      RoleName: localUser.role,
      organizationId: localUser.organizationId,
      organizationName: localUser.organizationName,
      OccupationId: localUser.occupationId,
      Occupation: localUser.occupationName,
      DepartmentId: localUser.departmentId,
      Department: localUser.departmentName,
      Salary: localUser.salary,
      YearSalary: localUser.yearSalary,
      Schedule: localUser.schedule,
      OrganizationOnboarding: localUser.organizationOnboarding,
      UserOnboarding: localUser.userOnboarding,
      TimezoneString: localUser.timezoneString,
      TimezoneOffset: localUser.timezoneOffset,
      userMode: localUser.hasOwnProperty('userMode') ? localUser.userMode : 0
    }
    
    return new User(newUser);
  }

  /**
   * Returns if the user is admin or not
   * @returns 
   */
  public isAdmin(): boolean {
    return this.role == Role.Admin;
  }

}
