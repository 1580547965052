import { Directive, Input } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[disableControl]'
})
export class DisableControlDirective {

  // Indicates if input should be enable or not
  @Input() disableControl: boolean;

  constructor( private ngControl : NgControl ) {
  }

  /**
   * Listen for inputs with this directive, in order to set inputs state
   * @param {object} changes - Inputs properties
   */
  ngOnChanges(changes: object) {
    if (changes['disableControl']) {
    const action = this.disableControl ? 'disable' : 'enable';
    this.ngControl.control[action]();
    }
  }

}
