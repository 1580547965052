<header>
    <div class="container">
        <div class="row">
            <div class="col">
                <a class="navbar-brand" [routerLink]="['/']">
                    <img src="../../../assets/img/tariy-header.png" alt="" class="main-logo d-inline-block align-top">
                  </a>
            </div>
        </div>
    </div>
</header>

<section>
    <div class="container small-container content-page p-5">
        <div class="row">
            <div class="col">
                <h1>Privacy Policy</h1>
            </div>
        </div>
        <div class="row">
            <div class="col content">
                <p><b>Effective Date: March 1, 2021</b></p>
                <p>Welcome to Tariy, LLC (“Tariy,” “we,” “us,” “our”) product offerings (the “TARIY Product”) or general marketing and recruiting website (the “Website”). This Privacy Policy is intended to help users understand how we collect, use, disclose, and protect the information we receive from users of the TARIY Product and Website, including users in the European Economic Area (“EEA”) (collectively, “you” or “user”). For the purposes of compliance with the EU General Data Protection Regulation (“GDPR”), we are the “controller” of Personal Data (i.e., Personal Information) we receive from you.</p>
                <p>Within this Privacy Policy, users may be referred to as Data Subjects. A Data Subject means an identified or identifiable natural person in the EEA as set forth in the GDPR.</p>
                
                <p><b>Scope</b></p>
                <p>This Privacy Policy applies to the TARIY Product and the Website, which includes tariy.ai, its subdomains, all of the websites and internet properties owned or operated by us, regardless of the medium in which the Website is accessed by a user (e.g., computer browser, mobile browser, etc.), and all activities, services, features, or resources offered by TARIY.</p>

                <p><b>Types of Information Collected</b></p>
                <p><b>Account and Personal Information</b></p>
                <p>We collect personal information to provide the information or services requested by an individual. “Personal Information” refers to any information relating to an identified or identifiable natural person who is the subject of the information. Personal Information may include, but is not limited to, your first and last name, email address, telephone number, address, IP address, financial information, and any other personal or preference information that you provide to us.</p>

                <p><b>Usage Information</b></p>
                <p>When you use and navigate the TARIY Product or Website, we may collect additional information from you that may not, by itself, reasonably identify you as the source (“Usage Information”). Usage Information may include, without limitation, the type of computer you are using, the type of internet browser you are using, the location from which you are using the TARIY Product or visiting the Website, the type of computer operating system you are using with the TARIY Product or to visit the Website, the domain name of the website from which you linked to the Website, the internet service provider utilized, or the areas of the TARIY Product or Website you visited and the actions you performed. We will take reasonable measures to ensure that the Usage Information we collect is not personally identifiable and may not later be easily used to identify you as otherwise required by applicable law.</p>

                <p><b>Information About Your Accounts on Third-Party Services</b></p>
                <p>TARIY provides the ability to log in to the TARIY Product using your Google or Microsoft account. If you authenticate yourself using any of these services, you grant us access to your email address, and, if available, your name, photo, and username associated with them. We do not receive your password. You will also grant us access to view events on all calendars you have access to in Google Calendar and to view your calendars in Google Calendar.</p>

                <p><b>Children Under 13</b></p>
                <p>We do not market to, and do not knowingly collect, any Personal Information from or about a child under the age of 13 without the consent of the child’s parent or legal guardian. If we discover that we have inadvertently collected information from a child under 13 years of age through the TARIY Product or Website, we will promptly take all reasonable measures to delete that data from our systems.</p>

                <p><b>How Information is Collected</b></p>
                <p><b>Information You Submit to Us</b></p>
                <p>When you visit the TARIY Product or Website, we may collect Personal Information you voluntarily provide to us.</p>

                <p><b>Cookies</b></p>
                <p>We store certain information, including Usage Information, from your browser using “cookies.” Cookies are small text files that are stored on a user’s computer hard drive. They are used to keep track of and store information about users. The information that is collected through cookies is handled in the same manner as other information collected here. If you prefer not to receive cookies, you can set your browser to warn you before accepting cookies and refuse the cookie when your browser alerts you to its presence. You can also refuse all cookies by turning them off in your browser. Please be aware, however, that certain features of the TARIY Product and Website may be unavailable if your browser is not set to support or enable cookies.</p>

                <p><b>Information from Other Sources</b></p>
                <p>We might receive information about you from other sources such as publicly available sources and information that we receive from our partners and other third parties. Since we may receive information about you from third parties whose privacy practices may differ from those described in this Privacy Policy, we do not endorse or make any representations or warranties concerning, and will not in any way be liable for, any informational content, products, services, software, or other materials available through third parties. Your use of third parties’ services and/or third-party websites is governed by and subject to the terms and conditions of those third parties and/or third-party websites. We encourage you to carefully review the privacy policies and statements of such third parties and/or third-party websites.</p>

                <p><b>Use of Information Collected</b></p>
                <p>Except as set forth herein, we do not sell, rent, or trade your information to third parties. We use your information for various general purposes to help enhance your experience as described below:</p>
                
                <ul>
                    <li>In accordance with our agreement with you.</li>
                    <li>Facilitate your transactions with TARIY.</li>
                    <li>Respond to your inquiries and requests.</li>
                    <li>Compare and review your Account and Personal Information for errors, omissions, and accuracy.</li>
                    <li>Prevent and detect fraud or abuse.</li>
                    <li>Improve the TARIY Product, Website, service, product offerings, marketing and promotional efforts, and overall customer experience.</li>
                    <li>Identify your product and service preferences.</li>
                    <li>Understand our customer demographics, preferences, interests, and behavior.</li>
                    <li>Contact you (via email, postal mail, or telephone) regarding products and services that we believe may be of interest to you.</li>
                    <li>Diagnose problems with the TARIY Product, Website, and servers, administer and tune the operation of the TARIY Product and Website, analyze trends, track traffic patterns, gather demographic information for aggregate use, and track the date and duration of each session within the TARIY Product.</li>
                    <li>Where required by law, we will ask you for your prior consent.</li>
                </ul>

                <p></p>

                <p><b>Sharing with Third Parties and Affiliates</b></p>
                <p>We will not share, sell, or distribute your information with any third parties, including any of our affiliated companies, members, officers, directors, shareholders, subsidiaries, agents, or representatives, except as provided in the following circumstances:</p>

                <p><b>Service Providers</b></p>
                <p>We may employ third-party service providers to perform functions or carry out advertising or marketing on our behalf. They may have access to Personal Information needed to perform their functions, but they may not use it for other purposes.</p>

                <p><b>Legal Obligations</b></p>
                <p>From time to time, we may be required to share Personal Information in response to a valid court order, subpoena, government investigation, or as otherwise required by law. We also reserve the right to report to law enforcement agencies any activities that we, in good faith, believe to be unlawful or related to an alleged unlawful activity.</p>

                <p><b>Protection of TARIY and Others</b></p>
                <p>We may release your information when we believe release is appropriate to comply with the law or any rule, regulation, government or court order; enforce or apply our User Terms (<a href="https://www.tariy.ai/terms">https://www.tariy.ai/terms</a>) and other agreements; or protect the rights, property, or safety of TARIY, our members, affiliated companies, our users, or others.</p>

                <p><b>Sale or Acquisition</b></p>
                <p>If we become involved in a transaction involving the sale or license of our assets, such as a merger or acquisition, or if TARIY is transferred to another company, we may disclose and/or transfer your information as part of the transaction. If the surviving entity is not TARIY, your information may be used pursuant to the surviving entity’s privacy policies, which may be different from this Privacy Policy.</p>

                <p><b>With Your Consent</b></p>
                <p>Other than as set out above, you will receive notice when information about you might go to third parties and you will have an opportunity to choose not to share the information.</p>

                <p><b>Aggregated Data</b></p>
                <p>We may also derive, disclose, and use information and data about you that is not personally identifiable, such as, for example, aggregated and statistical information about TARIY customers.</p>

                <p><b>How to Withdraw Your Consent</b></p>
                <p>At any time, Data Subjects from the EEA may withdraw consent you have provided to us for using, disclosing, or otherwise processing your Personal Information. You may withdraw your consent by communicating your request at the Contact Information provided below.</p>
                <p>Please note that your withdrawal of consent to process certain Personal Information about you (1) may limit our ability to deliver services to you and (2) does not affect the lawfulness of our processing activities based on your consent before its withdrawal. Note that even after withdrawing consent, IEA may use, disclose, or otherwise process your Personal Information if required to do so by law.</p>

                <p><b>Legal Rights</b></p>
                <p><b>EEA Data Subjects Rights</b></p>
                <p>Under certain circumstances, Data Subjects from the EEA may have the following rights under the GDPR:</p>

                <ul>
                    <li>To access the Personal Information we maintain about you</li>
                    <li>To be provided with information about how we process your Personal Information</li>
                    <li>To correct your Personal Information</li>
                    <li>To have your Personal Information erased</li>
                    <li>To object to or restrict how we process your Personal Information</li>
                    <li>To request your Personal Information to be transferred to a third-party</li>
                    <li>To exercise the above rights, please contact us at the information provided below. We will consider and process your request within a reasonable period of time. Please be aware that under certain circumstances, the GDPR may limit your exercise of these rights.</li>
                </ul>
                
                <p></p>

                <p><b>California Resident Rights</b></p>
                <p>California Civil Code Section 1798.83 permits our customers who are California residents to request and obtain from us once a year, free of charge, information about the personal information (if any) we disclosed to third parties for direct marketing purposes in the preceding calendar year. If applicable, this information would include a list of the categories of Personal Information shared and the names and addresses of all third parties with which we shared information in the immediately preceding calendar year. To make such a request, please contact us at the information provided below.</p>

                <p><b>Security</b></p>
                <p>We take reasonable precautions to protect the information that is either available from or collected by the TARIY Product and Website. We have taken steps designed to safeguard the TARIY Products and Website’s integrity, telecommunications, and computing infrastructure, including but not limited to, authentication, monitoring, auditing, and encryption. Unfortunately, no data transmitted over or accessible through the internet can be guaranteed to be completely secure. As a result, we cannot ensure or warrant that your information will be secure from misappropriation by hackers, nefarious or criminal activities, or in the event of a failure of computer hardware, software, or a telecommunications network.</p>

                <p><b>Links</b></p>
                <p>The TARIY Product and Website may contain links to, or information about, other products that are outside our control or custody. We are not responsible for the content or privacy practices of other third-party products and suggest you review their privacy statements to understand how your information is used or shared by those other products. We do not endorse or make any representations or warranties concerning, and will not in any way be liable for, any informational content, products, services, software, or other usage or materials available on other products, even if one or more pages of the other products is/are framed within the TARIY Product or Website.</p>

                <p><b>Modification</b></p>
                <p>This Privacy Policy was last modified on the date first printed above. We reserve the right to update this Privacy Policy from time to time, at any time, and without notice. You should check this page periodically for changes. We will not lessen your rights under this Privacy Policy without your explicit consent.</p>

                <p><b>Accessing and Updating Information</b></p>
                <p>If you ever wish to access your information or to have your information updated or modified, you may do so by contacting us at the information provided below. To cancel an account you may have with us and have your information returned to you, please also contact us at the information provided below. We will make commercially reasonable efforts to handle requests to update or modify your information within thirty (30) days.</p>
                <p>If you would like to opt-out of receiving direct marketing communications from us, you may do so by following any instructions included in the communication or by contacting us at the information provided below.</p>

                <p><b>How to File a Complaint</b></p>
                <p>You may file a complaint regarding this Privacy Policy or our privacy practices by communicating with us using the Contact Information provided below.</p>
                <p>Additionally, Data Subjects from the EEA may file a complaint with EU data protection authorities (“DPAs”). A list of DPAs from the European Commission may be found here: <a target="_blank" href="http://ec.europa.eu/newsroom/article29/document.cfm?action=display&doc_id=50061.">http://ec.europa.eu/newsroom/article29/document.cfm?action=display&doc_id=50061.</a></p>

                <p><b>TARIY Contact Information</b></p>
                <p>If you would like to contact us with questions or comments concerning the accuracy and/or privacy of your information, or if you believe that we have not adhered to this Privacy Policy, please contact us at info@tariy.ai or by sending a letter to:</p>

                <p>TARIY</p>
                <p>ATTN: Support</p>
                <p>2835 E Pershing Ave</p>
                <p>Phoenix AZ 85032</p>

            </div>
        </div>
    </div>
</section>

