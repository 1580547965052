 
export class MathUtils {
    /**
   * This method fix numbers into two decimals
   * if is 1 return 1
   * if is 1.0 return 1
   * if is 1.151551 return 1.15
   * if is not a number return 0
   * */
  static fixNumbers(number) {

    if (isNaN(number)) {
      return 0;
    }
    
    let num = number.toString();
    const dec = num.split('.')[1];//if not exist set undefined
    if (dec) {
      if (Number(dec) == 0)//this is for remove zero from example 1.0
      {
        return Number(num).toFixed(0);
      }

      const len = dec.length > 2 ? 2 : dec.length
      return Number(num).toFixed(len)
    }
    return num;
  }
}
