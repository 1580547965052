import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse
} from '@angular/common/http';
import { Observable, of } from 'rxjs';
import {tap, share } from 'rxjs/operators';
import { AuthService } from '../../services/auth/auth.service';
import { environment } from '../../../environments/environment';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(private authService: AuthService) {}

  private cache: Map<string, any> = new Map()
  minutes = 10;
  // Clones the current request then add headers like token
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    try{
      let currentUser = this.authService.currentUserValue;
      // Authorization header
      const headers = { Authorization: `${currentUser ? currentUser.token: ''}` };
      // Add headers for working properly when browser is Internet Explorer
      if (this.isIE()) {
          headers['Cache-Control'] = 'no-cache';
          headers['Pragma'] = 'no-cache';
          headers['Expires'] = 'Sat, 01 Jan 2000 00:00:00 GMT';
      }

      if (this.authService.isAuthenticated()) {
        request = request.clone({
          setHeaders: headers
        });
      }
      if(request.method !== "GET" && !request.urlWithParams.includes(`${environment.api_url}meeting/meetings`) && !request.urlWithParams.includes(`${environment.api_url}meeting/meetings/details`)) {
        this.cleaner(request);
        return next.handle(request);
      }
      else {
        const cachedResponse = this.cache.get(request.urlWithParams)
        if(cachedResponse) {
          if(cachedResponse.valid < Date.now()) {
            this.cache.delete(request.urlWithParams);
            return next.handle(request).pipe(
              tap(event =>{
                if(event instanceof HttpResponse) {
                  this.cache.set(request.urlWithParams, {'valid':(Date.now() + (1000*60*10)), 'request':[{'body': request.serializeBody(), 'response': event.clone()}]})
                }
              }),
              share()
            );
          }
          let bodiesFound = false;
          let response;
          cachedResponse.request.forEach(search => {
            if(search.body == request.serializeBody()) {
              bodiesFound = true;
              response = search.response.clone();
            }
          });
          if(!bodiesFound) {
            return next.handle(request).pipe(
              tap(event =>{
                if(event instanceof HttpResponse) {
                  let bodies = cachedResponse.request;
                  bodies.push({'body': request.serializeBody(), 'response': event.clone()});
                  this.cache.set(request.urlWithParams, {'valid':(Date.now() + (1000*60*10)), 'request':bodies})
                }
              }),
              share()
            );
          }
          return of(response);
        }
        else {
          return next.handle(request).pipe(
            tap(event =>{
              if(event instanceof HttpResponse) {
                this.cache.set(request.urlWithParams, {'valid':(Date.now() + (1000*60*this.minutes)), 'request':[{'body': request.serializeBody(), 'response': event.clone()}]})
              }
            }),
            share()
          );
        }
      }
    }catch(err){
      console.log(err);
    }

  }

  // Returns true when client browser is IE
  isIE(): boolean {
    const ua = navigator.userAgent;
    /* MSIE used to detect old browsers and Trident used to newer ones*/
    return ua.indexOf('MSIE ') > -1 || ua.indexOf('Trident/') > -1;
  }

  // Cleans cache if specific url is called
  cleaner(request: HttpRequest<any>) {
    let urlList = [{'requestUrl':`${environment.api_url}auth/api/organization`, 'cacheUrl':`${environment.api_url}auth/api/organization`}, 
    {'requestUrl':`${environment.api_url}auth/api/users/`, 'cacheUrl':`${environment.api_url}auth/api/tables/users?page=1&itemsPerPage=10&searchText=&orderColumn=&orderBy=&`},
    {'requestUrl':`${environment.api_url}zoom-oauth/msteams`, 'cacheUrl':`${environment.api_url}zoom-oauth/msteams/status`},
    {'requestUrl':`${environment.api_url}zoom-oauth/`, 'cacheUrl':`${environment.api_url}zoom-oauth/status`}]
    urlList.every(list => {
      if(request.urlWithParams.includes(list.requestUrl) && (request.method == "PUT" || request.method == "POST")) {
        this.cache.delete(list.cacheUrl)
        return false;
      }
      return true;
    });
  }

}
