import { NgModule  } from '@angular/core';
import { DecimalPipe } from '@angular/common';
import { MatTooltipModule} from '@angular/material/tooltip';
import { MatBadgeModule} from '@angular/material/badge';
import { MatChipsModule} from '@angular/material/chips';
// Components
import { PanelComponent } from '../../views/layouts/panel/panel.component';

// External plugins
import { NgbdSortableHeader } from 'src/app/views/dashboard/sortable.directive';
import { SidebarAccordionModule } from 'ng-sidebar-accordion';
import { NgxEchartsModule } from 'ngx-echarts';

// Pipes
import { HighlightSearchPipe } from 'src/app/pipes/highlight-search/highlight-search.pipe';
//import { RelativePercentagePipe } from 'src/app/pipes/relative-percentage/relative-percentage.pipe';

// Services
import { MeetingsService } from 'src/app/services/meetings/meetings.service';

// Pages
import { MeetingDetailComponent } from 'src/app/views/meeting-detail/meeting-detail.component';
import { MeetingsComponent } from '../../views/meetings/meetings.component';
import { NudgesComponent } from '../../views/nudges/nudges.component';
import { AITrainingComponent } from '../../views/ai-train/ai-training.component';
//import { RecommendationComponent } from '../../views/recommendations/reccomendation.component';
//import { BenchmarkComponent } from '../../views/benchmark/benchmark.component';
import { MyTeamComponent } from '../../views/my-team/my-team.component';
import { UserListComponent } from '../../views/users/user-list/user-list.component';
import { DashboardComponent } from '../../views/dashboard/dashboard.component';
import { InsightsComponent } from '../../views/insights/insights.component';
import { ReportsComponent } from '../../views/reports/reports.component';
import { OnboardingInvitedComponent } from 'src/app/views/onboarding-invited/onboarding-invited/onboarding-invited.component';
import { OnboardingOrgComponent } from 'src/app/views/onboarding-org/onboarding-org/onboarding-org.component';
import { ProfileComponent } from 'src/app/views/profile/profile.component';

// Components
import { NewsComponent } from 'src/app/components/news/news.component';

// Custom modules
import { SharedModule } from 'src/app/shared/shared.module';

@NgModule({
    exports: [DashboardComponent],
    declarations: [
      PanelComponent,
      DashboardComponent,
      InsightsComponent,
      ReportsComponent,
      MeetingsComponent,
      NudgesComponent,
      AITrainingComponent,
      //RecommendationComponent,
      //BenchmarkComponent,
      MyTeamComponent,
      MeetingDetailComponent,
      NewsComponent,
      NgbdSortableHeader,
      HighlightSearchPipe,
      //RelativePercentagePipe,
      UserListComponent,
      OnboardingInvitedComponent,
      OnboardingOrgComponent,
      ProfileComponent
    ],
    imports:[
      SidebarAccordionModule,
      SharedModule,
      MatTooltipModule,
      MatBadgeModule,
      MatChipsModule,
      NgxEchartsModule.forRoot({
        echarts: () => import('echarts'),
      }),
    ],
    providers:[
      DecimalPipe,
      MeetingsService
    ],
    entryComponents: [
      NewsComponent,
    ],
    bootstrap:[DashboardComponent]
})

export class PanelModule {}
