import * as moment from "moment";

export interface RangeDate {
  startDate,
  endDate
}

interface DateRange {
  TODAY: RangeDate,
  YESTERDAY: RangeDate,
  LAST_7_DAYS: RangeDate,
  THIS_MONTH: RangeDate,
  LAST_MONTH: RangeDate,
  THIS_WEEK: RangeDate,
  LAST_WEEK: RangeDate,
  THIS_QUARTER: RangeDate,
  LAST_QUARTER: RangeDate,
  THIS_YEAR: RangeDate,
  LAST_YEAR: RangeDate
}

export const DatePickerRanges: any = {   
  'Today': [moment().startOf('day'), moment().endOf('day')],
  'Yesterday': [moment().subtract(1, 'days').startOf('day'), moment().subtract(1, 'days').endOf('day')],
  'Tomorrow': [moment().add(1, 'days').startOf('day'), moment().add(1, 'days').endOf('day')],
  'Last Week': [moment().subtract(1, 'week').startOf('week'), moment().subtract(1, 'week').endOf('week')],
  'This Week': [moment().startOf('week'), moment().endOf('week')],
  'Next Week': [moment().add(1, 'week').startOf('week'), moment().add(1, 'week').endOf('week')],
  //'Last 7 Days': [moment().subtract(6, 'days').startOf('day'), moment().endOf('day')],
  'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
  'This Month': [moment().startOf('month'), moment().endOf('month')],
  'Next Month': [moment().add(1, 'month').startOf('month'), moment().add(1, 'month').endOf('month')],
  //'This Quarter': [moment().startOf('quarter'), moment().endOf('quarter')],
  //'This Year': [moment().startOf('year'), moment().endOf('year')],
};

/**
 * Predefined Date Range
 */
export const DefaultRangeSelected: DateRange = {  
  TODAY: { "startDate": moment(), "endDate": moment()},
  YESTERDAY: { "startDate": moment().subtract(1, 'days'), "endDate": moment().subtract(1, 'days')},
  LAST_7_DAYS: { "startDate": moment().subtract(6, 'days'), "endDate": moment()},
  THIS_MONTH: { "startDate": moment().startOf('month'), "endDate": moment().endOf('month')},
  LAST_MONTH: { "startDate": moment().subtract(1, 'month').startOf('month'), "endDate": moment().subtract(1, 'month').endOf('month')},
  THIS_WEEK: { "startDate": moment().startOf('week'), "endDate": moment().endOf('week') },
  LAST_WEEK: { "startDate": moment().subtract(1, 'week').startOf('week'), "endDate": moment().subtract(1, 'week').endOf('week') },
  THIS_QUARTER: { "startDate": moment().startOf('quarter'), "endDate": moment().endOf('quarter') },
  LAST_QUARTER: { "startDate": moment().subtract(1, 'quarter').startOf('quarter'), "endDate": moment().subtract(1, 'quarter').endOf('month')},
  THIS_YEAR: { "startDate": moment().startOf('year'), "endDate": moment().endOf('year') },
  LAST_YEAR: { "startDate": moment().subtract(1, 'year').startOf('year'), "endDate": moment().subtract(1, 'year').endOf('year')},
}

