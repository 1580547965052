import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { TariyTips } from 'src/app/models/tariy-tips/tariy-tips';
import { TariyTipsService } from 'src/app/services/tariy-tips/tariy-tips.service';

@Component({
  selector: 'app-recommendations',
  templateUrl: './recommendations.component.html',
  styleUrls: ['./recommendations.component.scss']
})
export class RecommendationsComponent implements OnInit {

  // Advices provided by service
  tariyTips: TariyTips[] = [];
  loading = false;

  constructor(private tipsService: TariyTipsService,
    private toastService: ToastrService) {

    // Get list of advices
    this.loading = true;
    this.tipsService.getAdvices().then( data => {
      let tips = data['Data'].map(item => new TariyTips(item))
      this.tariyTips = this.tariyTips.concat(tips);
    }).catch( err => {
      this.tariyTips = [];
      this.toastService.error("Can't load list of advices", "TariyTips");
    }).finally(()=>{
      this.loading = false;
    })
  }

  ngOnInit(): void {
  }

}
