import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";

// Layout
import { MemberPanelComponent } from '../../views/layouts/member-panel/member-panel.component';
// Pages
import { MemberProfileComponent } from "src/app/views/member-profile/member-profile.component";
import { Page404Component } from "src/app/views/page404/page404.component";
import { Role } from "src/enum/Role";
import { AuthGuard } from "src/app/guards/auth/auth.guard";
import { MeetingDetailComponent } from "src/app/views/meeting-detail/meeting-detail.component";


import { DashboardComponent } from "src/app/views/dashboard/dashboard.component";
import { MeetingsComponent } from "src/app/views/meetings/meetings.component";
import { NudgesComponent } from "src/app/views/nudges/nudges.component";
import { InsightsComponent } from "src/app/views/insights/insights.component";
import { ReportsComponent } from '../../views/reports/reports.component';


import { ConstructionComponent } from '../../views/construction/construction.component';
import { WaitingDashboardComponent } from "src/app/views/waiting-dashboard/waiting-dashboard.component";
import { ProfileComponent} from '../../views/profile/profile.component';


const routes: Routes = [
 {
  path: '',component: MemberPanelComponent,
  children: [
    { path: '', component: WaitingDashboardComponent, canActivate: [AuthGuard], data: { roles: [Role.Member] } },
    { path: 'events', component: MeetingsComponent, canActivate: [AuthGuard], data: { roles: [Role.Member] } },
    { path: 'nudges', component: ConstructionComponent, canActivate: [AuthGuard], data: { roles: [Role.Member] } },
    { path: 'insights', component: InsightsComponent, canActivate: [AuthGuard], data: { roles: [Role.Member] } },
    { path: 'reports', component: ConstructionComponent, canActivate: [AuthGuard], data: { roles: [Role.Member] } },
    { path: 'event-detail/:eventId', component: MeetingDetailComponent, canActivate: [AuthGuard], data: { roles: [Role.Member] }},
    { path: 'event-detail/:eventId/:meetingId', component: MeetingDetailComponent, canActivate: [AuthGuard], data: { roles: [Role.Member] }},
    { path: 'profile', component: ProfileComponent, canActivate: [AuthGuard], data: { roles: [Role.Member] } },
    { path: '**', component: Page404Component },

    // { path: '', component: MemberProfileComponent, canActivate: [AuthGuard], data: { roles: [Role.Member]  } },
    // { path: 'event-detail/:eventId', component: MeetingDetailComponent, canActivate: [AuthGuard], data: { roles: [Role.Member] }},
    // { path: 'event-detail/:eventId/:meetingId', component: MeetingDetailComponent, canActivate: [AuthGuard], data: { roles: [Role.Member] }},  
    // { path: '**', component: Page404Component }
  ]
 }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class MemberRoutingModule { }
