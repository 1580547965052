
<section class="account-settings">
    <div class="container-fluid p-lg-4 p-xl-4">
        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="header">GENERAL</div>
                    <div class="body">
                        <form class="frmGeneral" [formGroup]="organizationForm" *ngIf="!isLoadingOrganization">
                            <div class="form-row">
                                <div class="form-group col-12 col-md-6">
                                    <label for="organization_name">ORGANIZATION NAME</label>
                                    <input type="text" class="form-control" id="organization_name" placeholder="" formControlName="OrganizationName">
                                    <div *ngIf="isSubmittedOrganization && formFieldsOrganization.OrganizationName.errors" class="errors">
                                      <div *ngIf="formFieldsOrganization.OrganizationName.errors.required" class="error">This is a required field.</div>
                                    </div>
                                </div>
                                <div class="form-group select-form col-12 col-md-6">
                                  <label for="industry" class="form-label-select">INDUSTRY</label>
                                  <app-select-search name="industries"
                                  class="select-control"
                                  #industries [source]="industrySource"
                                  [selectedValue]="industrySelected ? industrySelected: null"
                                  [class.hasValue]="industrySelected != null"
                                  (selectedChange)="getIndustrySelected($event)"
                                  [canEdit]="false"></app-select-search>
                                  <div *ngIf="isSubmittedOrganization && industrySelected == null" class="errors pt-1">This is a required field.</div>
                                </div>
                            </div>
                            <div class="form-row">
                                <div class="form-group col-12 col-md-6">
                                    <label for="website">WEBSITE</label>
                                    <input type="text" class="form-control" id="website" formControlName="OrgniazationWebPage">
                                    <div *ngIf="isSubmittedOrganization && formFieldsOrganization.OrgniazationWebPage.errors" class="errors">
                                      <div *ngIf="formFieldsOrganization.OrgniazationWebPage.errors.required" class="error">This is a required field.</div>
                                    </div>
                                </div>
                                <div class="form-group col-12 col-md-2 offset-md-4 d-flex align-items-center justify-content-end mb-0">
                                    <button type="submit" class="btn btn-primary w-100" (click)="saveOrganizationInfo()">{{!isSavingOrganization ? 'SAVE CHANGES' : 'SAVING...'}}</button>
                                </div>
                            </div>
                        </form>
                        <div class="spinner-border text-primary mb-3" role="status" *ngIf="isLoadingOrganization">
                          <span class="sr-only">Loading...</span>
                        </div>
                    </div>
                </div>
            </div>
            <!-- nuevo -->
            <div class="col-12 col-md-6 col-lg-6 col-xl-6 mt-4" *ngIf="tariyUser.role == roles.ITAdmin">
                <div class="card">
                    <div class="header">Nudges</div>
                    <div class="body">
                        <form class="frmGeneral" [formGroup]="organizationForm" *ngIf="!isLoadingOrganization">
                            <div class="form-row">
                                <div class="col-md-12 col-xs-12 col-lg-8 col-xl-8">
                                    <div class="description align-items-center align-items-md-start">
                                        <p>Click the following button your team nudges settings</p>
                                        <button class="btn btn-block btn-primary mt-2" (click)="nudger()">NUDGE SETTINGS</button>
                                    </div>
                                </div>
                            </div>
                        </form>
                        <div class="spinner-border text-primary mb-3" role="status" *ngIf="isLoadingOrganization">
                          <span class="sr-only">Loading...</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-6 col-lg-6 col-xl-6 mt-4" *ngIf="tariyUser.role == roles.ITAdmin">
                <div class="card">
                    <div class="header">Notifications</div>
                    <div class="body">
                        <form class="frmGeneral" [formGroup]="organizationForm" *ngIf="!isLoadingOrganization">
                            <div class="form-row">
                                <div class="col-md-12 col-xs-12 col-lg-8 col-xl-8">
                                    <div class="description align-items-center align-items-md-start">
                                        <p>Click the following button your team notification settings</p>
                                        <button class="btn btn-block btn-primary mt-2" (click)="notification()">NOTIFICATION SETTINGS</button>
                                    </div>
                                </div>
                            </div>
                        </form>
                        <div class="spinner-border text-primary mb-3" role="status" *ngIf="isLoadingOrganization">
                          <span class="sr-only">Loading...</span>
                        </div>
                    </div>
                </div>
            </div>
            <!-- termina -->
            <div class="col-12 mt-4" *ngIf="tariyUser.role == roles.ITAdmin">
                <div class="card">
                    <div class="header no-border">CONNECTIONS</div>
                </div>
            </div>
            <div class="col-12 mt-3" *ngIf="tariyUser.role == roles.ITAdmin">
                <div class="card">
                    <div class="body w-border">
                        <div class="connections">
                            <div class="row w-100">
                                <div class="col-md-12 col-xs-12 col-lg-1 col-xl-1">
                                    <div class="d-flex align-items-center justify-content-center">
                                        <img src="../../../assets/img/zoom.PNG" alt="" class="w-auto teams-logo">
                                    </div>
                                </div>
                                <div class="col-md-12 col-xs-12 col-lg-8 col-xl-8">
                                    <div class="description align-items-center align-items-md-start">
                                        <div class="zoom-text">ZOOM</div>
                                        <p>Connect with Zoom and get all the analysis of your meetings with Tariy</p>
                                    </div>
                                </div>
                                <div class="col-md-12 col-xs-12 col-lg-3 col-xl-3 d-flex align-items-center" *ngIf="isLoadingZoomSettings">
                                  <div class="spinner-border text-primary mb-3" role="status">
                                    <span class="sr-only">Loading...</span>
                                  </div>
                                </div>
                                <div class="col-md-12 col-xs-12 col-lg-3 col-xl-3 d-flex align-items-center">
                                    <button class="btn btn-block btn-cancel" (click)="zoomInfo = !zoomInfo; confirmDeleteSettings = false;" *ngIf="zoomInfo">CANCEL?</button>
                                    <div class="col-12">
                                        <button class="btn btn-block btn-primary" (click)="connectZoom()" *ngIf="!zoomBind">CONNECT</button>
                                      <button class="btn-blue-light btn btn-block" *ngIf="zoomBind"><fa-icon icon="check"></fa-icon>  ENABLED</button>
                                      <button class="btn btn-block btn-danger" (click)="confirmZoom()" *ngIf="zoomBind">DELETE</button>
                                      <!-- <button class="btn btn-block btn-no-background"  *ngIf="isZoomSet" (click)="zoomInfo = !zoomInfo">CONFIGURE</button> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="body zoomblock" *ngIf="zoomInfo" [@enterAnimation]>
                        <form class="frmZoom" [formGroup]="formZoom">
                            <div class="form-row">
                                <div class="form-group col-12 col-md-6">
                                    <label for="api_key">API KEY</label>
                                    <input type="text" class="form-control" id="api_key" placeholder="API KEY" formControlName="apiKey">
                                    <div *ngIf="isSubmittedZoomForm && formFieldsZoom.apiKey.errors" class="errors">
                                      <div *ngIf="formFieldsZoom.apiKey.errors.required" class="error">This is a required field.</div>
                                    </div>
                                </div>
                                <div class="form-group col-12 col-md-6">
                                    <label for="api_secret">API SECRET</label>
                                    <input type="text" class="form-control" id="api_secret" placeholder="API SECRET" formControlName="apiSecret">
                                    <div *ngIf="isSubmittedZoomForm && formFieldsZoom.apiSecret.errors" class="errors">
                                      <div *ngIf="formFieldsZoom.apiSecret.errors.required" class="error">This is a required field.</div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-row mt-2">
                                <div class="form-group col-12 col-md-3">
                                    <a (click)="openStepsDirections()" class="cursor-pointer" *ngIf="!isZoomSet">
                                        <span>WHERE DO I FIND THIS DATA?</span>
                                        <fa-icon icon="chevron-right" class="ml-auto"></fa-icon>
                                    </a>
                                    <button (click)="deleteZoomSettings()" class="cursor-pointer btn btn-default" [ngClass]="!confirmDeleteSettings ? 'btn-disable' : 'btn-confirm'" *ngIf="isZoomSet">
                                        <span>{{!confirmDeleteSettings ? 'DISABLE ZOOM' : 'CLICK TO DISABLE ZOOM'}}</span>
                                    </button>
                                </div>
                                <div class="form-group col-12 col-md-2 offset-md-7">
                                    <button class="btn btn-default" (click)="saveZoomSettings()">{{!isSavingZoom ? 'SAVE' : 'SAVING...'}}</button>
                                </div>
                            </div>
                        </form>
                    </div> -->
                </div>
            </div>

            <div class="col-12 mt-3" *ngIf="tariyUser.role == roles.ITAdmin">
                <div class="card">
                    <div class="body w-border">
                        <div class="connections">
                            <div class="row w-100">
                                <div class="col-md-12 col-xs-12 col-lg-1 col-xl-1">
                                    <div class="d-flex align-items-center justify-content-center">
                                        <img src="../../../assets/img/MS_teams.png" alt="" class="teams-logo">
                                    </div>
                                </div>
                                <div class="col-md-12 col-xs-12 col-lg-8 col-xl-8">
                                    <div class="description align-items-center align-items-md-start">
                                        <div class="zoom-text">Microsoft Teams</div>
                                        <p>Connect with Microsoft Teams and get all the analysis of your meetings with Tariy</p>
                                    </div>
                                </div>
                                <div class="col-md-12 col-xs-12 col-lg-3 col-xl-3 d-flex align-items-center" *ngIf="isLoadingZoomSettings">
                                  <div class="spinner-border text-primary mb-3" role="status">
                                    <span class="sr-only">Loading...</span>
                                  </div>
                                </div>
                                <div class="col-md-12 col-xs-12 col-lg-3 col-xl-3 d-flex align-items-center">
                                    <div class="col-12">
                                        <button class="btn btn-block btn-primary" (click)="connectTeams()" *ngIf="!teamsBind">CONNECT</button>
                                        <button class="btn-blue-light btn btn-block" *ngIf="teamsBind"><fa-icon icon="check"></fa-icon>  ENABLED</button>
                                        <button class="btn btn-block btn-danger" (click)="confirmTeams()" *ngIf="teamsBind">DELETE</button>
                                      <!-- <button class="btn-blue-light btn btn-block" *ngIf="isZoomSet"><fa-icon icon="check"></fa-icon>  ENABLED</button>
                                      <button class="btn btn-block btn-no-background"  *ngIf="isZoomSet" (click)="zoomInfo = !zoomInfo">CONFIGURE</button> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
