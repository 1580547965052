import { Injectable } from '@angular/core';
import { User } from 'src/app/models/user/user';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PendoService {

  constructor() {}

  /**
   * Initialize the Pendo Service and register the user and organization
   */
  initialize(user: User): void {
    if(globalThis.pendo) {
      globalThis.pendo.initialize({
        visitor: {
            id:         user.id,  // Required if user is logged in
            email:      user.email,  // Recommended if using Pendo Feedback, or NPS Email
            full_name:  user.firstname + " " + user.lastname,    // Recommended if using Pendo Feedback
            role:         user.role, // Optional
            environment: environment.environment_name
          
            // You can add any additional visitor level key-values here,
            // as long as it's not one of the above reserved names.
        },
    
        account: {
            id:           user.organizationId, // Highly recommended
            name:         user.organizationName, // Optional
            environment: environment.environment_name

            // is_paying:    // Recommended if using Pendo Feedback
            // monthly_value:// Recommended if using Pendo Feedback
            // planLevel:    // Optional
            // planPrice:    // Optional
            // creationDate: // Optional
    
            // You can add any additional account level key-values here,
            // as long as it's not one of the above reserved names.
        }
    });
    }
  }

}
