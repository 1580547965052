// List of available meeting status
export const MeetingStatus = {
  1: 'Processing',
  2: 'Scheduled',
  3: 'Completed',
  4: 'Not_Online_meeting',
  5: 'Online_Meeting_Not_Found',
  6: 'Personal_Time',
  7: 'Deep_Work'
};


export const MeetingStatusFront = {
  1: 'Processing',
  2: 'Scheduled',
  3: 'Completed',
  4: 'Not found',
  5: 'Not found',
  6: 'Personal time',
  7: 'Deep work'
};

export const MeetingStatusText = {
  'Processing': 1,
  'Scheduled': 2,
  'Completed': 3,
  'Not_Online_Meeting': 4,
  'Meeting_not_found': 5,
  'Personal_time': 6,
  'Deep_work': 7
};

