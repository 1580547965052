import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Globals } from 'src/app/global';

@Injectable({
  providedIn: 'root'
})
export class MeetingDetailService {
  // variable that store the import of Globals Class that will help us to retrieve the urls for every service
  globals = Globals
  // Url of users api
  baseUrl = `${this.globals.urlTraiyBase}`;

  constructor(private http: HttpClient) { }

    /**
   * Get conversation of a meeting
   * @param {String} - Id of meeting
   */
     async getTranscriptByMeeting( meetingId: String ): Promise<any>{
      const url = `${this.globals.meetings.update}/${meetingId}`;
      return await this.http.get(url).toPromise();
    }

      /**
     * Get information of a meeting
     * @param {String} - Id of meeting
     */
    async meetingDetail( eventId: String,  meetingId: String | null): Promise<any>{
      const url = `${this.globals.meetings.newdetail}`;
      return await this.http.post(url,{ 
        'eventId': eventId, 
        'meetingId': meetingId }).toPromise();
    }
}
