<!-- <div fxLayout="column" fxLayoutAlign="start stretch" class="member_panel">
  <app-header [bgcolor]="'#f5f5f5'"></app-header>
  <div fxLayout="row">
    <div fxFlex.xs="100%" fxFlex.sm="100%" fxFlex.md="100%"  class="w-100 parent_section" style="margin-top: 78px;">
      <router-outlet></router-outlet>
    </div>
  </div>
</div> -->
<div fxLayout="column" fxLayoutAlign="start stretch">
  <app-header *ngIf="!hideLayout"></app-header>
  <div fxLayout="row">
    <app-sidebar fxHide.xs fxHide.sm style="max-width: 85px;min-width: 85px; position:relative" *ngIf="!hideLayout"></app-sidebar>
    <div fxFlex.xs="100%" fxFlex.sm="100%" fxFlex.md="100%"  class="w-100" [ngStyle]="{'margin-top': !hideLayout ?'62px':'0px'}">
      <router-outlet></router-outlet>
    </div>
  </div>