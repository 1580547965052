<section class="recommendations">
  <div class="row">
    <div class="col-12">
      <ul class="list-unstyled">
        <li class="loading" *ngIf="loading">
          <div class="description"><fa-icon icon="circle-notch" class="fa-lg fa-spin mr-2" [spin]="true"></fa-icon>Loading...</div>
        </li>
        <li *ngFor="let advice of tariyTips" class="{{ advice.class | lowercase }}" style="padding: 10px 15px !important;">
          <div class="row">
            <div class="col-2 text-center align-self-center">
              <span class="icon icon-{{ advice.class | lowercase }}"></span>
            </div>
            <div class="col-10 p-0">
              <p>{{ advice.tips }}</p>
            </div>
          </div>          
        </li>
      </ul>
    </div>
  </div>
</section>
