import { Component, QueryList, ViewChildren } from '@angular/core';
import { Observable } from 'rxjs';
import { NgbdSortableHeader, SortEvent } from './sortable.directive';
import { DatePickerRanges, DefaultRangeSelected } from '../../configuration/datepicker-range';
import { HistoryService } from 'src/app/services/history/history.service';
import { BestPracticesService } from 'src/app/services/best-practices/best-practices.service';
import { HistoryMetric } from 'src/app/models/history-metric/history-metrics';
import { ToastrService } from 'ngx-toastr';
import * as moment from "moment-timezone";
import { KeywordsService } from 'src/app/services/keywords/keywords.service';
import { Keyword } from 'src/app/classes/keyword/keyword';
import { DatePickerConfigService } from 'src/app/services/datepicker-config/datepicker-config.service';
import { GeneralMetricsService } from 'src/app/services/general-metrics/general-metrics.service';
import { ScoreDefinitionComponent } from 'src/app/components/scoreDefinition/score-definition.component';
import { CardInsightsComponent } from 'src/app/components/cardInsights/card-insights.component';
import { MatDialog } from '@angular/material/dialog';
import { faInfoCircle, faArrowAltCircleUp, faArrowAltCircleDown } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-waiting-dashboard',
  templateUrl: './waiting-dashboard.component.html',
  styleUrls: ['./waiting-dashboard.component.scss']
})
export class WaitingDashboardComponent {
  // Sets a directive to apply the funcionality of sorting
  @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;
  ceiler = Math.ceil;
  toInt = parseInt;
  
  tipIcon = faInfoCircle;
  // Period of time that user might need of defining by himself
  ranges = DatePickerRanges;

  loadingMetrics$: Observable<boolean>;
  loadingBestPractices$: Observable<boolean>;
  loadingKeywords$: Observable<boolean>;

  loadingPastMetrics$: Observable<boolean>;

  // Stores the metrics availables for history section
  metricsData = [];
  differences = [];
  bestPracticesData:any;
  keywordsData: Keyword[];
  selectedRangeDates = this.datePickerConfig.selectedDateRange;

  bestPractices$: Observable<any>;
  // Score of this member
  score: any;
  radius = 30;
  circumference = 0;
  dashoffset = 0;
  costCarousel = [' active', '', '', ''];
  timeCarousel = [' active', '', '', ''];
  freetimeCarousel = [' active', ''];
  meetingCarousel = [' active', '', ''];
  participantCarousel = [' active', '', ''];
  teamDynamicsCarousel = [' active', ''];
  slides = [0,0,0,0,0,0];
  incrementIcon = faArrowAltCircleUp;
  decrementIcon = faArrowAltCircleDown;
  seeOverview = true;
  constructor(public historyService: HistoryService,
    public bestPracticesService: BestPracticesService,
    public keywordsService: KeywordsService,
    private toastr: ToastrService,
    private dialog: MatDialog,
    private datePickerConfig: DatePickerConfigService,
    private metricsService: GeneralMetricsService
    ) {

    this.loadingMetrics$ = this.historyService.loading$;
    this.loadingPastMetrics$ = this.historyService.pastLoading$;

    this.loadingMetrics$.subscribe(data => {
    }, (err) => {
      this.toastr.error("Error", err, {
        disableTimeOut: false,
        closeButton: true,
        positionClass: 'toast-top-right',
        toastClass: 'toast-margin ngx-toastr'
      });
    });
    this.loadingPastMetrics$.subscribe(data => {
    }, (err) => {
      this.toastr.error("Error", err, {
        disableTimeOut: false,
        closeButton: true,
        positionClass: 'toast-top-right',
        toastClass: 'toast-margin ngx-toastr'
      });
    });

    this.historyService.metrics$.subscribe(data => {
      this.metricsData = data;
    });
    this.historyService.pastMetrics$.subscribe(data => {
      this.differences = data;
    });

    this.historyService.score$.subscribe(data => {
      this.score = data;
      if(!Number.isNaN(this.score)) {
        this.circumference = this.radius * 2 * Math.PI;
        this.dashoffset = this.circumference * ((this.score/100));
      }
    });

    this.bestPractices$ = this.bestPracticesService.metrics$;
    this.loadingBestPractices$ = this.bestPracticesService.loading$;
    this.loadingBestPractices$.subscribe(data => {
    }, (err) => {
      this.toastr.error("Error", err, {
        disableTimeOut: false,
        closeButton: true,
        positionClass: 'toast-top-right',
        toastClass: 'toast-margin ngx-toastr'
      });
    });

    this.bestPractices$.subscribe(data => {
      this.bestPracticesData = data;
    });

    this.loadingKeywords$ = this.keywordsService.loading$;
    this.loadingKeywords$.subscribe(data => {
    }, (err) => {
      this.toastr.error("Error", err, {
        disableTimeOut: false,
        closeButton: true,
        positionClass: 'toast-top-right',
        toastClass: 'toast-margin ngx-toastr'
      });
    });

    // Subscribe Keywords
    this.keywordsService.keywords$.subscribe(data => {
      this.keywordsData = data
    }, (err) => {
      this.toastr.error("Error", err, {
        disableTimeOut: false,
        closeButton: true,
        positionClass: 'toast-top-right',
        toastClass: 'toast-margin ngx-toastr'
      });
    });

  }

  /**
   * Receives params that indicates by which column should be sort
   * @param {column, direction}: SortEvent
   */
  onSort({column, direction}: SortEvent) {
    // resetting other headers
    this.headers.forEach(header => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });
  }

  checkNumericScore() {
    console.log(this.score);
    console.log(isNaN(+this.score));
    console.log(this.metricsData);
    return isNaN(+this.score);
  }

  switchView(value) {
    this.seeOverview = value;
    console.log(this.seeOverview);
  }

  nextSlide(carousel, slide, position) {
    carousel[slide[position]] = '';
    if(slide[position] >= (carousel.length - 1)) {
      slide[position] = 0;
    }
    else {
      slide[position]++;
    }
    carousel[slide[position]] = ' active';
    console.log(this.costCarousel, this.slides, carousel.length);
  }

  previousSlide(carousel, slide, position) {
    carousel[slide[position]] = '';
    slide[position] = slide[position] <= 0 ? carousel.length - 1 : slide[position] - 1;
    carousel[slide[position]] = ' active';
    console.log(this.costCarousel, this.slides, carousel.length);
  }

  openInsights(insights:string[], selectedCard: number) {
    console.log(insights);
    const dialogRef = this.dialog.open(CardInsightsComponent, {
      width: '80%',
      height: 'auto',
      minHeight: '200px',
      data: {insights: insights, selectedRow: selectedCard}
    });
  }

  updateDashboard(){
    this.datePickerConfig.selectedDateRange = this.selectedRangeDates;
    this.historyService.range_date = this.selectedRangeDates;
    this.bestPracticesService.range_date = this.selectedRangeDates;
    this.keywordsService.range_date = this.selectedRangeDates;
    //this.getScore();
  }

  isText(data) {
    return !Number.isInteger(data);
  }

  getScore(): void {
    this.metricsService.getScoresByUser(this.selectedRangeDates).then(result => {
      this.score = result['Data'];
      this.circumference = this.radius * 2 * Math.PI;
      this.dashoffset = this.circumference * ((this.score/100));
    }).catch(err => {
      this.score = 0;
      this.toastr.error("Can't load score", "Score");
    })
  }

  //Show Meeting Tips
  showTips(){
      // Opens dialog steps-direction with steps passed as data array
    const dialogRef = this.dialog.open(ScoreDefinitionComponent, {
      width: '80%',
      height: 'auto',
      minHeight: '200px'
    });
  }

}
