// List of available meeting status
export const MeetingTypes = {
  1: 'meet_cost',
  2: 'avg_meet_cost',
  3: 'meet_time',
  4: 'meet_len',
  5: 'deep_time',
  6: 'per_time',
  7: 'meet_num',
  8: 'meet_par'
  /*1: 'meeting_cost',
  2: 'avg_meeting_cost',
  3: 'avg_user_cost',
  4: 'busy_time',
  5: 'free_time',
  6: 'late_participants'*/
};

export const EquityMeetingTypes = {
  1: 'meet_gen',
  2: 'meet_race',
  3: 'meet_age',
  4: 'meet_emo',
  5: 'meet_part',
  6: 'meet_org',
};


export const MeetingTypesText = {
  'meeting_cost': 'Meeting Cost',
  'avg_meeting_cost': 'Average Meeting Cost',
  'avg_user_cost': 'Average User Cost',
  'busy_time': 'Meeting Time',
  'free_time': 'Meeting-Free Time',
  'late_participants': 'Late Participants',
  'avg_meet_cost': 'Average Meeting Cost',
  'meet_cost': 'Meeting Cost',
  'meet_len': 'Average Meeting Length',
  'meet_num': 'Meeting Number',
  'meet_par': 'Average Meeting Participants',
  'meet_time': 'Meeting Time',
  'per_time': 'Personal Time',
  'deep_time': 'Deep Work',
  'meet_gen': 'Gender',
  'meet_race': 'Race',
  'meet_age': 'Generation',
  'meet_emo': 'Behavior',
  'meet_part': 'Participation',
  'meet_org': 'Organizers',
};

export const MeetingtoTotals = {
  'meet_cost': 'cost',
  //'meet_len': 'Meeting Length',
  'meet_num': 'meetings',
  //'meet_par': 'Meeting Participants',
  'meet_time': 'time',
  'per_time': 'per_time',
  'deep_time': 'deep_work',
};

export const TypeUnit = {
  'meet_cost': 'cost',
  'avg_meet_cost': 'cost',
  'meet_time': 'time',
  'meet_len': 'time',
  'per_time': 'time',
  'deep_time': 'time',
  'meet_num': 'number',
  'meet_par': 'number',
};

export const NotImplemented = {
  'per_time': 'time',
  'deep_time': 'time',
};

export const CurrentTypeColors = {
  'meet_cost': ['#77B62D', '#75B42D', '#6AA329', '#5F9325', '#558321', '#4A721D', '#406218', '#355214', '#2A4110', '#20310C'],
  'avg_meet_cost': ['#4EBCB4', '#43B1AA', '#3EA39C', '#38948E', '#328580', '#2D7671', '#276863', '#225955', '#1C4A47', '#163B39'],
  'meet_time': ['#E6182D', '#DE172B', '#CB1527', '#B91324', '#A61120', '#94101D', '#810E19', '#6F0C16', '#5C0A12', '#4A080E'],
  'meet_len': ['#FFAE17', '#FFA90A', '#F59F00', '#E09200', '#CC8500', '#B87700', '#A36A00', '#8F5D00', '#7A5000', '#664200'],
  'per_time': ['#009ACB', '#008DB8', '#007DA3', '#006D8F', '#005E7A', '#004E66', '#003F52', '#002F3D', '#001F29', '#001014'],
  'deep_time': ['#7369DF', '#6527DB', '#5246D8', '#4235D4', '#382BCA', '#3327B9', '#2F24A8', '#2A2097', '#251D87'],
  'meet_num': ['#3072EB', '#2066E9', '#165CDF', '#1455CC', '#124DBA', '#1145A7', '#0F3E95', '#0D3682', '#0B2E6F', '#09265D'],
  'meet_par': ['#F36C20'],
};

export const ForecastTypeColors = {
  'meet_cost': ['#93D24B', '#9DD65C', '#A7DA6C', '#B1DE7C', '#BAE28D', '#C4E79D', '#CEEBAD', '#D8EFBE', '#E2F3CE', '#EBF7DE'],
  'avg_meet_cost': ['#6BC7C1', '#7ACDC7', '#89D2CD', '#97D8D3', '#A6DDDA', '#B5E3E0', '#C4E9E6', '#D3EEEC', '#E1F4F3', '#F0F9F9'],
  'meet_time': ['#EC4657', '#EE5968', '#EF6B79', '#F17E89', '#F3909A', '#F5A3AB', '#F7B5BC', '#F9C8CD', '#FBDADD', '#FDEDEE'],
  'meet_len': ['#FFBF47', '#FFC65C', '#FFCD70', '#FFD485', '#FFDB99', '#FFE2AD', '#FFEAC2', '#FFF1D6', '#FFF8EB', '#FFFFFF'],
  'per_time': ['#00BCF5', '#0AC6FF', '#1FCBFF', '#33CFFF', '#47D4FF', '#5CD9FF', '#70DEFF', '#85E2FF', '#99E7FF', '#ADECFF'],
  'deep_time': ['#8178E2', '#9189E6', '#A19AEA', '#B0ABED', '#C0BCF1', '#D0CDF4', '#E0DDF8', '#EFEEFB', '#FFFFFF'],
  'meet_num': ['#588DEE', '#6A99F0', '#7DA6F2', '#90B3F4', '#A2BFF6', '#B5CCF8', '#C7D9F9', '#DAE6FB', '#ECF2FD', '#FFFFFF'],
  'meet_par': ['#F68D51'],
};


export class AnalyticTypes { 
  static readonly meeting_cost = 'meeting_cost';
  static readonly avg_meeting_cost = 'avg_meeting_cost';
  static readonly avg_user_cost = 'avg_user_cost';
  static readonly busy_time = 'busy_time';
  static readonly free_time = 'free_time';
  static readonly late_time = 'late_time';
  static readonly late_participants = 'late_participants';
  static readonly avg_meet_cost = 'avg_meet_cost';
  static readonly meet_cost = 'meet_cost';
  static readonly meet_len = 'meet_len';
  static readonly meet_num = 'meet_num';
  static readonly meet_par = 'meet_par';
  static readonly meet_time = 'meet_time';
  static readonly per_time = 'per_time';
  static readonly deep_time = 'deep_time';
  static readonly meet_gen = 'meet_gen';
  static readonly meet_race = 'meet_race';
  static readonly meet_age = 'meet_age';
  static readonly meet_emo = 'meet_emo';
  static readonly meet_part = 'meet_part';
  static readonly meet_org = 'meet_org';
}