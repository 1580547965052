import { Input } from '@angular/core';
import { Component } from '@angular/core';
import { User } from 'src/app/models/user/user';
import { Role } from 'src/enum/Role';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from '../../../services/auth/auth.service';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {

  texty = 'User mode';
  constructor(private router: Router,
    private aroute: ActivatedRoute,
    private authService:AuthService
  ){}

  // Show/hide menu on responsive for mobiles
  isCollapsed = true;
  // Loads information of logged user
  userInfo: User;
  // Background color required for navbar
  @Input() bgcolor = "#fff";
  // List of available roles
  roles = Role;
  //User role
  userRole = 'Member';
  userMode;
  validPicture = true;
  showHelp = false;

  ngOnInit(): void {
    this.userInfo = this.authService.loginInfo;
    this.userRole = this.userInfo.role;
    this.userMode = this.userInfo.userMode == 1 ? false : true;
    this.texty = this.userInfo.userMode == 1 ? 'User mode' : this.userRole;
  }

  // Method that remove from localstorage the credentials
  // that allows user to move on the website
  logout(): void{
    this.authService.logout();
  }

  openProfile(): void{
    let eventRoute = `profile`;
    this.router.navigate([eventRoute], {relativeTo: this.aroute});
  }

  setUserMode(event): void {
    this.userInfo.userMode = this.userMode ? 0 : 1;
    this.texty = this.userMode ? this.userRole : 'User mode';
    this.authService.updateUser(this.userInfo);
    let routes = decodeURIComponent(this.router.url).split('/');
    let reloadRoute = routes.join('/');//`/${routes[1]}${routes.length > 2 ? '/' + routes[2] : ''}`;
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate([reloadRoute]);
    }); 
  }

  changeHelp(): void {
    this.showHelp = !this.showHelp;
  }

  openNewTab(url) {
    window.open(url, '_blank').focus();
  }

  invalidPicture(): void {
    this.validPicture = false;
  }

  /**
   * Show or hide the menu list
   */
  toggleMenu(): void {
    this.isCollapsed = !this.isCollapsed;
  }
}
