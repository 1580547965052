
import * as moment from "moment-timezone";
import { MathUtils } from "src/app/utils/mathUtils";
import { MeetingTypesText,  AnalyticTypes, CurrentTypeColors, ForecastTypeColors  } from "src/enum/meeting-types.enum";
import { Role } from "src/enum/Role";
import { MeetingMember } from '../meeting-member/meeting-member';
import { Observable } from 'rxjs';
 
export class ScoreHistory {
  tooltip: {};
  xAxis: {};
  yAxis: {};
  series: any[];
  animationEasing: string;
  animationDelayUpdate: any;
  instance: any;
  legend: any;
  colors: any[];
  forecastColors: any[];
  currenNumber: number;
  forecastNumber: number;
  showForecast: boolean;

  constructor(axis, data){
    this.showForecast = true;
    this.animationEasing = 'elasticOut';
    this.animationDelayUpdate = (idx) => idx * 5;
    this.currenNumber = 1;
    this.forecastNumber = 0;
    this.legend = {
      show: false,
      type: 'scroll',
      orient: 'horizontal',
      width: '80%',
      data: []
    };
    this.yAxis = {
    };
    this.xAxis = {
      name: 'Date',
      nameLocation: 'middle',
      nameGap: 40,
      data: axis,
      silent: false,
      splitLine: {
        show: false,
      }
    };
    this.instance = null;
    this.tooltip = {
      trigger: 'item'
    };
    this.colors = CurrentTypeColors['meet_num'];
    this.forecastColors = ForecastTypeColors['deep_time'];
    this.tooltip['formatter'] = (params) => {
      return(this.createTooltip(params, false, '%'));
    };
    this.legend['selected'] = {
      'Score': true
    };
    this.legend['data'].push({name: 'Score'});
    this.series = [{
      name: 'Score',
      type: 'line',
      smooth: true,
      areaStyle: {},
      data: data,
      color: this.colors[0],
      animationDelay: (idx) => idx * 10,
    }];
    //this.legend['selected']['Predicted ' + JSON.parse(localStorage.getItem('traiyUser'))['organizationName']] = true;
  }
  private setInstance(chart:any, type:string = null): void
  {
     this.instance = chart;
     this.finishLoading();
  }
  private addSeries(series: any[], name: string, type: string, select: boolean, forecastOn: boolean): void
  {
    this.showForecast = forecastOn;
    let continueAdd = true;
    let colorIndex = select ? 0: this.forecastNumber;
    this.series.forEach(x => continueAdd = ((x.name === 'Predicted ' + name) ? false: true) && continueAdd);
    if(continueAdd) {
      this.legend['selected']['Predicted ' + name] = select && this.showForecast;
      let tempSeries = {
        name: 'Predicted ' + name,
        type: type,
        data: series,
        color: this.forecastColors[this.forecastNumber],
        animationDelay: (idx) => idx * 10,
        //showBackground: true,
        itemStyle: {
          decal: {
            symbol: ['circle'],
            symbolSize: 0.8,
            dashArrayX: [8, 1],
            rotation: Math.PI/4
          }
        }
      };
      if (select) {
        this.series.splice(1, 0, tempSeries);
        this.legend['data'].splice(1, 0, {name: 'Predicted ' + name});
      }
      else {
        this.series.push(tempSeries);
        this.legend['data'].push({name: 'Predicted ' + name});
        this.forecastNumber++;
      }
      this.redraw();
    }
  }

  private addCurrentSeries(series: any[], name: string, type: string): void
  {
    let continueAdd = true;
    this.series.forEach(x => continueAdd = ((x.name === name) ? false: true) && continueAdd);
    if(continueAdd) {
      this.legend['selected'][name] = false;
      this.legend['data'].push({name: name});
      let tempSeries = {
        name: name,
        type: type,
        data: series,
        color: this.colors[this.currenNumber],
        animationDelay: (idx) => idx * 10,
      };
      this.series.push(tempSeries);
      this.currenNumber++;
      this.redraw();
    }
  }

  private showSelectedSeries(name): void
  {
    this.legend['selected'][name] = true;
    this.legend['selected']['Predicted ' + name] = true && this.showForecast;
    this.redraw();
  }

  private hideDeselectedSeries(name): void
  {
    this.legend['selected'][name] = false;
    this.legend['selected']['Predicted ' + name] = false;
    this.redraw();
  }

  private showMainSeries(orgName): void
  {
    this.legend['selected']['Predicted ' + orgName] = true && this.showForecast;
    this.legend['selected'][this.series[0].name] = true;
    this.redraw();
  }

  private hideMainSeries(orgName): void
  {
    this.legend['selected']['Predicted ' + orgName] = false;
    this.legend['selected'][this.series[0].name] = false;
    this.redraw();
  }
  private updateForecast(orgName): void
  {
    //this.showForecast = !this.showForecast;
    //let showMain = this.legend['selected'][this.series[0].name];
    Object.keys(this.legend['selected']).forEach(key =>{
      if(key.includes('Predicted')) {
        let counterpart = key.replace('Predicted ', '');
        this.legend['selected'][key] = key == `Predicted ${orgName}` ? this.legend['selected'][this.series[0].name] && this.showForecast : this.legend['selected'][counterpart] && this.showForecast;
        //this.legend['selected'][key] = this.legend['selected'][counterpart] && this.showForecast;
      }
    });
    //this.legend['selected']['Predicted ' + orgName] = false;
    //this.legend['selected'][this.series[0].name] = false;
    this.redraw();
  }

  private finishLoading(): void
  {
     this.instance.hideLoading();
  }

  private setChartType(type:string): void
  {
     this.series.forEach(x => x.type = type);
     this.redraw();
  }

  private setSpecialChartType(mainType:string, subType:string): void
  {
    this.series.forEach(x => {
        if(x.name.includes('Predicted')) {
          x.type = subType
        }
        else {
          x.type = mainType
        }
      }
    );
    this.redraw();
  }

  private createTooltip(params, before, unit): string
  {
    let availableColors = this.instance.getOption().color;
        let adderStyle = '';
        let adderList = '';
        let title = `${params.seriesName} for <b>${params.name}</b>`;
        
        for(let i = 0; i < this.series.length; i++) {
          adderStyle += `
          .series-${i}::before{
            color: ${availableColors[i%9]};
          }`;
          let data = (i == 0) ? this.series[i]['data'][params.dataIndex]: this.series[i]['data'][params.dataIndex];
          let value = `<strong>${(before) ? (unit + data) : (data + unit)}</strong></li>`;

          if(this.series.length == 1) {
            adderList += `<li class="series-${i}">${params.name}: <strong>${value}</strong></li>`;
            break;
          }

          if(params.seriesName.includes('Predicted')) {
            if(this.series[i]['name'].includes('Predicted')) {
              adderList += `<li class="series-${i}">${this.series[i]['name']}: <strong>${value}</strong></li>`;
            }
          }
          else {
            if(!this.series[i]['name'].includes('Predicted')) {
              adderList += (i == 0) ? `<li class="series-${i}">Total: <strong>${value}</strong></li>` : `<li class="series-${i}">${this.series[i]['name']}: <strong>${value}</strong></li>`;
            }
          }
          
        }
        
        return (
          `<style>
          .graph-tooltip{
            list-style: none;
          }
          
          .graph-tooltip li::before {
            content: "•";
            font-weight: bold;
            font-size: 1rem;
            display: inline-block; 
            width: 1em;
            margin-left: -1em;
          }
          ${adderStyle}
          </style>
          ${title}
          </br>
          <ul class="graph-tooltip">
            ${adderList}
          </ul>
          `
        );
  }
  private redraw(): void
  {
     this.instance.setOption({
       'tooltip': this.tooltip,
       'xAxis': this.xAxis,
       'yAxis': this.yAxis,
       'series': this.series,
       'animationEasing': this.animationEasing,
       'animationDelayUpdate': this.animationDelayUpdate,
       'legend': this.legend
     });
  }
}


