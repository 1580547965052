import { Injectable, EventEmitter } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SearchMatchesService {

  numberMatches$ = new EventEmitter<{ count: number }>();

  constructor(){}
}
