import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Globals } from 'src/app/global';

@Injectable({
  providedIn: 'root'
})
export class NotificationSettingsService {
  // variable that store the import of Globals Class that will help us to retrieve the urls for every service
  globals = Globals;

  constructor(private http: HttpClient) { }

  // Retrieves settings zoom data https://7bi4v36ta7.execute-api.us-west-1.amazonaws.com/Prod/update
  updateUserId(enable: boolean, id): Promise<object>{
    const url = `${this.globals.notifications.base}${this.globals.notifications.update}`;
    //const url = `https://7bi4v36ta7.execute-api.us-west-1.amazonaws.com/Prod/update`;
    return this.http.post(url, { 
      'enable': enable, 
      'push_id': id }).toPromise();
    }

  }
