import { Component, OnInit, Inject, Input } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { StepsConnection } from 'src/app/models/settings/steps-connection';
import { trigger, transition, style, animate } from '@angular/animations';

@Component({
  selector: 'app-card-insights',
  templateUrl: './card-insights.component.html',
  styleUrls: ['./card-insights.component.scss']
})
export class CardInsightsComponent implements OnInit {
  tips = [
    {class: 'meet_cost', key: 'Meeting Cost', insights: [], definition: 'The cost of all the meetings you have in the selected dates'},
    {class: 'meet_time', key: 'Meeting Time', insights: [], definition: 'The sum of all the meetings you have in the selected dates. This value is updated to the real meeting time when meeting is processed'},
    {class: 'avg_meet_time', key: 'Average Meeting Length', insights: [], definition: 'The average length of a meeting'},
    {class: 'meet_num', key: 'Meeting Number', insights: [], definition: 'The number of meetings you have scheduled in the selected dates'},
    {class: 'deep_work', key: 'Deep Work', insights: [], definition: 'The total time of deep work you have scheduled'},
    {class: 'per_time', key: 'Personal Time', insights: [], definition: 'The total time of meetings scheduled as personal time'},
    {class: 'avg_meet_cost', key: 'Average Meeting Cost', insights: [], definition: 'The average cost of all the meetings in the selected dates'},
    {class: 'meet_par', key: 'Average Meeting Participants', insights: [], definition: 'The average number of participants in all the meetings of the selected dates'}
  ];
  columnHeight = 0;


  constructor(public dialogRef: MatDialogRef<CardInsightsComponent>, @Inject(MAT_DIALOG_DATA) private data: any) { }

  ngOnInit(): void {
    // Set data sent from dialog data to steps
    //this.tips = this.data['tips'];
    this.columnHeight = 100/ (this.tips.length);
    let insights = this.data['insights'];
    let selectedRow = this.data['selectedRow'];
    this.tips[selectedRow].insights = insights;
    this.tips = [this.tips[selectedRow]]
  }

  // Close dialog
  close(){
    this.dialogRef.close();
  }

}
