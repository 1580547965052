import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, CanActivateChild, CanDeactivate, CanLoad, Route, Router, UrlSegment } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from 'src/app/services/auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanActivateChild, CanDeactivate<unknown>, CanLoad {


  constructor(private authService: AuthService, private router: Router) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    let url: string = state.url;
    return this.checkUserLogin(next, url);
  }
  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.canActivate(next, state);
  }
  canDeactivate(
    component: unknown,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return true;
  }
  canLoad(
    route: Route,
    segments: UrlSegment[]): Observable<boolean> | Promise<boolean> | boolean {
    return true;
  }

  checkUserLogin(route: ActivatedRouteSnapshot, url: any): boolean {
    if (this.authService.isAuthenticated()) {
      const user = this.authService.loginInfo;
      if (route.data.roles && !route.data.roles.includes(user.role)) {
        this.router.navigate(['/login']);
        return false;
      }
      if(user.email == 'todd@tariy.ai') {
        this.authService.logout();
        return false;
      }

      // CHECK THE ONBOARDING PROCESS
      // Out of the Onboarding pages
      if(!route.data.onboarding){
        if(user.organizationOnboarding && user.role == 'Admin'){// First time, first user
          this.router.navigate(['/admin/onboarding-invited']);
          return false;
        } else if (user.userOnboarding) {// Organization registered, but first time for this user
          this.router.navigate(['/admin/onboarding-organization']);
          return false;
        }
      }
      // Inside the onboarding pages
      else{
        if(user.organizationOnboarding && user.role == 'Admin'){// First time, first user
          return true;
        } else if (user.userOnboarding) {// Organization registered, but first time for this user
          return true;
        }
        else{
          this.router.navigate(['/admin']);
          return false;
        }
      }

      return true
    }

    this.router.navigate(['/login']);
    return false;
  }
}
