import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { tap, debounceTime, switchMap, delay } from 'rxjs/operators';
import { Globals } from 'src/app/global';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class BestPracticesService {

  // Listen for new strings that user provides
  private _search$ = new Subject<void>();
  // Listen for new changes on datatable or grid
  private _loading$ = new BehaviorSubject<boolean>(true);
  // variable that store the import of Globals Class that will help us to retrieve the urls for every service
  globals = Globals;
  // Url of users api
  baseUrl = `${this.globals.urlTraiyBase}`;
  // List of members
  private _bestPractices$ = new BehaviorSubject<any>([]);

  // Indicates if two ranges will be compared
  private _both_ranges = false;

  private _pagination: any = {
    page: 1,
    itemsPerPage: 12,
    searchText: '',
    orderColumn: '',
    orderBy: '',
    range_date: null,
    range_date_second: null
  };

  constructor(private httpClient: HttpClient) {
      this._search$.pipe(
        tap(() => this._loading$.next(true)),
        debounceTime(200),
        switchMap(() => this._search()),
        delay(200),
        tap(() => this._loading$.next(false))
      ).subscribe(result => {
        this._bestPractices$.next(result['Data']);
      });
    }

    // Getters an setters that manage filters an table options
    // that show results on datatable
    get metrics$() { return this._bestPractices$.asObservable(); }
    get loading$() { return this._loading$.asObservable(); }
    get range_date() { return this._pagination.range_date; }
    get range_date_second() { return this._pagination.range_date_second; }
    get both_ranges() { return this._both_ranges; }

    set both_ranges(both_ranges: boolean) { this._both_ranges = both_ranges; }
    set range_date(range_date) {
      this._set({range_date});
    }
    set range_date_second(range_date_second) {
      this._set({range_date_second});
    }

    // Helper that set values depending on its use
  private _set(patch: any) {
      Object.assign(this._pagination, patch);

      if(!this.both_ranges){
        this._search$.next();
      }else{
        if ( this.range_date != null && this.range_date_second != null && this.range_date['startDate'] != null && this.range_date_second['startDate'] != null){
          this._search$.next();
        }
      }
    }

    async _search(): Promise<object>{
      this._bestPractices$.next([]);
      let dates = {
        starts: this.range_date['startDate'].startOf('day').toISOString(),
        ends: this.range_date['endDate'].endOf('day').toISOString()
      };
      if(this.range_date_second){
        dates['starts2'] = this.range_date_second['startDate'].startOf('day').toISOString();
        dates['ends2'] = this.range_date_second['endDate'].endOf('day').toISOString();
      }
      const url = `${this.globals.availabilities.bestPractices}?${ new URLSearchParams(dates).toString() }`;
      let metrics = await this.httpClient.get(`${url}`).toPromise();

      return metrics;
    }
}
