import { Injectable } from '@angular/core';
import { DefaultRangeSelected, RangeDate } from 'src/app/configuration/datepicker-range';

@Injectable({
  providedIn: 'root'
})
export class DatePickerConfigService {

  private _selectedDateRange = DefaultRangeSelected.TODAY;
  private _selectedMeetingType = '';

  constructor() { }

  get selectedDateRange() {
    return this._selectedDateRange;
  }

  set selectedDateRange(dateRange: RangeDate) {
    this._selectedDateRange = dateRange;
  }

  get selectedMeetingTypeFilter() {
    return this._selectedMeetingType;
  }

  set selectedMeetingTypeFilter(meetingType: string) {
    this._selectedMeetingType = meetingType;
  }

  /**
   * Resets the select date range
   */
  public resetSelectedDateRange() {
    this.selectedDateRange = DefaultRangeSelected.TODAY;
  }

  /**
   * Resets the selected meeting type filter
   */
  public resetSelectedMeetingType() {
    this.selectedMeetingTypeFilter = '';
  }

}
