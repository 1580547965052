import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { CustomSelect } from 'src/app/classes/custom-select/custom-select';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, tap, switchMap } from 'rxjs/operators';
import { SourceCustomSelect } from 'src/app/classes/source-custom-select/source-custom-select';
import { FormGroup } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-select-search',
  templateUrl: './select-search.component.html',
  styleUrls: ['./select-search.component.scss']
})
export class SelectSearchComponent implements OnInit {

// Items already shown in the select
items: CustomSelect[] = [];
// New items retrieve by request
itemsBuffer: CustomSelect[] = [];
// Show loading when is getting more items
loading = false;
// Local form at this instance is child form
public selectForm: FormGroup = new FormGroup({});
// Use to search item
searchValue = '';
// Actual page on paginate
currentPage = 1;
// Numebr of items to display per page
itemsPerPage = 10;
// Total items on API
totalItems: number;
// total of paginate pages
totalPages: number;
// Total of rows returned
totalRows: number;
// Emitter used when selected item values is changed
@Output() selectedChange = new EventEmitter();
// Data to display title and placeholder component
@Input('selectedValue') selectedValue = '';
// Type of source used to get data items to display
@Input('source') source: SourceCustomSelect;
// Bind Label name
@Input() bindLabel: string = 'unique';
// Validate to edit permission
@Input('canEdit') canEdit;
// Placeholder
@Input('placeholder') placeholder = '';
// Observer waiting for new
select$ = new Subject<string>();

constructor(private toastService: ToastrService) { }

ngOnInit() {
  this.onSearch();
  this.fetchMore();
}

// Execute when scroll bar is on bottom
onScrollToEnd(): void {
  if (this.currentPage < Math.ceil(this.totalPages)) {
    this.currentPage++;
    this.fetchMore();
  }
}

// Get next data to display with infinity scroll
private fetchMore(): void {
  this.loading = true;
  let query = this.getQueryParameters();
  this.source.fetchMore(query).then(data => this.loadData(data));
}

// Return all query parameters into json object
getQueryParameters(): object {
  const querySearch: object = {
    searchText: this.searchValue || '',
    page: this.currentPage,
    per_page: this.itemsPerPage
  };
  return querySearch;
}

// Event when search box is clear
resetDataSelect (): void {
  this.onOpen();
}

// Execute on typing to search
onSearch(): void {
  this.select$.pipe(
    debounceTime(200),
    distinctUntilChanged(),
    tap(() => this.loading = true),
    switchMap( term => this.searchService(term)))
    .subscribe( data => this.loadData(data));
}

/*
* Called when searchbox have new characters, assuming initial values for a new request
* @param {object} data - Response content
*/
private searchService(term: string): Promise<object>{
  this.items = [];
  this.currentPage = 1;
  this.searchValue = term;
  let query = this.getQueryParameters();
  return this.source.fetchMore(query);
}

/*
* The retrieved data is used to set the list of options and pagination variables
* @param {object} data - Response content
*/
loadData(data: object): void{
  this.itemsBuffer = data['list'];
  this.currentPage = data['currentPage'];
  this.totalPages = data['totalPages'];
  this.totalItems = data['totalItems'];
  this.totalRows = data['totalRows'];
  if (this.currentPage == 1) {
    this.items = [];
  }
  this.items = this.items.concat(this.itemsBuffer);
  this.loading = false;
}

  /**
  * To resize when open checkboxes input, we will use this in the next sprint
  * @param {Event} event - Windows size changed event
  */
  resizeSelectOptions(event: MouseEvent): void {
    const select = event;
    const parentBox = document.querySelector('.cdk-overlay-connected-position-bounding-box');
    if (parentBox && select != null) {
      const box = parentBox.querySelector('.cdk-overlay-pane');
      box['style']['minWidth'] = 'auto';
      box['style']['marginTop'] = '50px';
      box['style']['marginLeft'] = ((select['offsetWidth'] - box['offsetWidth']) - 20) + 'px';
    }
  }

  /**
   * Send value selected to main page
   * @param $event - Contains properties of select when is triggered
   */
  selectChange($event: any): void {
    this.selectedChange.emit($event);
  }

  // Retrive the first page of results
  onOpen(): void {
    this.items = [];
    this.currentPage = 1;
    this.searchValue = '';
    this.fetchMore();
  }

}
