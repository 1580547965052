export const environment = {
  production: true,
  environment_name: 'production',

  api_url: 'https://api.tariy.ai/',
  client_secret: 'SUHpyo5av32-h1JD5fYbE3I3f~j-2U_.xA',
  google_client_id: '470971168239-g3blmeo5t3liv9smop1auprruq2g39iq.apps.googleusercontent.com',
  microsoft_client_id: "4ce36601-f4ef-4126-ad63-367f01af655e",
  tenant: '86706d8b-faa8-493f-8064-0a0a86d6d3d4',
  url_path: 'https://insights.tariy.ai',
  microsoft_redirect: 'https://insights.tariy.ai/redeem-code',
  onesignal: 'bd26dd37-27fe-4264-a30a-dc07860b85c4',
  zoom_oauth: 'https://zoom.us/oauth/authorize?response_type=code&client_id=rhRO0Fj6SWiReFjSz0Dq3Q&redirect_uri=https://insights.tariy.ai/itadmin/settings',
  teams_oauth: 'https://login.microsoftonline.com/common/adminconsent?client_id=4ce36601-f4ef-4126-ad63-367f01af655e&state=12345&redirect_uri=https://insights.tariy.ai/admin/settings',
  subdomain: window.location.href.substring(9, 1000).split(".")[0]
};
