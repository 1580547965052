import { trigger, transition, style, animate } from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/services/auth/auth.service';
import { Role } from 'src/enum/Role';
import { ZoomSettingsService } from 'src/app/services/settings/zoom-settings.service';
import { TeamsSettingsService } from 'src/app/services/settings/teams-settings.service';
import { OrganizationService } from 'src/app/services/settings/organization.service';
import { environment } from 'src/environments/environment';
import { StepsWizardComponent } from 'src/app/components/settings/steps-wizard/steps-wizard.component';
import { MatDialog } from '@angular/material/dialog';
import { StepsConnection } from 'src/app/models/settings/steps-connection';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { SourceIndustries } from 'src/app/classes/source-industries/source-industries';
import { ResourcesService } from 'src/app/services/resources/resources.service';
import { NudgeSettings } from 'src/app/components/nudgesSettings/nudge-settings.component';
import { NotificationSettings } from 'src/app/components/notificationSettings/notification-settings.component';
import Swal from 'sweetalert2';



@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss'],
  animations: [
    trigger(
      'enterAnimation', [
      transition(':enter', [
        style({ visibility: 'hidden', opacity: 0 }),
        animate('500ms', style({ visibility: 'hidden', opacity: 1 }))
      ]),
      transition(':leave', [
        style({ visibility: 'hidden', opacity: 1 }),
        animate('500ms', style({ visibility: 'visible', opacity: 0 }))
      ])
    ]
    )
  ],
})
export class SettingsComponent implements OnInit {

  // Url for getting admin consent
  microsoft_login_url = "https://login.microsoftonline.com/";
  // Receives tenant id from url after loading page
  tenant = '';
  // Receives admin_consent values after loading page
  admin_consent = false;
  // Helper that display result of request, useful for testing
  result: any;
  // Indicates if zoom data is required
  zoomInfo = false;
  // Organization ID stores in localstorage
  organizationId: string = '<organization_id>';
  // Steps for zooom connection
  zoomStepsData: StepsConnection[] = [];
  // Zoom form group
  formZoom: FormGroup;
  // Helper that indicates when the user form is saving
  isSubmittedZoomForm = false;
  // Info zoom
  isZoomSet = false;
  // Helper to confirm delete settings on zoom
  confirmDeleteSettings = false;
  // Controls when user call service
  isLoadingZoomSettings = false;
  // Controls when user call service on organization form
  isLoadingOrganization = false;
  // Controls when user clicks on save changes
  isSavingOrganization = false;
  // Controls when user clicks on save changes zoom
  isSavingZoom = false;
  // Selected value from ng-select for industries
  industrySelected = null;
  // Industries source class
  industrySource: SourceIndustries
  // Helper that indicates when organization form is submitted
  isSubmittedOrganization = false;
  // Organization form group
  organizationForm: FormGroup;
  //zoom code link
  code = "";
  // Tariy user localstorage
  tariyUser
  //zoom binding status
  zoomBind;
  //teams binding status
  teamsBind;
  //sweet alert notifications
  Swal = require('sweetalert2')
  roles = Role;
  constructor(
    private authService: AuthService,
    private activatedRoute: ActivatedRoute,
    private dialog: MatDialog,
    private zoomSettingsService: ZoomSettingsService,
    private teamsSettingsService: TeamsSettingsService,
    private organizationService: OrganizationService,
    private fb: FormBuilder,
    private resources: ResourcesService,
    private toastService: ToastrService,
    ) {
    this.activatedRoute.queryParams.subscribe(params => {
      this.tenant = params['tenant'];
      this.admin_consent = params['admin_consent'];
      this.code = params['code'];
      this.getTokens();
    });
    this.industrySource = new SourceIndustries(this.resources);
  }

  ngOnInit(): void {
    let getLink =  window.location.href ; 
    console.log(getLink);
    console.log(environment.subdomain);
    if (localStorage.getItem('traiyUser')){
      this.tariyUser = JSON.parse(localStorage.getItem('traiyUser'));
      console.log(this.tariyUser.role);
      if (this.tariyUser['organizationId']) {
        this.organizationId = this.tariyUser['organizationId'];
      }
      if (this.tariyUser['zoomBind']) {
        this.zoomBind = this.tariyUser['zoomBind']
      }
      else {
        this.checkZoomBind(this.tariyUser)
      }
    }

    this.statusZoom();
    this.statusTeams();

    if(this.code) {
      this.loginZoom();
    }
    if(this.tenant) {
      if(this.admin_consent) {
        this.loginTeams();
      }
    }

    // Init Zoom form
    this.formZoom = this.fb.group({
      apiKey: ['', Validators.required],
      apiSecret: ['', [Validators.required]]
    });

    // Init Zoom form
    this.organizationForm = this.fb.group({
      OrganizationName: ['', Validators.required],
      OrganizationId: ['', [Validators.required]],
      OrgniazationWebPage: ['', [Validators.required]],
      IndustryId: ['', [Validators.required]],
      Industry: ['', [Validators.required]]
    });

    // Zoom stepstData
    this.zoomStepsData = [
      {title: 'Register Your App!', text: 'To register your app, visit the <a target="_blank" href="https://marketplace.zoom.us/">Zoom App Marketplace</a> and click on the <b>Develop</b> option in the dropdown on the top-right corner and select <b>Build App.</b>', img: 'assets/img/settings/zoom/step1.png'},
      {title: 'Select JWT2', text: 'A page with various app types will be displayed. Select <b>JWT</b> as the app type and click on <b>Create</b>', img: 'assets/img/settings/zoom/step2.png'},
      {title: 'Information?', text: '<b>Fill</b> your basic information ', img: 'assets/img/settings/zoom/step3.png'},
      {title: 'Feature', text: `<b>Enable</b> the event Subcriptions and <b>Add new event Subscription</b> <div class="step-note"><span class="d-block"><b>Note:</b></span> The field Event notification endpoint URL must be <a href="${environment.url_path}/zoom/api/company/${this.organizationId}/meetings">${environment.url_path}/zoom/api/company/${this.organizationId}/meetings</a></div>`, img: 'assets/img/settings/zoom/step4.png'},
      {title: 'Event types', text: 'In the section of events, click in <b>Add events</b> and select all in <b>Meeting Section</b> and <b>Recording section</b> and save it.', img: 'assets/img/settings/zoom/step5.png'},
      {title: 'App Credentials', text: 'In the section of <b>App Credentials</b>, copy <b>Api Key</b> and <b>Api Secret</b>.<br> Then paste your credentials into <b>Tariy Application</b> in the section of <b>Settings / Connections.</b>', img: 'assets/img/settings/zoom/step6.png'},
    ];

    this.getZoomSettings();
    this.getOrganizationData();
  }

  //oauth login zoom function
  loginZoom() {
    let params = {'code' : this.code, 'organization': this.organizationId};
    this.zoomSettingsService.setZoomSettings(params).then((data:any) => {
      this.zoomBind = data.success;
      if(this.zoomBind) {
        let user = JSON.parse(localStorage.getItem('traiyUser'));
        user['zoomBind'] = this.zoomBind;
        localStorage.setItem('traiyUser', JSON.stringify(user));
        this.swalAlert('Zoom account successfully binded to TARIY', 'Success','success');
      }
      else {
        this.swalAlert('We had troubles binding your Zoom account to TARIY, please try again', 'Zoom error','error');
      }
    });
  }

  //oauth login teams function
  loginTeams() {
    let params = {'tenant' : this.tenant, 'organization': this.organizationId};
    this.teamsSettingsService.setTeamsSettings(params).then((data:any) => {
      this.teamsBind = data.success;
      if(this.teamsBind) {
        let user = JSON.parse(localStorage.getItem('traiyUser'));
        user['teamsBind'] = this.teamsBind;
        localStorage.setItem('traiyUser', JSON.stringify(user));
        this.swalAlert('Microsoft Teams account successfully binded to TARIY', 'Success','success');
      }
      else {
        this.swalAlert('We had troubles binding your Microsoft Teams account to TARIY, please try again', 'Microsoft Teams error','error');
      }
    });
  }

  // When button is clicked, then open the Microsoft's login with our params
  signInMicrosoft(): void{
    const params_login = new URLSearchParams({
      client_id:     environment.microsoft_client_id,
      state:         "12345",
      redirect_uri:  `${environment.url_path}/settings`,
    });
    window.open(`${this.microsoft_login_url}${environment.tenant}/adminconsent?${params_login}`);
  }

  // Get both tokens required that allows Microsoft's permissions
  // at this moment just printing up the result of request
  getTokens(): void{
    this.authService.getMicrosoftTokens().then(data => {
      this.result = data;
    }).catch(err => {
      this.result = err;
    })
  }

  // Opens dialog steps-direction with steps passed as data array
  openStepsDirections(){
    const dialogRef = this.dialog.open(StepsWizardComponent, {
      width: '80%',
      height: '80%',
      data: {stepsData: this.zoomStepsData},
      panelClass: "steps-wizard"
    });
  }

  // Convenience getter for easy access to form fields
  get formFieldsZoom() {
    return this.formZoom.controls;
  }

  // Convenience getter for easy access to form fields
  get formFieldsOrganization() {
    return this.organizationForm.controls;
  }

  // Save zoom settings
  async saveZoomSettings() {
    this.isSubmittedZoomForm = true;
    if (!this.formZoom.valid || this.isSavingZoom){
      return
    }
    this.isSavingZoom = true;
    const dataZoomSettings = {
      code: this.formZoom.value['apiKey'],
      organization: this.formZoom.value['apiSecret']
    };

    this.zoomSettingsService.setZoomSettings(dataZoomSettings).then(data => {
      data['IsSuccess'] ? this.toastService.success(data['Message'], "Zoom settings") : this.toastService.error(data['Message'], "Zoom settings");
        this.isSubmittedZoomForm = false;
        this.zoomInfo = false;
        this.isSavingZoom = false;
        this.getZoomSettings();
    }).catch(err => {
      this.toastService.success("Can't set zoom settings.", "Zoom settings");
      this.isSubmittedZoomForm = false;
      this.isSavingZoom = false;
    });
  }

  // Get zoom settings
  async getZoomSettings(){
    this.isZoomSet = false;
    this.isLoadingZoomSettings = true;
    this.formZoom.reset();
    const zoomData = await this.zoomSettingsService.getZoomSettings();
    if (zoomData['Data']['ApiKey'] && zoomData['Data']['ApiSecret']) {
      this.formZoom.controls['apiKey'].setValue(zoomData['Data']['ApiKey'])
      this.formZoom.controls['apiSecret'].setValue(zoomData['Data']['ApiSecret'])
      this.isZoomSet = true;
    }
    this.isLoadingZoomSettings = false;
  }

  // Call service to delete zoom settings data
  async deleteZoomSettings(){
    if (!this.confirmDeleteSettings) {
      this.confirmDeleteSettings = true;
      return;
    }
    this.zoomSettingsService.deleteZoomSettings().then(data => {
      data['IsSuccess'] ? this.toastService.success(data['Message'], "Zoom settings") : this.toastService.error(data['Message'], "Zoom settings");
        this.isSubmittedZoomForm = false;
        this.zoomInfo = false;
        this.confirmDeleteSettings = false;
        this.getZoomSettings();
    }).catch(err => {
      this.toastService.success("Can't delete zoom settings.", "Zoom settings");
      this.isSubmittedZoomForm = false;
      this.confirmDeleteSettings = false;
    });
  }

  /**
  *  Method to assign types selected on component
  *  @param {String} event - List of topic selected
  */
   getIndustrySelected(event): void {
    this.industrySelected = event;
  }


  // Get organization settings data
  async getOrganizationData(){
    this.isLoadingOrganization = true;
    const organizationData = await this.organizationService.getOrganizationData();
    if (organizationData['Data']) {
      this.industrySelected = {id: organizationData['Data']['IndustryId'], description: organizationData['Data']['Industry']};
      this.organizationForm.controls['OrganizationName'].setValue(organizationData['Data']['Name'])
      this.organizationForm.controls['OrgniazationWebPage'].setValue(organizationData['Data']['WebSite'])
    }
    this.isLoadingOrganization = false;
  }

  // Save form action
  saveOrganizationInfo(){
    this.isSubmittedOrganization = true;
    this.organizationForm.controls['OrganizationId'].setValue(this.organizationId);
    // Check select industry
    if (this.industrySelected != null) {
      this.organizationForm.controls['IndustryId'].setValue(this.industrySelected.id)
      this.organizationForm.controls['Industry'].setValue(this.industrySelected.description)
    }

    // Validates form
    if (!this.organizationForm.valid || this.isLoadingOrganization){
      return
    }
    this.isSavingOrganization = true;
    this.organizationService.setOrganizationData(this.organizationForm.value).then(data => {
      data['IsSuccess'] ? this.toastService.success(data['Message'], "Organization Info") : this.toastService.error(data['Message'], "Organization Info");
        this.isSubmittedOrganization = false;
        this.isSavingOrganization = false;
        this.getOrganizationData();
    }).catch(err => {
      this.toastService.success("Can't set zoom settings.", "Organization Info");
      this.isSubmittedOrganization = false;
      this.isSavingOrganization = false;
    });

  }

  //redirect to oauth login
  connectZoom() {
    window.location.href = environment.zoom_oauth;
  }

  //redirect to oauth login
  connectTeams() {
    window.location.href = environment.teams_oauth;
  }
  
  //get zoom status
  statusZoom() {
    this.zoomSettingsService.statusZoomSettings().then((data:any) => {
      this.zoomBind = data.success;
      let user = JSON.parse(localStorage.getItem('traiyUser'));
      user['zoomBind'] = this.zoomBind;
      localStorage.setItem('traiyUser', JSON.stringify(user));
    })
  }

  //get teams status
  statusTeams() {
    this.teamsSettingsService.statusTeamsSettings().then((data:any) => {
      this.teamsBind = data.success;
      let user = JSON.parse(localStorage.getItem('traiyUser'));
      user['teamsBind'] = this.teamsBind;
      localStorage.setItem('traiyUser', JSON.stringify(user));
    })
  }

  //unbind zoom 
  unbindZoom() {
    this.zoomSettingsService.deleteZoomSettings().then((data:any) => {
      this.zoomBind = !data.success;
      if(data.success) {
        let user = JSON.parse(localStorage.getItem('traiyUser'));
        user['zoomBind'] = this.zoomBind;
        localStorage.setItem('traiyUser', JSON.stringify(user));
        this.swalAlert('Zoom account successfully unbinded to TARIY', 'Success','success');
      }
      else {
        this.swalAlert('We had troubles unbinding your Zoom account to TARIY, please try again', 'Zoom error','error');
      }
    });
  }

  //unbind teams 
  unbindTeams() {
    this.teamsSettingsService.deleteTeamsSettings().then((data:any) => {
      this.teamsBind = !data.success;
      if(data.success) {
        let user = JSON.parse(localStorage.getItem('traiyUser'));
        user['teamsBind'] = this.teamsBind;
        localStorage.setItem('traiyUser', JSON.stringify(user));
        this.swalAlert('Microsoft Teams account successfully unbinded to TARIY', 'Success','success');
      }
      else {
        this.swalAlert('We had troubles unbinding your Microsoft Teams account to TARIY, please try again', 'Microsoft Teams error','error');
      }
    });
  }

  //confirm teams unbind
  confirmZoom() {
    this.swalConfirm('Are you sure you want to unbind your Zoom account?', 'Zoom unbinding', 'question', 1);
  }

  //confirm teams unbind
  confirmTeams() {
    this.swalConfirm('Are you sure you want to unbind your Microsoft Teams account?', 'Microsoft Teams unbinding', 'question', 2);
  }

  //check zoomBind
  checkZoomBind(user) {
    this.zoomSettingsService.statusZoomSettings().then((data:any) => {
      user['zoomBind'] = data.success;
      this.zoomBind = data.success;
      localStorage.setItem('traiyUser', JSON.stringify(user));
    });
  }

  toastError(message:string, topic:string) {
    this.toastService.error(message, topic, {
      disableTimeOut: false,
      closeButton: true,
      enableHtml: true,
      positionClass: 'toast-top-right',
      toastClass: 'toast-margin ngx-toastr'
    });
  }

  toastSuccess(message:string, topic:string) {
    this.toastService.success(message, topic, {
      disableTimeOut: false,
      closeButton: true,
      enableHtml: true,
      positionClass: 'toast-top-right',
      toastClass: 'toast-margin ngx-toastr'
    });
  }

  //swal alert type (successs, error, warning, info, question)
  swalAlert(message:string, topic:string, type:any) {
    Swal.fire({
      icon: type,
      title: topic,
      text: message
    });
  }

  //swal confirmation type:(successs, error, warning, info, question),
  //app (1:zoom, 2:teams)
  swalConfirm(message:string, topic:string, type:any, app:number) {
    Swal.fire({
      icon: type,
      title: topic,
      text: message,
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Delete'
    }).then(result => {
      if(result.isConfirmed) {
        switch (app){
          case 1: {
            this.unbindZoom();
            break;
          }
          case 2: {
            this.unbindTeams();
            break;
          }
          default:
            break;
        }
      }
    });
  }

  //redirect to oauth login
  nudger() {
    const dialogRef = this.dialog.open(NudgeSettings, {
      width: '40%',
      height: 'auto',
      minHeight: '200px'
    });
  }

  //redirect to oauth login
  notification() {
    const dialogRef = this.dialog.open(NotificationSettings, {
      width: '80%',
      height: 'auto',
      minHeight: '200px'
    });
  }
}
