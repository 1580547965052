export const MeetingSourceText = {
    'zoom': 'Zoom',
    'teams': 'Teams',
    'google': 'Google',
    'phone': 'Phone',
    'other': 'Other'
}

export const MeetingSourceIcon = {
    'zoom': 'zoom.svg',
    'teams': 'teams.svg',
    'google': 'google-meet.svg',
    'phone': 'phone.svg',
}
