import { Component, HostListener, OnInit, ViewChildren } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { title } from 'process';
import { Observable } from 'rxjs';
import { DatePickerRanges, DefaultRangeSelected, RangeDate } from 'src/app/configuration/datepicker-range';
import { ChartMetric } from 'src/app/models/chart-metric/chart-metrics';
import { MeetingMember } from '../../models/meeting-member/meeting-member';
import { DatePickerConfigService } from 'src/app/services/datepicker-config/datepicker-config.service';
import { InsightsService } from 'src/app/services/insights/insights.service';
import { ReportService } from 'src/app/services/reports/report.service';
import { MembersService } from '../../services/members/members.service';
import { MeetingTypes, MeetingTypesText, MeetingtoTotals, TypeUnit, NotImplemented } from 'src/enum/meeting-types.enum';
import { DomSanitizer } from '@angular/platform-browser';
import { EChartsOption } from 'echarts';
import { csv, range } from 'd3';
import { faCalendar } from '@fortawesome/free-solid-svg-icons';
import { IDropdownSettings, NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import {duration} from 'moment-timezone';
import * as moment from 'moment-timezone';


@Component({
  selector: 'app-construction',
  templateUrl: './construction.component.html',
  styleUrls: ['./construction.component.scss']
})
export class ConstructionComponent implements OnInit {
  
  constructor(
  ) {
  }

  ngOnInit(): void {
  }

}
