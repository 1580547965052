import { Directive, EventEmitter, Input, Output } from '@angular/core';
import { MeetingMember } from '../../models/meeting-member/meeting-member';
// Gets column position by the column name position
export type SortColumn = keyof MeetingMember | '';
// Specifies the available options can be order by
export type SortDirection = 'ASC' | 'DESC' | '';
const rotate: {[key: string]: SortDirection} = { 'ASC': 'DESC', 'DESC': '', '': 'ASC' };
export interface SortEvent {
  column: SortColumn;
  direction: SortDirection;
}
// Directive that is applied on the headers and adds sorting funcionality
@Directive({
  selector: 'th[sortable]',
  host: {
    '[class.asc]': 'direction === "ASC"',
    '[class.desc]': 'direction === "DESC"',
    '(click)': 'rotate()'
  }
})
export class NgbdSortableHeader {

  // Indicates name of column
  @Input() sortable: SortColumn = '';
  // Direction that list of elements will be sorted by
  @Input() direction: SortDirection = '';
  // Emit a response that datatable was updated
  @Output() sort = new EventEmitter<SortEvent>();

  rotate() {
    this.direction = rotate[this.direction];
    this.sort.emit({column: this.sortable, direction: this.direction});
  }
}
