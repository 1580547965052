// Class that allows the creation of new instances
export class TariyTips {
  id: string;
  category: string;
  class: string;
  tips: string;
  image: string;
  sequenceNo: string;

  constructor(data){
    let spaceReg = new RegExp(" ",'g');
    this.id = data.Id;
    this.class = data.Category.replace(spaceReg, "-");
    this.category = data.Category;
    this.tips = data.Tips;
    this.image = data.image;
    this.sequenceNo = data.SequenceNo;
  }
}
