// Enum of all the roles available in the web app
export enum Role {
  SuperAdmin = 'SuperAdmin',
  Admin = 'Admin',
  Manager = 'Manager',
  Member = 'Member',
  Waiting = 'Waiting',
  ITAdmin = 'ITAdmin'
};

// List of roles used for Dropdowns
export const RoleList = [
  {"id": "SuperAdmin", "name": "SuperAdmin"},
  {"id": "Admin", "name": "Admin"},
  {"id": "Manager", "name": "Manager"},
  {"id": "Member", "name": "Member"},
  {"id": "Waiting", "name": "Waiting"},
  {"id": "ITAdmin", "name": "ITAdmin"}
];