import { NgModule } from '@angular/core';

// Custom modules
import { MemberRoutingModule } from './member-routing.module';
import { SharedModule } from 'src/app/shared/shared.module';

// Layout
import { MemberPanelComponent } from 'src/app/views/layouts/member-panel/member-panel.component';

// Pages
import { MemberProfileComponent } from '../../views/member-profile/member-profile.component';

@NgModule({
  declarations: [
    MemberProfileComponent,
    MemberPanelComponent
  ],
  imports: [
    MemberRoutingModule,
    SharedModule
  ]
})
export class MemberModule { }
