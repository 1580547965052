<section class="insights d-flex justify-content-center">
    <div class="col-12 d-flex justify-content-center align-items-center">
        <div class="col-11 col-md-8 col-lg-8 col-xl-8 h-md-75 h-lg-75 h-xl-75 main-content" >
            <div class="row justify-content-center mt-5">
                <div class="col-12 col-md-4 col-lg-4 col-xl-4 text-center d-inline-flex align-items-center" style="flex-flow: column;">
                    <img [src]="user?.picture" class="img-fluid mr-2 mobile-user profile-img" *ngIf="validPicture" (error)="invalidPicture()">
                    <img src="../../../assets/img/no-picture-user.png" class="img-fluid mr-2 mobile-user no-photo profile-img" *ngIf="!validPicture">
                    <!-- <img src="../../../assets/img/no-picture-user.png" class="img-fluid mr-2 profile no-photo profile-img"> -->
                    <div class="form-group select-form w-100 mt-3">
                        <input type="text" class="form-control w-100 text-center" [placeholder]="user.email" autocomplete="off" formControlName="email" disabled>
                    </div>
                </div>
                <div></div>
            </div>
            <form action="" class="userDetail mt-5" [formGroup]="profileForm">
                <div class="row justify-content-center">
                    <div class="col-12 col-md-4 col-lg-4 col-xl-4 text-center " >
                        <div class="form-group select-form">
                            <label for="firstName">FIRST NAME</label>
                            <input type="text" class="form-control" placeholder="Ex. Software Developer" autocomplete="off" formControlName="firstName" [readonly]="!editable">
                            <div *ngIf="isSubmitted && formFields.firstName.errors" class="errors pt-4">This is a required
                                field.</div>
                        </div>
                    </div>
                    <div class="col-12 col-md-4 col-lg-4 col-xl-4 text-center d-flex align-items-center">
                        <div class="form-group select-form w-100">
                            <label for="lastName">LAST NAME</label>
                            <input type="text" class="form-control" placeholder="Ex. Software Developer" autocomplete="off" formControlName="lastName" [readonly]="!editable">
                            <div *ngIf="isSubmitted && formFields.lastName.errors" class="errors pt-4">This is a required
                                field.</div>
                        </div>
                    </div>
                </div>
    
                <div class="row justify-content-center">
                    <div class="col-12 col-md-4 col-lg-4 col-xl-4 text-center " >
                        <div class="form-group select-form">
                            <label for="jobSelected">JOB TITLE</label>
                            <input type="text" class="form-control" placeholder="Ex. Software Developer" autocomplete="off" formControlName="jobSelected" [readonly]="!editable">
                            <div *ngIf="isSubmitted && formFields.jobSelected.errors" class="errors pt-4">This is a required
                                field.</div>
                        </div>
                    </div>
                    <div class="col-12 col-md-4 col-lg-4 col-xl-4 text-center d-flex align-items-center" >
                        <div class="form-group select-form m-0 w-100 h-100">
                            <label for="email">DEPARTMENT</label>
                            <ng-select name="departmentId" class="select-control text-left" formControlName="departmentId" [items]="departments"
                                bindLabel="name" bindValue="id" [clearable]="false" placeholder="DEPARTMENT" [readonly]="!editable || isLoading"></ng-select>
                            <div *ngIf="isSubmitted && formFields.departmentId.errors" class="errors pt-4">
                                <div *ngIf="formFields.departmentId.errors.required" class="error">This is a required field.</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row justify-content-center">
                    <div class="col-12 col-md-8 col-lg-8 col-xl-8" >
                        <div class="form-group select-form d-inline-flex mt-1">
                            <input type="checkbox" class="form-control mr-2" style="width: 17px; height: 17px; margin-top: auto;" formControlName="useData" 
                                [attr.disabled]="!editable ? true : null">
                            <label for="useData" class="" style="font-size: 1.25rem;">Click here if you authorize the use of biometric data</label>
                            <div *ngIf="isSubmitted && formFields.useData.errors" class="errors pt-4">
                                <div *ngIf="formFields.useData.errors.required" class="error">This is a required field.</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row justify-content-center">
                    <div class="col-12 col-md-8 col-lg-8 col-xl-8 d-flex justify-content-start justify-content-md-end justify-content-lg-end justify-content-xl-end" >
                        <button class="btn btn-started mr-2 edit-btn" (click)="enableEdit()"  *ngIf="editable">CANCEL
                        </button>
                        <button class="btn btn-started mr-2 save-btn" (click)="saveChanges()" *ngIf="editable" >SAVE CHANGES
                        </button>
                        <button class="btn btn-started mr-2 edit-btn" (click)="enableEdit()" *ngIf="!editable" [disabled]="isLoading">EDIT
                        </button>
                    </div>
                    
                </div>
            </form>
        </div>
        
    </div>
    
    
    <!-- <div class="main-container" style="background-color: burlywood;">
        <img src="../../../assets/img/construction.svg" alt="">
        <span class="title">We are working on it</span>
        <span class="subtitle">We are creating a custom experience for you</span>
        <span class="subtitle">This will be ready by the 12th of September</span>
    </div> -->

</section>