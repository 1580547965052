<section class="insights">
    <!-- Main menu-->
    <div class="container-fluid main-menu">
        <div class="row">

            <!-- List/Grid switcher -->
            <div class="col-12 col-lg-2 d-none d-lg-flex justify-content-start align-items-end">
                <div class="options">

                    <!-- List -->
                    <div class="item" (click)="showList = !showList" [ngClass]="showList ? 'active': ''">
                        <img src="../../../assets/img/List-a.svg" alt="" *ngIf="showList">
                        <img src="../../../assets/img/List-i.svg" alt="" *ngIf="!showList">
                        <span>List</span>
                    </div>

                    <!-- Grid -->
                    <div class="item" (click)="showList = !showList" [ngClass]="!showList ? 'active': ''">
                        <img src="../../../assets/img/Grid-a.svg" alt="" *ngIf="!showList">
                        <img src="../../../assets/img/Grid-i.svg" alt="" *ngIf="showList">
                        <span>Grid</span>
                    </div>
                </div>
            </div>

            <!-- Filter pills (desktop) -->
            <div class="col-12 col-lg-5 filter-options-buttons">
                <div class="group-buttons d-flex align-items-center justify-content-center justify-content-center justify-content-lg-center flex-wrap">
                    <button 
                        class="btn rounded-pill"
                        [ngClass]="selectedFilter === filterOptions.DAY ? 'btn-primary' : 'btn-outline-primary'"
                        [disabled]="isLoading"
                        (click)="onFilterChange(filterOptions.DAY)">
                        1 day
                    </button>

                    <button 
                        class="btn rounded-pill"
                        [ngClass]="selectedFilter === filterOptions.WEEK ? 'btn-primary' : 'btn-outline-primary'"
                        [disabled]="isLoading"
                        (click)="onFilterChange(filterOptions.WEEK)">
                        1 week
                    </button>

                    <button 
                        class="btn rounded-pill"
                        [ngClass]="selectedFilter === filterOptions.MONTH ? 'btn-primary' : 'btn-outline-primary'"
                        [disabled]="isLoading"
                        (click)="onFilterChange(filterOptions.MONTH)">
                        1 month
                    </button>

                    <button 
                        class="btn rounded-pill"
                        [ngClass]="selectedFilter === filterOptions.QUARTER? 'btn-primary' : 'btn-outline-primary'"
                        [disabled]="isLoading"
                        (click)="onFilterChange(filterOptions.QUARTER)">
                        1 quarter
                    </button>

                    <button 
                        class="btn rounded-pill"
                        [ngClass]="selectedFilter === filterOptions.YEAR ? 'btn-primary' : 'btn-outline-primary'"
                        [disabled]="isLoading"
                        (click)="onFilterChange(filterOptions.YEAR)">
                        1 year
                    </button>
                </div>
            </div>

            <!-- Filter select (mobile) -->
            <div class="col-12 col-lg-5 filter-options-select">
                <label for="filter">Filter:</label>
                <select
                    name="status"
                    class="form-control my-1"
                    [(ngModel)]="selectedFilter"
                    (change)="onFilterChange()"
                    [disabled]="isLoading">

                    <option [value]="filterOptions.DAY">1 day</option>
                    <option [value]="filterOptions.WEEK">1 week</option>
                    <option [value]="filterOptions.MONTH">1 month</option>
                    <option [value]="filterOptions.QUARTER">1 quarter</option>
                    <option [value]="filterOptions.YEAR">1 year</option>
                    <option [value]="filterOptions.CUSTOM" *ngIf="isCustomFilter">Custom filter</option>
                </select>
            </div>

            <!-- Calendar label -->
            <div class="col-12 col-lg-2 mb-sm-3">
                <div class="row w-100">
                    <label for="daterange" class="col col-form-label text-right mt-3 ml-auto">Filter by date:</label>
                </div>
            </div>

            <!-- DatePicker -->
            <div class="col-12 col-lg-3 mb-3 mb-lg-0 pl-lg-0 pr-0 d-flex align-items-center">
                <div class="row w-100">
                    <div class="col daterange-input mx-3 mx-lg-0">
                        <input ngxDaterangepickerMd
                            type="text"
                            name="daterange"
                            class="form-control daterange"
                            autocomplete="off"
                            placeholder="Choose date"
                            [ranges]="ranges"
                            [showCustomRangeLabel]="true"
                            [alwaysShowCalendars]="true"
                            [showRangeLabelOnInput]="true"
                            [(ngModel)]="selectedRangeDates"
                            [disabled]="isLoading"
                            (change)="onDatePickerChange($event)"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- Table -->
    <div class="container-fluid dashtable" *ngIf="showList"
        [ngClass]="showList ? 'px-3 py-1 px-lg-4 px-xl-4' : 'px-3 py-1 pb-0'">
        <div class="col-12">
            <div class="row table-container" [ngClass]="!showList ? 'grid-version': ''">
                <div class="col-12">
                    <div class="row d-flex align-items-center py-2">
                        <table class="table w-100">
                            <thead>
                                <tr>
                                    <th colspan="2" width="25%" class="empty">&nbsp;</th>
                                    <th class="text-center" width="17%">
                                        <div>{{ resultsColumnTitle }}</div>
                                    </th>
                                    <th class="text-center" width="13%">
                                        <div>{{ comparationColumnTitle }}</div>
                                    </th>
                                    <th width="30%">
                                        <div>INSIGHTS</div>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr class="vertical-header">
                                    <th rowspan="3" class="vertical-title">
                                        <div class="title-dollars">DOLLARS</div>
                                    </th>
                                </tr>
                                <tr class="empty-row">
                                    <td colspan="3"></td>
                                    <td colspan="1"></td>
                                </tr>

                                <!-- Skeleton -->
                                <ng-container class="tr-loading" *ngFor="let metric of ((isLoading || hasError) ? metricType: []) | slice:0:2; let i = index;">
                                    <tr [ngClass]="i == 1 ? 'd-none': ''">
                                        <td>
                                            <div class="description">
                                                <ngx-skeleton-loader appearance="circle" [theme]="{ height: '100px',width: '100px'}">
                                                </ngx-skeleton-loader>
                                                <div class="gravatar-title ml-4">
                                                    <div>
                                                        <ngx-skeleton-loader
                                                            [theme]="{width: '200px','border-radius': '0',height: '15px','margin-bottom': '10px'}">
                                                        </ngx-skeleton-loader>
                                                    </div>
                                                    <div>
                                                        <ngx-skeleton-loader [theme]="{ width: '170px', 'border-radius': '0', height: '15px' }">
                                                        </ngx-skeleton-loader>
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                        <td class="range">
                                            <ngx-skeleton-loader></ngx-skeleton-loader>
                                        </td>
                                        <td class="result">
                                            <ngx-skeleton-loader></ngx-skeleton-loader>
                                        </td>
                                        <td class="insight">
                                            <ngx-skeleton-loader></ngx-skeleton-loader>
                                            <ngx-skeleton-loader></ngx-skeleton-loader>
                                            <ngx-skeleton-loader [theme]="{ 'margin-bottom': '0px'}"></ngx-skeleton-loader>
                                        </td>
                                    </tr>
                                    <tr class="empty-row">
                                        <td colspan="3"></td>
                                        <td colspan="1"></td>
                                    </tr>
                                </ng-container>
                                <!-- /Skeleton -->

                                <ng-container *ngFor="let metric of metricType | slice: 0:2; let i = index">
                                    <tr *ngIf="!(isLoading || hasError)" [ngClass]="i == 1 ? 'd-none': ''">
                                        <td>
                                            <div class="description">
                                                <img
                                                    src="{{ '../../../assets/img/'+ metricsData[metric]?.computation +'.svg' }}" />
                                                <p>{{ metricsData[metric]?.title }}</p>
                                            </div>
                                        </td>
                                        <td class="range">
                                            <div class="d-flex align-items-center justify-content-center">
                                                <span>
                                                    {{ metricsData[metric]?.total | currency }}
                                                </span>

                                                <span
                                                    *ngIf="!isCustomFilter && relativeChangePercentageTotal[metric] !== undefined"
                                                    class="relative-percentage"
                                                    [ngClass]="getRelativePercentageClass(metric, relativeChangePercentageTotal[metric])">
                                                    &nbsp; ({{ relativeChangePercentageTotal[metric] | relativePercentage }})
                                                </span>
                                            </div>
                                        </td>
                                        <td class="result" [ngClass]="isCustomFilter ? 'disabled' : ''">
                                            {{ pastMetricsData[metric]?.total | currency }}
                                        </td>
                                        <td class="insight">
                                            <div *ngIf="metricsData[metric]?.insights; else noInsights">
                                                <ng-container *ngIf="metricsData[metric]?.loaded; else notFinished">
                                                    <ng-container *ngFor="let insight of metricsData[metric]?.insights">
                                                        <p> {{insight}}</p>
                                                    </ng-container>
                                                </ng-container>
                                                <ng-template #notFinished>
                                                    {{metricsData[metric]?.loading}}
                                                    <ngx-skeleton-loader></ngx-skeleton-loader>
                                                    <ngx-skeleton-loader></ngx-skeleton-loader>
                                                    <ngx-skeleton-loader [theme]="{ 'margin-bottom': '0px'}"></ngx-skeleton-loader>
                                                </ng-template>
                                            </div>
                                            <ng-template #noInsights>
                                                <p>No Insights available.</p>
                                            </ng-template>
                                        </td>
                                    </tr>
                                    <tr class="empty-row TRES" *ngIf="!(isLoading || hasError)">
                                        <td colspan="3"></td>
                                        <td colspan="1"></td>
                                    </tr>
                                </ng-container>
    
                                <tr class="vertical-header hours">
                                    <th rowspan="8" class="vertical-title">
                                        <div class="title-hours">TIME</div>
                                    </th>
                                </tr>
    
                                <!-- Skeleton -->
                                <ng-container class="tr-loading"
                                    *ngFor="let metric of ((isLoading || hasError) ? metricType : []) | slice:0:4">
                                    <tr>
                                        <td>
                                            <div class="description">
                                                <ngx-skeleton-loader appearance="circle"
                                                    [theme]="{ height: '80px',width: '80px'}"></ngx-skeleton-loader>
                                                <div class="gravatar-title ml-4">
                                                    <div>
                                                        <ngx-skeleton-loader
                                                            [theme]="{width: '200px','border-radius': '0',height: '20px','margin-bottom': '10px'}">
                                                        </ngx-skeleton-loader>
                                                    </div>
                                                    <div>
                                                        <ngx-skeleton-loader
                                                            [theme]="{ width: '170px', 'border-radius': '0', height: '20px' }">
                                                        </ngx-skeleton-loader>
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                        <td class="range">
                                            <ngx-skeleton-loader></ngx-skeleton-loader>
                                        </td>
                                        <td class="result">
                                            <ngx-skeleton-loader></ngx-skeleton-loader>
                                        </td>
                                        <td class="insight">
                                            <ngx-skeleton-loader></ngx-skeleton-loader>
                                            <ngx-skeleton-loader></ngx-skeleton-loader>
                                            <ngx-skeleton-loader [theme]="{ 'margin-bottom': '0px'}"></ngx-skeleton-loader>
                                        </td>
                                    </tr>
                                    <tr class="empty-row">
                                        <td colspan="3"></td>
                                        <td colspan="1"></td>
                                    </tr>
                                </ng-container>
                                <!-- /Skeleton -->
    
                                <ng-container *ngFor="let metric of metricType | slice:2:6">
                                    <tr *ngIf="!(isLoading || hasError)">
                                        <td>
                                            <div class="description">
                                                <img
                                                    src="{{ '../../../assets/img/'+ metricsData[metric]?.computation +'.svg' }}" />
                                                <p>{{ metricsData[metric]?.title }}</p>
                                            </div>
                                        </td>
                                        <td class="range">
                                            <div class="d-flex align-items-center justify-content-center">
                                                <span>{{ metricsData[metric]?.hours | number: '1.02' }} hr</span>

                                                <span
                                                    *ngIf="!isCustomFilter && relativeChangePercentageHours[metric] !== undefined"
                                                    class="relative-percentage"
                                                    [ngClass]="getRelativePercentageClass(metric, relativeChangePercentageHours[metric])">
                                                    &nbsp; ({{ relativeChangePercentageHours[metric] | relativePercentage }})
                                                </span>
                                            </div>
                                        </td>
                                        <td class="result" [ngClass]="isCustomFilter ? 'disabled' : ''">
                                            {{ pastMetricsData[metric]?.hours | number: '1.02' }} hr
                                        </td>
                                        <td class="insight">
                                            <!-- <h6>LOWER COST OF MEETINGS</h6> -->
                                            <div *ngIf="metricsData[metric]?.insights; else noInsights">
                                                <ng-container *ngIf="metricsData[metric]?.loaded; else notFinished">
                                                    <ng-container *ngFor="let insight of metricsData[metric]?.insights">
                                                        <p> {{insight}}</p>
                                                    </ng-container>
                                                </ng-container>
                                                <ng-template #notFinished>
                                                    {{metricsData[metric]?.loading}}
                                                    <ngx-skeleton-loader></ngx-skeleton-loader>
                                                    <ngx-skeleton-loader></ngx-skeleton-loader>
                                                    <ngx-skeleton-loader [theme]="{ 'margin-bottom': '0px'}"></ngx-skeleton-loader>
                                                </ng-template>
                                            </div>
                                            <ng-template #noInsights>
                                                <p>No Insights available.</p>
                                            </ng-template>
                                        </td>
                                    </tr>
                                    <tr class="empty-row">
                                        <td colspan="3"></td>
                                        <td colspan="1"></td>
                                    </tr>
                                </ng-container>

                                <tr class="vertical-header hours">
                                    <th rowspan="2" class="vertical-title">
                                        <div class="title-hours">NUMBERS</div>
                                    </th>
                                </tr>
    
                                <!-- Skeleton -->
                                <ng-container class="tr-loading"
                                    *ngFor="let metric of ((isLoading || hasError) ? metricType : []) | slice:0:2; let i = index;">
                                    <tr [ngClass]="i == 1 ? 'd-none': ''">
                                        <td>
                                            <div class="description">
                                                <ngx-skeleton-loader appearance="circle"
                                                    [theme]="{ height: '80px',width: '80px'}"></ngx-skeleton-loader>
                                                <div class="gravatar-title ml-4">
                                                    <div>
                                                        <ngx-skeleton-loader
                                                            [theme]="{width: '200px','border-radius': '0',height: '20px','margin-bottom': '10px'}">
                                                        </ngx-skeleton-loader>
                                                    </div>
                                                    <div>
                                                        <ngx-skeleton-loader
                                                            [theme]="{ width: '170px', 'border-radius': '0', height: '20px' }">
                                                        </ngx-skeleton-loader>
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                        <td class="range">
                                            <ngx-skeleton-loader></ngx-skeleton-loader>
                                        </td>
                                        <td class="result">
                                            <ngx-skeleton-loader></ngx-skeleton-loader>
                                        </td>
                                        <td class="insight">
                                            <ngx-skeleton-loader></ngx-skeleton-loader>
                                            <ngx-skeleton-loader></ngx-skeleton-loader>
                                            <ngx-skeleton-loader [theme]="{ 'margin-bottom': '0px'}"></ngx-skeleton-loader>
                                        </td>
                                    </tr>
                                    <tr class="empty-row">
                                        <td colspan="3"></td>
                                        <td colspan="1"></td>
                                    </tr>
                                </ng-container>
                                <!-- /Skeleton -->
    
                                <ng-container *ngFor="let metric of metricType | slice:6:8; let i = index;">
                                    <tr *ngIf="!(isLoading || hasError)" [ngClass]="i == 1 ? 'd-none': ''">
                                        <td>
                                            <div class="description">
                                                <img
                                                    src="{{ '../../../assets/img/'+ metricsData[metric]?.computation +'.svg' }}" />
                                                <p>{{ metricsData[metric]?.title }}</p>
                                            </div>
                                        </td>
                                        <td class="range">
                                            <div class="d-flex align-items-center justify-content-center">
                                                <span>{{ metricsData[metric]?.hours | number: '1.0' }}</span>

                                                <span
                                                    *ngIf="!isCustomFilter && relativeChangePercentageHours[metric] !== undefined"
                                                    class="relative-percentage"
                                                    [ngClass]="getRelativePercentageClass(metric, relativeChangePercentageHours[metric])">
                                                    &nbsp; ({{ relativeChangePercentageHours[metric] | relativePercentage }})
                                                </span>
                                            </div>
                                        </td>
                                        <td class="result" [ngClass]="isCustomFilter ? 'disabled' : ''">
                                            {{ pastMetricsData[metric]?.hours | number: '1.0' }}
                                        </td>
                                        <td class="insight">
                                            <!-- <h6>LOWER COST OF MEETINGS</h6> -->
                                            <div *ngIf="metricsData[metric]?.insights; else noInsights">
                                                <ng-container *ngIf="metricsData[metric]?.loaded; else notFinished">
                                                    <ng-container *ngFor="let insight of metricsData[metric]?.insights">
                                                        <p> {{insight}}</p>
                                                    </ng-container>
                                                </ng-container>
                                                <ng-template #notFinished>
                                                    {{metricsData[metric]?.loading}}
                                                    <ngx-skeleton-loader></ngx-skeleton-loader>
                                                    <ngx-skeleton-loader></ngx-skeleton-loader>
                                                    <ngx-skeleton-loader [theme]="{ 'margin-bottom': '0px'}"></ngx-skeleton-loader>
                                                </ng-template>
                                            </div>
                                            <ng-template #noInsights>
                                                <p>No Insights available.</p>
                                            </ng-template>
                                        </td>
                                    </tr>
                                    <tr class="empty-row">
                                        <td colspan="3"></td>
                                        <td colspan="1"></td>
                                    </tr>
                                </ng-container>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- Grid 456-->
    <div class="container-fluid grid pt-0 pt-lg-3 pb-4" *ngIf="!showList">
        <div class="col-12">
            <div class="row equal">
                <ng-container *ngFor="let metric of metricType | slice:0:2; let i = index">
                    <div class="col-12 col-lg-6 col-xl-4" *ngIf="!(isLoading || hasError)" [ngClass]="i == 1 ? 'd-none': ''">
                        <div class="card">
                            <div class="header">
                                <div class="title">
                                    <img src='{{ "../../../assets/img/" + metricsData[metric]?.computation + ".svg" }}' />
                                    <div class="text">{{ metricsData[metric]?.title }}</div>
                                </div>
                                <div class="type">
                                    <span class="info-svg" *ngIf="!cardShowInfo[i]"
                                        (click)="cardShowInfo[i] = !cardShowInfo[i]"></span>
                                    <span class="question-svg" *ngIf="cardShowInfo[i]"
                                        (click)="cardShowInfo[i] = !cardShowInfo[i]"></span>
                                </div>
                            </div>
                            <div class="body" [ngClass]="cardShowInfo[i] ? 'showInfo': ''">
                                <div class="information">
                                    <!-- <div class="title">LOWER THE COST OF MEETINGS</div> -->
                                    <div *ngIf="metricsData[metric]?.insights; else noInsights">
                                        <ng-container *ngFor="let insight of metricsData[metric]?.insights">
                                            <p> {{insight}}</p>
                                        </ng-container>
                                    </div>
                                    <ng-template #noInsights>
                                        <p>No Insights available.</p>
                                    </ng-template>
                                </div>
                                <div class="dates">
                                    <div class="startDate">
                                        <div class="date">
                                            {{ resultsColumnTitle }}
                                        </div>

                                        <div class="total">
                                            {{ metricsData[metric]?.total | currency }}
                                        </div>
                                    </div>

                                    <div class="endDate" *ngIf="!isCustomFilter">
                                        <div class="date">
                                            {{ comparationColumnTitle }}
                                        </div>

                                        <div class="total">
                                            {{ pastMetricsData[metric]?.total | currency }}
                                        </div>
                                    </div>

                                </div>
                                <div class="footer">
                                    <div class="row">
                                        <div class="col-6">
                                            <span
                                                *ngIf="!isCustomFilter"
                                                class="totalDiff">
                                                {{ metricsData[metric]?.total - pastMetricsData[metric]?.total | currency }}
                                            </span>
                                            <span *ngIf="isCustomFilter">
                                                {{ metricsData[metric]?.total | currency }}
                                            </span>
                                        </div>

                                        <div class="col-6 text-right">
                                            <span
                                                *ngIf="!isCustomFilter"
                                                [ngClass]="getRelativePercentageClass(metric, relativeChangePercentageTotal[metric])"
                                                class="totalDiff relative-percentage">
                                                {{ relativeChangePercentageTotal[metric] | relativePercentage }}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-container>
                <ng-container *ngFor="let metric of metricType | slice:2:4; let i = index">
                    <div class="col-12 col-lg-6 col-xl-4" *ngIf="!(isLoading || hasError)">
                        <div class="card">
                            <div class="header">
                                <div class="title">
                                    <img src='{{ "../../../assets/img/" + metricsData[metric]?.computation + ".svg" }}' />
                                    <div class="text">{{ metricsData[metric]?.title }}</div>
                                </div>
                                <div class="type">
                                    <span class="info-svg" *ngIf="!cardShowInfo[i+2]"
                                        (click)="cardShowInfo[i+2] = !cardShowInfo[i+2]"></span>
                                    <span class="question-svg" *ngIf="cardShowInfo[i+2]"
                                        (click)="cardShowInfo[i+2] = !cardShowInfo[i+2]"></span>
                                </div>
                            </div>
                            <div class="body" [ngClass]="cardShowInfo[i+2] ? 'showInfo': ''">
                                <div class="information">
                                    <!-- <div class="title">LOWER THE COST OF MEETINGS</div> -->
                                    <div *ngIf="metricsData[metric]?.insights; else noInsights">
                                        <ng-container *ngFor="let insight of metricsData[metric]?.insights">
                                            <p> {{insight}}</p>
                                        </ng-container>
                                    </div>
                                    <ng-template #noInsights>
                                        <p>No Insights available.</p>
                                    </ng-template>
                                </div>
                                <div class="dates">
                                    <div class="startDate">
                                        <div class="date">
                                            {{ resultsColumnTitle }}
                                        </div>

                                        <div class="total">
                                            {{ metricsData[metric]?.hours | number: '1.02' }} hr
                                        </div>
                                    </div>

                                    <div class="endDate" *ngIf="!isCustomFilter">
                                        <div class="date">
                                            {{ comparationColumnTitle }}
                                        </div>

                                        <div class="total">
                                            {{ pastMetricsData[metric]?.hours | number: '1.02' }} hr
                                            <span class="relative-percentage" [ngClass]="getRelativePercentageClass(metric, relativeChangePercentageHours[metric])">
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="footer">
                                    <div class="row">
                                        <div class="col-6">
                                            <span
                                                *ngIf="!isCustomFilter"
                                                class="totalDiff">
                                                {{ metricsData[metric]?.hours - pastMetricsData[metric]?.hours | number: '1.02' }} hr
                                            </span>
                                            <span *ngIf="isCustomFilter">
                                                {{ metricsData[metric]?.hours | number: '1.02' }} hr
                                            </span>
                                        </div>

                                        <div class="col-6 text-right">
                                            <span
                                                *ngIf="!isCustomFilter"
                                                [ngClass]="getRelativePercentageClass(metric, relativeChangePercentageHours[metric])"
                                                class="totalDiff relative-percentage">
                                                {{ relativeChangePercentageHours[metric] | relativePercentage }}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-container>
                <ng-container *ngFor="let metric of metricType | slice:4:6; let i = index">
                    <div class="col-12 col-lg-6 col-xl-4" *ngIf="!(isLoading || hasError)">
                        <div class="card">
                            <div class="header">
                                <div class="title">
                                    <img src='{{ "../../../assets/img/" + metricsData[metric]?.computation + ".svg" }}' />
                                    <div class="text">{{ metricsData[metric]?.title }}</div>
                                </div>
                                <div class="type">
                                    <span class="info-svg" *ngIf="!cardShowInfo[i+4]"
                                        (click)="cardShowInfo[i+4] = !cardShowInfo[i+4]"></span>
                                    <span class="question-svg" *ngIf="cardShowInfo[i+4]"
                                        (click)="cardShowInfo[i+4] = !cardShowInfo[i+4]"></span>
                                </div>
                            </div>
                            <div class="body" [ngClass]="cardShowInfo[i+4] ? 'showInfo': ''">
                                <div class="information">
                                    <!-- <div class="title">LOWER THE COST OF MEETINGS</div> -->
                                    <div *ngIf="metricsData[metric]?.insights; else noInsights">
                                        <ng-container *ngFor="let insight of metricsData[metric]?.insights">
                                            <p> {{insight}}</p>
                                        </ng-container>
                                    </div>
                                    <ng-template #noInsights>
                                        <p>No Insights available.</p>
                                    </ng-template>
                                </div>
                                <div class="dates">
                                    <div class="startDate">
                                        <div class="date">
                                            {{ resultsColumnTitle }}
                                        </div>

                                        <div class="total">
                                            {{ metricsData[metric]?.counter | number: '1.0' }}
                                        </div>
                                    </div>

                                    <div class="endDate" *ngIf="!isCustomFilter">
                                        <div class="date">
                                            {{ comparationColumnTitle }}
                                        </div>

                                        <div class="total">
                                            {{ pastMetricsData[metric]?.counter | number: '1.0' }}
                                            <span class="relative-percentage" [ngClass]="getRelativePercentageClass(metric, relativeChangePercentageHours[metric])">
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="footer">
                                    <div class="row">
                                        <div class="col-6">
                                            <span
                                                *ngIf="!isCustomFilter"
                                                class="totalDiff">
                                                {{ metricsData[metric]?.counter - pastMetricsData[metric]?.counter | number: '1.0' }}
                                            </span>
                                            <span *ngIf="isCustomFilter">
                                                {{ metricsData[metric]?.counter | number: '1.0' }}
                                            </span>
                                        </div>

                                        <div class="col-6 text-right">
                                            <span
                                                *ngIf="!isCustomFilter"
                                                [ngClass]="getRelativePercentageClass(metric, relativeChangePercentageHours[metric])"
                                                class="totalDiff relative-percentage">
                                                {{ relativeChangePercentageHours[metric] | relativePercentage }}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-container>
                <ng-container *ngFor="let metric of metricType | slice:6:8; let i = index">
                    <div class="col-12 col-lg-6 col-xl-4" *ngIf="!(isLoading || hasError)" [ngClass]="i == 1 ? 'd-none': ''">
                        <div class="card">
                            <div class="header">
                                <div class="title">
                                    <img src='{{ "../../../assets/img/" + metricsData[metric]?.computation + ".svg" }}' />
                                    <div class="text">{{ metricsData[metric]?.title }}</div>
                                </div>
                                <div class="type">
                                    <span class="info-svg" *ngIf="!cardShowInfo[i+6]"
                                        (click)="cardShowInfo[i+6] = !cardShowInfo[i+6]"></span>
                                    <span class="question-svg" *ngIf="cardShowInfo[i+6]"
                                        (click)="cardShowInfo[i+6] = !cardShowInfo[i+6]"></span>
                                </div>
                            </div>
                            <div class="body" [ngClass]="cardShowInfo[i+6] ? 'showInfo': ''">
                                <div class="information">
                                    <!-- <div class="title">LOWER THE COST OF MEETINGS</div> -->
                                    <div *ngIf="metricsData[metric]?.insights; else noInsights">
                                        <ng-container *ngFor="let insight of metricsData[metric]?.insights">
                                            <p> {{insight}}</p>
                                        </ng-container>
                                    </div>
                                    <ng-template #noInsights>
                                        <p>No Insights available.</p>
                                    </ng-template>
                                </div>
                                <div class="dates">
                                    <div class="startDate">
                                        <div class="date">
                                            {{ resultsColumnTitle }}
                                        </div>

                                        <div class="total">
                                            {{ metricsData[metric]?.hours | number: '1.02' }} hr
                                        </div>
                                    </div>

                                    <div class="endDate" *ngIf="!isCustomFilter">
                                        <div class="date">
                                            {{ comparationColumnTitle }}
                                        </div>

                                        <div class="total">
                                            {{ pastMetricsData[metric]?.hours | number: '1.02' }} hr
                                            <span class="relative-percentage" [ngClass]="getRelativePercentageClass(metric, relativeChangePercentageHours[metric])">
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="footer">
                                    <div class="row">
                                        <div class="col-6">
                                            <span
                                                *ngIf="!isCustomFilter"
                                                class="totalDiff">
                                                {{ metricsData[metric]?.hours - pastMetricsData[metric]?.hours | number: '1.02' }} hr
                                            </span>
                                            <span *ngIf="isCustomFilter">
                                                {{ metricsData[metric]?.hours | number: '1.02' }} hr
                                            </span>
                                        </div>

                                        <div class="col-6 text-right">
                                            <span
                                                *ngIf="!isCustomFilter"
                                                [ngClass]="getRelativePercentageClass(metric, relativeChangePercentageHours[metric])"
                                                class="totalDiff relative-percentage">
                                                {{ relativeChangePercentageHours[metric] | relativePercentage }}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-container>
                <ng-container *ngIf="isLoading || hasError">
                    <div class="col-12 col-lg-6 col-xl-4" *ngFor="let metric of metricType">
                        <div class="card skeleton">
                            <div class="header pb-0">
                                <div class="title w-100">
                                    <ngx-skeleton-loader appearance="circle" [theme]="{ height: '45px',width: '45px'}">
                                    </ngx-skeleton-loader>
                                    <div
                                        class="gravatar-title ml-4 d-flex align-items-center justify-content-between w-100">
                                        <div>
                                            <ngx-skeleton-loader
                                                [theme]="{ width: '200px','border-radius': '0', height: '20px', 'margin-bottom': '0px'}">
                                            </ngx-skeleton-loader>
                                        </div>
                                        <div>
                                            <ngx-skeleton-loader
                                                [theme]="{ width: '40px', 'border-radius': '0', height: '20px', 'margin-bottom': '0px' }">
                                            </ngx-skeleton-loader>
                                        </div>
                                    </div>
                                </div>
                                <div class="type">
                                    <ngx-skeleton-loader></ngx-skeleton-loader>
                                </div>
                            </div>
                            <div class="body">
                                <div class="dates">
                                    <div class="startDate">
                                        <div>
                                            <ngx-skeleton-loader
                                                [theme]="{ width: '170px', 'border-radius': '0', height: '15px', 'margin-bottom': '12px' }">
                                            </ngx-skeleton-loader>
                                        </div>
                                        <div>
                                            <ngx-skeleton-loader
                                                [theme]="{ width: '170px', 'border-radius': '0', height: '15px', 'margin-bottom': '0' }">
                                            </ngx-skeleton-loader>
                                        </div>
                                    </div>
                                    <div class="endDate">
                                        <div>
                                            <ngx-skeleton-loader
                                                [theme]="{ width: '170px', 'border-radius': '0', height: '15px', 'margin-bottom': '12px' }">
                                            </ngx-skeleton-loader>
                                        </div>
                                        <div>
                                            <ngx-skeleton-loader
                                                [theme]="{ width: '170px', 'border-radius': '0', height: '15px', 'margin-bottom': '0' }">
                                            </ngx-skeleton-loader>
                                        </div>
                                    </div>
                                </div>
                                <div class="footer d-flex flex-row align-items-center justify-content-between">
                                    <div>
                                        <ngx-skeleton-loader
                                            [theme]="{ width: '170px', 'border-radius': '0', height: '15px', 'margin-bottom': '0' }">
                                        </ngx-skeleton-loader>
                                    </div>
                                    <div>
                                        <ngx-skeleton-loader
                                            [theme]="{ width: '70px', 'border-radius': '0', height: '15px', 'margin-bottom': '0' }">
                                        </ngx-skeleton-loader>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>

</section>
