import { NgModule } from '@angular/core';

// Custom modules
import { ITAdminRoutingModule } from './itadmin-routing.module';
import { SharedModule } from 'src/app/shared/shared.module';

// Layout
import { WaitlistPanelComponent } from 'src/app/views/layouts/waitlist-panel/waitlist-panel.component';

// Pages
import { WaitingDashboardComponent } from '../../views/waiting-dashboard/waiting-dashboard.component';
import { MemberInsightsComponent } from '../../views/member-insights/member-insights.component';
import { ConstructionComponent } from '../../views/construction/construction.component';


@NgModule({
  declarations: [
  ],
  imports: [
    ITAdminRoutingModule,
    SharedModule
  ]
})
export class ITAdminModule { }
