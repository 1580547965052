import { Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { title } from 'process';
import { Observable } from 'rxjs';
import { DatePickerRanges, DefaultRangeSelected, RangeDate } from 'src/app/configuration/datepicker-range';
import { HistoryMetric } from 'src/app/models/history-metric/history-metrics';
import { MeetingMember } from '../../models/meeting-member/meeting-member';
import { DatePickerConfigService } from 'src/app/services/datepicker-config/datepicker-config.service';
import { InsightsService } from 'src/app/services/insights/insights.service';
import { MembersService } from '../../services/members/members.service';
import { MeetingTypes } from 'src/enum/meeting-types.enum';
import { InsightsFilterOptions } from './member-insights-filter';
import * as moment from 'moment-timezone';

@Component({
  selector: 'app-member-insights',
  templateUrl: './member-insights.component.html',
  styleUrls: ['./member-insights.component.scss']
})
export class MemberInsightsComponent implements OnInit {
   // Helper that show/hide grid or table of meetings
  showList = true;

  // Indicates how many members per page will be displayed
  selectedRangeDates = this.datePickerConfigService.selectedDateRange;

  // Period of time that user might need of defining by himself
  ranges = DatePickerRanges;

  // Filter
  filterOptions = InsightsFilterOptions;

  // Selected filter
  selectedFilter = InsightsFilterOptions.DAY;

  // Helper that indicates if extra information of card is shown
  cardShowInfo = [false,false,false,false,false];

  // Listen for changes from history metrics
  metrics$: Observable<HistoryMetric[]>;
  pastMetrics$: Observable<HistoryMetric[]>;
  members$: Observable<MeetingMember[]>;

  // Indicates how many members per page will be displayed
  loading$: Observable<boolean>;
  pastLoading$: Observable<boolean>;

  isLoadingMetrics: boolean;
  isPastLoading: boolean;

  hasError$: Observable<boolean>;

  // Stores the metrics availables for history section
  metricsData = [];
  pastMetricsData = [];

  hasError: boolean;

  // Helper used to display metrics on a specific order
  metricType = Object.values(MeetingTypes);


  constructor(
    private insightsService: InsightsService,
    private datePickerConfigService: DatePickerConfigService,
    private toastr: ToastrService,
    private memberInsightService:MembersService,
    private aroute: ActivatedRoute
  ) {

    this.metrics$ = insightsService.metrics$;
    this.pastMetrics$ = insightsService.pastMetrics$;
    this.memberInsightService.infoRequired = 'dashboard';
    this.memberInsightService.itemsPerPage = 1000;

    this.loading$ = insightsService.loading$;
    this.pastLoading$ = insightsService.pastLoading$;
    this.metrics$['users'] = memberInsightService.members$;
    this.members$ = memberInsightService.members$;

    this.hasError$ = insightsService.hasError$;

    this.loading$.subscribe(value => {
      this.isLoadingMetrics = value;
    });

    this.pastLoading$.subscribe(value => {
      this.isPastLoading = value;
    });

    this.metrics$.subscribe(data => {
      this.metricsData = data;
    });

    this.pastMetrics$.subscribe(data => {
      this.pastMetricsData = data;
    });

    /*this.members$.subscribe(data => {
      this.metricsData.push(data);
      this.metricsData['members'] = data;
      this.createUserInsights(data);
      console.log('members', this.metricsData);
    });*/

    this.hasError$.subscribe(hasError => {
      if (hasError) {
        this.handleError();
      }
      this.hasError = hasError;
    })
  }

  ngOnInit(): void {
    this.updateFilterSelection(this.selectedRangeDates);

    if(this.aroute.snapshot.queryParams.hasOwnProperty('start')) {
      this.selectedRangeDates['startDate'] = moment(this.aroute.snapshot.queryParams['start'],"YYYY-MM-DD").startOf('day')
      this.selectedRangeDates['endDate'] = moment(this.aroute.snapshot.queryParams['end'],"YYYY-MM-DD").endOf('day');
      this.insightsService.forceCache = this.aroute.snapshot.queryParams['fcache'];
    }

    this.insightsService.range_date = this.selectedRangeDates;
    this.insightsService.range_date_second = this.selectedPastRange;
    this.insightsService.isCustomFilter = this.isCustomFilter;

    this.memberInsightService.range_date = this.selectedRangeDates;

    this.updateFilterSelection(this.selectedRangeDates);
    this.onDateChange();

    if(window.innerWidth < 992){
      this.showList = false;
    }
  }

  // When the window's size is lowest than the specified
  // always shows the grid version
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    let width = event.target.innerWidth;
    if (width < 992){
      this.showList = false;
    }
  }

  public createUserInsights(users: MeetingMember[]) {
    if(users.length > 0) {
      if(this.isLoading) {
        setTimeout(() => this.createUserInsights(users), 100);
      }
      let replacer = users.find(u => this.metricsData['avg_user_cost']['insights'][0].includes(u.email));
      this.metricsData['avg_user_cost']['insights'][0] = this.metricsData['avg_user_cost']['insights'][0].replace(replacer.email, replacer.fullname);
      replacer = users.find(u => this.metricsData['avg_user_cost']['insights'][1].includes(u.email));
      this.metricsData['avg_user_cost']['insights'][1] = this.metricsData['avg_user_cost']['insights'][1].replace(replacer.email, replacer.fullname);
      this.metricsData['avg_user_cost']['loaded'] = true;

      let meetingTimeInsights = [];
      let meetingestUser = users.filter(u => parseFloat(u.attended.hours) > 0).sort((a, b) =>  parseFloat(a.attended.hours)<parseFloat(b.attended.hours)?1:-1);
      meetingTimeInsights.push(`${meetingestUser[0].fullname} is your attendee with the most meeting time during this period, with ${meetingestUser[0].attended.hours} hours of meeting time`);
      meetingTimeInsights.push(`${meetingestUser[meetingestUser.length - 1].fullname} is your attendee with the least meeting time during this period, with ${meetingestUser[meetingestUser.length - 1].attended.hours} hours of meeting time`);
      this.metricsData['busy_time']['insights'] = meetingTimeInsights;
      this.metricsData['busy_time']['loaded'] = true;

      let freeTimeInsights = [];
      let freestUser = users.filter(u => parseFloat(u.attended.hours) > 0).sort((a, b)  => parseFloat(a.attended.hours)>parseFloat(b.attended.hours)?1:-1);
      freeTimeInsights.push(`${freestUser[0].fullname} is your most available attendee during this period, with ${freestUser[0].attended.hours} hours of meeting time`);
      freeTimeInsights.push(`${freestUser[freestUser.length - 1].fullname} is your least available attendee during this period, with ${freestUser[freestUser.length - 1].attended.hours} hours of meeting time`);
      this.metricsData['free_time']['insights'] = freeTimeInsights;
      this.metricsData['free_time']['loaded'] = true;
      
      let lateTimeInsights = [];
      let latesttUser = freestUser.sort((a, b) => parseFloat(a.late.hours)<parseFloat(b.late.hours)?1:-1);
      lateTimeInsights.push(`${latesttUser[0].fullname} is your attendee with the most cumulative late attendance during this period, with ${latesttUser[0].late.hours} hours`);
      lateTimeInsights.push(`${latesttUser[latesttUser.length - 1].fullname} is your attendee with the less cumulative late attendance during this period, with ${latesttUser[latesttUser.length - 1].late.hours} hours`);
      this.metricsData['late_participants']['insights'] = lateTimeInsights;
      this.metricsData['late_participants']['loaded'] = true;
    }
  }
  public onDateChange() {
    if (this.isCustomFilter) {
      this.resetPastMetricsData();
    }

    this.insightsService.isCustomFilter = this.isCustomFilter;

    this.insightsService.range_date = this.selectedRangeDates;
    this.insightsService.range_date_second = this.selectedPastRange;
    this.memberInsightService.range_date = this.selectedRangeDates;

    this.datePickerConfigService.selectedDateRange = this.selectedRangeDates;
  }

  public onFilterChange(filter = "") {
    if (filter !== "") {
      this.selectedFilter = filter;
    }

    switch (this.selectedFilter) {
      case this.filterOptions.DAY:
        this.selectedRangeDates = DefaultRangeSelected.TODAY;
        break;
      case this.filterOptions.WEEK:
        this.selectedRangeDates = DefaultRangeSelected.THIS_WEEK;
        break;
      case this.filterOptions.MONTH:
        this.selectedRangeDates = DefaultRangeSelected.THIS_MONTH;
        break;
      case this.filterOptions.QUARTER:
        this.selectedRangeDates = DefaultRangeSelected.THIS_QUARTER;
        break;
      case this.filterOptions.YEAR:
        this.selectedRangeDates = DefaultRangeSelected.THIS_YEAR;
        break;
      default:
        break;
    }

    this.onDateChange();
  }

  public onDatePickerChange(event) {
    if (event.startDate === null || event.endDate === null) {
      return;
    }

    this.updateFilterSelection(event);
    this.onDateChange();
  }

  public getRelativePercentageClass(metric: string, value: number) {
    if (this.isCustomFilter) {
      return 'disabled';
    }
    
    const isPositive = value > 0;

    switch (metric) {
      // Meeting cost
      case 'meet_cost': return isPositive ? 'negative' : 'positive';

      // Average meeting cost
      case 'avg_meet_cost': return isPositive ? 'negative' : 'positive';

      // Average user cost
      case 'meet_time': return isPositive ? 'negative' : 'positive';

      // Meeting time
      case 'meet_len': return isPositive ? 'negative' : 'positive';

      // Meeting-free time
      case 'meet_num': return isPositive ? 'negative' : 'positive';

      // Late participants
      case 'meet_par': return isPositive ? 'negative' : 'positive';

      // Meeting-free time
      case 'per_time': return isPositive ? 'negative' : 'positive';

      // Late participants
      case 'deep_time': return isPositive ? 'negative' : 'positive';
    }

    return 'no-changes';
  }

  private resetPastMetricsData() {
    if(!this.isPastLoading) {
      for (const metric of this.metricType) {
        this.pastMetricsData[metric].hours = 0;
        this.pastMetricsData[metric].total = '0';
      }
    }
  }

  private updateFilterSelection(dateRange) {
    var selectedFilter = Object
      .keys(this.ranges)
      .filter(x => this.ranges[x][0]?.toString() === dateRange.startDate?.startOf('day').toString() 
                    && this.ranges[x][1]?.toString() === dateRange.endDate?.endOf('day').toString());

    switch(selectedFilter[0]) {
      case 'Today':
        this.selectedFilter = this.filterOptions.DAY;
        break;
      case 'This Week':
        this.selectedFilter = this.filterOptions.WEEK;
        break;
      case 'This Month':
        this.selectedFilter = this.filterOptions.MONTH;
        break;
      case 'This Quarter':
        this.selectedFilter = this.filterOptions.QUARTER;
        break;
      case 'This Year':
        this.selectedFilter = this.filterOptions.YEAR;
        break;
      default:
        this.selectedFilter = this.filterOptions.CUSTOM;
    }

  }

  private handleError() {
    this.toastr.error("An error ocurred while getting your insights, please try again later.", "Error", {
        disableTimeOut: false,
        closeButton: true,
        positionClass: 'toast-top-right',
        toastClass: 'toast-margin ngx-toastr'
    });
  }

  get currentQuarterTitle(): string {
    return `${DefaultRangeSelected.THIS_QUARTER.startDate.format('MMM').toUpperCase()} - ` +
      `${DefaultRangeSelected.THIS_QUARTER.endDate.format('MMM').toUpperCase()}`;
  }

  get lastQuarterTitle(): string {
    return `${DefaultRangeSelected.LAST_QUARTER.startDate.format('MMM').toUpperCase()} - ` +
      `${DefaultRangeSelected.LAST_QUARTER.endDate.format('MMM').toUpperCase()}`;
  }

  get relativeChangePercentageTotal() {
    let percentages = {};

    for (const metric of this.metricType) {
      const initialValue = Number(this.pastMetricsData[metric]?.total);
      const finalValue = Number(this.metricsData[metric]?.total);
      
      var relativePercentage = 0;

      if (initialValue === 0) {
        relativePercentage = undefined;
      } else {
        relativePercentage = (finalValue - initialValue) / initialValue;
      }

      percentages[metric] = relativePercentage;
    }

    return percentages;
  }

  get relativeChangePercentageHours() {
    let percentages = {};

    for (const metric of this.metricType) {
      const initialValue = Number(this.pastMetricsData[metric]?.hours);
      const finalValue = Number(this.metricsData[metric]?.hours);

      var relativePercentage = 0;

      if (initialValue === 0) {
        relativePercentage = undefined;
      } else {
        relativePercentage = (finalValue - initialValue) / initialValue;
      } 

      percentages[metric] = relativePercentage;
    }

    return percentages;
  }

  get selectedPastRange(): RangeDate {
    var adjustedRangeDate = DefaultRangeSelected.YESTERDAY;

    switch (this.selectedFilter) {
      case this.filterOptions.DAY:
        adjustedRangeDate = DefaultRangeSelected.YESTERDAY;
        break;
      case this.filterOptions.WEEK:
        adjustedRangeDate = DefaultRangeSelected.LAST_WEEK;
        break;
      case this.filterOptions.MONTH:
        adjustedRangeDate = DefaultRangeSelected.LAST_MONTH;
        break;
      case this.filterOptions.QUARTER:
        adjustedRangeDate = DefaultRangeSelected.LAST_QUARTER;
        break;
      case this.filterOptions.YEAR:
        adjustedRangeDate = DefaultRangeSelected.LAST_YEAR;
        break;
      default:
        break;
    }
    return adjustedRangeDate;
  }

  get comparationColumnTitle(): string {
    switch (this.selectedFilter) {
      case this.filterOptions.DAY: return 'YESTERDAY';
      case this.filterOptions.WEEK: return 'LAST WEEK';
      case this.filterOptions.MONTH: return 'LAST MONTH';
      case this.filterOptions.QUARTER: return this.lastQuarterTitle;
      case this.filterOptions.YEAR: return 'LAST YEAR';
    }

    return 'DISABLED';
  }

  get resultsColumnTitle(): string {
    switch (this.selectedFilter) {
      case this.filterOptions.DAY: return 'CURRENT DAY';
      case this.filterOptions.WEEK: return 'THIS WEEK';
      case this.filterOptions.MONTH: return 'THIS MONTH';
      case this.filterOptions.QUARTER: return this.currentQuarterTitle;
      case this.filterOptions.YEAR: return 'THIS YEAR';
    }

    return 'RESULTS';
  }

  get isCustomFilter(): boolean {
    return this.selectedFilter === this.filterOptions.CUSTOM;
  }

  get isLoading(): boolean {
    return this.isLoadingMetrics
      || this.isPastLoading;
  }

}
