import { trigger, transition, style, animate } from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/services/auth/auth.service';
import { Role } from 'src/enum/Role';
import { ZoomSettingsService } from 'src/app/services/settings/zoom-settings.service';
import { TeamsSettingsService } from 'src/app/services/settings/teams-settings.service';
import { OrganizationService } from 'src/app/services/settings/organization.service';
import { environment } from 'src/environments/environment';
import { StepsWizardComponent } from 'src/app/components/settings/steps-wizard/steps-wizard.component';
import { MatDialog } from '@angular/material/dialog';
import { StepsConnection } from 'src/app/models/settings/steps-connection';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { SourceIndustries } from 'src/app/classes/source-industries/source-industries';
import { ResourcesService } from 'src/app/services/resources/resources.service';
import { NudgeSettings } from 'src/app/components/nudgesSettings/nudge-settings.component';
import { NotificationSettings } from 'src/app/components/notificationSettings/notification-settings.component';
import Swal from 'sweetalert2';



@Component({
  selector: 'app-zoom-install',
  templateUrl: './zoom-install.component.html',
  styleUrls: ['./zoom-install.component.scss'],
  animations: [
    trigger(
      'enterAnimation', [
      transition(':enter', [
        style({ visibility: 'hidden', opacity: 0 }),
        animate('500ms', style({ visibility: 'hidden', opacity: 1 }))
      ]),
      transition(':leave', [
        style({ visibility: 'hidden', opacity: 1 }),
        animate('500ms', style({ visibility: 'visible', opacity: 0 }))
      ])
    ]
    )
  ],
})
export class ZoomInstallComponent implements OnInit {

  // Url for getting admin consent
  microsoft_login_url = "https://login.microsoftonline.com/";
  // Receives tenant id from url after loading page
  tenant = '';
  // Receives admin_consent values after loading page
  admin_consent = false;
  // Helper that display result of request, useful for testing
  result: any;
  // Indicates if zoom data is required
  zoomInfo = false;
  // Organization ID stores in localstorage
  organizationId: string = '<organization_id>';
  // Steps for zooom connection
  zoomStepsData: StepsConnection[] = [];
  // Zoom form group
  formZoom: FormGroup;
  // Helper that indicates when the user form is saving
  isSubmittedZoomForm = false;
  // Info zoom
  isZoomSet = false;
  // Helper to confirm delete settings on zoom
  confirmDeleteSettings = false;
  // Controls when user call service
  isLoadingZoomSettings = false;
  // Controls when user call service on organization form
  isLoadingOrganization = false;
  // Controls when user clicks on save changes
  isSavingOrganization = false;
  // Controls when user clicks on save changes zoom
  isSavingZoom = false;
  // Selected value from ng-select for industries
  industrySelected = null;
  // Industries source class
  industrySource: SourceIndustries
  // Helper that indicates when organization form is submitted
  isSubmittedOrganization = false;
  // Organization form group
  organizationForm: FormGroup;
  //zoom code link
  code = "";
  // Tariy user localstorage
  tariyUser
  //zoom binding status
  zoomBind;
  //teams binding status
  teamsBind;
  //sweet alert notifications
  Swal = require('sweetalert2')
  roles = Role;
  constructor(
    private authService: AuthService,
    private activatedRoute: ActivatedRoute,
    private dialog: MatDialog,
    private zoomSettingsService: ZoomSettingsService,
    private teamsSettingsService: TeamsSettingsService,
    private organizationService: OrganizationService,
    private fb: FormBuilder,
    private resources: ResourcesService,
    private toastService: ToastrService,
    ) {
    this.activatedRoute.queryParams.subscribe(params => {
      this.code = params['code'];
    });
    this.industrySource = new SourceIndustries(this.resources);
  }

  ngOnInit(): void {
    let getLink =  window.location.href ;

    if(this.code) {
      this.loginZoom();
    }
  }

  //oauth login zoom function
  loginZoom() {
    console.log(this.code)
    //return
    let params = {'code' : this.code};
    this.zoomSettingsService.setZoomAppSettings(params).then((data:any) => {
      this.zoomBind = data.success;
      if(this.zoomBind) {
        this.swalAlert('Zoom account successfully binded to TARIY', 'Success','success');
      }
      else {
        this.swalAlert('We had troubles binding your Zoom account to TARIY, please try again', 'Zoom error','error');
      }
    });
  }

  //redirect to oauth login
  connectZoom() {
    window.location.href = environment.zoom_oauth;
  }


  //unbind zoom 
  unbindZoom() {
    this.zoomSettingsService.deleteZoomSettings().then((data:any) => {
      this.zoomBind = !data.success;
      if(data.success) {
        let user = JSON.parse(localStorage.getItem('traiyUser'));
        user['zoomBind'] = this.zoomBind;
        localStorage.setItem('traiyUser', JSON.stringify(user));
        this.swalAlert('Zoom account successfully unbinded to TARIY', 'Success','success');
      }
      else {
        this.swalAlert('We had troubles unbinding your Zoom account to TARIY, please try again', 'Zoom error','error');
      }
    });
  }


  //confirm teams unbind
  confirmZoom() {
    this.swalConfirm('Are you sure you want to unbind your Zoom account?', 'Zoom unbinding', 'question', 1);
  }


  toastError(message:string, topic:string) {
    this.toastService.error(message, topic, {
      disableTimeOut: false,
      closeButton: true,
      enableHtml: true,
      positionClass: 'toast-top-right',
      toastClass: 'toast-margin ngx-toastr'
    });
  }

  toastSuccess(message:string, topic:string) {
    this.toastService.success(message, topic, {
      disableTimeOut: false,
      closeButton: true,
      enableHtml: true,
      positionClass: 'toast-top-right',
      toastClass: 'toast-margin ngx-toastr'
    });
  }

  //swal alert type (successs, error, warning, info, question)
  swalAlert(message:string, topic:string, type:any) {
    Swal.fire({
      icon: type,
      title: topic,
      text: message
    });
  }

  //swal confirmation type:(successs, error, warning, info, question),
  //app (1:zoom, 2:teams)
  swalConfirm(message:string, topic:string, type:any, app:number) {
    Swal.fire({
      icon: type,
      title: topic,
      text: message,
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Delete'
    }).then(result => {
      if(result.isConfirmed) {
        switch (app){
          case 1: {
            this.unbindZoom();
            break;
          }
          default:
            break;
        }
      }
    });
  }
}
