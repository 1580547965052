<section class="meeting-detail">
  <div class="container-fluid">
    <div class="row">
      <div class="col-12 col-md-4 d-flex align-items-center flex-column flex-lg-row flex-xl-row">
        <h3 class="page-title mb-2 mb-lg-0 mb-xl-0">

          <div class="d-inline-flex" [routerLink]="['/member/']">
            <fa-icon [icon]="backIcon" *ngIf="role == 'Member'"></fa-icon>
          </div>
          
          <!-- Event title -->
          {{ eventDetail?.title }} &nbsp;

          <!-- Event type-->
          <i
            popper="{{ getMeetingTypeText(eventDetail?.meetingType) }}"
            [popperShowOnStart]="false"
            [popperTrigger]="'hover'"
            [popperHideOnScroll]="true"
            [popperPlacement]="'bottom'"
            class="title-meeting-type fas"
            [ngClass]="getMeetingTypeIcon(eventDetail?.meetingType)"></i> &nbsp;

          <!-- Event source -->
          <img
            popper="{{ getMeetingSourceText(eventDetail?.source) }}"
            [popperShowOnStart]="false"
            [popperTrigger]="'hover'"
            [popperHideOnScroll]="true"
            [popperPlacement]="'bottom'"
            *ngIf="eventDetail && eventDetail?.source !== 'other'"
            src="../../../assets/img/{{ getMeetingSourceIcon(eventDetail?.source) }}"
            class="title-meeting-source"
            alt="{{ eventDetail?.source }}">

        </h3>
        <span class="badge ml-lg-4 status mb-2 mb-lg-0 mb-xl-0" [ngClass]="eventDetail?.statusText">{{ eventDetail?.statusText }}</span>

      </div>

      <div class="col-12 col-md-4 d-flex align-items-center justify-content-center flex-column flex-lg-row flex-xl-row">
        <h3 class="page-title mb-2 mb-lg-0 mb-xl-0 mr-4 meeting-score" (click)="showDetails()"><b *ngIf="(((role == 'Member') && (email == eventDetail?.organizerEmail)) || ((role == 'Admin') || (role == 'Manager')) && !loadingDetail)">Meeting Score&reg;</b></h3>
        <svg width="80" height="80" viewBox="0 0 80 80" class="progress__svg meeting-score" 
        *ngIf="(((role == 'Member') && (email == eventDetail?.organizerEmail)) || ((role == 'Admin') || (role == 'Manager')) && !loadingDetail)" (click)="showDetails()">
          <circle
            [attr.r]="radius"
            cx="40"
            cy="40"
            stroke-width="12"
            class="progress__value"
          >
          </circle>
          <circle
            [style.strokeDasharray]="circumference"
            [style.strokeDashoffset]="dashoffset"
            [attr.r]="radius"
            cx="40"
            cy="40"
            stroke-width="13"
            class="progress__meter"
          >
          </circle>
          <text x="27" y="-33" class="progress__text" *ngIf="eventDetail?.getScoreText() != 'N/A'">{{ eventDetail?.getScoreText() }}</text>
          <text x="23" y="-33" class="progress__text" *ngIf="eventDetail?.getScoreText() == 'N/A'">{{ eventDetail?.getScoreText() }}</text>
        </svg>
        <fa-icon [icon]="tipIcon" class="tipIcon pl-4" (click)="showTips()" *ngIf="(((role == 'Member') && (email == eventDetail?.organizerEmail)) || ((role == 'Admin') || (role == 'Manager')) && !loadingDetail)"></fa-icon>
        <!-- <span class="value score" style="position: absolute;"><b *ngIf="(((role == 'Member') && (email == eventDetail?.organizerEmail)) || ((role == 'Admin') || (role == 'Manager')) && !loadingDetail)">{{ eventDetail?.getScoreText() }}</b></span> -->
      </div>

      <div class="col-12 col-md-4 d-flex justify-content-end align-items-center">
        <div class="date">
          <div class="d-flex align-items-center">
            <div>Date:</div>
            <img src="../../../assets/img/calendar_blue.svg" class="calendar ml-2">
          </div>
          <span>{{ eventDetail != null ? (eventDetail?.date | date : 'long') : ''  }}</span>
        </div>
      </div>
    </div>
    <div class="row my-4 equal">
      <div class="col-12 col-xl-4 col-lg-4">
        <div class="card">
          <div class="header"><div class="title">GENERAL INFO</div></div>
          <div class="body">
            <div class="loadingInfo"  *ngIf="loadingDetail">
              <div class="description"><fa-icon icon="circle-notch" class="fa-lg fa-spin mr-2" [spin]="true"></fa-icon>Loading...</div>
            </div>
            <ul class="general"  #getElementHeight>
              <li>
                <div class="d-flex align-items-center">
                  <img [src]="eventDetail?.picture" class="user" *ngIf="validPicture" (error)="invalidPicture()">
                  <img src="../../../assets/img/no-picture-user.png" class="img-fluid mr-2 user no-photo" *ngIf="!validPicture">
                  <div class="title">{{ eventDetail?.organizer }}</div>
                </div>
                <p class="role">Organizer</p>
              </li>
              <li *ngIf="((role == 'Member') && (email == eventDetail?.organizerEmail)) || ((role == 'Admin') || (role == 'Manager'))">
                <div class="title">Meeting cost</div>
                <span class="value cost" *ngIf="!loadingDetail">{{ eventDetail?.meetingCost | currency }}</span></li>
              <!-- <li *ngIf="((role == 'Member') && (email == eventDetail?.organizerEmail)) || ((role == 'Admin') || (role == 'Manager'))">
                <div class="title">Meeting score</div>
                <span class="value score"><b *ngIf="!loadingDetail">{{ eventDetail?.getScoreText() }}</b></span>
              </li> -->
              <li>
                <div class="title">Meeting sentiment</div>
                <span class="value" style="width: 100px; padding: 0px;" 
                [ngClass]="{'positiveSentiment': eventDetail?.getSentimentSpin() == 'Positive',
                'neutralSentiment': (eventDetail?.getSentimentSpin() == 'Neutral') || (eventDetail?.getSentimentSpin() == 'N/A'),
                'negativeSentiment': eventDetail?.getSentimentSpin() == 'Negative'}"
                [matTooltip]="eventDetail?.getTooltipSentiment()"
                matTooltipPosition="right"
                matTooltipClass="tooltip_space"
                *ngIf="!loadingDetail">
                  <div class="col-8">
                    <span>
                      {{ eventDetail?.getSentimentScoreText() }}
                    </span>
                    <span *ngIf="eventDetail?.getSentimentSpin() == 'N/A'" class="no-adjust">
                      {{eventDetail?.getSentimentSpin()}}
                    </span>
                  </div>
                  <div class="col-4">
                    <fa-icon [icon]="checkIcon" *ngIf="eventDetail?.getSentimentSpin() == 'Positive'"></fa-icon>
                    <fa-icon [icon]="crossIcon" *ngIf="eventDetail?.getSentimentSpin() == 'Negative'"></fa-icon>
                  </div>
                </span>
              </li>
              <li>
                <div class="title">Duration</div>
                <span class="value duration"><b *ngIf="!loadingDetail">{{ eventDetail?.getDurationInMinutes() }}</b>min</span>
              </li>
              <li>
                <div class="title">Started on time</div>
                
                <span class="value" [ngClass]="{'late': eventDetail?.isLate, 'duration': !eventDetail?.isLate}" *ngIf="!loadingDetail ">
                  <b >{{eventDetail?.onTime}} </b>
                    <!-- <b >{{ eventDetail?.minutesLate >= 1  ?  eventDetail?.getMinutesLateFixed() : (eventDetail?.minutesLate*60).toFixed(0) }} </b> 
                    <span *ngIf="eventDetail?.minutesLate >= 1 ; else seg" >min</span> 
                    <ng-template #seg>
                      seg
                    </ng-template> -->
                </span>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="col-12 col-xl-8 col-lg-8">
        <div class="card">
          <div class="header d-flex">
            <div class="title text-center" [ngClass] = "{'col-6': eventDetail?.showBehavior, 'col-9': !eventDetail?.showBehavior}">ATTENDEES</div>
            <div class="title text-center separator pl-2" [ngClass] = "{'col-6': eventDetail?.showBehavior, 'col-3': !eventDetail?.showBehavior}">BEHAVIOR</div>
          </div>
          <div class="body">
            <ul class="attendees" style="max-height:{{getElementHeight.offsetHeight}}px">
              <ng-container *ngIf="internalAttendees.length>0; else nobodyAttendees">
                <li style="background-color: #fafafa; color: #858585; font-weight: 700; padding: 0px 25px; text-align: center; font-size: 16px; position: sticky; top: 0px; z-index: 50;">
                  <div class="" style="padding: 10px 0px;" [ngClass] = "{'col-4': eventDetail?.showBehavior, 'col-5': !eventDetail?.showBehavior}"><span>Internal Attendees</span></div>
                  <div class="" style="padding: 10px 0px;" [ngClass] = "{'col-2': eventDetail?.showBehavior, 'col-4': !eventDetail?.showBehavior}"><span>Email</span></div>
                  <div class="col-2" style="padding: 10px 0px;" *ngIf="eventDetail?.showBehavior"><span>Sentiment</span></div>
                  <div class="col-2" style="padding: 10px 0px;" *ngIf="eventDetail?.showBehavior"><span>Participation</span></div>
                  <div class="" style="padding: 10px 0px;" [ngClass] = "{'col-2': eventDetail?.showBehavior, 'col-3': !eventDetail?.showBehavior}"><span>Late</span></div>
                </li>
                <li *ngFor="let attendee of internalAttendees">
                  <div class="d-inline-flex align-items-center" [ngClass] = "{'col-4': eventDetail?.showBehavior, 'col-5': !eventDetail?.showBehavior}">
                    <img [src]="attendee['picture']" class="user" *ngIf="attendee['validPicture']" 
                    [ngClass]="{'attended-attendee': (attendee['assisted'] == 1) || (attendee['assisted'] == 4), 
                    'missed-attendee': (attendee['assisted'] == 0) || (attendee['assisted'] == 3), 
                    'unknown-attendee': (attendee['assisted'] == 2) || (attendee['assisted'] == 5)}"
                    [matTooltip]="attendeeStatus[attendee['assisted']]"
                    matTooltipPosition="left"
                    matTooltipClass="tooltip_space" (error)="attendee.invalidPicture()">

                    <img src="../../../assets/img/no-picture-user.png" class="img-fluid mr-2 user no-photo" *ngIf="!attendee['validPicture']"
                    [ngClass]="{'attended-attendee': (attendee['assisted'] == 1) || (attendee['assisted'] == 4), 
                    'missed-attendee': (attendee['assisted'] == 0) || (attendee['assisted'] == 3), 
                    'unknown-attendee': (attendee['assisted'] == 2) || (attendee['assisted'] == 5)}"
                    [matTooltip]="attendeeStatus[attendee['assisted']]"
                    matTooltipPosition="left"
                    matTooltipClass="tooltip_space">

                    <div class="title">
                      {{ attendee['name'] != null ? attendee['name'] : attendee['email']}}
                    </div>
                  </div>
                  
                  
                  <div
                    popper="{{ attendee['email'] }}"
                    [popperShowOnStart]="false"
                    [popperTrigger]="'hover'"
                    [popperHideOnScroll]="true"
                    [popperPlacement]="'top'"
                    class="email text-center" [ngClass] = "{'col-2': eventDetail?.showBehavior, 'col-4': !eventDetail?.showBehavior}">
                      {{ attendee['email'] }}
                  </div>

                  
                  <div
                    popper="{{ attendee?.getTooltipSentiment() }}"
                    [popperShowOnStart]="false"
                    [popperTrigger]="'hover'"
                    [popperHideOnScroll]="true"
                    [popperPlacement]="'top'"
                    class="email col-2 text-center behavior" *ngIf="eventDetail?.showBehavior">
                    <span [class]="attendee.getSentimentClass()" *ngIf="attendee.define">{{ attendee['sentimentScore'].toFixed(2) + '%'}}</span>
                    <span [class]="attendee.getSentimentClass()" *ngIf="!attendee.define">N/A</span>
                  </div>
                  <div
                    popper="{{ attendee?.getTooltipParticipation() }}"
                    [popperShowOnStart]="false"
                    [popperTrigger]="'hover'"
                    [popperHideOnScroll]="true"
                    [popperPlacement]="'top'"
                    class="email col-2 text-center behavior" *ngIf="eventDetail?.showBehavior">
                    <span [class]="attendee.getParticipationClass()" *ngIf="attendee.define">{{ attendee['participation']['Participation'].toFixed(2) + '%'}}</span>
                    <span [class]="attendee.getParticipationClass()" *ngIf="!attendee.define">N/A</span>
                  </div>
                  <div class="email text-center behavior" [ngClass] = "{'col-2': eventDetail?.showBehavior, 'col-3': !eventDetail?.showBehavior}">
                    <span [class]="attendee.getLateClass()" *ngIf="attendee['assisted'] == 1">{{ attendee['late'].toFixed(2) + 'min'}}</span>
                    <span [class]="attendee.getLateClass()" *ngIf="attendee['assisted'] != 1">N/A</span>
                  </div>
                </li>
                <!-- <li *ngFor="let attendee of internalAttendees">
                  <img [src]="attendee['picture']" class="user" *ngIf="attendee['picture']" 
                  [ngClass]="{'attended-attendee': (attendee['assisted'] == 1) || (attendee['assisted'] == 4), 
                  'missed-attendee': (attendee['assisted'] == 0) || (attendee['assisted'] == 3), 
                  'unknown-attendee': (attendee['assisted'] == 2) || (attendee['assisted'] == 5)}"
                  [matTooltip]="attendeeStatus[attendee['assisted']]"
                  matTooltipPosition="left"
                  matTooltipClass="tooltip_space">
                  <img src="../../../assets/img/no-picture-user.png" class="img-fluid mr-2 user no-photo" *ngIf="!attendee['picture']"
                  [ngClass]="{'attended-attendee': (attendee['assisted'] == 1) || (attendee['assisted'] == 4), 
                  'missed-attendee': (attendee['assisted'] == 0) || (attendee['assisted'] == 3), 
                  'unknown-attendee': (attendee['assisted'] == 2) || (attendee['assisted'] == 5)}"
                  [matTooltip]="attendeeStatus[attendee['assisted']]"
                  matTooltipPosition="left"
                  matTooltipClass="tooltip_space">
                  <div class="title">
                    {{ attendee['name'] != null ? attendee['name'] : attendee['email']}}
                  </div>
                  <div
                    popper="{{ attendee['email'] }}"
                    [popperShowOnStart]="false"
                    [popperTrigger]="'hover'"
                    [popperHideOnScroll]="true"
                    [popperPlacement]="'bottom'"
                    class="email">
                      {{ attendee['email'] }}
                  </div>
                </li> -->
              </ng-container>
              <ng-template #nobodyAttendees>
                <li class="loadingList" *ngIf="loadingDetail">
                  <div class="text-center"><fa-icon icon="circle-notch" class="fa-lg fa-spin mr-2" [spin]="true"></fa-icon>Loading...</div>
                </li>
                <li *ngIf="!loadingDetail">No internal attendees</li>
              </ng-template>

              
              <ng-container *ngIf="externalAttendees.length>0; else nobody">
                <li style="background-color: #fafafa; color: #858585; font-weight: 700; padding: 0px 25px; text-align: center; font-size: 16px; position: sticky; top: 0px; z-index: 660;">
                  <div class="" style="padding: 10px 0px;" [ngClass] = "{'col-4': eventDetail?.showBehavior, 'col-5': !eventDetail?.showBehavior}"><span>External Attendees</span></div>
                  <div class="" style="padding: 10px 0px;" [ngClass] = "{'col-2': eventDetail?.showBehavior, 'col-4': !eventDetail?.showBehavior}"><span>Email</span></div>
                  <div class="col-2" style="padding: 10px 0px;" *ngIf="eventDetail?.showBehavior"><span>Sentiment</span></div>
                  <div class="col-2" style="padding: 10px 0px;" *ngIf="eventDetail?.showBehavior"><span>Participation</span></div>
                  <div class="" style="padding: 10px 0px;" [ngClass] = "{'col-2': eventDetail?.showBehavior, 'col-3': !eventDetail?.showBehavior}"><span>Late</span></div>
                </li>
                <li *ngFor="let attendee of externalAttendees">
                  <div class="d-inline-flex align-items-center" [ngClass] = "{'col-4': eventDetail?.showBehavior, 'col-5': !eventDetail?.showBehavior}">
                    <img [src]="attendee['picture']" class="user" *ngIf="attendee['validPicture']" 
                    [ngClass]="{'attended-attendee': (attendee['assisted'] == 1) || (attendee['assisted'] == 4), 
                    'missed-attendee': (attendee['assisted'] == 0) || (attendee['assisted'] == 3), 
                    'unknown-attendee': (attendee['assisted'] == 2) || (attendee['assisted'] == 5)}"
                    [matTooltip]="attendeeStatus[attendee['assisted']]"
                    matTooltipPosition="left"
                    matTooltipClass="tooltip_space" (error)="attendee.invalidPicture()">

                    <img src="../../../assets/img/no-picture-user.png" class="img-fluid mr-2 user no-photo" *ngIf="!attendee['validPicture']"
                    [ngClass]="{'attended-attendee': (attendee['assisted'] == 1) || (attendee['assisted'] == 4), 
                    'missed-attendee': (attendee['assisted'] == 0) || (attendee['assisted'] == 3), 
                    'unknown-attendee': (attendee['assisted'] == 2) || (attendee['assisted'] == 5)}"
                    [matTooltip]="attendeeStatus[attendee['assisted']]"
                    matTooltipPosition="left"
                    matTooltipClass="tooltip_space">

                    <div class="title">
                      {{ attendee['name'] != null ? attendee['name'] : attendee['email']}}
                    </div>
                  </div>
                  
                  
                  <div
                    popper="{{ attendee['email'] }}"
                    [popperShowOnStart]="false"
                    [popperTrigger]="'hover'"
                    [popperHideOnScroll]="true"
                    [popperPlacement]="'top'"
                    class="email text-center" [ngClass] = "{'col-2': eventDetail?.showBehavior, 'col-4': !eventDetail?.showBehavior}">
                      {{ attendee['email'] }}
                  </div>

                  
                  <div
                    popper="{{ attendee?.getTooltipSentiment() }}"
                    [popperShowOnStart]="false"
                    [popperTrigger]="'hover'"
                    [popperHideOnScroll]="true"
                    [popperPlacement]="'top'"
                    class="email col-2 text-center behavior" *ngIf="eventDetail?.showBehavior">
                    <span [class]="attendee.getSentimentClass()" *ngIf="attendee.define">{{ attendee['sentimentScore'].toFixed(2) + '%'}}</span>
                    <span [class]="attendee.getSentimentClass()" *ngIf="!attendee.define">N/A</span>
                  </div>
                  <div
                    popper="{{ attendee?.getTooltipParticipation() }}"
                    [popperShowOnStart]="false"
                    [popperTrigger]="'hover'"
                    [popperHideOnScroll]="true"
                    [popperPlacement]="'top'"
                    class="email col-2 text-center behavior" *ngIf="eventDetail?.showBehavior">
                    <span [class]="attendee.getParticipationClass()" *ngIf="attendee.define">{{ attendee['participation']['Participation'].toFixed(2) + '%'}}</span>
                    <span [class]="attendee.getParticipationClass()" *ngIf="!attendee.define">N/A</span>
                  </div>
                  <div class="email text-center behavior" [ngClass] = "{'col-2': eventDetail?.showBehavior, 'col-3': !eventDetail?.showBehavior}">
                    <span [class]="attendee.getLateClass()" *ngIf="attendee['assisted'] == 1">{{ attendee['late'].toFixed(2) + 'min'}}</span>
                    <span [class]="attendee.getLateClass()" *ngIf="attendee['assisted'] != 1">N/A</span>
                  </div>
                </li>
              </ng-container>
              <ng-template #nobody>
                <li class="loadingList" *ngIf="loadingDetail">
                  <div class="text-center"><fa-icon icon="circle-notch" class="fa-lg fa-spin mr-2" [spin]="true"></fa-icon>Loading...</div>
                </li>
                <li *ngIf="!loadingDetail">No external attendees</li>
              </ng-template>
            </ul>
          </div>
        </div>
      </div>

      <!-- <div class="col-12 col-xl-4 col-lg-4">
        <div class="card">
          <div class="header"><div class="title">EXTERNAL ATTENDEES</div></div>
          <div class="body">
            <ul class="missed" style="max-height:{{getElementHeight.offsetHeight}}px">
              <ng-container *ngIf="externalAttendees.length>0; else nobody">
                <li class="loading-advices" *ngIf="loadingDetail">
                  <div class="description"><fa-icon icon="circle-notch" class="fa-lg fa-spin mr-2" [spin]="true"></fa-icon>Loading...</div>
                </li>
                <li *ngFor="let who of externalAttendees">
                  <img [src]="who['picture']" class="user" *ngIf="who['picture']"
                  [ngClass]="{'attended-attendee': (who['assisted'] == 1) || (who['assisted'] == 4), 
                  'missed-attendee': (who['assisted'] == 0) || (who['assisted'] == 3), 
                  'unknown-attendee': (who['assisted'] == 2) || (who['assisted'] == 5)}"
                  [matTooltip]="attendeeStatus[who['assisted']]"
                  matTooltipPosition="left"
                  matTooltipClass="tooltip_space">
                  <img src="../../../assets/img/no-picture-user.png" class="img-fluid mr-2 user no-photo" *ngIf="!who['picture']" 
                  [ngClass]="{'attended-attendee': (who['assisted'] == 1) || (who['assisted'] == 4), 
                  'missed-attendee': (who['assisted'] == 0) || (who['assisted'] == 3), 
                  'unknown-attendee': (who['assisted'] == 2) || (who['assisted'] == 5)}"
                  [matTooltip]="attendeeStatus[who['assisted']]"
                  matTooltipPosition="left"
                  matTooltipClass="tooltip_space">
                  <div class="title">
                    {{ who['name'] != null ? who['name'] : who['email']}} 
                  </div>
                  <div
                    popper="{{ who['email'] }}"
                    [popperShowOnStart]="false"
                    [popperTrigger]="'hover'"
                    [popperHideOnScroll]="true"
                    [popperPlacement]="'bottom'"
                    class="email">
                      {{ who['email'] }}
                  </div>
                </li>
              </ng-container>
              <ng-template #nobody>
                <li class="loadingList" *ngIf="loadingDetail">
                  <div class="description text-center"><fa-icon icon="circle-notch" class="fa-lg fa-spin mr-2" [spin]="true"></fa-icon>Loading...</div>
                </li>
                <li class="no" *ngIf="!loadingDetail">N/A</li>
              </ng-template>
            </ul>
          </div>
        </div>
      </div> -->
    </div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="header">
            <div class="title">TRANSCRIPT</div>
          </div>
          <div class="body" *ngIf="chats?.length != 0 && chats != undefined; else noTranscription">
            <div class="keywords-block p-2">
              <ul class="keywords">
                <li class="title">KEYWORDS: </li>
                <ng-container *ngIf="eventDetail?.keywords.length > 0; else emptyKeywords">
                  <li *ngFor="let keyword of eventDetail.keywords">{{ keyword }}</li>
                </ng-container>
                <ng-template #emptyKeywords><li class="nokeywords">None</li></ng-template>
              </ul>
            </div>
            <div class="transcript">
              <div class="search">
                <div class="searchbox">
                  <input class="form-control" placeholder="Search:"  [(ngModel)]="searchText" (ngModelChange)="onFieldChange($event)" />
                  <img src="../../../assets/img/search.svg" class="search" />
                </div>
                <h6>Occurrences found: {{ totalMatches }}</h6>
              </div>
              <div class="conversation">
                <div class="chat d-flex" *ngFor="let chat of chats">
                  <h6 class="d-flex justify-content-end align-items-center mr-2 col-1 p-0" style="font-weight: 600; color: '#4B8DF2'; white-space: nowrap;">{{chat.speaker}}:</h6>
                  <p class="reply col-11 p-0" innerHTML="{{ chat.text  | highlightSearch : searchText }}"></p>
                </div>
              </div>
            </div>
          </div>
          <ng-template #noTranscription>
            <b class="p-4">Transcript not found</b>
          </ng-template>
        </div>
      </div>
    </div>
  </div>
</section>
