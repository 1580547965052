import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Globals } from 'src/app/global';
import { AuthService } from '../auth/auth.service';
import { User } from '../../models/user/user';
import { map } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GeneralMetricsService {
  // variable that store the import of Globals Class that will help us to retrieve the urls for every service
  globals = Globals;
  // Logged user data
  userLogged: User;

  constructor(private http: HttpClient,
    private authService: AuthService) {

    this.userLogged = this.authService.loginInfo;
  }

  userOverview(range_date: object): Promise<object> {
    const organization_id = this.authService.loginInfo.organizationId;
    let dates = {
      starts: range_date['startDate'].startOf('day').toISOString(),
      ends: range_date['endDate'].endOf('day').toISOString()
    };
    const url = `${this.globals.availabilities.overview}/${organization_id}/users/${this.userLogged.email}?${new URLSearchParams(dates).toString()}&computation=all`;
    return this.http.get(url).toPromise();
  }

  bestPracticesByUser(range_date: object): Promise<object> {
    let dates = {
      starts: range_date['startDate'].startOf('day').toISOString(),
      ends: range_date['endDate'].endOf('day').toISOString()
    };
    const datesString = Object.keys(dates).map(key => key + '=' + dates[key]).join('&');
    const url = `${this.globals.availabilities.bestPractices}/?${datesString}`;
    return this.http.get(url).toPromise();
  }

  getScoresByUser(range_date: object): Promise<object> {
    let dates = {
      starts: range_date['startDate'].startOf('day').toISOString(),
      ends: range_date['endDate'].endOf('day').toISOString()
    }
    const datesString = Object.keys(dates).map(key => key + '=' + dates[key]).join('&');
    const url = `${this.globals.availabilities.scores}/?${datesString}`;
    return this.http.get(url).toPromise();
  }

  getScoreHistory(range_date: object): Promise<object> {
    let dates = {
      starts: range_date['startDate'].startOf('day').toISOString(),
      ends: range_date['endDate'].endOf('day').toISOString()
    }
    const datesString = Object.keys(dates).map(key => key + '=' + dates[key]).join('&');
    const url = `${this.globals.meetings.scoreHistory}/?${datesString}`;
    return this.http.get(url).toPromise();
  }

  getKeywordsByUser(range_date: object): Promise<object> {
    let dates = {
      starts: range_date['startDate'].startOf('day').toISOString(),
      ends: range_date['endDate'].endOf('day').toISOString()
    };
    const datesString = Object.keys(dates).map(key => key + '=' + dates[key]).join('&');
    const url = `${this.globals.availabilities.keywords}/?${datesString}`;
    return this.http.get(url).toPromise();
  }
}
