<div class="col-10 pb-3 pt-3" style="background-color: white; margin-left: auto; margin-right: auto;">
  <div class="row ml-1 mr-1 justify-content-center">
    <img src="../../../assets/tariy-logo.png" alt="" class="w-50">
  </div>
  <div class="modalNote row d-none d-xl-block d-lg-block" style="color: #6c6565;">
    Select all of which apply to complete your profile.
  </div>
  <div class="row option-container d-flex justify-content-center align-items-start">
    <div class="row">
      <span>Gender</span>
    </div>
    <div class="row">
      <mat-chip-list [selectable]="true">
        <mat-chip *ngFor="let chip of genders; let i=index" [selected]="selectedGender === chip.value" (click)="changeSelectedGender(chip.value)">{{chip.name}}</mat-chip>
      </mat-chip-list >
    </div>
  </div>

  <div class="row option-container d-flex justify-content-center align-items-start">
    <div class="row">
      <span>Communication Style</span>
    </div>
    <div class="row">
      <mat-chip-list [selectable]="true">
        <mat-chip *ngFor="let chip of communication; let i=index" [selected]="selectedCommunication === chip.value" (click)="changeSelectedCommunication(chip.value)" [matTooltip]="chip.tooltip">{{chip.name}}</mat-chip>
      </mat-chip-list >
    </div>
  </div>

  <div class="row option-container d-flex justify-content-center align-items-start">
    <div class="row">
      <span>Generation</span>
    </div>
    <div class="row">
      <mat-chip-list [selectable]="true">
        <mat-chip *ngFor="let chip of generation; let i=index" [selected]="selectedGeneration === chip.value" (click)="changeSelectedGeneration(chip.value)" [matTooltip]="chip.tooltip">{{chip.name}}</mat-chip>
      </mat-chip-list >
    </div>
  </div>

  <div class="row option-container d-flex justify-content-center align-items-start">
    <div class="row">
      <span>Job Title (optional)</span>
    </div>
    <div class="row mt-2">
      <input [(ngModel)]="jobTitle" type="text" class="form-input">
    </div>
  </div>
  
  <div class="row d-flex justify-content-center align-items-center" >
    <button class="mt-4 ml-2 btn btn-block btn-primary" style="width: 80%;" (click)="close()" >Complete Profile</button>
  </div>
  
</div>

