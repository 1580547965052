import { Component, OnInit, Renderer2, ViewChild, ElementRef } from '@angular/core';
import { AuthService } from 'src/app/services/auth/auth.service';
import { SearchNudgeService } from 'src/app/services/nudges/searchnudge.service';
import { Role } from 'src/enum/Role';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {

  // Get logged user in order to add organization's data to the form that will be updated
  loggedUserInfo = {};
  // Retrieves defined roles of system
  roles = Role;
  showSubmenu = false;
  @ViewChild('submenu') submenu: ElementRef;
  @ViewChild('toggleMenu') toggleMenu: ElementRef;
  @ViewChild('toggleImg') toggleImage: ElementRef;
  @ViewChild('toggleTxt') toggleText: ElementRef;
  showNudgeBadge = false;

  constructor(private authService: AuthService,
    private checkService: SearchNudgeService,
    private renderer: Renderer2) {
    this.loggedUserInfo = this.authService.currentUserValue;
    this.renderer.listen('window', 'click',(e:Event)=>{
      if(e.target !== this.toggleMenu.nativeElement && e.target!==this.submenu?.nativeElement && e.target !== this.toggleImage.nativeElement &&  e.target !== this.toggleText.nativeElement){
      this.showSubmenu=false;
      }
      else {
        this.showSubmenu=!this.showSubmenu;
      }
    });

    checkService.nudges$.subscribe((data: boolean) => {
      this.showNudgeBadge = data;
    });
  }

  ngOnInit(): void {
  }
  activateSubmenu() {
  }

  openNewTab(url) {
    window.open(url, '_blank').focus();
   }

}
