import { Injectable } from '@angular/core';
import { BehaviorSubject, throwError } from 'rxjs';
import { Globals } from 'src/app/global';
import { HttpClient } from '@angular/common/http';
import { PaginationState } from 'src/app/models/pagination/pagination';
import { HistoryMetric } from 'src/app/models/history-metric/history-metrics';
import { Subject } from 'rxjs';
import { tap, debounceTime, switchMap, delay, catchError } from 'rxjs/operators';
import { AuthService } from '../auth/auth.service';

@Injectable({
  providedIn: 'root',
})
export class InsightsService {
  // Listen for new strings that user provides
  private _search$ = new Subject<void>();
  private _pastSearch$ = new Subject<void>();

  // Listen for new changes on datatable or grid
  private _loading$ = new BehaviorSubject<boolean>(true);
  private _pastLoading$ = new BehaviorSubject<boolean>(true);

  private _hasError$ = new BehaviorSubject<boolean>(false);

  // variable that store the import of Globals Class that will help us to retrieve the urls for every service
  globals = Globals;

  // Url of users api
  baseUrl = `${this.globals.urlTraiyBase}`;

  // List of members
  private _metrics$ = new BehaviorSubject<any>([]);
  private _pastMetrics$ = new BehaviorSubject<any>([]);
  private _chart$ = new BehaviorSubject<any>([]);

  // custom filter
  private _isCustomFilter = false;

  private _pagination: PaginationState = {
    page: 1,
    itemsPerPage: 12,
    searchText: '',
    orderColumn: '',
    orderBy: '',
    range_date: null,
    range_date_second: null,
  };
  private _forceCache = 0;
  constructor(
    private httpClient: HttpClient,
    private authService: AuthService
  ) {
    this.init();
  }

  private init() {
    this._search$
      .pipe(
        tap(() => this._loading$.next(true)),
        debounceTime(200),
        switchMap(() => this._search()),
        delay(200),
        tap(() => this._loading$.next(false)),
        catchError(err => throwError(err))
      )
      .subscribe((result) => {
        let metrics = [];
        this._chart$.next(result);
        for (var key in result['Insights']) {
          result['Insights'][key].query_params.computation = key;
          result['Insights'][key].role = this.authService.loginInfo.role;
          metrics[key] = new HistoryMetric(result['Insights'][key]);
        }
        this._metrics$.next(metrics);
      }, error => {
        this._handleError(error);
      });

    if (!this.isCustomFilter) {
      this._pastSearch$
        .pipe(
          tap(() => this._pastLoading$.next(true)),
          debounceTime(200),
          switchMap(() => this._pastSearch()),
          delay(200),
          tap(() => this._pastLoading$.next(false)),
          catchError(err => throwError(err))
        )
        .subscribe((result) => {
          let pastMetrics = [];
          for (var key in result['Insights']) {
            result['Insights'][key]['query_params']['computation'] = key;
            result['Insights'][key]['role'] = this.authService.loginInfo.role;
            pastMetrics[key] = new HistoryMetric(result['Insights'][key]);
          }
          this._pastMetrics$.next(pastMetrics);
        }, error => {
          this._handleError(error);
        });
    }
  }

  private _handleError(error) {
    this._hasError$.next(true);
    console.error(error);

    this._metrics$.next([]);
    this._chart$.next([]);
    this._pastMetrics$.next([]);
    this._loading$.next(false);
    this._pastLoading$.next(false);

    this.init();
  }

  // Getters an setters that manage filters an table options
  // that show results on datatable
  get metrics$() {
    return this._metrics$.asObservable();
  }
  get pastMetrics$() {
    return this._pastMetrics$.asObservable();
  }
  get chartData$() {
    return this._chart$.asObservable();
  }
  get loading$() {
    return this._loading$.asObservable();
  }
  get pastLoading$() {
    return this._pastLoading$.asObservable();
  }
  get hasError$() {
    return this._hasError$.asObservable();
  }
  get range_date() {
    return this._pagination.range_date;
  }
  get range_date_second() {
    return this._pagination.range_date_second;
  }
  get isCustomFilter() {
    return this._isCustomFilter;
  }
  get forceCache() {
    return this._forceCache;
  }

  set range_date(range_date) {
    this._set({ range_date });
  }
  set range_date_second(range_date_second) {
    this._set({ range_date_second });
  }
  set isCustomFilter(isCustomFilter) {
    this._isCustomFilter = isCustomFilter;
  }
  set forceCache(force) {
    this._forceCache = force;
    this._hasError$.next(false);
    this._search$.next();
  }

  // Helper that set values depending on its use
  private _set(patch: any) {
    Object.assign(this._pagination, patch);

    if (
      this.range_date != null &&
      this.range_date_second != null &&
      this.range_date['startDate'] != null &&
      this.range_date_second['startDate'] != null
    ) {
      this._hasError$.next(false);
      this._search$.next();
      if (!this.isCustomFilter) {
        this._pastSearch$.next();
      }
    }
  }

  async _search(): Promise<object> {
    this._metrics$.next([]);
    this._chart$.next([]);
    let user = JSON.parse(localStorage.getItem('traiyUser'));
    let dates = {
      starts: this.range_date['startDate'].startOf('day').toISOString(),
      ends: this.range_date['endDate'].endOf('day').toISOString(),
      userMode: user.hasOwnProperty('userMode') ? user['userMode'] : 0,
      fCache: this.forceCache.toString()
    };

    const url = `${this.globals.availabilities.history}?${new URLSearchParams(
      dates
    ).toString()}`;

    const url2 = `https://api.tariy.ai/meeting/meetings/insights?${new URLSearchParams(
      dates
    ).toString()}`;
    //let metrics = await this.httpClient.get(`${url}`).toPromise();
    
    let metrics = await this.httpClient.get(`${url2}`).toPromise();
    return metrics;
  }

  async _pastSearch(): Promise<object> {
    this._pastMetrics$.next([]);
    
    let user = JSON.parse(localStorage.getItem('traiyUser'));
    let pastDates = {
      starts: this.range_date_second['startDate'].startOf('day').toISOString(),
      ends: this.range_date_second['endDate'].endOf('day').toISOString(),
      userMode: user.hasOwnProperty('userMode') ? user['userMode'] : 0,
      fCache: this.forceCache.toString()
    };

    const pastUrl = `${
      this.globals.availabilities.history
    }?${new URLSearchParams(pastDates).toString()}`;

    const pasturl2 = `https://api.tariy.ai/meeting/meetings/insights?${new URLSearchParams(
      pastDates
    ).toString()}`;

    //let pastMetrics = await this.httpClient.get(`${pastUrl}`).toPromise();
    let pastMetrics = await this.httpClient.get(`${pasturl2}`).toPromise();

    return pastMetrics;
  }
}
