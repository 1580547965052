<div class="row step-container">
  <div class="container-image col-md-12 col-lg-7 col-sm-12 col-xl-7 col-xs-12 py-5 px-5">
    <img [src]="stepsData[selectedIndexStep]['img']" class="step-image" [@imageAnimation]="selectedIndexStep">
  </div>
  <div class="steps col-md-12 col-lg-5 col-sm-12 col-xl-5 col-xs-12 mt-5 mb-5 p-0">
    <div class="row steps-row-info">
      <div class="col-12 px-5 pt-5">
        <div [@textAnimation]="selectedIndexStep" class="d-inline-block">
          <span class="step-number">{{selectedIndexStep + 1}}</span>
        </div>
        <div class="d-inline-block">
          <h1 class="step-title" [@textAnimation]="selectedIndexStep"><b>{{stepsData[selectedIndexStep]['title']}}</b></h1>
        </div>
        <div class="step-indicators col-12 p-0">
          <div class="step-indicator" *ngFor="let indicator of stepsData; let i = index" [ngClass]="{'current-indicator': i == selectedIndexStep}"></div>
        </div>
        <p class="step-text" [innerHTML]="stepsData[selectedIndexStep]['text']" [@textAnimation]="selectedIndexStep"></p>
      </div>
    </div>
    <div class="row">
      <div class="col-12 stepper-controls px-5">
        <div class="row">
          <div class="col-3 text-left p-0">
            <a class="btn-control btn-close" (click)="close()">Close</a>
          </div>
          <div class="col-9 text-right p-0">
            <a class="btn-control mr-2 btn-prev" (click)="prev()" *ngIf="selectedIndexStep > 0 && selectedIndexStep < stepsData.length - 1"><fa-icon icon="chevron-left"></fa-icon> Prev</a>
            <a class="btn-control btn-next" (click)="next()">{{selectedIndexStep == stepsData.length - 1 ? 'Back to connections' : 'Next'}} <fa-icon icon="chevron-right"></fa-icon></a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
