import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Globals } from 'src/app/global';

@Injectable({
  providedIn: 'root'
})
export class MemberCrudService {
  // variable that store the import of Globals Class that will help us to retrieve the urls for every service
  globals = Globals;
  constructor(private http: HttpClient) { }

  /**
   * Method that updates an specific member
   * @param {object} params - Object that contains member info
   */
  async updateMember(params = {}): Promise<object> {
    const url = `${this.globals.urlTraiyBase}${this.globals.users.root}`;
    return await this.http.put(`${url}/${params['Email']}`, params).toPromise();
  }
  /**
   * Method that updates an specific member timezone
   * @param {object} params - Object that contains member info
   */
   async updateTimezone(params = {}): Promise<object> {
    const url = `${this.globals.urlTraiyBase}${this.globals.users.root}${this.globals.users.timezone}`;
    return await this.http.put(`${url}/${params['Email']}`, params).toPromise();
  }
}
