<div class="select-control">
  <ng-select [items]="items"
              [virtualScroll]="true"
              [loading]="loading"
              notFoundText="Results not found"
              loadingText="Loading..."
              [placeholder]="placeholder"
              [clearOnBackspace]="false"
              (open)="onOpen()"
              (scrollToEnd)="onScrollToEnd()"
              [typeahead]="select$"
              class="select-custom"
              [(ngModel)]="selectedValue"
              [disabled]="canEdit"
              bindLabel="description"
              [clearable]="false"
              (change)="selectChange($event)">
      <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
          {{item.description}}
      </ng-template>
  </ng-select>
</div>
