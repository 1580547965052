import { NgModule, APP_INITIALIZER } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { ToastrModule } from 'ngx-toastr';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { MatTooltipModule} from '@angular/material/tooltip';
import { MatBadgeModule} from '@angular/material/badge';
import { MatChipsModule} from '@angular/material/chips';
import { CookieService } from 'ngx-cookie-service';

import { AppComponent } from './app.component';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { AuthInterceptor } from './interceptors/auth/auth.interceptor';

// Custom modules
import { PanelModule } from './routing/panel/panel.module';
import { MemberModule } from './routing/member/member.module';
import { WaitlistModule } from './routing/waitlist/waitlist.module';
import { ITAdminModule } from './routing/itadmin/itadmin.module';

// Pages
import { SettingsComponent } from './views/settings/settings.component';
import { HelpComponent } from './views/help/help.component';
import { RedeemCodeComponent } from './views/redeem-code/redeem-code.component';
import { SharedModule } from './shared/shared.module';
import { MatDialogModule } from '@angular/material/dialog';
import { StepsWizardComponent } from './components/settings/steps-wizard/steps-wizard.component';
import { ScoreDetailsComponent } from './components/scoreDetails/score-details.component';
import { ScoreDefinitionComponent } from './components/scoreDefinition/score-definition.component';
import { TrainingCategoriesComponent } from './components/trainingCategories/training-categories.component';
import { ZoomProfileComponent } from './components/zoomProfile/zoom-profile.component';
import { LoadingComponent } from './components/loadingComponent/loading-component.component';
import { CardInsightsComponent } from './components/cardInsights/card-insights.component';
import { NudgeSettings } from './components/nudgesSettings/nudge-settings.component';
import { NotificationSettings } from './components/notificationSettings/notification-settings.component';
import { TipsDetailsComponent } from './components/tipsDetails/tips-details.component';
import { MatStepperModule } from '@angular/material/stepper';
import { PrivacyComponent } from './views/privacy/privacy.component';
import { ZoomMeetingComponent } from './views/zoom-meeting/zoom-meeting.component';
import { ZoomInstallComponent } from './views/zoom-install-app/zoom-install.component';
import { ZoomRedirectComponent } from './views/zoom-redirect/zoom-redirect.component';

import { UserTimezoneService } from './services/user-timezone/user-timezone.service';
import { IntercomModule } from 'ng-intercom';

@NgModule({
  declarations: [
    AppComponent,
    SettingsComponent,
    HelpComponent,
    RedeemCodeComponent,
    StepsWizardComponent,
    ScoreDetailsComponent,
    ScoreDefinitionComponent,
    TrainingCategoriesComponent,
    ZoomProfileComponent,
    LoadingComponent,
    CardInsightsComponent,
    NudgeSettings,
    NotificationSettings,
    TipsDetailsComponent,
    PrivacyComponent,
    ZoomMeetingComponent,
    ZoomInstallComponent,
    ZoomRedirectComponent
  ],
  imports: [
    NgxDaterangepickerMd.forRoot({
      displayFormat:'LL',
    }),
    NgMultiSelectDropDownModule.forRoot(),
    AppRoutingModule,
    ToastrModule.forRoot(),
    HttpClientModule,
    PanelModule,
    MemberModule,
    WaitlistModule,
    ITAdminModule,
    SharedModule,
    MatDialogModule,
    MatStepperModule,
    MatChipsModule,
    MatTooltipModule,
    MatBadgeModule,
    IntercomModule.forRoot({
      appId: 'cvgrqmpc', // from your Intercom config
      updateOnRouterChange: true // will automatically run `update` on router event changes. Default: `false`
    }),
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    CookieService,
    {
      provide: APP_INITIALIZER,
      useFactory: userTimezoneServiceFactory,
      multi: true,
      deps: [UserTimezoneService]
    }
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
}

export function userTimezoneServiceFactory(userTimezoneService: UserTimezoneService) {
  return (): Promise<boolean> => {
    return userTimezoneService.updateTimezone();
  }
}