import { Component, OnInit, Inject, Input } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { StepsConnection } from 'src/app/models/settings/steps-connection';
import { trigger, transition, style, animate } from '@angular/animations';

@Component({
  selector: 'app-score-definition',
  templateUrl: './score-definition.component.html',
  styleUrls: ['./score-definition.component.scss']
})
export class ScoreDefinitionComponent implements OnInit {
  tips = [
    {class: 'cost', key: 'Cost', value: ['75-100', 'If your scores are between 75 and 100 your meetings are really quite productive. While there is room for improvement, your scores are above what is typical.']},
    {class: 'analytics', key: 'Meeting Start', value: ['50-75', 'If your scores are between 50 and 75 your meetings are generally hit-or-miss. Plenty of time is being wasted. Improvements need to be made, but your scores are about what we find in most organizations.']},
    {class: 'analytics', key: 'Meeting Ends', value: ['> 50', 'If your scores are below 50 your meetings need substantial improvement. Your scores are considerably below average.']},
    // {class: 'analytics', key: 'Duration', value: ['TARIY takes in consideration the actual meeting time duration.', 'TARIY recommends meetings under 45 minutes.']},
    // {class: 'analytics', key: 'Num of Attendees', value: ['TARIY values how many attendees were present in the meeting.', 'TARIY recommends meetings with under 8 attendees.']},
    // {class: 'analytics', key: 'Sentiment', value: ['TARIY analyses the meeting transcript and our algorithm assigns a sentiment to each participant.', 'TARIY recommends engaging and inclusive meetings.']},
    // {class: 'analytics', key: 'Participation', value: ['TARIY takes in consideration how much each attendee participated.', 'TARIY recommends every attendee should at least have a 10% participation.']},
    // {class: 'scheduled', key: 'Timeframe', value: ['TARIY takes in consideration the timeframe when the meeting is scheduled for.', 'TARIY recommends meetings between 9:00am-12:00pm and 2:00pm-4:00pm.']},
    // {class: 'scheduled', key: 'Day', value: ['TARIY uses the day of the week the meeting was scheduled for.', 'TARIY recommends scheduling meetings between Tuesday and Thursday.']},
    // {class: 'scheduled', key: 'Notice', value: ['TARIY takes in consideration how many days in advance was the meeting scheduled.', 'TARIY recommends scheduling meetings 5 days in advance.']},
    // {class: 'scheduled', key: 'Has Agenda', value: ['TARIY analyses the meeting invite and searches for an agenda.', 'TARIY recommends every meeting should have an agenda']},
  ];
  columnHeight = 0;


  constructor(public dialogRef: MatDialogRef<ScoreDefinitionComponent>, @Inject(MAT_DIALOG_DATA) private data: any) { }

  ngOnInit(): void {
    // Set data sent from dialog data to steps
    //this.tips = this.data['tips'];
    this.columnHeight = 100/ (this.tips.length);
  }

  // Close dialog
  close(){
    this.dialogRef.close();
  }

}
