<div class="modalNote d-none d-xl-block d-lg-block">
  TARIY insights regarding your meetings.
</div>
<mat-dialog-content class="card mt-2">
  <div class="header">
    <!-- <div class="title text-center pt-1 pb-1 main-title" style="height:50%;">TARIY Tips</div> -->
    <div class="title col-2 d-none d-xl-inline-flex d-lg-inline-flex text-center justify-content-center pt-1" style="height:50%">Parameter</div>
    <div class="title col-2 d-none d-xl-inline-flex d-lg-inline-flex text-center justify-content-center pt-1" style="height:50%">Definition</div>
    <div class="title col-8 d-none d-xl-inline-flex d-lg-inline-flex text-center justify-content-center pt-1" style="height:50%">Insights</div>
  </div>
  <div class="body">
    <div class="row" *ngFor="let tip of tips; let i = index" style="height:auto" [ngClass]="tip.class">
      <div class="col-12 col-xl-2 col-lg-2 pl-4 align-items-center pb-2 pt-2 d-flex">{{tip.key}}</div>
      <div class="col-12 col-xl-2 col-lg-2 align-items-center d-none d-xl-flex d-lg-flex pb-2 pt-2"><span>{{tip.definition}}</span></div>
      <div class="col-12 col-xl-8 col-lg-8 align-items-start justify-content-center d-flex flex-column pb-2 pt-2">
        <ng-container *ngFor="let insight of tip.insights">
          <p class="insights pr-1"> {{insight}}</p>
      </ng-container>
      </div>
    </div>
  </div>
</mat-dialog-content>