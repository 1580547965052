import { Component, OnChanges, Inject, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { StepsConnection } from 'src/app/models/settings/steps-connection';
import { trigger, transition, style, animate } from '@angular/animations';
import { faInfoCircle, faArrowAltCircleUp, faArrowAltCircleDown } from '@fortawesome/free-solid-svg-icons';
import { CardInsightsComponent } from 'src/app/components/cardInsights/card-insights.component';

@Component({
  selector: 'app-dashboard-card',
  templateUrl: './dashboard-card.component.html',
  styleUrls: ['./dashboard-card.component.scss']
})
export class DashboardCardComponent implements OnChanges {
  ceiler = Math.ceil;
  @Input() title: string;
  @Input() dataTitle: string;
  @Input() icon: string;
  @Input() amount: string;
  @Input() total: number;
  @Input() range: string;
  @Input() difference: string;
  @Input() isIncrement: boolean;
  @Input() yesterdayAmount: string;
  @Input() yesterdayTotal: number;
  @Input() hasCompareData: boolean;
  @Input() carouselClass: string;
  @Input() dataClass: string;
  @Input() loading: Observable<boolean>;
  @Input() insight: string[];
  arrowIcon: any;
  iconToInsight = {
    'meet_cost': 0,
    'meet_time': 1,
    'avg_meet_time': 2,
    'meet_num': 3,
    'deep_work': 4,
    'per_time': 5,
    'avg_meet_cost': 6,
    'meet_par': 7,
  }
  

  constructor(
    private dialog: MatDialog
  ) {
    this.arrowIcon = this.isIncrement ? faArrowAltCircleDown: faArrowAltCircleUp;
  }

   ngOnChanges() {
    // create header using child_id
    this.arrowIcon = this.isIncrement ? faArrowAltCircleDown: faArrowAltCircleUp;
  }

  openInsights() {
    if(this.icon in this.iconToInsight) {
      const dialogRef = this.dialog.open(CardInsightsComponent, {
        width: '80%',
        height: 'auto',
        minHeight: '200px',
        data: {insights: this.insight, selectedRow: this.iconToInsight[this.icon]}
      });
    }
  }


}
