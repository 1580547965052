import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Globals } from 'src/app/global';

@Injectable({
  providedIn: 'root'
})
export class TeamsSettingsService {
  // variable that store the import of Globals Class that will help us to retrieve the urls for every service
  globals = Globals;

  constructor(private http: HttpClient) { }

  /**
    Set settings teams data
    @param object {string, string} params - Data for zoom settings
  */
  setTeamsSettings(params: {tenant: string, organization: string}): Promise<object>{
    //const url = `${this.globals.settings.zoomSettings}`;
    
    //const url = 'https://phbeqykb8j.execute-api.us-west-2.amazonaws.com/default/zoomoauth';
    const url = `${this.globals.settings.teamsOauth}/oauth`;
    return this.http.post(url, params).toPromise();
  }

  /**
    Get teams status data
    @param object params - Data for zoom settings
  */
    statusTeamsSettings(): Promise<object>{
      //const url = `${this.globals.settings.zoomSettings}`;
      
      //const url = 'https://rdw98qzgu8.execute-api.us-west-2.amazonaws.com/Prod/status';
      const url = `${this.globals.settings.teamsOauth}/status`;
      return this.http.get(url).toPromise();
    }
  

  // Delete settings teams data
  deleteTeamsSettings(): Promise<object>{
    //const url = `${this.globals.settings.zoomSettings}`;
    const url = `${this.globals.settings.teamsOauth}/delete`;
    return this.http.post(url, {}).toPromise();
  }
}
