<section class="login" style="background-color: #fbfbfb;" *ngIf="inMeeting">
  <div class="container-md h-100">
    <div class="row" style="height: 40px; background-color: #478BF5; padding-top: 0px; box-shadow: 1px 1px 3px 0px rgb(0 0 0 / 20%); color: white; position: relative;">
      <div class="col-12 d-flex align-items-center pl-0">
        <div class="col-3 d-flex">
          <div class="d-flex top-buttons invite-button" (click)="invite()" [matTooltip]="'Send meeting recap to emails'">
            <span><fa-icon [icon]="inviteIcon"></fa-icon></span>
          </div>
          <div class="d-flex top-buttons" (click)="openInfo()" [matTooltip]="'Learn more about TARIY'">
            <span><fa-icon [icon]="questionIcon"></fa-icon></span>
          </div>
        </div>
        <div class="col-6 d-flex">
          <div class="d-flex ml-auto mr-auto">
            <span style="font-weight: bold;">Time remaining</span>
          </div>
        </div>
        <div class="col-3">
          <div class="d-flex align-items-center ml-auto justify-content-end">
            <!-- <svg xmlns="http://www.w3.org/2000/svg" width="40" height="39" version="1.1" enable-background="new 0 0 164 209" xml:space="preserve" style="transform: scale(0.75);">
              <circle   r="15" cx="20" cy="20" fill="none" stroke="#FFFFFF" stroke-width="5"></circle>
              <circle transform="rotate(-90 20 20)"  r="5" cx="20" cy="20" fill="none" [attr.stroke-dasharray]="clockPercent" stroke="#FFFFFF" stroke-width="10"></circle>
            </svg> -->
            <span style="font-weight: 600;">{{ formatDuration(duration - currentDuration) }}</span>
          </div>
        </div>
        
      </div>
      <div style="position: absolute; top: 30px; left: 0px; z-index: 10000;" [ngStyle]="{'display': !showInput ? 'none' : 'initial' }">
        <div style="    padding: 5px;
        background-color: rgba(71,139,245,0.85);
        border-radius: 8px;
        box-shadow: 1px 1px 3px 0 rgb(0 0 0 / 20%);">
          <input type="text" class="form-control" placeholder="Emails" [(ngModel)]="emails" (change)="addEmail($event)">
        </div>
      </div>
    </div>
    <div class="row justify-content-center mt-3 mb-2 mr-1 ml-1" style="height: 70px;">
      <div class="col-12 d-flex align-items-center pr-0 pl-0" style="background-color: white; 
      border: 1px solid #f0f0f0;
      border-radius: 3px;
      box-shadow: 1px 1px 3px 0 rgb(0 0 0 / 20%);">
        <div class="col-7 p-0">
          <span style="font-size: 1.15rem; 
          line-height: 1.5rem;
          text-align: end;
          font-weight: 700;
          padding-left: 0.5rem;">Meeting Score&reg;
          </span>
        </div>
        <div class="col-5 p-0">
          <svg style="min-height: 60px; min-width: 60px; margin-left: 10px" width="60" height="60" viewBox="0 0 80 80" class="progress__svg meeting-score">
            <circle
              [attr.r]="radius"
              cx="40"
              cy="40"
              stroke-width="8"
              class="progress__value"
            >
            </circle>
            <circle
              [style.strokeDasharray]="circumference"
              [style.strokeDashoffset]="dashoffsetScore"
              [attr.r]="radius"
              cx="40"
              cy="40"
              stroke-width="9"
              class="progress__meter"
            >
            </circle>
            <!-- <text x="54" y="-70" class="progress__text" >{{ formatDuration(duration - currentDuration) }}</text> -->
          </svg>
          <span style="position: relative; top: 2px; left: -41px; font-weight: bold; font-size: 1.1rem;">{{(meetingScore + (participationScore*30)) | number: '1.0-0' }}</span>
          <span style="font-size: 1.1rem; position: relative; left: -14px;">
            <fa-icon [icon]="improveIcon" class="pl-1" style="color: #478BF5;" *ngIf="previousScore < (meetingScore + (participationScore*30))"></fa-icon>
            <fa-icon [icon]="unimproveIcon" class="pl-1" style="color: #f34f77;" *ngIf="previousScore > (meetingScore + (participationScore*30))"></fa-icon>
            <fa-icon [icon]="sameIcon" style="color: #c5c5c5;" class="pl-1" *ngIf="previousScore == (meetingScore + (participationScore*30))"></fa-icon>
          </span>
        </div>
      </div>
    </div>

    <div class="row justify-content-center mt-3 mb-2 mr-1 ml-1 pb-1 pt-1" style="min-height: 35px;
      height: auto;
      background-color: #478BF5;
      color: white; 
      border: 1px solid #5296FF;
      border-radius: 3px;
      position: relative;
      box-shadow: 1px 1px 3px 0 rgb(0 0 0 / 20%);">
      <div class="row w-100 justify-content-center align-items-center" style="font-size: 1.1rem; font-weight: 600;">
        Alerts {{alerts.length == 0 ? 0:alertSlide + 1}} of {{alerts.length}}
      </div>
      <div class="row w-100" *ngIf="alerts.length > 0">
        <div class="col-12 d-flex align-items-center pr-0 pl-0" *ngFor="let alert of alerts;  let i = index;">
          <span class="w-100" style="font-size: 0.95rem;
          text-align: center;
          padding-right: 1rem;
          padding-left: 1rem;" *ngIf="alertSlide == i">{{alert}}
          </span>
        </div>
      </div>
      <div class="row w-100 pl-1 pr-1" style="position: absolute; height: 100%; align-items: center;" *ngIf="alerts.length > 1">
        <span class="changeSlide" (click)="changeSlide(false)"><fa-icon [icon]="leftArrow"></fa-icon></span>
        <span class="ml-auto changeSlide justify-content-end" (click)="changeSlide(true)"><fa-icon [icon]="rightArrow"></fa-icon></span>
      </div>
    </div>

    <div class="row mt-3 mb-2 ml-1 mr-1" style="border: 1px solid #f0f0f0;
      border-radius: 3px;
      box-shadow: 1px 1px 3px 0 rgb(0 0 0 / 20%);">
      <div class="col-12 participants-card">
        <div class="row justify-content-center pb-1 pt-1" style="border-bottom: 1px solid #c5c5c5; font-weight: 600; font-size: 0.85rem; padding-left: 8px;">
          <span>Participation</span>
        </div>
        <div class="row" *ngFor="let speaker of speakers; let i = index;" style="margin-bottom: 5px;" [ngStyle]="{'border-bottom': i < (speakers.length - 1) ? '1px dashed #c5c5c5' : null }">
          <div class="col-12">
            <div class="row pt-1">
              <div class="col-8 pr-0" style="position: relative;">
                <span class="speaker-name" (click)="openOptions(i)" style="font-weight: bold;">{{speaker.name}}
                </span>
              </div>
              <div class="col-3" style="text-align: end;">
                <span>{{speaker.duration/getTotalSpeakTime() | percent}}</span>
              </div>
              
            </div>

            <div class="row" *ngIf="speaker.openOptions">
              <div class="col-4 p-0 d-flex justify-content-center" style="position: relative;">
                <span class="option-subtitle">Gender</span>
              </div>

              <div class="col-4 p-0 d-flex justify-content-center" style="position: relative;">
                <span class="option-subtitle">Communication</span>
              </div>

              <div class="col-4 p-0 d-flex justify-content-center" style="position: relative;">
                <span class="option-subtitle">Generation</span>
              </div>
            </div>

            <div class="row pt-1" *ngIf="speaker.openOptions">
              <div class="col-4 p-0 d-flex justify-content-center" style="position: relative;">
                <span *ngIf="speaker.gender === 0" class="gender-box" style="background-color: #77b62d; color: white;" [matTooltip]="'Male'" (click)="openGender(i)">M</span>
                <span *ngIf="speaker.gender === 1" class="gender-box" style="background-color: #7369df; color: white;" [matTooltip]="'Female'" (click)="openGender(i)">F</span>
                <span *ngIf="speaker.gender === 2" class="gender-box" style="background-color: #f36c20; color: white;" [matTooltip]="'Non-Binary'" (click)="openGender(i)">NB</span>
                <span *ngIf="speaker.gender === 3" class="gender-box" style="background-color: #2948e3; color: white;" [matTooltip]="'Unknown'" (click)="openGender(i)">UNK</span>

                <div class="d-flex align-items-center" style="position: absolute;
                  background-color: white;
                  width: auto;
                  height: 25px;
                  z-index: 100;
                  top: -25px;
                  padding: 0px 3px;
                  border-radius: 5px;
                  border: 1px solid #c5c5c5;" *ngIf="speaker.openGender">
                  <span class="gender-option" [matTooltip]="'Male'" (click)="changeGender(0, i)">M</span>
                  <span class="gender-option" [matTooltip]="'Female'" (click)="changeGender(1, i)">F</span>
                  <span class="gender-option" [matTooltip]="'Non-Binary'" (click)="changeGender(2, i)">NB</span>
                </div>
              </div>

              <div class="col-4 p-0 d-flex justify-content-center" style="position: relative;">
                <span *ngIf="speaker.communication === 0" class="gender-box" style="background-color: #ad39db; color: white;" [matTooltip]="'Analytical'" (click)="openCommunication(i)">AN</span>
                <span *ngIf="speaker.communication === 1" class="gender-box" style="background-color: #a41e5d; color: white;" [matTooltip]="'Intuitive'" (click)="openCommunication(i)">IN</span>
                <span *ngIf="speaker.communication === 2" class="gender-box" style="background-color: #33aed5; color: white;" [matTooltip]="'Functional'" (click)="openCommunication(i)">FN</span>
                <span *ngIf="speaker.communication === 3" class="gender-box" style="background-color: #df2681; color: white;" [matTooltip]="'Personal'" (click)="openCommunication(i)">PE</span>
                <span *ngIf="speaker.communication === 7" class="gender-box" style="background-color: #2948e3; color: white;" [matTooltip]="'Unknown'" (click)="openCommunication(i)">UNK</span>

                <div class="d-flex align-items-center" style="position: absolute;
                  background-color: white;
                  width: auto;
                  height: 25px;
                  z-index: 100;
                  top: -25px;
                  padding: 0px 3px;
                  border-radius: 5px;
                  border: 1px solid #c5c5c5;" *ngIf="speaker.openCommunication">
                  <span class="gender-option" [matTooltip]="'Analytical'" (click)="changeCommunication(0, i)">AN</span>
                  <span class="gender-option" [matTooltip]="'Intuitive'" (click)="changeCommunication(1, i)">IN</span>
                  <span class="gender-option" [matTooltip]="'Functional'" (click)="changeCommunication(2, i)">FN</span>
                  <span class="gender-option" [matTooltip]="'Personal'" (click)="changeCommunication(3, i)">PE</span>
                </div>
              </div>

              <div class="col-4 p-0 d-flex justify-content-center" style="position: relative;">
                <span *ngIf="speaker.age === 0" class="gender-box" style="background-color: #33afd5; color: white;" [matTooltip]="'Baby Boomer'" (click)="openAge(i)">BB</span>
                <span *ngIf="speaker.age === 1" class="gender-box" style="background-color: #f89f15; color: white;" [matTooltip]="'Gen X'" (click)="openAge(i)">GX</span>
                <span *ngIf="speaker.age === 2" class="gender-box" style="background-color: #7269de; color: white;" [matTooltip]="'Millennial'" (click)="openAge(i)">MI</span>
                <span *ngIf="speaker.age === 3" class="gender-box" style="background-color: #a41e5d; color: white;" [matTooltip]="'Gen Z'" (click)="openAge(i)">GZ</span>
                <span *ngIf="speaker.age === 4" class="gender-box" style="background-color: #2948e3; color: white;" [matTooltip]="'Unknown'" (click)="openAge(i)">UNK</span>
                
                <div class="d-flex align-items-center" style="position: absolute;
                  background-color: white;
                  width: auto;
                  height: 25px;
                  z-index: 100;
                  top: -25px;
                  left: -30px;
                  padding: 0px 3px;
                  border-radius: 5px;
                  border: 1px solid #c5c5c5;" *ngIf="speaker.openAge">
                  <span class="gender-option" [matTooltip]="'Baby Boomer'" (click)="changeAge(0, i)">BB</span>
                  <span class="gender-option" [matTooltip]="'Gen X'" (click)="changeAge(1, i)">GX</span>
                  <span class="gender-option" [matTooltip]="'Millennials'" (click)="changeAge(2, i)">MI</span>
                  <span class="gender-option" [matTooltip]="'Gen Z'" (click)="changeAge(3, i)">GZ</span>
                </div>
              </div>

            </div>
            <div class="row">
              <div class="col-12">
                <span>Monologues: {{speaker.monologues}}</span>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <svg height="20" width="100%">
                  <line x1="5" y1="10" x2="98%" y2="10" style="stroke:#E5E5E5;stroke-width:10" stroke-linecap="round"></line>
                  <line x1="6" y1="10" [attr.x2]="(speaker.duration/getTotalSpeakTime())*96 + 1.5 + '%'" y2="10" style="stroke:#478BF5;stroke-width:6" stroke-linecap="round"></line>
                  <!-- <circle cx="5" r="5" cy="0"></circle> -->
                </svg>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row ml-1 mr-1" style="font-weight: 700;">
      <div class="col-5 d-flex mt-2 mb-1" style="background-color: #478BF5; padding: 0px;
      height: 38px; 
      border: 1px solid #f0f0f0;
      border-radius: 3px;
      color: white;
      box-shadow: 1px 1px 3px 0 rgb(0 0 0 / 5%);">
        <div class="d-flex align-items-center justify-content-start" style="padding-left: 5px;">
          <span>Interruptions</span>
        </div>
        <div class="d-flex align-items-center ml-auto mr-auto">
          <span style="color: white; font-weight: bold;">{{interruptions}}</span>
        </div>
      </div>
      <div class="col-5 d-flex mt-2 mb-1 ml-auto" style="background-color: #478BF5; padding: 0px;
      height: 38px;
      color: white;
      border: 1px solid #f0f0f0;
      border-radius: 3px;
      box-shadow: 1px 1px 3px 0 rgb(0 0 0 / 5%);">
        <div class="d-flex align-items-center justify-content-start" style="padding-left: 5px;">
          <span>Monologues</span>
        </div>
        <div class="d-flex align-items-center ml-auto mr-auto">
          <span style="color: white; font-weight: bold;">{{monologueCount}}</span>
        </div>
      </div>
    </div>

    <div class="row ml-1 mr-1">
      <div class="col-12 d-flex" style="background-color: white; padding: 5px;
      margin: 8px 0px;
      height: 46px;
      flex-flow: column; 
      border: 1px solid #f0f0f0;
      border-radius: 3px;
      box-shadow: 1px 1px 3px 0 rgb(0 0 0 / 20%);">
        <div class="row w-100 ml-0" style="font-size: 11px;">
          <div class="col-2" style="padding: 0px;">
            <span>Gender</span>
          </div>
          <div class="col-10 d-flex justify-content-end" style="padding: 0px;" >
            <span style="margin-right: 5px; margin-left: auto;" *ngIf="gendersTotal.male > 0">
              <svg width="8" height="8" viewBox="0 0 8 8">
                <circle r="2.5" cx="2.5" cy="2.5" stroke-width="12" fill="#77b62d"></circle>
              </svg> 
              <span>Male</span>
            </span>

            <span style="margin-right: 5px;" *ngIf="gendersTotal.female > 0">
              <svg width="8" height="8" viewBox="0 0 8 8">
                <circle r="2.5" cx="2.5" cy="2.5" stroke-width="12" fill="#7369df"></circle>
              </svg> 
              <span>Female</span>
            </span>
            
            <span style="margin-right: 5px;" *ngIf="gendersTotal.nonBinary > 0">
              <svg width="8" height="8" viewBox="0 0 8 8">
                <circle r="2.5" cx="2.5" cy="2.5" stroke-width="12" fill="#f36c20"></circle>
              </svg> 
              <span>Non-binary</span>
            </span>
            
            <span style="margin-right: 5px;" *ngIf="gendersTotal.unknown > 0">
              <svg width="8" height="8" viewBox="0 0 8 8">
                <circle r="2.5" cx="2.5" cy="2.5" stroke-width="12" fill="#2948e3"></circle>
              </svg> 
              <span>Unknown</span>
            </span>
          </div>
        </div>
        <div class="row w-100 ml-0" style="font-size: 11px;">
          <svg height="20" width="100%">
            <line x1="5" y1="10" x2="98%" y2="10" style="stroke:#E5E5E5;stroke-width:10" stroke-linecap="round"></line>
            <line x1="6" y1="10" [attr.x2]="genderAxes[0]" y2="10" style="stroke:#77b62d;stroke-width:6" stroke-linecap="round" *ngIf="gendersTotal.male > 0" [matTooltip]="'Male ' + getPercent(gendersTotal.male)"></line>
            <line [attr.x1]="genderAxes[0]" y1="10" [attr.x2]="genderAxes[1]" y2="10" style="stroke:#7369df;stroke-width:6" stroke-linecap="round" *ngIf="gendersTotal.female > 0" [matTooltip]="'Female ' + getPercent(gendersTotal.female)"></line>
            <line [attr.x1]="genderAxes[1]" y1="10" [attr.x2]="genderAxes[2]" y2="10" style="stroke:#f36c20;stroke-width:6" stroke-linecap="round" *ngIf="gendersTotal.nonBinary > 0" [matTooltip]="'Non-Binary ' + getPercent(gendersTotal.nonBinary)"></line>
            <line [attr.x1]="genderAxes[2]" y1="10" [attr.x2]="genderAxes[3]" y2="10" style="stroke:#2948e3;stroke-width:6" stroke-linecap="round" *ngIf="gendersTotal.unknown > 0" [matTooltip]="'Unknown ' + getPercent(gendersTotal.unknown)"></line>
            <!-- <circle cx="5" r="5" cy="0"></circle> -->
          </svg>
        </div>
      </div>
    </div>

    <div class="row ml-1 mr-1">
      <div class="col-12 d-flex" style="background-color: white; padding: 5px;
      margin: 8px 0px;
      height: 46px;
      flex-flow: column; 
      border: 1px solid #f0f0f0;
      border-radius: 3px;
      box-shadow: 1px 1px 3px 0 rgb(0 0 0 / 20%);">
        <div class="row w-100 ml-0" style="font-size: 11px;">
          <div class="col-2" style="padding: 0px;">
            <span>Communication</span>
          </div>
          <div class="col-10 d-flex justify-content-end" style="padding: 0px;" >
            <span style="margin-right: 5px; margin-left: auto;" *ngIf="communicationsTotal.analytical > 0">
              <svg width="8" height="8" viewBox="0 0 8 8">
                <circle r="2.5" cx="2.5" cy="2.5" stroke-width="12" fill="#ad39db"></circle>
              </svg> 
              <span>Analytical</span>
            </span>

            <span style="margin-right: 5px;" *ngIf="communicationsTotal.intuitive > 0">
              <svg width="8" height="8" viewBox="0 0 8 8">
                <circle r="2.5" cx="2.5" cy="2.5" stroke-width="12" fill="#a41e5d"></circle>
              </svg> 
              <span>Intuitive</span>
            </span>
            
            <span style="margin-right: 5px;" *ngIf="communicationsTotal.functional > 0">
              <svg width="8" height="8" viewBox="0 0 8 8">
                <circle r="2.5" cx="2.5" cy="2.5" stroke-width="12" fill="#33aed5"></circle>
              </svg> 
              <span>Functional</span>
            </span>

            <span style="margin-right: 5px;" *ngIf="communicationsTotal.personal > 0">
              <svg width="8" height="8" viewBox="0 0 8 8">
                <circle r="2.5" cx="2.5" cy="2.5" stroke-width="12" fill="#df2681"></circle>
              </svg> 
              <span>Personal</span>
            </span>
            
            <span style="margin-right: 5px;" *ngIf="communicationsTotal.unknown > 0">
              <svg width="8" height="8" viewBox="0 0 8 8">
                <circle r="2.5" cx="2.5" cy="2.5" stroke-width="12" fill="#2948e3"></circle>
              </svg> 
              <span>Unknown</span>
            </span>
          </div>
        </div>
        <div class="row w-100 ml-0" style="font-size: 11px;">
          <svg height="20" width="100%">
            <line x1="5" y1="10" x2="98%" y2="10" style="stroke:#E5E5E5;stroke-width:10" stroke-linecap="round"></line>
            <line x1="6" y1="10" [attr.x2]="communicationAxes[0]" y2="10" style="stroke:#ad39db;stroke-width:6" stroke-linecap="round" *ngIf="communicationsTotal.analytical > 0" [matTooltip]="'Analytical ' + getPercent(communicationsTotal.analytical)"></line>
            <line [attr.x1]="communicationAxes[0]" y1="10" [attr.x2]="communicationAxes[1]" y2="10" style="stroke:#a41e5d;stroke-width:6" stroke-linecap="round" *ngIf="communicationsTotal.intuitive > 0" [matTooltip]="'Intuitive ' + getPercent(communicationsTotal.intuitive)" ></line>
            <line [attr.x1]="communicationAxes[1]" y1="10" [attr.x2]="communicationAxes[2]" y2="10" style="stroke:#33aed5;stroke-width:6" stroke-linecap="round" *ngIf="communicationsTotal.functional > 0" [matTooltip]="'Functional ' + getPercent(communicationsTotal.functional)"></line>
            <line [attr.x1]="communicationAxes[2]" y1="10" [attr.x2]="communicationAxes[3]" y2="10" style="stroke:#df2681;stroke-width:6" stroke-linecap="round" *ngIf="communicationsTotal.personal > 0" [matTooltip]="'Personal ' + getPercent(communicationsTotal.personal)"></line>
            <line [attr.x1]="communicationAxes[3]" y1="10" [attr.x2]="communicationAxes[4]" y2="10" style="stroke:#2948e3;stroke-width:6" stroke-linecap="round" *ngIf="communicationsTotal.unknown > 0" [matTooltip]="'Unknown ' + getPercent(communicationsTotal.unknown)" ></line>
            <!-- <circle cx="5" r="5" cy="0"></circle> -->
          </svg>
        </div>
      </div>
    </div>

    <div class="row ml-1 mr-1">
      <div class="col-12 d-flex" style="background-color: white; padding: 5px;
      margin: 8px 0px;
      height: 46px;
      flex-flow: column; 
      border: 1px solid #f0f0f0;
      border-radius: 3px;
      box-shadow: 1px 1px 3px 0 rgb(0 0 0 / 20%);">
        <div class="row w-100 ml-0" style="font-size: 11px;">
          <div class="col-2" style="padding: 0px;">
            <span>Generation</span>
          </div>
          <div class="col-10 d-flex justify-content-end" style="padding: 0px;" >
            <span style="margin-right: 5px; margin-left: auto;" *ngIf="agesTotal.bb > 0">
              <svg width="8" height="8" viewBox="0 0 8 8">
                <circle r="2.5" cx="2.5" cy="2.5" stroke-width="12" fill="#33afd5"></circle>
              </svg> 
              <span>Baby Boomer</span>
            </span>

            <span style="margin-right: 5px;" *ngIf="agesTotal.gx > 0">
              <svg width="8" height="8" viewBox="0 0 8 8">
                <circle r="2.5" cx="2.5" cy="2.5" stroke-width="12" fill="#f89f15"></circle>
              </svg> 
              <span>Gen X</span>
            </span>
            
            <span style="margin-right: 5px;" *ngIf="agesTotal.ml > 0">
              <svg width="8" height="8" viewBox="0 0 8 8">
                <circle r="2.5" cx="2.5" cy="2.5" stroke-width="12" fill="#7269de"></circle>
              </svg> 
              <span>Millennial</span>
            </span>

            <span style="margin-right: 5px;" *ngIf="agesTotal.gz > 0">
              <svg width="8" height="8" viewBox="0 0 8 8">
                <circle r="2.5" cx="2.5" cy="2.5" stroke-width="12" fill="#a41e5d"></circle>
              </svg> 
              <span>Gen Z</span>
            </span>
            
            <span style="margin-right: 5px;" *ngIf="agesTotal.unknown > 0">
              <svg width="8" height="8" viewBox="0 0 8 8">
                <circle r="2.5" cx="2.5" cy="2.5" stroke-width="12" fill="#2948e3"></circle>
              </svg> 
              <span>Unknown</span>
            </span>
          </div>
        </div>
        <div class="row w-100 ml-0" style="font-size: 11px;">
          <svg height="20" width="100%">
            <line x1="5" y1="10" x2="98%" y2="10" style="stroke:#E5E5E5;stroke-width:10" stroke-linecap="round"></line>
            <line x1="6" y1="10" [attr.x2]="ageAxes[0]" y2="10" style="stroke:#33afd5;stroke-width:6" stroke-linecap="round" *ngIf="agesTotal.bb > 0" [matTooltip]="'Baby Boomer ' + getPercent(agesTotal.bb)"></line>
            <line [attr.x1]="ageAxes[0]" y1="10" [attr.x2]="ageAxes[1]" y2="10" style="stroke:#f89f15;stroke-width:6" stroke-linecap="round" *ngIf="agesTotal.gx > 0" [matTooltip]="'Gen X ' + getPercent(agesTotal.gx)" ></line>
            <line [attr.x1]="ageAxes[1]" y1="10" [attr.x2]="ageAxes[2]" y2="10" style="stroke:#7269de;stroke-width:6" stroke-linecap="round" *ngIf="agesTotal.ml > 0" [matTooltip]="'Millennial ' + getPercent(agesTotal.ml)"></line>
            <line [attr.x1]="ageAxes[2]" y1="10" [attr.x2]="ageAxes[3]" y2="10" style="stroke:#a41e5d;stroke-width:6" stroke-linecap="round" *ngIf="agesTotal.gz > 0" [matTooltip]="'Gen Z ' + getPercent(agesTotal.gz)"></line>
            <line [attr.x1]="ageAxes[3]" y1="10" [attr.x2]="ageAxes[4]" y2="10" style="stroke:#2948e3;stroke-width:6" stroke-linecap="round" *ngIf="agesTotal.unknown > 0" [matTooltip]="'Unknown ' + getPercent(agesTotal.unknown)" ></line>
            <!-- <circle cx="5" r="5" cy="0"></circle> -->
          </svg>
        </div>
      </div>
    </div>

    <div class="row ml-1 mr-1" *ngIf="showQuestions">
      <div class="col-12 d-flex" style="background-color: white; padding: 5px;
      margin: 8px 0px;
      min-height: 92px;
      flex-flow: column; 
      border: 1px solid #f0f0f0;
      border-radius: 3px;
      box-shadow: 1px 1px 3px 0 rgb(0 0 0 / 20%);">
        <div class="row w-100 ml-0 pt-2" style="font-size: 11px;">
          <div class="col-8 p-0 d-flex justify-content-start">
            <span style="font-size: 0.9rem;">Meeting had a purpose?</span>
          </div>
          <div class="col-4 p-0 d-flex justify-content-start answer-block">
            <div class="col-4 p-0 d-flex justify-content-center answer-option answer-left" style="border-right: 1px solid #c5c5c5;" [ngClass]="agendaAnswer == 0 ? 'selected-answer':''" (click)="answerAgenda(0)"><span>YES</span></div>
            <div class="col-4 p-0 d-flex justify-content-center answer-option" style="border-right: 1px solid #c5c5c5;" [ngClass]="agendaAnswer == 1 ? 'selected-answer':''" (click)="answerAgenda(1)"><span>IDK</span></div>
            <div class="col-4 p-0 d-flex justify-content-center answer-option answer-right" [ngClass]="agendaAnswer == 2 ? 'selected-answer':''" (click)="answerAgenda(2)"><span>NO</span></div>
          </div>
        </div>
        <div class="row w-100 ml-0 pt-2" style="font-size: 11px;">
          <div class="col-8 p-0 d-flex justify-content-start">
            <span style="font-size: 0.9rem;">Meeting was effective?</span>
          </div>
          <div class="col-4 p-0 d-flex justify-content-start answer-block">
            <div class="col-4 p-0 d-flex justify-content-center answer-option answer-left" style="border-right: 1px solid #c5c5c5;" [ngClass]="effectiveAnswer == 0 ? 'selected-answer':''" (click)="answerEffective(0)"><span>YES</span></div>
            <div class="col-4 p-0 d-flex justify-content-center answer-option" style="border-right: 1px solid #c5c5c5;" [ngClass]="effectiveAnswer == 1 ? 'selected-answer':''" (click)="answerEffective(1)"><span>IDK</span></div>
            <div class="col-4 p-0 d-flex justify-content-center answer-option answer-right" [ngClass]="effectiveAnswer == 2 ? 'selected-answer':''" (click)="answerEffective(2)"><span>NO</span></div>
          </div>
        </div>
        <div class="row w-100 ml-0 pt-2 pb-1" style="font-size: 11px;">
          <div class="col-8 p-0 d-flex justify-content-start">
            <span style="font-size: 0.9rem;">Meeting was inclusive?</span>
          </div>
          <div class="col-4 p-0 d-flex justify-content-start answer-block">
            <div class="col-4 p-0 d-flex justify-content-center answer-option answer-left" style="border-right: 1px solid #c5c5c5;" [ngClass]="inclusiveAnswer == 0 ? 'selected-answer':''" (click)="answerInclusive(0)"><span>YES</span></div>
            <div class="col-4 p-0 d-flex justify-content-center answer-option" style="border-right: 1px solid #c5c5c5;" [ngClass]="inclusiveAnswer == 1 ? 'selected-answer':''" (click)="answerInclusive(1)"><span>IDK</span></div>
            <div class="col-4 p-0 d-flex justify-content-center answer-option answer-right" [ngClass]="inclusiveAnswer == 2 ? 'selected-answer':''" (click)="answerInclusive(2)"><span>NO</span></div>
          </div>
        </div>
      </div>
    </div>

    <div class="row ml-1 mr-1 justify-content-center">
      <img src="../../../assets/tariy-logo.png" alt="" class="w-50">
    </div>

  </div>
</section>
<section *ngIf="!inMeeting">
  <div class="container-md h-100">
    <div class="row general-info mb-2 pl-5" style="margin-top: 4rem; font-size: 1rem;">

      <div class="row w-100 mt-3">
        <span style="font-size: 2rem;">Getting started with TARIY</span>
      </div>
      <div class="row w-100 mt-3">
        <span>To start using the TARIY app during your zoom meeting, first you need to locate the "Apps" button in the bottom of your zoom client during a meeting and press it, 
          as shown in the image below.</span>
      </div>

      <div class="row justify-content-center w-100 mt-3" style="text-align: center;">
        <div style="height: fit-content;">
          <img [src]="'../../../assets/zoom-app/open.png'" alt="" style="width: 80%">
        </div>
      </div>

      <div class="row w-100 mt-3">
        <span>A side panel with a list of your installed apps will open. Locate the TARIY app either in "Recently Opened" or "My Apps", click on it and the app will open,
           as show in image below.</span>
      </div>

      <div class="row justify-content-start w-100 mt-3 mb-3" >
        <div style="height: fit-content;">
          <img [src]="'../../../assets/zoom-app/apps.png'" alt="" style="width: 80%">
        </div>
      </div>
    </div>

    <div class="row general-info mb-2 pl-5" style="margin-top: 4rem; font-size: 1rem;">
      <div class="row w-100 mt-3">
        <span style="font-size: 2rem;">TARIY app</span>
      </div>

      <div class="row justify-content-start w-100 mt-3" >
        <div style="height: fit-content;">
          <img [src]="'../../../assets/zoom-app/tariy.png'" alt="" style="width: 80%">
        </div>
      </div>

      <div class="row w-100 mt-1">
        <span>The TARIY app is divided in several sections, which will be further explained below.</span>
      </div>

      <div class="row w-100 mt-1 mb-3">
        <ul>
          <li>Header: Shows the time remaining</li>
          <li>Meeting Score&reg;: Shows the current score obtained in the meeting</li>
          <li>Alerts: Shows the alerts regarding the current meeting</li>
          <li>Participants: Shows a list of participants and the talk-time percentage</li>
          <li>Interaction: Shows some interactions that occured during the meeting</li>
          <li>Gender breakdown: Shows a percentage of talk-time by gender </li>
        </ul>
      </div>
    </div>

    <div class="row general-info mb-2 pl-5" style="margin-top: 4rem; font-size: 1rem;">
      <div class="row w-100 mt-3">
        <span style="font-size: 2rem;">Header</span>
      </div>

      <div class="row justify-content-start w-100 mt-3" >
        <div style="height: fit-content;">
          <img [src]="'../../../assets/zoom-app/header.png'" alt="" style="width: 80%">
        </div>
      </div>

      <div class="row w-100 mt-1">
        <span>The header, as shown in the image above, is divided in 3 sections:</span>
      </div>

      <div class="row w-100 mt-1 mb-3">
        <ol>
          <li>Sends an invitation to use the app to all the participants</li>
          <li>Redirects to a website with more information regarding TARIY</li>
          <li>Shows the time remaining in the meeting, obtained either from the scheduled meeting or from TARIY recommended duration</li>
        </ol>
      </div>
    </div>

    <div class="row general-info mb-2 pl-5" style="margin-top: 4rem; font-size: 1rem;">
      <div class="row w-100 mt-3">
        <span style="font-size: 2rem;">Meeting Score&reg;</span>
      </div>

      <div class="row justify-content-start w-100 mt-3" >
        <div style="height: fit-content;">
          <img [src]="'../../../assets/zoom-app/score.png'" alt="" style="width: 80%">
        </div>
      </div>

      <div class="row w-100 mt-1">
        <span>The score section, as shown in the image above, is divided in 2 sections:</span>
      </div>

      <div class="row w-100 mt-1 mb-3">
        <ol>
          <li>Shows the current in-meeting score&reg;(Different from the complete meeting score obtained in the TARIY website)</li>
          <li>Shows an icon regarding the behavior of score
            <ul>
              <li>Downward pointing arrow: Means the score is lowering</li>
              <li>Minus icon: Means the score is staying the same</li>
              <li>Upwards pointing arrow: Means the score is improving</li>
            </ul>
          </li>
        </ol>
      </div>
    </div>

    <div class="row general-info mb-2 pl-5" style="margin-top: 4rem; font-size: 1rem;">
      <div class="row w-100 mt-3">
        <span style="font-size: 2rem;">Alerts</span>
      </div>

      <div class="row justify-content-start w-100 mt-3" >
        <div style="height: fit-content;">
          <img [src]="'../../../assets/zoom-app/alerts.png'" alt="" style="width: 80%">
        </div>
      </div>

      <div class="row w-100 mt-1">
        <span>The alerts section, as shown in the image above, is divided in 3 sections:</span>
      </div>

      <div class="row w-100 mt-1 mb-3">
        <ol>
          <li>Click on it to show the previous alert (only shows up when there are more than 1 alert) </li>
          <li>Click on it to show the next alert (only shows up when there are more than 1 alert) </li>
          <li>Shows an alert regarding the current meeting, which can be one of the following
            <ul>
              <li>5 min remaining: Shows up when the meeting only has 5 minutes remaining or less</li>
              <li>Meeting time over: Shows up when the meeting duration is above the scheduled duration</li>
              <li>Monologue warning: Shows up when a participant has entered a monologue</li>
              <li>Max interruptions: Shows up when a meeting has reached the maximum number of recommended interruptions</li>
              <li>Low participation: Shows up when a participant has a participation below the recommended</li>
              <li>No participation: Shows up when a participant hasn't talk during the meeting</li>
            </ul>
          </li>
        </ol>
      </div>
    </div>

    <div class="row general-info mb-2 pl-5" style="margin-top: 4rem; font-size: 1rem;">
      <div class="row w-100 mt-3">
        <span style="font-size: 2rem;">Participants</span>
      </div>

      <div class="row justify-content-start w-100 mt-3" >
        <div style="height: fit-content;">
          <img [src]="'../../../assets/zoom-app/participants.png'" alt="" style="width: 80%">
        </div>
      </div>

      <div class="row w-100 mt-1">
        <span>The participants section, as shown in the image above, is divided in 5 sections:</span>
      </div>

      <div class="row w-100 mt-1 mb-3">
        <ol>
          <li>Show the participant name</li>
          <li>Shows the participant gender
            <ul>
              <li>F: Female</li>
              <li>M: Male</li>
              <li>NB: Non-Binary</li>
              <li>UNK: Unknown</li>
            </ul>
          </li>
          <li>Show the number of times a participant has entered a monologue (above 1min during a One-on-One meeting or 1:30 min in a meeting)</li>
          <li>Show a visual representation of the participant talk-time</li>
          <li>Shows the participation percentage of a participant</li>
        </ol>
      </div>
    </div>

    <div class="row general-info mb-2 pl-5" style="margin-top: 4rem; font-size: 1rem;">
      <div class="row w-100 mt-3">
        <span style="font-size: 2rem;">Interactions</span>
      </div>

      <div class="row justify-content-start w-100 mt-3" >
        <div style="height: fit-content;">
          <img [src]="'../../../assets/zoom-app/interactions.png'" alt="" style="width: 80%">
        </div>
      </div>

      <div class="row w-100 mt-1">
        <span>The interactions section, as shown in the image above, is divided in 2 sections:</span>
      </div>

      <div class="row w-100 mt-1 mb-3">
        <ol>
          <li>Interruptions: Shows the number of interruptions that have occured during the meeting</li>
          <li>Monologues: Shows the number of monologues that have occured during the meeting</li>
        </ol>
      </div>
    </div>

    <div class="row general-info mb-2 pl-5" style="margin-top: 4rem; font-size: 1rem;">
      <div class="row w-100 mt-3">
        <span style="font-size: 2rem;">Gender Breakdown</span>
      </div>

      <div class="row justify-content-start w-100 mt-3" >
        <div style="height: fit-content;">
          <img [src]="'../../../assets/zoom-app/breakdown.png'" alt="" style="width: 80%">
        </div>
      </div>

      <div class="row w-100 mt-1">
        <span>The gender breakdown section, as shown in the image above, shows a breakdown by gender of how the percentage of participation by gender:</span>
      </div>
    </div>
  </div>
</section>




