
<section class="account-settings">
    <div class="container-fluid p-lg-4 p-xl-4">
        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="body d-flex justify-content-center" style="font-size: 1.75rem; flex-flow: column;">
                        <span style="text-align: center;">Thanks for installing the TARIY Zoom app.</span>
                        <br>
                        <span style="text-align: center;">Open Zoom and start having more inclusive & productive meetings.</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="row ml-1 mr-1 justify-content-center">
            <img src="../../../assets/tariy-logo.png" alt="" class="w-25">
        </div>
    </div>
</section>
