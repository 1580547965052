<nav class="navbar navbar-expand-lg bg-traiy-blue" [style.background-color]="bgcolor">
  <div class="header-buttons">
    <a class="navbar-brand" [routerLink]="['/']">
      <img src="../../../assets/img/tariy-header.png" alt="" class="main-logo d-inline-block align-top">
    </a>
    <button class="navbar-toggler" type="button" (click)="toggleMenu()" aria-controls="navbarText" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon">
        <fa-icon icon="bars" class="fa-lg"></fa-icon>
      </span>
    </button>
  </div>
  <div class="collapse navbar-collapse" id="navbarText" [ngbCollapse]="isCollapsed">
    <div class="d-flex align-items-center d-lg-none d-xl-none pl-4 pb-2">
      <img [src]="userInfo.picture" class="img-fluid mr-2 mobile-user" *ngIf="validPicture" (error)="invalidPicture()">
      <img src="../../../assets/img/no-picture-user.png" class="img-fluid mr-2 mobile-user no-photo" *ngIf="!validPicture">
      <p class="username">Hi,&nbsp;<b>{{ userInfo.firstname }}!</b></p>
      <mat-slide-toggle [(ngModel)]="userMode" (change)="setUserMode($event)" class="justify-content-end ml-4 toggle-text" *ngIf="userInfo.role != roles.Waiting && userInfo.role != roles.Member && userInfo.role != roles.ITAdmin">{{texty}}</mat-slide-toggle>
    </div>
    <hr>

    <!-- Member Menu version movil -->
    
    
    <ul class="navbar-nav mr-auto  d-block d-lg-none d-xl-none" *ngIf="userInfo.role == roles.Member" (click)="toggleMenu()">
      <li class="nav-item" routerLinkActive="active-link" [routerLinkActiveOptions]="{exact: true}">
        <a class="nav-link" [routerLink]="['/member']">Dashboard <span class="sr-only">(current)</span></a>
        <hr>
      </li>
      <li class="nav-item" routerLinkActive="active-link">
        <a class="nav-link" [routerLink]="['events']">Events</a>
      </li>
      <li class="nav-item" routerLinkActive="active-link">
        <a class="nav-link" [routerLink]="['insights']">Insights</a>
        <hr>
      </li>
      <li class="nav-item" routerLinkActive="active-link">
        <a class="nav-link" [routerLink]="['nudges']">Nudges</a>
      </li>
      <li class="nav-item" routerLinkActive="active-link">
        <a class="nav-link" [routerLink]="['reports']">Reports</a>
        <hr>
      </li>
      <li class="nav-item" routerLinkActive="active-link" (click)="changeHelp();$event.stopPropagation()" [style]="showHelp ? 'box-shadow: 0px 5px 12px -14px rgb(0 0 0 / 50%);':''">
        <span class="nav-link" >Help <span *ngIf="showHelp">&#9650;</span><span *ngIf="!showHelp">&#9660;</span></span>
      </li>
      <li class="nav-item" routerLinkActive="active-link" *ngIf="showHelp" (click)="openNewTab('https://tariy.canny.io/');$event.stopPropagation()">
        <span class="nav-link" >Give Feedback</span>
      </li>
      <li class="nav-item" routerLinkActive="active-link" *ngIf="showHelp" (click)="openNewTab('https://intercom.help/tariy/en/');$event.stopPropagation()">
        <span class="nav-link" >Help Center</span>
      </li>
      <li class="nav-item" routerLinkActive="active-link">
        <a class="nav-link" (click)="logout()">Logout</a>
      </li>
    </ul>

    <!-- Admin and Manager Menu version movil -->
    <ul class="navbar-nav mr-auto  d-block d-lg-none d-xl-none" *ngIf="userInfo.role != roles.Member" (click)="toggleMenu()">
      <li class="nav-item" routerLinkActive="active-link" [routerLinkActiveOptions]="{exact: true}">
        <a class="nav-link" [routerLink]="['/admin']">Dashboard <span class="sr-only">(current)</span></a>
      </li>
      <li class="nav-item" routerLinkActive="active-link">
        <a class="nav-link" [routerLink]="['events']">Events</a>
      </li>
      <li class="nav-item" routerLinkActive="active-link" *ngIf="userInfo.role != roles.ITAdmin">
        <a class="nav-link" [routerLink]="['my-team']">My Team</a>
      </li>
      <li class="nav-item" routerLinkActive="active-link">
        <a class="nav-link" [routerLink]="['insights']">Insights</a>
        <hr>
      </li>
      <!-- <li class="nav-item" routerLinkActive="active-link">
        <a class="nav-link" [routerLink]="['reports']">Reports</a>
        <hr>
      </li> -->
      <li class="nav-item" routerLinkActive="active-link">
        <a class="nav-link" [routerLink]="['ai-training']">AI Training</a>
        <hr>
      </li>
      <li class="nav-item" routerLinkActive="active-link">
        <a class="nav-link" [routerLink]="['nudges']">Nudges</a>
      </li>
      <li class="nav-item" routerLinkActive="active-link" *ngIf="userInfo.role == roles.Admin || userInfo.role == roles.ITAdmin">
        <a class="nav-link" [routerLink]="['settings']">Settings</a>
      </li>
      <li class="nav-item" routerLinkActive="active-link" *ngIf="userInfo.role != roles.ITAdmin">
        <a class="nav-link" [routerLink]="['users']" >Users</a>
      </li>
      <li class="nav-item" routerLinkActive="active-link" (click)="changeHelp();$event.stopPropagation()" [style]="showHelp ? 'box-shadow: 0px 5px 12px -14px rgb(0 0 0 / 50%);':''">
        <span class="nav-link" >Help <span *ngIf="showHelp">&#9650;</span><span *ngIf="!showHelp">&#9660;</span></span>
      </li>
      <li class="nav-item" routerLinkActive="active-link" *ngIf="showHelp" (click)="openNewTab('https://tariy.canny.io/');$event.stopPropagation()">
        <span class="nav-link" >Give Feedback</span>
      </li>
      <li class="nav-item" routerLinkActive="active-link" *ngIf="showHelp" (click)="openNewTab('https://intercom.help/tariy/en/');$event.stopPropagation()">
        <span class="nav-link" >Help Center</span>
      </li>
      <li class="nav-item" routerLinkActive="active-link">
        <a class="nav-link" (click)="openProfile()">Profile</a>
      </li>
      <li class="nav-item" routerLinkActive="active-link">
        <a class="nav-link" (click)="logout()">Logout</a>
      </li>
    </ul>
    <mat-slide-toggle [(ngModel)]="userMode" (change)="setUserMode($event)" class="toggle-text" *ngIf="userInfo.role != roles.Waiting && userInfo.role != roles.Member && userInfo.role != roles.ITAdmin">{{texty}}</mat-slide-toggle>
    <ul class="navbar-nav justify-content-end ml-4 account d-none d-lg-block d-xl-block">
      <li class="nav-item d-flex align-items-center">
        <img [src]="userInfo.picture" class="img-fluid mr-2 profile" *ngIf="validPicture" (error)="invalidPicture()">
        <img src="../../../assets/img/no-picture-user.png" class="img-fluid mr-2 profile no-photo" *ngIf="!validPicture">
          Hi,&nbsp;<b>{{ userInfo.firstname }}!</b>
         <div ngbDropdown>
          <a class="nav-link" tabindex="0" ngbDropdownToggle id="navbarDropdown2" role="button">
            <fa-icon icon="chevron-down" class="fa-xs"></fa-icon>
          </a>
          <div ngbDropdownMenu aria-labelledby="navbarDropdown2" class="dropdown-menu dropdown-menu-right">
            <ul class="p-0">
              <li style="list-style: none; border-bottom: 1px solid #cecece;">
                <a ngbDropdownItem (click)="openProfile()" class="d-flex justify-content-between" role="button">
                  <span>Profile</span>
                </a>
              </li>
              <li style="list-style: none;">
                <a ngbDropdownItem href="#" (click)="logout()" class="d-flex justify-content-between">
                  <span>Logout</span>
                  <img src="../../../../assets/img/sign-out.svg" alt="">
                </a>
              </li>
            </ul>
          </div>
        </div>
      </li>
    </ul>

  </div>
</nav>