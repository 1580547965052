<div class="card">
  <div class="header">
    <div class="title text-center" style="height:50%; border-bottom: 1px solid #e5e5e5;">Score Details</div>
    <div class="title col-6 text-center d-inline-flex justify-content-center pt-1" style="height:50%">Parameter</div>
    <div class="title col-6 text-center d-inline-flex justify-content-center pt-1" style="height:50%">Percentage</div>
  </div>
  <div class="body">
    <div class="row" *ngFor="let score of scoreDefinition" style="height:{{columnHeight}}%">
      <div class="col-6 pl-4 d-flex align-items-center">{{score.key}}</div>
      <div class="col-6 d-flex align-items-center justify-content-center">{{score.value}}%</div>
    </div>
  </div>
</div>
