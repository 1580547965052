import { Component, OnDestroy } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, ActivationEnd, ChildActivationEnd, NavigationEnd,  Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { filter, first, map, take } from 'rxjs/operators';

@Component({
  selector: 'app-panel',
  templateUrl: './panel.component.html',
  styleUrls: ['./panel.component.scss']
})
export class PanelComponent{
  // Depending on page route settings, sidebar and header will be shown
  hideLayout = false;
  

  constructor(public router: Router) {
    this.router.events
      .pipe(filter(event => event instanceof ActivationEnd), first())
      .subscribe( (ev: ActivationEnd) => {
        if (ev.snapshot.data['hideLayout']) {
          this.hideLayout = true;
        }
    });
  }
}
