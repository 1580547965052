import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-member-panel',
  templateUrl: './member-panel.component.html',
  styleUrls: ['./member-panel.component.scss']
})
export class MemberPanelComponent implements OnInit {
  hideLayout = false;
  constructor() { }

  ngOnInit(): void {
  }

}
